import React, { useState } from "react";
import { Card, Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import ProductsNumbersTable from "../../../Components/Storage/ProductsNumbers/Table";
import productsApi from "../../../apis/products/api";
import { productsActions } from "../../../redux/slices/products";
import { useSearch } from "../../../hooks/useSearch";
import PageContainer from "../../../Components/Layout/PageContainer";

const ProductsNumbers = () => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const searchFunc = async (query) => {
    setLoading(true);
    const { data } = await productsApi.products.getAll(query);
    dispatch(productsActions.setData({ 
      field: 'products',
      data: data.data, 
      total: data.meta?.total
     }));
    setLoading(false);
  }

  const { products: { list: products, total } } = useSelector((state) => state.products);

  const { searchValues, handleChange, setPageSize, navigateToPage, onSort } = useSearch({
    name: 'productsNumbers',
    cachedValues: {
      order_by: 'created_at',
      order_value: 'desc'
    },
    debouncedValues: {
      matricule: "",
    },
    searchFunc
  });

  return (
      <PageContainer>

        <BreadCrumb
          title="Product Numbers"
          pageTitle="Storage"
          to="/dashboard/storage"
        />

        <Card>
          <ProductsNumbersTable
              data={products}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              filters={searchValues}
              handleFilterChange={handleChange}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              onSort={onSort}
              sortDirection={searchValues.order_value}
              fetching={loading}
              path="/dashboard/products"
          />
        </Card>
        
      </PageContainer>
  );
};

export default ProductsNumbers;
