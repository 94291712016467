import React, { useEffect } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { SLIDER_PERMISSIONS } from "../../../permissions/home";
import SlidersTable from "../../../Components/Settings/Sliders/Table";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import Restricted from "../../../Components/Guard/Restricted";
import { useTranslation } from "react-i18next";
import { deleteSettingsDataAction, getAllSettingsDataAction } from "../../../redux/actions/settings";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";

const HomeSliders = () => {
  
  const { t } = useTranslation();

  const {
    callback,
    itemsToDelete,
    deleteModal,
    isDeleting,
    setItemsToDelete,
    setDeleteModal,
    load,
  } = useDeleteMultiple();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSettingsDataAction({
      field: 'homeSliders'
    }));
  }, [dispatch]);

  const removeSlider = () => {
    load();
    const ids = itemsToDelete.selectedRows?.map((item) => item.id);
    dispatch(deleteSettingsDataAction({ 
      field: 'homeSliders',
      data: {
        ids
      },
      onSuccess: callback
     }));
  };

  return (
      <PageContainer>

        <BreadCrumb pageTitle="Settings" title="Home Sliders" />

        <div className="hstack gap-2 mb-4">

          <Link to="home-sliders/create">
            <CustomButton
              className="btn-success"
            >
              <i className="ri-add-line align-bottom me-1"></i>
              Create
            </CustomButton>
          </Link>

          <Restricted permissions={SLIDER_PERMISSIONS.delete}>
            <CustomButton
              className="btn-danger"
              disabled={!itemsToDelete.selectedRows?.length > 0}
              onClick={() => setDeleteModal(true)}
            >
              <i className="ri-delete-bin-line align-bottom me-1"></i>
              Delete
            </CustomButton>
          </Restricted>

        </div>

        <Card>
          <SlidersTable
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDelete={removeSlider}
            isDeleting={isDeleting}
          />
        </Card>

      </PageContainer>
  );
};

export default HomeSliders;
