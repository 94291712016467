import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import createHistorySchema from "../../../helpers/validation/Users/createHistoryValidation";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserHistoryBody,
  updateUserHistoryBody,
} from "../../../helpers/RequestBodies/Users/historyRequestBody";
import { useGetValues } from "../../../hooks/useCreateUpdateModal";
import { setFormErrors } from "../../../helpers/setFormErrors";
import { useHistory } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import Select from "react-select";
import CustomTextarea from "../../Custom/CustomTextarea";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import { useTranslation } from "react-i18next";
import { createUsersDataAction, getAllUsersDataAction, updateUsersDataAction } from "../../../redux/actions/users";
import SaveButton from "../../Common/Buttons/SaveButton";

const HistoryForm = ({ type, initialValues }) => {

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(createHistorySchema),
    defaultValues: initialValues,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useGetValues({ currentValues: initialValues, setValue, clearErrors });

  const { t } = useTranslation();

  const submitForm = (data) => {

    setLoading(true);

    if (type === "create") {
      const requestBody = createUserHistoryBody(data);

      dispatch(
        createUsersDataAction({
          field: 'history',
          data: requestBody,
          onSuccess: () => history.push("/dashboard/users/history"),
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    } else if (type === "update") {

      const requestBody = updateUserHistoryBody(data);
      dispatch(
        updateUsersDataAction({
          field: 'history',
          data: { 
            id: initialValues?.id, 
            data: requestBody 
          },
          onSuccess: () => setLoading(false),
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    }
  };

  const { users: { list: users }, statuses: { list: statuses } } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(
      getAllUsersDataAction({
        field: 'users',
        query: { 
          page_size: 'all' 
        }
      })
    );
    dispatch(getAllUsersDataAction({
      field: 'statuses',
    }));
  }, [dispatch]);

  return (
      <form onSubmit={handleSubmit(submitForm)}>
        <Card>
          <CardBody>
            <Input type="hidden" id="id-field" />
            <Row className="g-3">
              <Col lg={6}>
                <div>
                  <CustomLabel
                    htmlFor="history_company-field"
                    className="form-label"
                  >
                    User
                  </CustomLabel>
                  {!!users.length && (
                    <Controller
                      name="user_id"
                      control={control}
                      render={({ name, field: { onChange } }) => (
                        <Select
                          name={name}
                          onChange={(val) => {
                            onChange(val.value);
                          }}
                          isDisabled={type === "update"}
                          defaultValue={initialValues?.displayUser}
                          options={users.map((user) => ({
                            label: `${user.first_name} ${user.last_name}`,
                            value: user.id,
                          }))}
                        />
                      )}
                    />
                  )}
                  {errorMessage(errors, "user_id")}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <CustomLabel
                    htmlFor="history_status-field"
                    className="form-label"
                  >
                    Status
                  </CustomLabel>
                  <select
                    {...register("status")}
                    name="status"
                    className="form-select"
                  >
                    {statuses.map((status) => (
                      <CustomOption key={status.id} value={status.id}>
                        {status.name}
                      </CustomOption>
                    ))}
                  </select>
                  {errorMessage(errors, "status")}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <CustomLabel
                    htmlFor="history-note-field"
                    className="form-label"
                  >
                    Description
                  </CustomLabel>
                  <CustomTextarea
                    name="description"
                    rows={3}
                    id="zone_note-field"
                    className="form-control"
                    placeholder="Enter Note"
                    {...register("description")}
                  />
                  {errorMessage(errors, "description")}
                </div>
              </Col>
            </Row>

            <div className="mt-2 hstack gap-2 justify-content-end">
              <SaveButton />
            </div>

          </CardBody>
 
        </Card>

      </form>
  );
};

export default HistoryForm;
