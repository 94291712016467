import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import FileManagerAsideFolder from "./FileManagerAsideFolder";
import { useFileManager } from "../../../context/FileManagerContext";
import CustomButton from "../../Common/CustomButton";
import FileManagerFileItemList from "./FileManagerFileItemList";
import FileManagerFileDetails from "./FileManagerFileDetails";
import CoverSpinner from "../../Common/CoverSpinner";
import useUltimateFilter, { FILTER_TYPES } from "../../../hooks/useUltimateFilter";
import CustomInput from "../../Custom/CustomInput";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import { useAuthState } from "../../../context/authContext";
import { isSuperAdmin } from "../../../helpers/isSuperAdmin";

const FileManagerWrapper = ({ canSelectFor }) => {

  const { t } = useTranslation();

  const {
    files: allFiles,
    createNewFolder,
    uploadNewFile,
    deleteFiles,
    fetchFiles,
    updateFolderName,
  } = useFileManager();

  const [selectedFolder, setSelectedFolder] = useState({
    id: 0,
    name: "Root Folder",
    isDirectory: true,
    items: [],
  });

  const { adminData } = useAuthState();

  const [modalFolder, setModalFolder] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const [file, setFile] = useState(null);

  const [fileQuery, setFileQuery] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const items = useUltimateFilter(selectedFolder.items, {
    name: {
      key: "name",
      value: fileQuery,
      type: FILTER_TYPES.MATCH,
    },
  });

  const [sidebarData, setSidebarData] = useState("");

  const [dublicatedNameError, setDublicatedNameError] = useState(null);

  // const [sidebarOpen, setSidebarOpen] = useState(false)

  const [isDirectorySubmitting, setIsDirectorySubmitting] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const [isDeletingFolder, setIsDeletingFolder] = useState(false);

  const [isDeletingFile, setIsDeletingFile] = useState(false);

  const [newFolderName, setNewFolderName] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  function sidebarOpen(value) {
    const element = document.getElementsByTagName("body")[0];
    element.classList.add(value);
  }

  const fileToggle = () => {
    setModalFile((current) => !current);
  };

  const addNewFolder = async () => {
    if (!newFolderName) return;

    setIsDirectorySubmitting(true);

    try {
      if (isEdit) {
        await updateFolderName(selectedFolder.id, newFolderName);
      } else {
        await createNewFolder({
          parent_id: selectedFolder.id,
          isDirectory: true,
          name: newFolderName,
        });
      }
      setModalFolder(false);
      setNewFolderName("");
    } catch (error) {
      setDublicatedNameError(
        error?.response?.data?.error?.name[0] || "Something went wrong"
      );
    } finally {
      setIsDirectorySubmitting(false);
    }
  };

  function sidebarClose(value) {
    const element = document.getElementsByTagName("body")[0];
    element.classList.remove(value);
  }

  const handleUploadFile = async () => {
    if (!file) return;
    setIsFileUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("active", true);
    if (selectedFolder.id !== 0) {
      formData.append("parent", selectedFolder.id);
      // requestBody.parent = selectedFolder.id;
    }
    try {
      const { data: uploadedFile } = await uploadNewFile(formData);
      setModalFile(false);
      setSelectedFolder((current) => {
        return {
          ...current,
          items: [...current.items, uploadedFile],
        };
      });
    } finally {
      setIsFileUploading(false);
    }
  };

  const deleteFile = async (ids, multiple) => {
    setIsDeletingFile(true);
    await deleteFiles(ids);
    setIsDeletingFile(false);
    setSelectedFolder((current) => {
      return {
        ...current,
        items: current.items.filter((item) => {
          if (ids.includes(item.id)) return false;
          return true;
        }),
      };
    });

    if (multiple) {
      setSelectedFiles([]);
    }
  };

  const deleteFolder = async (id) => {
    setIsDeletingFolder(true);
    await deleteFiles([id]);
    setIsDeletingFolder(false);
    setSelectedFolder({
      id: 0,
      name: "Root Folder",
      isDirectory: true,
      items: [],
    });
  };

  const handleFolderClicks = (isEdit) => {
    if (isEdit) {
      setIsEdit(true);
      setNewFolderName(selectedFolder.name);
      setDublicatedNameError(null);
    } else {
      setIsEdit(false);
      setNewFolderName("");
      setDublicatedNameError(null);
    }
    setModalFolder(true);
  };

  const renderFolders = (allFiles) => {
    return allFiles
      .filter((file) => {
        return file.isDirectory === true;
      })
      .map((folder) => (
        <FileManagerAsideFolder
          key={folder.id}
          {...folder}
          selectedFolderID={selectedFolder.id}
          renderFolders={renderFolders}
          setSelectedFolder={setSelectedFolder}
        />
      ));
  };

  const selectAllFiles = (e) => {
    if (e.target.checked) {
      setSelectedFiles(selectedFolder.items.map((item) => item.id));
    } else {
      setSelectedFiles([]);
    }
  };

  useEffect(() => {
    setSelectedFiles([]);
  }, [selectedFolder.id]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fileSidebar = () => {
    document.getElementById("folder-overview").style.display = "none";
    document.getElementById("file-overview").style.display = "block";
  };

  return (
    <>
      <div className="file-manager-wrapper- chat-wrapper d-lg-flex gap-1 mx-n4 p-1">
        <div className="file-manager-sidebar">
          <div className="p-3 d-flex flex-column">
            <div className="mb-3">
              <h5 className="mb-0 fw-bold">{t("My Drive")}</h5>
            </div>
            <div className="search-box">
              <CustomInput
                type="search"
                className="form-control bg-light border-light"
                placeholder="Search here..."
                value={fileQuery}
                onChange={(e) => setFileQuery(e.target.value)}
              />
              <i className="ri-search-2-line search-icon"></i>
            </div>
            <SimpleBar className="mt-3 mx-n4 px-4 file-menu-sidebar-scroll">
              <ul className="list-unstyled file-manager-menu">
                {renderFolders(allFiles)}
              </ul>
            </SimpleBar>
          </div>
        </div>
        <div className="file-manager-content w-100 p-3 py-0">
          <SimpleBar className="mx-n3 pt-4 px-4 file-manager-content-scroll">
            <div id="folder-list" className="mb-2">
              <Row className="justify-content-beetwen g-2 mb-4">
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2 d-block d-lg-none">
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="fs-17 mb-0">{selectedFolder.name}</h5>
                    </div>
                  </div>
                </Col>
                <Col className="col-auto">
                  <div className="d-flex gap-2 align-items-start">
                    {selectedFolder.id !== 0 && (
                      <>
                        {isSuperAdmin(adminData) && 
                          <CustomButton
                            className="btn btn-danger btn-sm text-nowrap create-folder-modal"
                            onClick={() => deleteFolder(selectedFolder.id)}
                            isLoading={isDeletingFolder}
                          >
                            <i className="ri-delete-bin-line align-bottom me-1"></i>{" "}
                            {t("Delete Folder")}
                          </CustomButton>
                        }

                        <button
                          className="btn btn-primary btn-sm text-nowrap create-folder-modal"
                          onClick={() => handleFolderClicks(true)}
                        >
                          <i className="ri-pencil-line align-bottom me-1"></i>
                          {t("Edit Folder")}
                        </button>
                      </>
                    )}

                    <button
                      className="btn btn-success btn-sm text-nowrap create-folder-modal"
                      onClick={() => handleFolderClicks()}
                    >
                      <i className="ri-add-line align-bottom me-1"></i>{" "}
                      {t("Create Folder")}
                    </button>
                  </div>
                </Col>
              </Row>

            </div>
            <div>
              <div className="d-flex align-items-center mb-3">
                <h5 className="flex-grow-1 fs-17 mb-0" id="filetype-title">
                  {t("Files")}
                </h5>
                {selectedFolder.id !== 0 && (
                  <div className="flex-shrink-0 d-flex gap-2">
                    {(selectedFiles.length > 0 && isSuperAdmin(adminData)) && (
                      <button
                        className="btn btn-danger btn-sm createFile-modal"
                        onClick={() => deleteFile(selectedFiles, true)}
                      >
                        <i className="ri-delete-bin-line align-bottom me-1"></i>{" "}
                        {t("Delete Files")}
                      </button>
                    )}
                    <button
                      className="btn btn-primary btn-sm createFile-modal"
                      onClick={() => setModalFile(true)}
                    >
                      <i className="ri-upload-line align-bottom me-1"></i>{" "}
                      {t("Upload File")}
                    </button>
                  </div>
                )}
              </div>
              <div className="table-responsive">
                {selectedFolder.items.length > 0 ? (
                  <table
                    className="table align-middle table-nowrap mb-0"
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <thead className="table-active">
                      <tr>
                        {isSuperAdmin(adminData) && 
                          <th scope="col">
                            <input
                              type="checkbox"
                              id="select-files"
                              checked={
                                selectedFiles.length ===
                                selectedFolder.items.length
                              }
                              onChange={selectAllFiles}
                            />
                          </th>
                        }
                        <th scope="col">{t("Name")}</th>
                        {/* <th scope="col">File Item</th> */}
                        <th scope="col">{t("File Size")}</th>
                        {/* <th scope="col">Recent Date</th> */}
                        <th scope="col" className="text-center">
                          {t("Actions")}
                        </th>
                      </tr>
                    </thead>

                    <tbody id="file-list">
                      {items.map((item, key) => (
                        <FileManagerFileItemList
                          key={item.id}
                          item={item}
                          setSidebarData={setSidebarData}
                          canSelectFor={canSelectFor}
                          selectedFiles={selectedFiles}
                          setSelectedFiles={setSelectedFiles}
                          fileSidebar={fileSidebar}
                          sidebarOpen={sidebarOpen}
                          deleteFile={() => deleteFile([item.id])}
                        />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <>
                    <div className="d-flex justify-content-center">
                      <span>{t("No Files to show")}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </SimpleBar>
        </div>

        <FileManagerFileDetails
          sidebarClose={sidebarClose}
          file={sidebarData}
        />
      </div>

      <Modal
        className="fade zoomIn"
        isOpen={modalFolder}
        toggle={() => setModalFolder(!modalFolder)}
        id="createFolderModal"
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader
          className="p-3 bg-light"
          id="createFolderModalLabel"
          toggle={() => setModalFolder(!modalFolder)}
        >
          {t(isEdit ? "Folder Rename" : "Create Folder")}
        </ModalHeader>

        <ModalBody>
          <form
            autoComplete="off"
            className="needs-validation createfolder-form"
            id="createfolder-form"
          >
            <div className="mb-4">
              <CustomLabel htmlFor="foldername-input" className="form-label">
                Folder Name
              </CustomLabel>
              <CustomInput
                type="text"
                className="form-control"
                id="foldername-input"
                name="folderName"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
              />
              {dublicatedNameError && (
                <div className="invalid-feedback d-block">
                  {t(dublicatedNameError)}
                </div>
              )}
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-danger"
                onClick={() => setModalFolder(false)}
              >
                <i className="ri-close-line align-bottom"></i> {t("Close")}
              </button>
              <CustomButton
                className="btn btn-primary"
                id="addNewFolder"
                type="button"
                onClick={addNewFolder}
                isLoading={isDirectorySubmitting}
              >
                {isEdit ? "Save" : "Add Folder"}
              </CustomButton>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* File Modal */}
      <Modal
        id="createFileModal"
        isOpen={modalFile}
        toggle={fileToggle}
        modalClassName="zoomIn"
        centered
        tabIndex="-1"
      >
        <ModalHeader toggle={fileToggle} className="p-3 bg-soft-success">
          {t(!!isEdit ? "File Rename" : "Upload File")}
        </ModalHeader>
        <ModalBody>
          <form
            className="needs-validation createfile-form"
            id="createfile-form"
          >
            <div className="mb-4">
              <Input
                className="form-control"
                type="file"
                id="formFileMultiple"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </div>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-ghost-danger"
                onClick={() => setModalFile(false)}
              >
                <i className="ri-close-line align-bottom"></i> {t("Close")}
              </button>
              <CustomButton
                type="button"
                className="btn btn-primary"
                onClick={handleUploadFile}
                isLoading={isFileUploading}
              >
                Upload
              </CustomButton>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <CoverSpinner loading={isDeletingFile} />
    </>
  );
};

export default FileManagerWrapper;
