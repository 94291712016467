const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "products",
  initialState: {
    products: {
        list: [],
        total: 0
    },
    offers: {
        list:  [],
        total: 0
    },
    categories: {
        list: [],
        total: 0
    },
    promoCamps: {
        list: [],
        total: 0
    },
    locations: {
        list: [],
        total: 0,
    },
    measurementUnits: {
        list: [],
        total: 0
    },
    vats: {
        list: [],
        total: 0
    },
    vendors: {
        list: [],
        total: 0
    },
    codebars: {
        list: [],
        total: 0
    },
    problems: {
        list: [],
        total: 0
    },
  },
  reducers: {
    setData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          list: payload.data,
          total: payload.total,
        };
      }
    },

    createData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: [...state[payload.field].list, payload.data],
        };
      }
    },

    updateData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: state[payload.field].list.map((item) =>
            item.id === payload.id ? payload.data : item
          ),
        };
      }
    },

    deleteData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          total: state[payload.field].total - payload.data.ids.length,
          list: state[payload.field].list.filter(
            (item) => !payload.data.ids.includes(item.id)
          ),
        };
      }
    },
  },
});

export const productsActions = slice.actions;

export default slice.reducer;
