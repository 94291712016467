import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../Common/DataTable";
import CustomInput from "../../Custom/CustomInput";
import StatusToggle from "../../Common/StatusToggle";

const MeasurementUnitsTable = ({
  filters,
  handleFilterChange,
  ...props
}) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },
    {
      name:         
      <CustomInput
        className="form-control"
        placeholder="Name"
        value={filters.name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        })}
        data-filtered={!!filters.name}
      />,
      selector: (row) => row.name,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: row => <StatusToggle type="units" id={row.id} field="status" status={row.status} />,
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default MeasurementUnitsTable;
