import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CategoriesTable from "../../../Components/Products/Categories/Table";
import { useDispatch, useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import Restricted from "./../../../Components/Guard/Restricted";
import { PRODUCT_CATEGORIES_PERMISSIONS } from "./../../../permissions/products";
import { deleteProductsDataAction, getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const Categories = () => {
  
  const { callback, deleteModal, isDeleting, itemsToDelete, load, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const { categories: { list: categories, total } } = useSelector((state) => state.products);

  const [loading, setLoading] = useState(true);

  const searchFunc = async (query) => {
    if (!loading) setLoading(true);
    dispatch(
      getAllProductsDataAction({
        field: 'categories',
        query,
        onSuccess: () => setLoading(false),
      })
    );
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'categories',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const dispatch = useDispatch();

  const handleDelete = () => {
    load();
    const ids = itemsToDelete.selectedRows.map((item) => item.id);
    dispatch(
      deleteProductsDataAction({
        field: 'categories',
        data: {
          ids,
        },
        onSuccess() {
          callback();
          search();
        },
      })
    );
  };

  return (
    <PageContainer>

        <BreadCrumb
          title="Categories"
          pageTitle="Products"
          to="/dashboard/products"
        />

        <div className="hstack gap-2">
          
          <Restricted permissions={PRODUCT_CATEGORIES_PERMISSIONS.create}>
            <Link to="/dashboard/products/categories/create">
              <CustomButton 
                className="btn-success" 
              >
                <i className="ri-add-fill me-1 align-bottom"></i>
                Create
              </CustomButton>
            </Link>
          </Restricted>

          <Restricted permissions={PRODUCT_CATEGORIES_PERMISSIONS.delete}>
            <CustomButton
              className="btn-danger"
              disabled={!itemsToDelete.selectedRows?.length > 0}
              onClick={() => setDeleteModal(true)}
            >
              <i className="ri-delete-bin-line me-1 align-bottom"></i>
              Delete
            </CustomButton>
          </Restricted>

          <Link to="/dashboard/products/categories/sort">
            <CustomButton 
              className="btn-primary"
            >
              <i className="ri-list-unordered me-1 align-bottom"></i>
              Sort
            </CustomButton>
          </Link>

        </div>

        <Card className="mt-3">
          <CategoriesTable
              data={categories}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              handleDelete={handleDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              sortDirection={searchValues.order_value}
              fetching={loading}
              isDeleting={isDeleting}
              path="/dashboard/products/categories"
          />
        </Card>

    </PageContainer>
  );
};

export default Categories;
