import React, { useEffect } from "react";
import { Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import StorageTable from "../../../Components/Storage/Storage/Table";
import { getAllStorageDataAction } from "../../../redux/actions/storage";
import PageContainer from "../../../Components/Layout/PageContainer";

const Storage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStorageDataAction({
      field: 'storage'
    }));
  }, [dispatch]);

  return (
      <PageContainer>
        <BreadCrumb pageTitle="Storage" title="View" to="/dashboard/storage" />
        <Card>
          <StorageTable />
        </Card>
      </PageContainer>
  );
};

export default Storage;
