import axiosInstance from "../axios";

const ordersApi = {

    orders: {

        getAll: (query) => {
            return axiosInstance.get(`/orders`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/orders/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/orders`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/orders/${id}`, data);
        },

        merge: (data) => {
            return axiosInstance.post(`/orders/merge`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/orders`, {
                data
            });
        },

        sendOrderEmail: (id) => {
            return axiosInstance.post('/orders/email', {
                "order_id" : id
            })
        },

        products: {
            
            create:  (data) => {
                return axiosInstance.post('/orderitems', data);
            },

            update: (id, data) => {
                return axiosInstance.post(`/orderitems/${id}`, data);
            },

            updateMultiple: (data) => {
                return axiosInstance.post(`/updateMultipleItems`, data);
            },

            refresh: (id) => {
                return axiosInstance.post(`/orderitems/refresh/${id}`);
            },
            
            delete: (data) => {
                return axiosInstance.delete(`/orderitems`, {
                    data
                });
            },
        },

        status: {
            update: (data) => {
                return axiosInstance.post(`/orders/status`, data)
            },
        },

        details: {
            update: (id, data) => {
                return axiosInstance.post(`/orders/${id}/details`, data)
            }
        },

        transport: {

            get: (id) => {
                return axiosInstance.get(`/order/${id}/order_details`)
            },

            update: (id, data) => {
                return axiosInstance.post(`/order/${id}/order_details`, data)
            },

            sendPdfToMail: (id, data) => {
                return axiosInstance.post(`/order/${id}/order_details/mail`, data)
            },
        }

    },

    deliveryMethods: {

        getAll: (query) => {
            return axiosInstance.get(`/deliverytypes`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/deliverytypes/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/deliverytypes`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/deliverytypes/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/deliverytypes`, {
                data
            });
        },
    },

    priceOffers: {

        getAll: (query) => {
            return axiosInstance.get(`/order/offerprices`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/order/offerprices/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/order/offerprices`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/order/offerprices/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/order/offerprices`, {
                data
            });
        },

        copy: (data) => {
            return axiosInstance.post(`order/offerprices/user`, data);
        },

        toOrder: (data) => {
            return axiosInstance.post(`order/offerprices/order`, data);
        },

        sendToEmail: (id) => {
            return axiosInstance.post(`order/offerprices/mail/${id}`);
        },

        products: {

            create:  (data) => {
                return axiosInstance.post('/order/offer_price_products', data)
            },

            update: (id, data) => {
                return axiosInstance.post(`/order/offer_price_products/${id}`, data)
            },

            updateMultiple: (data) => {
                return axiosInstance.post(`/order/offer_price_products_multiple`, data);
            },

            delete: (data) => {
                return axiosInstance.delete('/order/offer_price_products', {
                    data
                })
            }
        }

    },
    
    newsletters: {

        getAll: (query) => {
            return axiosInstance.get(`/order/newsletters`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/order/newsletters/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/order/newsletters`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/order/newsletters/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/order/newsletters`, {
                data
            });
        },

        products: {
 
            create:  (data) => {
                return axiosInstance.post(`/order/newsletter/product`, data)
            },
    
            update: (id, data) => {
                return axiosInstance.post(`/order/newsletter/product/${id}`, data)
            },

            updateMultiple: (data) => {
                return axiosInstance.post(`/order/newsletter/product_multiple`, data);
            },
    
            delete: (data) => {
                return axiosInstance.delete('/order/newsletter/product', {
                    data
                })
            }
        },

        email: {

            getHistory: (params) => {
                return axiosInstance.get(`/email_history`, {
                    params
                })
            },

            sendToEmail: (id) => {
                return axiosInstance.post(`/order/newsletters/mail/${id}`)
            }
        }
    },

    settings: {
  
        get: (type) => {
            return axiosInstance.get(`/settings/order/${type}`);
        },

        update: (data) => {
            return axiosInstance.post(`/settings`, data);
          },
    },

    statuses: {
        getAll: (query) => {
            return axiosInstance.get(`/orderstatus`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/orderstatus/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/orderstatus`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/orderstatus/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/orderstatus`, {
                data
            });
        },
    },

    transports: {

        getAll: (query) => {
            return axiosInstance.get(`/deliveries`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/deliveries/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/deliveries`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/deliveries/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/deliveries`, {
                data
            });
        },
    },

    units: {
                
        getAll: (query) => {
            return axiosInstance.get(`/orderunits`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/orderunits/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/orderunits`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/orderunits/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/orderunits`, {
                data
            });
        },
    }

}

export default ordersApi