import calculateTotalPrice from "./calculateTotalPrice";

export const calculatePercentage = (sellingPrice, purchasePrice) => {
    const diff = sellingPrice - purchasePrice;
    const percentage = (+diff * 100) / +purchasePrice;
    return percentage.toFixed(2);
};

export const calculateFullPercentage = (sellingPrice, purchasePrice) => {
  const diff = sellingPrice - purchasePrice;
  const percentage = (+diff * 100) / +purchasePrice;
  return percentage;
};

export const extractQuantityBasedonUnit = (current) => {
  if (current.product_unit?.name?.toLowerCase() === 'kg' && +current.weight) return +current.weight
  return +current.qty
}

export const calculatePrices = ({ order }) => {

    if (!order) return {
        sellingPrice: 0,
        purchasePrice: 0,
        percentage: 0,
        diff: 0,
    };

    let list = [];

    if (order?.merged) {
      Object.values(order.items).forEach((items) => {
        items.filter(item => !item.no_stock).forEach((item) => {
          list.push(item);
        });
      });
    } else {
        list = order.items.filter(item => !item.no_stock);
    }

    const purchasePrice = list.reduce((prev, current) => {
      let price = +calculateTotalPrice(+current.price_purchase, 0, extractQuantityBasedonUnit(current));
      return price + prev;
    }, 0);

    const netPrice = list.reduce((prev, current) => {
      let price = +calculateTotalPrice(+current.price, 0, extractQuantityBasedonUnit(current));
      return price + prev;
    }, 0);

    const sellingPrice = list.reduce((prev, current) => {
      let price = +calculateTotalPrice(+current.price, +current.promo, extractQuantityBasedonUnit(current));
        return price + prev;
    }, 0);

    const netExtraCost = +order.delivery_price * +order.delivery_qty || 0;

    const extraCost = (+order.delivery_price * +order.delivery_qty) + (+order.delivery_qty * +order.delivery_price) * 0.25 || 0;

    return {
      purchasePrice: (purchasePrice + extraCost).toFixed(2),
      netPrice: netPrice.toFixed(2),
      percentage: calculatePercentage(sellingPrice, purchasePrice),
      fullPercentage: calculateFullPercentage(sellingPrice, purchasePrice),
      netSellingPrice: (sellingPrice + +netExtraCost).toFixed(2),
      sellingPrice: (sellingPrice + +extraCost).toFixed(2),
      discountPrice: (netPrice - sellingPrice).toFixed(2),
      diff: (sellingPrice - purchasePrice).toFixed(2),
      extraCost: Number(extraCost).toFixed(2),
      netExtraCost: Number(netExtraCost).toFixed(2)
    };
  };
