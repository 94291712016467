import React, { useState } from "react";
import { Card, Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PriceOffersTable from "../../../Components/Orders/PriceOffers/Table";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersDataAction, deleteOrdersDataAction } from "../../../redux/actions/orders";
import CustomButton from "../../../Components/Common/CustomButton";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import NewPriceOfferModal from "../../../Components/Orders/PriceOffers/Modal";
import { useTranslation } from "react-i18next";
import { useSearch } from "../../../hooks/useSearch";
import PageContainer from "../../../Components/Layout/PageContainer";

const PriceOffers = () => {

  const { priceOffers: { list: priceOffers, total } } = useSelector((state) => state.orders);
  
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { load, callback, deleteModal, isDeleting, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const { create, modalType, update } = useCreateUpdateModal({
    company: {
      value: "",
      label: "",
    },
    note: "",
  });

  const searchFunc = (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllOrdersDataAction({
      field: 'priceOffers',
      query,
      onSuccess: () => setLoading(false),
    }));
  };
  
  const { searchValues, setPageSize, navigateToPage, onSort, search } = useSearch({
    name: 'priceOffers',
    searchFunc
  });

  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  const openCreateModal = () => {
    create();
    setModalOpen(true);
  };

  const openUpdateModal = (row) => {
    update({
      id: row.id,
      company: {
        value: row.user.id,
        label:
          row.user.profile?.company ||
          `${row.user.first_name} ${row.user.last_name}`,
      },
      note: row.note,
    });
    setModalOpen(true);
  };
  
  const handleDelete = () => {
    load();
    dispatch(deleteOrdersDataAction({ 
      field: 'priceOffers',
      data: {
        ids: itemsToDelete.selectedRows.map((item) => item.id)
      },
      onSuccess() {
        callback();
        search();
      }
     }));
  };

  return (
      <PageContainer>

        <BreadCrumb
          pageTitle="Orders"
          title="Price Offers"
          to="/dashboard/orders"
        />

        <NewPriceOfferModal
          modalType={modalType}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
        />

        <div className="hstack gap-2">
          <CustomButton
            onClick={openCreateModal}
            className="btn-success"
          > 
            Create
          </CustomButton>
          <CustomButton
            className="btn-danger"
            disabled={!itemsToDelete.selectedRows?.length > 0}
            onClick={() => setDeleteModal(true)}
          >
            <i className="ri-delete-bin-line align-bottom me-1"></i> 
            {t("Delete")}
          </CustomButton>
        </div>

        <Card className="mt-3">
          <PriceOffersTable
            data={priceOffers}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            loading={loading}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            isDeleting={isDeleting}
            handleDelete={handleDelete}
            openUpdateModal={openUpdateModal}
            selectableRows
            itemsToDelete={itemsToDelete}
            filters={searchValues}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            sortDirection={searchValues.order_value}
            fetching={loading}
            onSort={onSort}
            toggleModal={toggleModal}
          />
        </Card>
        
      </PageContainer>
  );
};

export default PriceOffers;
