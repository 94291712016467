import React, { useEffect, useState } from "react";
import NewOrderModal from "../../../Components/Orders/Orders/CreateOrder/Modal";
import OrdersTable from "../../../Components/Orders/Orders/Table";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import { objectFilter } from "../../../helpers/objectFilter";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { deleteOrdersDataAction, getAllOrdersDataAction } from "../../../redux/actions/orders";
import PageContainer from "../../../Components/Layout/PageContainer";
import Restricted from "../../../Components/Guard/Restricted";
import CustomButton from "../../../Components/Common/CustomButton";
import toastAlert from "../../../helpers/toastAlert";
import ordersApi from "../../../apis/orders/api";
import { isSuperAdmin } from "../../../helpers/isSuperAdmin";
import { useAuthState } from "../../../context/authContext";
import { ORDER_PERMISSIONS } from "../../../permissions/orders";
import { Card } from "reactstrap";
import { useSearch } from "../../../hooks/useSearch";

const Orders = () => {
  
  const [modalOpen, setModalOpen] = useState(false);

  const [isMerging, setIsMerging] = useState(false);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { adminData } = useAuthState();

  const { load, callback, deleteModal, isDeleting, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const searchFunc = (query) => {
    if ((query.date_from && !query.date_to) || (!query.date_from && query.date_to)) return;
    if (!loading) setLoading(true);
    dispatch(
      getAllOrdersDataAction({
        field: 'orders',
        query: objectFilter({
          ...query,
          for: 'op'
        }),
        onSuccess: () => setLoading(false),
      })
    );
  };

  const { searchValues, handleChange, handleMultipleChanges, setPageSize, navigateToPage, onSort, search } = useSearch({
    name: 'orders',
    cachedValues: {
      company_id: "",
      status_id: "",
      delivery_type_id: "",
      date_from: "",
      date_to: "",
      order_by: "created_at",
      order_value: "desc",
    },
    debouncedValues: {
      name: '',
      matricule: ''
    },
    searchFunc
  });

  const { create, modalType, update } = useCreateUpdateModal({
    user_company_id: "",
    delivery_type_id: "",
    delivery_id: "",
    delivered_at: "",
  });

  const openModalCreate = () => {
    create();
    setModalOpen(true);
  };

  const { orders: { list: orders, total } } = useSelector(state => state.orders);

  const openModalUpdate = (value) => {
    const {
      company,
      delivery_type,
      delivery,
      delivered_at,
      current_status,
      id,
      payment,
    } = value;
    
    update({
      id,
      user_company_id: company.id,
      user_company_name: company?.profile?.company || `${company?.first_name} ${company?.last_name}` || "",
      delivery_type_id: delivery_type?.id || "",
      delivery_id: delivery?.id || "",
      delivered_at: new Date(delivered_at),
      current_status_id: current_status?.id || "",
      payment: payment || "",
    });
    setModalOpen(true);
  };

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  const handleDelete = () => {
    load();
    dispatch(
      deleteOrdersDataAction({
        field: 'orders',
        data: {
          ids: itemsToDelete?.selectedRows?.map((item) => item.id)
        },
        onSuccess: () => {
          callback();
          search();
        },
      })
    );
  };

  const canMergeOrders = () => {
    const items = itemsToDelete?.selectedRows;

    if (!items) return false;

    if (items?.length < 2) return false;

    const firstID = items[0]?.company?.id;

    for (let i = 1; i < items.length; i++) {
      const itemID = items[i]?.company?.id;

      if (itemID && firstID && itemID !== firstID) {
        return false;
      }
    }

    return true;
  };

  const canMerge = canMergeOrders();

  const onMergeClick = async () => {
    setIsMerging(true);
    const ids = itemsToDelete?.selectedRows?.map((item) => item.id);
    try {
      await ordersApi.orders.merge({
        ids
      })
      setItemsToDelete([]);
      search();
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      setIsMerging(false);
    }
  };

  useEffect(() => {

    dispatch(getAllOrdersDataAction({
      field: 'deliveryMethods',
      query: {
        page_size: 'all'
      }
    }))

    dispatch(getAllOrdersDataAction({
      field: 'statuses',
      query: {
        page_size: 'all'
      }
    }))

  }, []);

  return (
      <PageContainer>
        
        <NewOrderModal
          modalType={modalType}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
        />

        <BreadCrumb title="View" pageTitle="Orders" to="/dashboard/orders" />

        <div className="hstack gap-2">

            <Restricted permissions={ORDER_PERMISSIONS.create}>
              <CustomButton
                className="btn-success"
                onClick={openModalCreate}
              >
                Create
              </CustomButton>
            </Restricted>

            {isSuperAdmin(adminData) && 
              <Restricted permissions={ORDER_PERMISSIONS.delete}>
                <CustomButton
                  className="btn-danger"
                  disabled={!+itemsToDelete.selectedRows?.length}
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </CustomButton>
              </Restricted> 
            }

            <CustomButton
              className="btn-primary"
              isLoading={isMerging}
              onClick={onMergeClick}
              disabled={!canMerge}
            >
              Merge
            </CustomButton>

          </div>
  
        <Card className="mt-3">
          <OrdersTable
            data={orders}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            selectableRows={isSuperAdmin(adminData)}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            setDeleteModal={setDeleteModal}
            filters={searchValues}
            handleFilterChange={handleChange}
            handleMultipleFilterChanges={handleMultipleChanges}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            sortDirection={searchValues.order_value}
            fetching={loading}
            onSort={onSort}
            openModalCreate={openModalCreate}
            openModalUpdate={openModalUpdate}
            toggleModal={toggleModal}
            deleteModal={deleteModal}
            handleDelete={handleDelete}
            isDeleting={isDeleting}
          />
        </Card>

      </PageContainer>
  );
};

export default Orders;
