import { objectFilter } from "../../objectFilter"

export const createUserBody = (data) => {
    return objectFilter({
        ...data,
        is_company: data.status === 'company',
        is_supplier: false,
        is_client: data.status === 'individual',
      })
}

export const updateUserBody = (data) => {
    return objectFilter({
        first_name: data.first_name,
        last_name: data.last_name,
        promo: data.promo,
        email: data.email,
        send_invitation: data.send_invitation,
        is_company: data.status === 'company',
        is_client: data.status === 'individual',
        is_supplier: false,
        active: data.active,
        password: data.password,
      })
}


export const userProfileBody = (data) => {
    return objectFilter({
      ...data,
      user_group_id: data.group_id
    })
}

export const userAddressBody = (data) => {
    return objectFilter({
      ...data,
    })
}