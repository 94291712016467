import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { createTransportSchema } from "../../../helpers/validation/Orders/createTransport";
import { useDispatch } from "react-redux";
import CustomLabel from "../../Custom/CustomLabel";
import { createOrdersDataAction, updateOrdersDataAction } from "../../../redux/actions/orders";
import SaveButton from "../../Common/Buttons/SaveButton";

const TransportForm = ({ type, initialValues }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(createTransportSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const submitForm = async (data) => {
    setIsLoading(true);
    if (type === "create") {
      dispatch(
        createOrdersDataAction({
          field: 'transports',
          data,
          onSuccess: () => history.push("/dashboard/orders/transports"),
        })
      );
    } else {
      dispatch(
        updateOrdersDataAction({
          field: 'transports',
          data: {
            id: initialValues.id,
            data,
          },
          onSUccess: () => setIsLoading(false),
        })
      );
    }
  };


  return (
    <div>
      <Col md={12}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card className="p-3">

            <Row className="gx-4 gy-3">

              <Col md={6}>
                <CustomLabel for="name">Name</CustomLabel>
                <input
                  name="name"
                  {...register("name")}
                  className="form-control"
                  id="inputName"
                />
                {errorMessage(errors, "name")}
              </Col>

              <Col md={6}>
                <CustomLabel for="email">Email</CustomLabel>
                <input
                  name="email"
                  {...register("email")}
                  type="email"
                  className="form-control"
                  id="inputName"
                />
                {errorMessage(errors, "email")}
              </Col>

              <Col md={6}>
                  <div className="form-check form-switch">
                    <input 
                      {...register('status')} 
                      className="form-check-input fs-16" 
                      type="checkbox" 
                      role="switch" 
                      id="status" 
                    />
                    <CustomLabel 
                      className="form-check-label" 
                      htmlFor="status"
                    >
                      Active
                    </CustomLabel>
                  </div>
              </Col>

            </Row>
            
          </Card>


          <div className="d-flex justify-content-end">
            <SaveButton
              isLoading={isLoading}
            />

          </div>

        </form>
      </Col>
    </div>
  );
};

export default TransportForm;
