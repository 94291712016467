import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import usersApi from "../../../apis/users/api";
import ZoneForm from "../../../Components/Users/Zones/Form";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateZone = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const {isLoading, isRefetching} = useQuery(["zones", id], () => usersApi.zones.getOne(id), {
    onSuccess(data) {
      const { data: response } = data;
      setInitialValues({
        ...response.data,
        delivery_days: response.data.delivery_days.map((day) => {
          return {
            ...day,
            time: new Date(day.time).toLocaleTimeString(),
          };
        }),
      });
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Zones" title="Update" to="/dashboard/users/zones" />
      {initialValues && 
        <ZoneForm 
          type="update" 
          initialValues={initialValues} 
        />
      }
    </PageContainer>
  );
};

export default UpdateZone;
