import moment from "moment";

export const formatDate = (date) => {
    return moment(date).format('DD MMM YYYY, HH:mm')
}

export const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date(new Date().setHours(+hours, +minutes));
    return formatDate(date)
}
