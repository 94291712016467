import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextarea from "../../Custom/CustomTextarea";
import Files from "./Files";

const ProblemModal = ({ open, toggleModal, details }) => {
  const { t } = useTranslation();

  return (
    <Modal modalClassName="zoomIn" size="l" isOpen={open} toggle={toggleModal} centered>
      
      <ModalHeader className="bg-light p-3" toggle={toggleModal}>
        {t("Details")}
      </ModalHeader>

      <ModalBody>
        <Row className="gy-4">
          <Col lg={12}>
            <CustomLabel>Added By</CustomLabel>
            <CustomInput
              className="form-control"
              value={details.user}
              onChange={() => {}}
              disabled
            />
          </Col>

          <Col lg={12}>
            <CustomLabel>Description</CustomLabel>
            <CustomTextarea
              className="form-control"
              type="textarea"
              onChange={() => {}}
              value={details.description}
              disabled
              rows={3}
            />
          </Col>

          <Col lg={12}>
            <Files files={details.files} />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ProblemModal;
