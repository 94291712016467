import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import ControlTable from "../../../Components/Storage/Control/Table";
import { useDispatch, useSelector } from "react-redux";
import productsApi from "../../../apis/products/api";
import { productsActions } from "../../../redux/slices/products";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useSearch } from "../../../hooks/useSearch";

const StorageControl = () => {

  const [loading, setLoading] = useState(true);
  
  const dispatch = useDispatch();

  const { products: { list: products, total } } = useSelector((state) => state.products);

  const searchFunc = async (query) => {
    setLoading(true);
    const { data } = await productsApi.products.getAll({
      ...query,
      for: 'control'
    });
    dispatch(productsActions.setData({ 
      field: 'products',
      data: data.data, 
      total: data.meta?.total
     }));
    setLoading(false);
  }

  const { searchValues, handleChange, setPageSize, navigateToPage, onSort } = useSearch({
    name: 'storageControl',
    cachedValues: {
      order_by: 'created_at',
      order_value: 'desc'
    },
    debouncedValues: {
      name: "",
    },
    searchFunc
  });

  return (
      <PageContainer>

        <BreadCrumb
          pageTitle="Storage"
          title="Control"
          to="/dashboard/storage"
        />

        <ControlTable
            data={products}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            filters={searchValues}
            handleFilterChange={handleChange}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            onSort={onSort}
            sortDirection={searchValues.order_value}
            fetching={loading}
            path="/dashboard/products"
        />

      </PageContainer>
  );
};

export default StorageControl;
