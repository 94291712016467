import { objectFilter } from "../../objectFilter"

export const createProductBody = (data) => {

    return objectFilter({
        ...data,
        user_groups: data.groups,
        taxes: [data.taxes],
        tax: data.taxes,
        no_price: false,
        no_group: data.groups?.length === 0,
        no_codebar: false,
        no_category: false,
        is_main_publish: JSON.parse(data.is_main_publish),
        has_promo: false,
        units: data.units.map(unit => unit.id),
      })
}

export const createVarsBody = (data) => {

  return objectFilter({
      ...data,
      qty: data.total_qty,
      tax: data.tax,
      current_status: data.status,
    })
}
