import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getTrackingData } from "../apis/trackingApi";

export default function useTracking(record, id) {

    const [trackings, setTracking] = useState([])

    const {refetch} = useQuery(['trackings', record, id], () => getTrackingData(record, id), {
        onSuccess(data) {
            
            const { data: response } = data.data
            // const result = response.map(item => {
            //     return {
            //         ...item,
            //     }
            // })
            setTracking(response)
             
        }
    })

    return {
        trackings,
        refetch
    }
}