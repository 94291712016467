import React, { useState } from 'react'

const FileManagerAsideFolder = ({ name, id, items, renderFolders, selectedFolderID, setSelectedFolder }) => {

    const [expended, setExpended] = useState(false);

    const onFolderClick = (e) => {
      e.preventDefault();
      setExpended(current => !current);

      setSelectedFolder({
        name, id, isDirectory: true, items: items.filter(item => item.isDirectory === false)
      })
    }

    return (
      <li>
        <a
            data-bs-toggle="collapse"
            href="#!"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            role="button"
            aria-expanded={selectedFolderID === id}
            aria-controls="collapseExample"
          >
            <div className='d-flex justify-content-between'>
              <div className='get-items-folder' onClick={onFolderClick}>
                <i className="ri-folder-2-line align-bottom me-2"></i>{" "}
                <span className="file-list-link">{name}</span>
              </div>
              {/* <i className="ri-more-line cursor-pointer"></i> */}
            </div>
          </a>
          {
            (expended || id === 0) && (
              <div className="collapse show" id="collapseExample">
                  <ul className="sub-menu list-unstyled">
                    {renderFolders(items)}
                  </ul>
              </div>
            )
          }
      </li>
    )
}

export default FileManagerAsideFolder