import { useEffect, useState } from "react";

export default function useDebounceValue({value, delay = 300, cb}) {
    const [newValue, setNewValue] = useState(value);

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (cb) {
                cb()
            }
            setNewValue(value)
        }, delay)

        return () => clearTimeout(timeout)
    }, [value])

    return newValue
}