import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useFileManager } from '../../../context/FileManagerContext'
import FileManagerWrapper from './FileManagerWrapper'
import { useTranslation } from 'react-i18next'

const FileManagerModal = () => {

    const { isFileManagerModalOpen, closeFileManagerModal } = useFileManager();

    const { t } = useTranslation();

    return (
        <Modal size="xl" isOpen={isFileManagerModalOpen} toggle={closeFileManagerModal} style={{
            width: '100%'
        }}>
            <ModalHeader className="bg-light text-capitalize p-3" toggle={closeFileManagerModal}>
                {t('File Manager')}
            </ModalHeader>
            
            <ModalBody>
                <FileManagerWrapper
                    canSelectFor={true}
                />
            </ModalBody>

        </Modal>
    )
}

export default FileManagerModal