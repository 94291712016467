import React, { useEffect } from "react";
import { Card, CardFooter, Col, Form, Row } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "./../../../helpers/validation/errorMessage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import PolicySchema from "../../../helpers/validation/WebSettings/PolicyValidation";
import { WEB_SETTINGS_PERMISSIONS } from "./../../../permissions/webSettings";
import Restricted from "./../../Guard/Restricted";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";
import SaveButton from "../../Common/Buttons/SaveButton";

const Policy = () => {

  const dispatch = useDispatch();

  const { policy } = useSelector((state) => state.settings.webSettings);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(PolicySchema),
  });

  const submitPolicy = (data) => {
    dispatch(
      setWebSettingsAction({
        field: 'policy',
        data: {
          type: "policy",
          data
        }
      })
    );
  };

  
  useEffect(() => {
    dispatch(getWebSettingsAction({
      field: 'policy',
    }));
  }, []);

  return (
    <Col lg={12}>
      <Card>
        <Form onSubmit={handleSubmit(submitPolicy)}>

            <Row className="g-3">
              <Col
                className="form-check form-switch"
              >
                <CustomInput
                  defaultChecked={policy.status}
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  {...register("status")}
                />
                <CustomLabel
                  className="form-check-label"
                  htmlFor="customSwitchsizemd"
                >
                  Active
                </CustomLabel>
                {errorMessage(errors, "status")}
              </Col>

              <Col
                lg={12}
              >
                <Controller
                  name="policy"
                  control={control}
                  render={({ name, field: { onChange } }) => (
                    <CKEditor
                      data={policy.policy !== null && policy.policy}
                      name={name}
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        onChange(editor.getData());
                      }}
                    />
                  )}
                />
                {errorMessage(errors, "policy")}
              </Col>
            </Row>

          <Restricted permissions={WEB_SETTINGS_PERMISSIONS.update}>
            <CardFooter className="mt-3">
              <div className="d-flex justify-content-end">
                  <SaveButton />
              </div>
            </CardFooter>
          </Restricted>

        </Form>
      </Card>
    </Col>
  );
};

export default Policy;
