import React, { useEffect } from "react";
import { Card } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ContactsTable from "../../../Components/Settings/Contacts/ContactsTable";
import { useSelector } from "react-redux";
import { getAllSettingsDataAction } from "../../../redux/actions/settings";
import PageContainer from "../../../Components/Layout/PageContainer";

const Contacts = () => {

  const dispatch = useDispatch();

  const { contacts: { list: contacts } } = useSelector(state => state.settings);

  useEffect(() => {
    dispatch(getAllSettingsDataAction({
      field: 'contacts'
    }));
  }, [dispatch]);

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Settings" title="Contacts" />
      <Card>
        <ContactsTable
          data={contacts}
        />
      </Card>
    </PageContainer>
  );
};

export default Contacts;
