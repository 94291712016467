import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// ! Home permissions
import * as ALL_HOME_PERMISSIONS from "../permissions/home";
// ! Websettings permissions
import * as ALL_WEBSETTINGS_PERMISSIONS from "../permissions/webSettings";
// ! Product permissions
import * as ALL_PRODUCTS_PERMISSIONS from "../permissions/products";
// ! User Permissions
import * as ALL_USER_PERMISSIONS from "../permissions/users";
// ! Orders permissions
import * as ALL_ORDER_PERMISSIONS from "../permissions/orders";
// ! Storage permissions
import * as ALL_STORAGE_PERMISSIONS from "../permissions/storage";
// ! Other permissions
import * as ALL_OTHER_PERMISSIONS from "../permissions/admins_roles";

const Navdata = () => {

  const history = useHistory();

  // ! Main Links
  const [isHome, setIsHome] = useState(false);
  const [isOrders, setIsOrders] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isStorage, setIsStorage] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isSettings, setIsSettings] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Home");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "home") {
      setIsHome(false);
    }
    if (iscurrentState !== "settings") {
      setIsSettings(false);
    }
    if (iscurrentState !== "orders") {
      setIsOrders(false);
    }
    if (iscurrentState !== "products") {
      setIsProducts(false);
    }
    if (iscurrentState !== "storage") {
      setIsStorage(false);
    }
    if (iscurrentState !== "users") {
      setIsUsers(false);
    }
  }, [history, isHome, isSettings, isOrders, isProducts, isStorage, isUsers]);

  const menuItems = [
    {
      id: "home",
      label: "Home",
      icon: "ri-home-8-line",
      link: "/dashboard",
      stateVariables: isHome,
      click: function (e) {
        e.preventDefault();
        setIsHome(!isHome);
        setIscurrentState("home");
        updateIconSidebar(e);
      },
    },

    {
      id: "orders",
      label: "Orders",
      icon: "ri-shopping-cart-2-line",
      link: "/#",
      stateVariables: isOrders,
      click: function (e) {
        e.preventDefault();
        setIsOrders(!isOrders);
        setIscurrentState("orders");
        updateIconSidebar(e);
      },
      permissions: {
        ...ALL_ORDER_PERMISSIONS,
      },
      subItems: [
        {
          id: "orders",
          label: "Orders",
          link: "/dashboard/orders",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_PERMISSIONS,
        },
        {
          id: "price-offers",
          label: "Price Offers",
          link: "/dashboard/orders/price-offers",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_PRICE_OFFERS_PERMISSIONS,
        },
        {
          id: "newsletter",
          label: "Newsletters",
          link: "/dashboard/orders/newsletters",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_NEWSLETTERS_PERMISSIONS,
        },
        {
          id: "statuses",
          label: "Statuses",
          link: "/dashboard/orders/statuses",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_STATUS_PERMISSIONS,
        },
        {
          id: "delivery-methods",
          label: "Delivery methods",
          link: "/dashboard/orders/delivery-methods",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_DELIVERY_TYPES_PERMISSIONS,
      
        },
        {
          id: "units",
          label: "Units",
          link: "/dashboard/orders/units",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_UNITS_PERMISSIONS,
        },
        {
          id: "transports",
          label: "Transports",
          link: "/dashboard/orders/transports",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_DELIVERY_PERMISSIONS,
        },
        {
          id: "settings",
          label: "Settings",
          link: "/dashboard/orders/settings",
          parentId: "orders",
          permissions: ALL_ORDER_PERMISSIONS.ORDER_SETTINGS_PERMISSIONS,
        },
      ],
    },

    {
      id: "products",
      label: "Products",
      icon: "ri-store-line",
      link: "/#",
      stateVariables: isProducts,
      click: function (e) {
        e.preventDefault();
        setIsProducts(!isProducts);
        setIscurrentState("products");
        updateIconSidebar(e);
      },
      permissions: {
        ...ALL_PRODUCTS_PERMISSIONS,
      },
      subItems: [
        {
          id: "products",
          label: "Products",
          link: "/dashboard/products",
          parentId: "products",
          permissions: ALL_PRODUCTS_PERMISSIONS.PRODUCT_PERMISSIONS,
        },
        {
          id: "offers",
          label: "Offers",
          link: "/dashboard/products/offers",
          parentId: "products",
          permissions: ALL_PRODUCTS_PERMISSIONS.PRODUCT_OFFER_PERMISSIONS,
        },
        {
          id: "categories",
          label: "Categories",
          link: "/dashboard/products/categories",
          parentId: "products",
          permissions: ALL_PRODUCTS_PERMISSIONS.PRODUCT_CATEGORIES_PERMISSIONS,
        },
        {
          id: "locations",
          label: "Locations",
          link: "/dashboard/products/locations",
          parentId: "products",
          permissions: ALL_PRODUCTS_PERMISSIONS.PRODUCT_LOCATION_PERMISSIONS,
        },
        {
          id: "measurement-units",
          label: "Measurement units",
          link: "/dashboard/products/measurement-units",
          parentId: "products",
          permissions: ALL_PRODUCTS_PERMISSIONS.PRODUCT_UNITS_PERMISSIONS,
        },
        {
          id: "vat",
          label: "VAT",
          link: "/dashboard/products/vat",
          parentId: "products",
          permissions: ALL_PRODUCTS_PERMISSIONS.PRODUCT_VAT_PERMISSIONS,
        },
        {
          id: "vendors",
          label: "Vendors",
          link: "/dashboard/products/vendors",
          parentId: "products",
          permissions: ALL_PRODUCTS_PERMISSIONS.PRODUCT_VENDORS_PERMISSIONS,
        },

        {
          id: "products-problems",
          label: "Problems",
          link: "/dashboard/products/problems",
          parentId: "products",
          permissions:
            ALL_PRODUCTS_PERMISSIONS.PRODUCT_INVALID_PRODUCTS_PERMISSIONS,
        },
        {
          id: "catalogue",
          label: "Catalogue",
          link: "/dashboard/products/catalogue",
          parentId: "products",
        },
      ],
    },

    {
      id: "storage",
      label: "Storage",
      icon: "ri-building-line",
      link: "/#",
      stateVariables: isStorage,
      click: function (e) {
        e.preventDefault();
        setIsStorage(!isStorage);
        setIscurrentState("storage");
        updateIconSidebar(e);
      },
      permissions: {
        ...ALL_STORAGE_PERMISSIONS,
      },
      subItems: [
        {
          id: "sub-storage",
          label: "Storage",
          link: "/dashboard/storage",
          parentId: "storage",
          permissions: ALL_STORAGE_PERMISSIONS.STORAGE_PERMISSIONS,
        },
        {
          id: "lot-movement",
          label: "Lot Movement",
          link: "/dashboard/storage/lot-movement",
          parentId: "storage",
          permissions: ALL_STORAGE_PERMISSIONS.LOT_MOVEMENT_PERMISSIONS,
        },
        {
          id: "new-stock",
          label: "New stock",
          link: "/dashboard/storage/new-stock",
          parentId: "storage",
          permissions: ALL_STORAGE_PERMISSIONS.NEW_STOCK_PERMISSIONS,
        },
        {
          id: "number-of-products",
          label: "Products Numbers",
          link: "/dashboard/storage/products-numbers",
          parentId: "storage",
          permissions: ALL_STORAGE_PERMISSIONS.PRODUCTS_NUMBERS_PERMISSIONS,
        },
        {
          id: "store-problems",
          label: "Problems",
          link: "/dashboard/storage/problems",
          parentId: "storage",
          permissions: ALL_STORAGE_PERMISSIONS.STORAGE_PROBLEMS_PERMISSIONS,
        },
        {
          id: "control",
          label: "Control",
          link: "/dashboard/storage/control",
          parentId: "storage",
          permissions: ALL_STORAGE_PERMISSIONS.STORAGE_CONTROL_PERMISSIONS,
        },
      ],
    },

    {
      id: "users",
      label: "Users",
      icon: "ri-user-3-line",
      link: "/#",
      stateVariables: isUsers,
      click: function (e) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("users");
        updateIconSidebar(e);
      },
      permissions: {
        ...ALL_USER_PERMISSIONS,
      },
      subItems: [
        {
          id: "users",
          label: "Users",
          link: "/dashboard/users",
          parentId: "users",
          permissions: ALL_USER_PERMISSIONS.USER_PERMISSIONS,
        },
        {
          id: "groups",
          label: "Groups",
          link: "/dashboard/users/groups",
          parentId: "users",
          permissions: ALL_USER_PERMISSIONS.USER_GROUP_PERMISSIONS,
        },
        {
          id: "zones",
          label: "Zones",
          link: "/dashboard/users/zones",
          parentId: "users",
          permissions: ALL_USER_PERMISSIONS.USER_ZONES_PERMISSIONS,
        },
        {
          id: "status",
          label: "Statuses",
          link: "/dashboard/users/statuses",
          parentId: "users",
          permissions: ALL_USER_PERMISSIONS.USER_STATUS_PERMISSIONS,
        },
        {
          id: "history",
          label: "History",
          link: "/dashboard/users/history",
          parentId: "users",
          permissions: ALL_USER_PERMISSIONS.USER_HISTORY_PERMISSIONS,
        },
      ],
    },

    {
      id: "settings",
      label: "Settings",
      icon: "ri-settings-2-line",
      link: "/#",
      stateVariables: isSettings,
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState("settings");
        updateIconSidebar(e);
      },
      permissions: {
        ...ALL_WEBSETTINGS_PERMISSIONS,
      },
      subItems: [
        {
          id: "web",
          label: "Web",
          link: "/dashboard/settings/web",
          parentId: "settings",
          permissions: ALL_WEBSETTINGS_PERMISSIONS.WEB_SETTINGS_PERMISSIONS.read,
        },
        {
          id: "opening-hours",
          label: "Opening Hours",
          link: "/dashboard/settings/opening-hours",
          parentId: "settings",
          permissions:
            ALL_WEBSETTINGS_PERMISSIONS.OPENING_HOURS_PERMISSIONS.read,
        },
        {
          id: "home-sliders",
          label: "Home Sliders",
          link: "/dashboard/settings/home-sliders",
          parentId: "settings",
          permissions: ALL_HOME_PERMISSIONS.SLIDER_PERMISSIONS,
        },
        {
          id: "contacts",
          label: "Contacts",
          link: "/dashboard/settings/contacts",
          parentId: "settings",
          permissions: ALL_HOME_PERMISSIONS.CONTACTS_PERMISSIONS,
        },
        {
          id: "languagesSettings",
          label: "Languages",
          link: "/dashboard/settings/languages",
          parentId: "settings",
          permissions:
            ALL_WEBSETTINGS_PERMISSIONS.OPENING_HOURS_PERMISSIONS.read,
        },

        {
          id: "fileManager",
          label: "File Manager",
          icon: "ri-folder-2-line",
          link: "/dashboard/settings/file-manager",
          permissions: ALL_OTHER_PERMISSIONS.FILE_MANAGER,
        },
        
        {
          id: "stock-staff",
          label: "Stock Staff",
          icon: "ri-smartphone-line",
          link: "/dashboard/settings/stock-staff",
          parentId: 'settings',
          permissions: ALL_OTHER_PERMISSIONS.STOCK_STAFF,
        },
        
        {
          id: "admins",
          label: "Admins",
          icon: "ri-git-merge-line",
          link: "/dashboard/settings/admins",
          parentId: 'settings',
          permissions: ALL_OTHER_PERMISSIONS.ADMIN_AND_ROLES_PERMISSIONS,
        },
      ],
    },

  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
