import React, { useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { errorMessage } from "../../../../helpers/validation/errorMessage";
import {
  createUserBody,
  updateUserBody,
} from "../../../../helpers/RequestBodies/Users/usersRequestBody";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createUserSchema,
  updateUserSchema,
} from "../../../../helpers/validation/Users/createUser";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setFormErrors } from "../../../../helpers/setFormErrors";
import CustomLabel from "../../../Custom/CustomLabel";
import CustomInput from "../../../Custom/CustomInput";
import CustomOption from "../../../Custom/CustomOption";
import { useTranslation } from "react-i18next";
import { createUsersDataAction, updateUsersDataAction } from "../../../../redux/actions/users";
import SaveButton from "../../../Common/Buttons/SaveButton";

const Account = ({ type, initialValues, refetchTracking }) => {
  
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(
      type === "create" ? createUserSchema : updateUserSchema
    ),
    defaultValues: {
      ...initialValues,
    },
  });

  const history = useHistory();

  const dispatch = useDispatch();

  const { id } = useParams();

  const onSubmit = (data) => {
    setLoading(true);
    if (type === "create") {
      const requestBody = createUserBody(data);
      dispatch(
        createUsersDataAction({
          field: 'users',
          data: requestBody,
          onSuccess: () => history.push("/dashboard/users"),
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    } else if (type === "update") {
      const requestBody = updateUserBody(data);
      dispatch(
        updateUsersDataAction({
          field: 'users',
          data: { 
            id, 
            data: requestBody 
          },
          onSuccess: () => {
            setLoading(false);
            refetchTracking();
          },
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-2">
        <Col className="mb-3" lg={6}>
          <CustomLabel className="form-label" htmlFor="user-first-name-input">
            First name
          </CustomLabel>
          <CustomInput
            className="form-control"
            name="first_name"
            {...register("first_name")}
          />
          {errorMessage(errors, "first_name")}
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel className="form-label">
            Last name
          </CustomLabel>
          <CustomInput
            className="form-control"
            name="last_name"
            {...register("last_name")}
          />
          {errorMessage(errors, "last_name")}
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel className="form-label">
            User Type
          </CustomLabel>
          <select {...register("status")} name="status" className="form-select">
            <CustomOption value="individual">Individual</CustomOption>
            <CustomOption value="company">Company</CustomOption>
          </select>
          {errorMessage(errors, "status")}
        </Col>

        <Col lg={6} className="mb-3 d-flex align-items-center" >
            <div className="form-check form-switch mt-4">
                <input 
                  {...register('active')} 
                  className="form-check-input fs-16" 
                  type="checkbox" 
                  role="switch" 
                  id="status" 
                />
                <CustomLabel 
                  className="form-check-label" 
                  htmlFor="status"
                >
                  Active
                </CustomLabel>
            </div>
        </Col>


        <Col className="mb-3" lg={6}>
          <CustomLabel
            className="form-label"
          >
            Email
          </CustomLabel>
          <CustomInput
            type="email"
            className="form-control"
            name="email"
            {...register("email")}
          />
          {errorMessage(errors, "email")}

          
          {type === "create" && (
            <div className="d-flex align-items-start mt-2">
              <CustomInput
                type="checkbox"
                className="form-check-input me-2"
                id="send_invitation"
                name="send_invitation"
                defaultChecked={initialValues?.send_invitation}
                {...register("send_invitation")}
              />
              <div>
                <CustomLabel htmlFor="send_invitation">
                  Send invitation by e-mail
                </CustomLabel>
                {errorMessage(errors, "send_invitation")}
              </div>
            </div>
          )}
        </Col>
        
        <Col className="mb-3" lg={6}>
          
          <CustomLabel
            className="form-label"
          >
            Password
          </CustomLabel>

          <CustomInput
            type="password"
            className="form-control"
            name="password"
            {...register("password")}
          />

          {errorMessage(errors, "password")}
        </Col>

        <div className="d-flex justify-content-end">
          <SaveButton 
            isLoading={loading}
          />
        </div>

      </Row>
    </form>
  );
};

export default Account;
