import { useState, useContext, createContext } from "react";
import {
  handleUploadOrCreate,
  getAllFiles,
  deleteMultipleFiles,
  updateFileName,
} from "../apis/fileManagerApi";

const FileManagerContext = createContext({});

const FileManagerProvider = ({ children }) => {
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFor, setSelectedFor] = useState(null);
  const [isFileManagerModalOpen, setIsFileManagerModalOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const openFileManagerModal = (forWhat) => {
    if (!forWhat) return;
    setSelectedFor(forWhat);
    setIsFileManagerModalOpen(true);
  };

  const closeFileManagerModal = () => {
    setSelectedFor(null);
    setIsFileManagerModalOpen(false);
  };

  const onSelectFile = (cb) => {
    cb({
      file: selectedFile,
      selectedFor: selectedFor,
    });
  };

  const onChooseClick = (fOr) => {
    setSelectedFor(fOr);
  };

  const fetchFiles = async () => {
    const { data } = await getAllFiles();
    setFiles([
      {
        id: 0,
        name: "Root Folder",
        isDirectory: true,
        items: data.data,
      },
    ]);
  };

  const createNewFolder = async (data) => {
    const { parent_id, ...rest } = data;
    let requestBody = {
      ...rest,
      active: true,
    };
    if (parent_id !== 0) {
      requestBody = {
        ...requestBody,
        parent: parent_id,
      };
    }
    const { data: response } = await handleUploadOrCreate(requestBody);
    fetchFiles();
    return response;
  };

  const uploadNewFile = async (data) => {
    const { data: response } = await handleUploadOrCreate(data);
    fetchFiles();
    return response;
  };

  const deleteFiles = async (ids) => {
    const { data } = await deleteMultipleFiles(ids);
    fetchFiles();
    return data;
  };

  const updateFolderName = async (id, newName) => {
    const { data } = await updateFileName(id, newName);
    fetchFiles();
    return data;
  };

  const selectFile = (file) => {
    setSelectedFile(file);
  };

  return (
    <FileManagerContext.Provider
      value={{
        files,
        selectedFile,
        setSelectedFile,
        onChooseClick,
        selectedFor,
        selectFile,
        fetchFiles,
        createNewFolder,
        uploadNewFile,
        deleteFiles,
        updateFolderName,
        setSelectedFor,
        isFileManagerModalOpen,
        openFileManagerModal,
        closeFileManagerModal,
      }}
    >
      {children}
    </FileManagerContext.Provider>
  );
};

export default FileManagerProvider;

export const useFileManager = () => {
  return useContext(FileManagerContext);
};
