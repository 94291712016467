import React, { useState, useRef } from "react";
import Select from "react-select";
import formatSelectData from "../../../../helpers/formatSelectData";
import CustomButton from "../../../Common/CustomButton";
import DataTable from "../../../Common/DataTable";
import ExpandedRow from "./ExpandedRow";
import usersApi from "../../../../apis/users/api";
import toastAlert from "../../../../helpers/toastAlert";
import productsApi from "../../../../apis/products/api";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

const AddDiscountedProductTable = ({ initialValues, refetch, refetchTracking }) => {
  
  const [errors, setErrors] = useState({
    product_id: [],
    price: [],
  });

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const unitsRef = useRef([]);

  const [selectedProduct, setSelectedProduct] = useState({});

  const [loadingProduct, setLoadingProduct] = useState(false);

  const [products, setProducts] = useState([]);

  const productOptions = formatSelectData(products || []);

  const handleSubmit = async () => {
    setLoading(true);

    const requestBody = {
      user_id: initialValues?.id,
      product_id: selectedProduct.id,
      units: unitsRef.current,
    };

    try {
      await usersApi.users.discounts.create(requestBody);
      setSelectedProduct({});
      toastAlert({ type: 'success'})
      await refetch();
      refetchTracking();
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setLoading(false);
    }
  };

  const fetchProduct = async (value) => {
    try {
      setLoadingProduct(true);
      setSelectedProduct({});
      if (!value) return setSelectedProduct({});
      const { data: { data: productData } } = await productsApi.products.getOne(value, {
        for: 'order'
      });
      setSelectedProduct(productData);
    } catch (error) {
      toastAlert({ type: 'error' })
    } finally {
      setLoadingProduct(false);
    }
  };

  const { isLoading: loadingProducts, isRefetching: refetchingProducts } = useQuery(['products'],  () => productsApi.products.getAll({
    for: 'order',
    page_size: 'all'
  }), {
    cacheTime: 0,
    onSuccess({data : { data }}) {
      setProducts(data);
    }
  })

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product Number")}</span>
      ),
      selector: () => selectedProduct?.matricule,
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Product")}</span>,
      selector: () => (
        <div style={{ width: "400px" }}>
          <Select
            onChange={(value) => fetchProduct(value.value)}
            placeholder={t('Select')}
            value={productOptions.find(opt => opt.value === selectedProduct.id) ?? null}
            isLoading={loadingProducts || refetchingProducts || loadingProduct}
            isDisabled={loadingProducts || refetchingProducts || loadingProduct}
            options={productOptions}
            menuPortalTarget={document.body}
          />
        </div>
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Action")}</span>,
      selector: () => (
        <CustomButton
          type="button"
          isLoading={loading}
          onClick={handleSubmit}
          className="btn btn-success btn-sm"
        >
          <i className="ri-add-box-line fs-16"></i>
        </CustomButton>
      ),
    },
  ];

  return (
    <React.Fragment>
     
      <DataTable 
        data={[selectedProduct]}
        columns={columns}
        noPagination
        expandableRows
        expandableRowComponent={() => {
          if (selectedProduct.id && !loadingProduct)
            return (
              <ExpandedRow
                selectedProduct={selectedProduct}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                errors={errors}
                unitsRef={unitsRef}
                type="create"
              />
            );
        }}
        expandableRowExpanded={(row) => row.id}
      />
    </React.Fragment>
  );
};

export default AddDiscountedProductTable;
