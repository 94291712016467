import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import DataTable from "../../Common/DataTable";
import { useHistory, useParams } from "react-router-dom";
import calculateTotalPrice from "../../../helpers/calculateTotalPrice";
import CoverSpinner from "../../Common/CoverSpinner";
import CustomButton from "../../Common/CustomButton";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import toastAlert from "../../../helpers/toastAlert";
import { extractQuantityBasedonUnit } from "../../../helpers/calculateOrderPrices";
import AddProductToNewsletter from "./AddProductToNewsletter";
import ordersApi from "../../../apis/orders/api";
import { useTranslation } from "react-i18next";

const NewsletterProducts = ({ items, read, newsletter, refetchNewsletter, visibleColumns, toggleVisibleColumns, refetchEmailHistory }) => {

  const [list, setList] = useState(items || []);

  const [isUpdating, setIsUpdating] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const { load, callback, deleteModal, isDeleting, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const { t } = useTranslation();

  const history = useHistory();
  
  const { id } = useParams();

  const handleChange = (id) => (e) => {
    setList((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [e.target.name]: e.target.value,
          };
        }
        return item;
      });
    });
  };

  const onDeleteMultipleClick = async () => {
    try {
      load();
      const ids = itemsToDelete.selectedRows.map((item) => item.id);
      await ordersApi.newsletters.products.delete({ ids });
      toastAlert({ type: 'success'})
      refetchNewsletter();
      setList((prev) => {
        return prev.filter((item) => {
          if (ids.includes(item.id)) return false;
          return true;
        });
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      callback();
    }
  };

  const updateItem = async (row) => {
    setIsUpdating(true);
    const id = row.id;
    const requestBody = {
      newsletter_id: newsletter.id,
      product_unit: row.product_unit?.id || row.product_unit,
      promo: row.promo,
      price: row.price,
      qty: row.qty,
    };
    try {
      await ordersApi.newsletters.products.update(id, requestBody);
      refetchNewsletter();
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsUpdating(false);
    }
  };

  const updateAll = async () => {
    setIsUpdating(true);
    const allItems = list.map((row) => ({
      id: row.id,
      product_unit: row.product_unit?.id || row.product_unit,
      promo: row.promo,
      price: row.price,
      qty: row.qty,
    }));
    try {
      if (allItems.length > 0) {
        await ordersApi.newsletters.products.updateMultiple({
          items: allItems
        });
        toastAlert({ type: 'success'})
        refetchNewsletter();
      } else {
        toastAlert({ type: 'success'})
      }
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsUpdating(false);
    }
  };

  const sendEmail = async () => {
    try {
      setIsSending(true);
      await ordersApi.newsletters.email.sendToEmail(id);
      await refetchEmailHistory();
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsSending(false);
    }
  };

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product number")}</span>
      ),
      selector: (row) => row.product?.matricule || "",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Product Name")}</span>,
      selector: (row) =>
        (
          <span
            onClick={() =>
              history.push(`/dashboard/products/update/${row.product?.id}`)
            }
            title={row.product?.name}
            className="cursor-pointer"
          >
            <b>{row.product?.name}</b>
          </span>
        ) || "-",
      sortable: true,
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Qty")}</span>,
      selector: (row) => (
        <input
          onChange={handleChange(row?.id)}
          name="qty"
          value={row.qty || ""}
          type="number"
          className="form-control"
          step="any"
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Type")}</span>,
      selector: (row) =>
        `${row.product_unit?.name} ${row.weight ? `(${row.weight})` : ""}`,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Price")}</span>,
      selector: (row) => (
        <input
          onChange={handleChange(row.id)}
          name="price"
          value={row.price || ""}
          type="number"
          className="form-control"
          step="any"
        />
      ),
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Purchase Price")}</span>
      ),
      selector: (row) => (
        <input
          type="number"
          className="form-control"
          disabled
          value={row?.price_purchase || ""}
          step="any"
        />
      ),
      id: "pruchase_price",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Discount")}</span>,
      selector: (row) => (
        <input
          onChange={handleChange(row.id)}
          name="promo"
          value={row.promo || ""}
          className="form-control"
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Total")}</span>,
      selector: (row) =>
        Number(
          calculateTotalPrice(
            row.price,
            row.promo,
            extractQuantityBasedonUnit(row)
          )
        ).toFixed(2),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Actions")}</span>,
      selector: (row) => (
        <button type="button" className={`btn btn-sm btn-success`}>
          <i
            onClick={() => updateItem(row)}
            className="ri-save-2-line cursor-pointer fs-16"
          ></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    setList(items);
  }, [items])

  return (
    <>
      <Row className="mb-3">
        <div className="hstack gap-2">
          <button
            type="button"
            className={`btn btn-sm btn-${
              visibleColumns?.purchasePrice ? "success" : "danger"
            } d-flex align-items-center gap-1`}
            onClick={(e) => toggleVisibleColumns()}
          >
            {visibleColumns?.purchasePrice ? (
              <i className="ri-check-line"></i>
            ) : (
              <i className="ri-close-line"></i>
            )}
            {t("Purchase Price")}
          </button>
          <button
            className="btn btn-dark btn-sm"
            disabled={!itemsToDelete.selectedRows?.length > 0}
            onClick={() => setDeleteModal(true)}
          >
            <i className="ri-delete-bin-line align-bottom"></i>{" "}
            {t("Delete Selected")}
          </button>
        </div>
      </Row>

      <AddProductToNewsletter
        items={list}
        refetchNewsletter={refetchNewsletter}
        newsletter={newsletter}
        setList={setList}
        visibleColumns={visibleColumns}
      />

      <Row className="mt-3">
        <form>
          <Card>
            <DataTable
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              columns={
                visibleColumns.purchasePrice
                  ? columns
                  : columns.filter((col) => !col.id)
              }
              data={list}
              isDeleting={isDeleting}
              handleDelete={onDeleteMultipleClick}
              selectableRows={!read}
              selectableRowDisabled={(row) => row.isExtraCost}
              pageSize={10000}
            />
          </Card>

          <CustomButton
            type="button"
            isLoading={isUpdating}
            className="btn btn-secondary"
            onClick={updateAll}
          >
            Save
          </CustomButton>

          <CustomButton
            style={{
              marginLeft: 12,
            }}
            type="button"
            className="btn btn-primary"
            onClick={sendEmail}
            isLoading={isSending}
          >
            Send Email
          </CustomButton>

        </form>

        <CoverSpinner loading={isUpdating} />

      </Row>
    </>
  );
};

export default NewsletterProducts;
