import React from 'react'
import CustomButton from '../CustomButton'

const SaveButton = ({ isLoading, className, children, ...props }) => {

  return (
    <CustomButton
        type="submit"
        className={`btn btn-success ${className}`}
        isLoading={isLoading}
        {...props}
        >
        <i className="ri-save-line align-bottom me-1"></i>
        {children || 'Save'}
    </CustomButton>
  )
}

export default SaveButton