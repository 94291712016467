import React, { useEffect } from 'react'
import { Col, Label } from 'reactstrap'
import { useFileManager } from '../../../context/FileManagerContext';
import { errorMessage } from '../../../helpers/validation/errorMessage';
import useSetFile from '../../../hooks/useSetFile';
import SelectFileButton from '../../Common/SelectFileButton';

const CreateCategoryImage = ({categoryImage, setCategoryImage, setValue, errors}) => {

    const { openFileManagerModal } = useFileManager();

    useSetFile({
        whatFor: "category-image",
        cb: (file) => {
            setCategoryImage({id: file.id, preview: file.path})
        },
      });

    const removeImage = (pd) => {
        return setCategoryImage(null)
    }

    useEffect(() => {
        setValue("file_id", +categoryImage?.id);
    }, [categoryImage]);

  return (
    <React.Fragment>
        <Col lg={6} md={6} xs={12}>
              <Label>Image</Label>
              <SelectFileButton
                text="Select Image"
                onClick={() => openFileManagerModal("category-image")}
              />
              {/* {imagePreview && <img src={imagePreview}/>} */}
              {errorMessage(errors, "file_id")}
            </Col>

            <Col>
            {categoryImage
                && 
            // <Col key={key={index}} md={2}>
                <div style={{position: 'relative', maxWidth: '250px'}}>
                    <img alt={categoryImage?.preview} className='img-thumbnail mt-4' src={categoryImage?.preview} />
                    <i className="ri-delete-bin-fill"
                        style={{position: 'absolute', top: '20%', right: '5%', fontSize: '1.1rem', cursor: 'pointer', color: 'white'}}
                        onClick={removeImage}
                    ></i>
                </div>
            // </Col>
                }
            </Col>
    </React.Fragment>
  )
}

export default CreateCategoryImage