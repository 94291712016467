import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { updateProfileSchema } from "../../helpers/validation/Profile/profileValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../helpers/validation/errorMessage";
import { useAuthState } from "../../context/authContext";
import CustomButton from "../../Components/Common/CustomButton";
import toastAlert from "../../helpers/toastAlert";
import { setFormErrors } from "../../helpers/setFormErrors";
import PageContainer from "../../Components/Layout/PageContainer";

const Profile = () => {

  const { adminData, updateAdminData } = useAuthState();
  
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors }, reset, setError} = useForm({
    resolver: yupResolver(updateProfileSchema),
    defaultValues: adminData,
  });

  const submitform = async (data) => {
    setLoading(true);
    try {
      await updateAdminData(data);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
      setFormErrors(error?.response?.data?.error, setError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (adminData) reset(adminData);
  }, [adminData]);

  return (
      <PageContainer>
        <BreadCrumb pageTitle="Dashboard" title="Admin profile" />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Edit Admin Info
                  </h5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <Row className="mt-3">
                  <Form onSubmit={handleSubmit(submitform)}>
                    <input type="submit" hidden />
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">Admin name</Label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("name")}
                          />
                          {errorMessage(errors, "name")}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">Admin email</Label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("email")}
                          />{" "}
                          {errorMessage(errors, "email")}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">Old password</Label>
                          <input
                            type="password"
                            className="form-control"
                            {...register("password_old")}
                          />
                          {errorMessage(errors, "password_old")}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">New password</Label>

                          <input
                            type="password"
                            className="form-control"
                            {...register("password")}
                          />
                          {errorMessage(errors, "password")}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Confirm new password
                          </Label>

                          <input
                            type="password"
                            className="form-control"
                            {...register("password_confirmation")}
                          />
                          {errorMessage(errors, "password_confirmation")}
                        </div>
                      </Col>
                    </Row>
                    <CardFooter className="px-0 mt-5">
                      <div className="d-flex align-items-center gap-2">
                        <Link to="/dashboard/">
                          <Button type="button" color="light" id="close-modal">
                            Close
                          </Button>
                        </Link>
                        <CustomButton
                          isLoading={loading}
                          type="submit"
                          className='btn btn-success'
                        >
                          <i className="ri-save-line align-bottom me-1"></i>{" "}
                          Update
                        </CustomButton>
                      </div>
                    </CardFooter>
                  </Form>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </PageContainer>
  );
};

export default Profile;
