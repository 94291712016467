import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import DataTable from "../../../Common/DataTable";

const Prices = ({ initialValues, setValue,  watch }) => {

  const [unitPrices, setUnitPrices] = useState(null);

  const { 
    products: { measurementUnits: { list: measurementUnits }},
    users: { groups: { list: groups }}
  } = useSelector((state) => state);

  const selectedGroups = watch('groups');

  const { t } = useTranslation();


  const handleSelectionChange = (id) => {
    setUnitPrices(prev => prev.map(item => {
      if (item.id === id) return {
        ...item,
        selected: !item.selected
      }
      return item
    }))
  };

  const handleGroupPriceChange = (unitId, groupId, value) => {
    setUnitPrices(prev => prev.map(item => {
      if (item.id === unitId) return {
        ...item,
        prices: item.prices.map(group => {
          if (group.id === groupId) return {
            ...group,
            price: value
          }
          return group
        })
      }

      return item
    }))
  };

  const handleInputChange = (unitId, field, value) => {
    setUnitPrices(prev => prev.map(item => {
      if (item.id === unitId) return {
        ...item,
        [field]: value
      }
      return item
    }))
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Unit")}</span>,
      selector: (row) => row.name,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Purchase Price")}</span>
      ),
      selector: (row) => (
        <>
          <input
            name="pieces"
            value={row.price_purchase}
            onChange={(e) =>
              handleInputChange(
                row.id,
                "price_purchase",
                e.target.value,
              )
            }
            type="number"
            className="form-control"
            id="inputName"
            step="any"
          />
        </>
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Pieces")}</span>,
      selector: (row) => (
        <>
          <input
            name="pieces"
            value={row.pieces}
            onChange={(e) =>
              handleInputChange(
                row.id,
                "pieces",
                e.target.value,
              )
            }
            type="number"
            className="form-control"
            id="inputName"
            step="any"
          />
        </>
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Weight")}</span>,
      selector: (row) => (
            <input
            name="unit_weight"
            value={row.unit_weight}
            onChange={(e) => {
              if (row.id === 1) {
                handleInputChange(
                  row.id,
                  "unit_weight",
                  e.target.value,
                )
              }
            }}
            type="number"
            className="form-control"
            id="inputName"
            step="any"
            disabled={row.id !== 1}
          />
      ),
    },

    ...(unitPrices?.at(0).prices?.filter(item => item.selected) || []).map((group) => ({
      name: <span className="font-weight-bold fs-13">{group.name}</span>,
      selector: (row) => (
        <>
          <input
            type="number"
            className="form-control"
            step="any"
            value={row.prices.find(item => item.id === group.id)?.price}
            onChange={(e) =>
              handleGroupPriceChange(
                row.id,
                group.id,
                e.target.value,
              )
            }
          />
        </>
      ),
    })),
  ];

  useEffect(() => {
    if (measurementUnits?.length && selectedGroups?.length) {
      if (unitPrices === null) {
        setUnitPrices(measurementUnits.map(unit => {
          const initialVal = initialValues?.unit_prices?.find(item => item.unit_id === unit.id);
          return {
            id: unit.id,
            name: unit.name,
            selected: !!initialVal,
            price_purchase: initialVal ? initialVal.price_purchase : 0,
            pieces: initialVal ? initialVal.pieces : 0,
            ...(unit.id === 1 && {
              unit_weight: initialVal ? initialVal.unit_weight : 0
            }),
            prices: groups?.map(group => {
              const initialGroupVal = initialVal?.groups_prices.find(item => item.user_group_id === group.id);
              return {
                id: group.id,
                name: group.name,
                selected: selectedGroups.includes(group.id),
                price: initialGroupVal ? initialGroupVal?.price : 0,
              }
            })
          }
        }
        ))
      } else {
        setUnitPrices(prev => prev.map(unit => ({
          ...unit,
          prices: unit.prices.map(group => ({
            ...group,
            selected: selectedGroups.includes(group.id),
          }))
        })))
      }
    }
  }, [initialValues, measurementUnits, groups, selectedGroups])


  useEffect(() => {
    setValue("unit_prices", unitPrices?.filter(item => item.selected).map(item => ({
      unit_id: item.id,
      price_purchase: item.price_purchase,
      pieces: item.pieces,
      unit_weight: item.unit_weight,
      groups_prices: item.prices.filter(group => group.selected).map(group => ({
        user_group_id: group.id,
        price: group.price
      }))
    })));
    setValue("units", unitPrices?.map(item => item.id) || null);
  }, [setValue, unitPrices]);

  return (
    <React.Fragment>
      <Row>
          {unitPrices?.map((unit) => (
            <Col md={2} key={unit.id}>

              <div className="form-check form-check-primary mb-3">

                <input
                  id={`unit-${unit.id}`}
                  className="form-check-input"
                  type="checkbox"
                  checked={unit.selected}
                  onChange={() => handleSelectionChange(unit.id)}
                />

                <Label 
                  className="form-check-label" 
                  htmlFor={`unit-${unit.id}`}
                >
                  {unit.name}
                </Label>

              </div>

            </Col>
          ))}
      </Row>
      <Row>
        <Col md={12}>
          <DataTable data={unitPrices?.filter(item => item.selected)} columns={columns} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Prices;
