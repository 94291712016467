import React, { useEffect } from "react";
import { Button, CardFooter, Col, Label, Row } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import SeoSchema from "../../../helpers/validation/WebSettings/SeoValidation";
import { errorMessage } from "./../../../helpers/validation/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { WEB_SETTINGS_PERMISSIONS } from "./../../../permissions/webSettings";
import Restricted from "./../../Guard/Restricted";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextarea from "../../Custom/CustomTextarea";
import CustomButton from "../../Common/CustomButton";
import { useTranslation } from "react-i18next";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";
import SaveButton from "../../Common/Buttons/SaveButton";

const Seo = () => {

  const dispatch = useDispatch();

  const { seo } = useSelector((state) => state.settings.webSettings);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(SeoSchema),
    defaultValues: seo,
  });

  const submitSeo = (data) => {
    dispatch(
      setWebSettingsAction({
        field: 'seo',
        data: {
          type: "seo",
          data
        }
      })
    );
  };

  useEffect(() => {
    if (seo) {
      reset(seo);
    }
  }, [seo]);

  useEffect(() => {
    dispatch(getWebSettingsAction({
      field: 'seo',
    }));
  }, []);

  return (
    <form onSubmit={handleSubmit(submitSeo)}>
      <input type="submit" hidden />
      <Row>
        <Col lg={6}>
          <CustomLabel htmlFor="" className="form-label">
            Page Title
          </CustomLabel>
          <input type="text" className="form-control" {...register("title")} />
          {errorMessage(errors, "title")}
        </Col>
        <Col lg={6}>
          <CustomLabel htmlFor="seo-page-keywords-input" className="form-label">
            Page Keywords
          </CustomLabel>
          <input
            type="text"
            className="form-control"
            id="seo-page-keywords-input"
            {...register("keywords")}
          />
          {errorMessage(errors, "keywords")}
        </Col>

        <Col lg={12} className="mt-3">
          <CustomLabel htmlFor="page-description-field" className="form-label">
            Page Description
          </CustomLabel>
          <CustomTextarea
            name="page-description"
            id="page-description-field"
            className="form-control"
            placeholder="Enter Page Description"
            {...register("description")}
          />
          {errorMessage(errors, "description")}
        </Col>

        <Restricted permission={WEB_SETTINGS_PERMISSIONS.update}>
          <CardFooter className="mt-3">
            <div className="d-flex justify-content-end">

                <SaveButton />
            </div>
          </CardFooter>
        </Restricted>
        
      </Row>
    </form>
  );
};

export default Seo;
