import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import StockStaffTable from "../../../Components/Settings/StockStaff/Table";
import { deleteSettingsDataAction, getAllSettingsDataAction } from "../../../redux/actions/settings";
import CustomButton from "../../../Components/Common/CustomButton";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useSearch } from "../../../hooks/useSearch";

const StockStaff = () => {

  const { load, callback, itemsToDelete, deleteModal, isDeleting, setItemsToDelete, setDeleteModal } = useDeleteMultiple();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { stockStaff: { list: stockStaff, total } } = useSelector((state) => state.settings);

  const searchFunc = (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllSettingsDataAction({
      field: 'stockStaff',
      query,
      onSuccess: () => setLoading(false)
    }));
  }

  const { searchValues, setPageSize, navigateToPage, search } = useSearch({
    name: 'stockStaff',
    searchFunc
  });

  const handleDelete = () => {
    load();
    dispatch(deleteSettingsDataAction({ 
      field: 'stockStaff',
      data: {
        ids: itemsToDelete.selectedRows?.map((item) => item.id)
      },
      onSuccess() {
        callback();
        search();
      }
     }));
  };

  return (
      <PageContainer>

        <BreadCrumb pageTitle="Settings" title="Stock Staff" to="/" />

        <div className="hstack gap-2">

            <div>
              <Link to="stock-staff/create">
                <CustomButton
                  type="button"
                  className="btn btn-success"
                >
                  <i className="ri-add-line align-bottom me-1"></i>
                  Create
                </CustomButton>
              </Link>
            </div>

            <div>
              <CustomButton
                className="btn btn-danger"
                disabled={!itemsToDelete.selectedRows?.length > 0}
                onClick={() => setDeleteModal(true)}
              >
                <i className="ri-delete-bin-line align-bottom me-1"></i>
                Delete
              </CustomButton>
            </div>

        </div>

        <Card className="mt-3">
          <StockStaffTable
            data={stockStaff}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            selectableRows
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            setDeleteModal={setDeleteModal}
            filters={searchValues}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            sortDirection={searchValues.order_value}
            fetching={loading}
            deleteModal={deleteModal}
            handleDelete={handleDelete}
            isDeleting={isDeleting}
            path="/dashboard/settings/stock-staff"
          />
        </Card>

      </PageContainer>
  );
};

export default StockStaff;