import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import formatSelectData from "../../../../helpers/formatSelectData";
import ordersApi from "../../../../apis/orders/api";
import CustomButton from "../../../Common/CustomButton";
import productsApi from "../../../../apis/products/api";
import DataTable from "../../../Common/DataTable";
import calculateTotalPrice from "../../../../helpers/calculateTotalPrice";
import toastAlert from "../../../../helpers/toastAlert";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { extractProductPrices, getColumnName } from "../../../../helpers/orders.helper";

const AddProductToOrder = ({ userDiscounts, order, allItems, refetchOrder, refetchTracking, visibleColumns }) => {

  const [errors, setErrors] = useState({
    product_id: [],
    qty: [],
    product_unit: [],
    promo: [],
    price: [],
  });

  const [products, setProducts] = useState([]);

  const { t } = useTranslation();

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [loadingProduct, setLoadingProduct] = useState(false);

  const { id } = useParams();

  const { isLoading: loadingProducts, isRefetching: refetchingProducts } = useQuery(['products'], () => productsApi.products.getAll({
    for: 'order',
    page_size: 'all'
  }), {
    cacheTime: 0,
    onSuccess({ data: { data }}) {
      setProducts(data);
    }
  })

  const [data, setData] = useState({
    product_id: '',
    product_number: '',
    product_name: '',
    total_qty: "",
    unit_prices: [],
    qty: 1,
    promo: "",
    product_unit: "",
    price: "",
    price_purchase: "",
  });

  const productSelectRef = useRef();

  const unitSelectRef = useRef();

  // Product selection options format and filter exiting products

  const productOptions = formatSelectData(products.filter((product) => {
      if (allItems.map((item) => +item?.product?.id).includes(+product?.id))
        return false;
      return true;
    }) || []
  );

  // Change quantity column name depending on selected unit

  const clearErrors = (e) => {
    if (e?.target?.name) {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: [],
      }));
    } else if (typeof e === 'string') {
      if (e === 'product_id') {
        setErrors({
          product_id: [],
          qty: [],
          product_unit: [],
          promo: [],
          price: [],
        })
      } else {
        setErrors((prev) => ({
          ...prev,
          [e]: [],
        }));
      }
    }
    
  };

  const clearData = () => {
    setData({
      product_id: '',
      product_number: '',
      product_name: '',
      total_weight: '',
      total_qty: "",
      unit_prices: [],
      qty: 1,
      promo: "",
      product_unit: "",
      price: "",
      price_purchase: "",
    });
  };

  const handleChange = (e) => {
    clearErrors(e);
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: +e.target.value,
      };
    });
  };

  const handleUnitChange = (e) => {

    const id = +e.target.value;

    const unit = data.unit_prices.find(unit => unit.id === id);

    setData(prev => ({
      ...prev,
      product_unit: id,
      price_purchase: unit.purchase_price,
      // Extract total qty
      total_qty: unit.id === 1 ? data.total_weight : Math.floor(data.total_qty / (unit.pieces || 1)) || "0",
        // Prioratize discount over base price
      price: unit.price.discount ?? unit.price.base,
       // Display the base price if a discount exists
      ...(unit.price.discount !== null ? { base_price: unit.price.base } : {})
    }))
    clearErrors(e);
  }

  const handleSubmit = async () => {
    try {
      setIsSubmiting(true);
      const { product_name, product_number, unit_prices, total_qty, ...rest } = data;
      await ordersApi.orders.products.create({ order_id: +id, ...rest });
      productSelectRef.current.focus();
      toastAlert({ type: 'success'})
      refetchTracking();
      refetchOrder();
      clearData();
    } catch (error) {
      toastAlert({ type: 'error' })
      setErrors((prev) => ({
        ...prev,
        ...error.response?.data?.error,
      }));
    } finally {
      setIsSubmiting(false);
    }
  };

  const fetchProduct = async (id) => {
    try {
      setLoadingProduct(true);
      const {data: { data: product }} = await productsApi.products.getOne(id, {
        for: 'order'
      });
      const prices = extractProductPrices({ product, userInfo: order.company, userDiscounts})
      setData(prices);
    }  catch (error) {
        toastAlert({ type: 'error' })
    } finally {
        setLoadingProduct(false);
    }
  };


  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product Number")}</span>
      ),
      selector: () => (
        <input
          type="number"
          className="form-control"
          disabled
          value={data?.product_number ?? ""}
          step="any"
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Product")}</span>,
      selector: () => (
        <div style={{ minWidth: "230px" }}>
          <Select
            value={data.product_id ? {
              label: data.product_name,
              value: data.product_id,
            } : null}
            placeholder={t('Select')}
            onChange={item => {
              if (item.value) {
                fetchProduct(item.value);
                clearErrors('product_id');
              } else {
                clearData();
              }
            }}
            options={productOptions}
            isLoading={loadingProducts || refetchingProducts || loadingProduct}
            isDisabled={loadingProducts || refetchingProducts || loadingProduct}
            menuPortalTarget={document.body}
            ref={productSelectRef}
            openMenuOnFocus
          />
        </div>
      ),
      grow: 4,
    },

    {
      name: <span className="font-weight-bold fs-13">{t(getColumnName(data.unit_prices, data.product_unit))}</span>,
      selector: () => (
        <input
          type="number"
          className="form-control"
          disabled
          value={data?.total_qty ?? ""}
          step="any"
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Units")}</span>,
      selector: () => (
        <select
          onChange={handleUnitChange}
          name="product_unit"
          className={`form-select ${errors.product_unit[0] && "is-invalid"}`}
          value={data.product_unit ?? ''}
          ref={unitSelectRef}
        >
          {data?.unit_prices?.map((unit) => {
            return (
              <option key={unit?.id} value={unit?.id}>
                {unit?.name}
              </option>
            );
          })}
        </select>
      ),
      grow: 2,
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Purchase Price")}</span>
      ),
      selector: () => (
          <input
              type="number"
              className="form-control"
              disabled
              value={data.price_purchase ?? ""}
              step="any"
          />
      ),
      id: "purchase_price",
      grow: 2,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Quantity")}</span>,
      selector: () => (
        <input
          name="qty"
          value={data.qty ?? ""}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              return handleSubmit();
            }
          }}
          type="number"
          className={`form-control ${errors.qty[0] && "is-invalid"}`}
          step="any"
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Unit Price")}</span>,
      selector: () => (
        <div className="d-flex gap-2">
          {data.base_price && (
            <input
              className="form-control text-danger"
              disabled
              value={data.base_price}
            />
          )}
          <input
            name="price"
            type="number"
            onChange={handleChange}
            className={`form-control ${errors.price[0] && "is-invalid"}`}
            value={data.price ?? ""}
            step="any"
          />
        </div>
      ),
      grow: 4,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Discount")}</span>,
      selector: () => (
        <input
          name="promo"
          onChange={handleChange}
          type="number"
          className={`form-control ${errors.promo[0] && "is-invalid"}`}
          value={data.promo ?? ""}
          max="100"
          step="any"
        />
      ),
      grow: 2,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Total")}</span>,
      selector: () => Number(calculateTotalPrice(+data.price, +data.promo, +data.qty)).toFixed(2),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Action")}</span>,
      selector: () => (
        <CustomButton
          type="button"
          isLoading={isSubmiting}
          onClick={handleSubmit}
          className="btn btn-success btn-sm"
        >
          <i className="ri-add-box-line fs-16"></i>
        </CustomButton>
      ),
    },
  ];

  // Focus the product select menu on render

  useEffect(() => {
    if (products?.length) {
      productSelectRef.current?.focus();
    }
  }, [products]);

  return (
    <Card>
      <DataTable
        data={[data]}
        columns={
          visibleColumns.purchasePrice
            ? columns
            : columns.filter((col) => !col.id)
        }
        noPagination
      />
    </Card>
  );
};

export default AddProductToOrder;
