const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "storage",
  initialState: {
    storage: {
        list: [],
        total: 0
    },
    lotMovement: {
        list: [],
        total: 0
    },
    productsNumbers: {
        list: [],
        total: 0
    },
    problems: {
        list: [],
        total: 0
    },
    control: {
        list: [],
        total: 0
    },
    newStock: {
        list: [],
        total: 0
    },
  },
  reducers: {
    setData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          list: payload.data,
          total: payload.total,
        };
      }
    },
  },
});

export const storageActions = slice.actions;

export default slice.reducer;
