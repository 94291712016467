import React, { useState } from "react";
import SliderForm from "../../../Components/Settings/Sliders/Form";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import settingsApi from "../../../apis/settings/api";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateHomeSlider = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["sliders", id], () => settingsApi.homeSliders.getOne(id), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
        {
          initialValues && 
            <SliderForm 
              type="update" 
              initialValues={initialValues} 
            />
        }
    </PageContainer>
  );
};

export default UpdateHomeSlider;
