import * as yup from "yup";

const createGroupSchema = yup.object().shape({
  description: yup.string().required("Group name is required"),
  code: yup.string().required("Group code is required"),
  description: yup
    .string()
    .required("Group description should be required please"),
});

export default createGroupSchema;
