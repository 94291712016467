import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DataTable from "../../Common/DataTable";
import CustomInput from "../../Custom/CustomInput";
import Flatpickr from 'react-flatpickr'

const LotMovementTable = ({ filters, handleFilterChange, handleMultipleFilterChanges, ...props }) => {

  const { t } = useTranslation();

  const history = useHistory();

  const columns = [
    
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: (row) => row.id,
      grow: -1,
    },

    {
      name:      
      <CustomInput
        className="form-control"
        placeholder="Order"
        value={filters.matricule}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'matricule',
          value: e.target.value
        })}
        data-filtered={!!filters.matricule}
      />,
      selector: (row) =>
        row.order_code ? (
          <span
            className=" cursor-pointer"
            onClick={() =>
              history.push(
                `/dashboard/orders/change-order/${row.order_id}/order`
              )
            }
            title={row.order_code}
          >
            <span style={{fontWeight: '600'}}>{row.order_code}</span>
          </span>
        ) : (
          "-"
        ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Admin")}</span>,
      selector: (row) => row.user || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Customer")}</span>,
      selector: (row) => (
        <span 
          className="cursor-pointer" 
          onClick={() => history.push(`/dashboard/users/update/${row.customer_id}`)} 
          title={row.customer}>{row.customer || "-"}
        </span>
      ),
      grow: 3,
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Qty Ordered")}</span>
      ),
      selector: (row) => row.qty_ordered || "-",
      grow: -1,
    },

    {
      name: (
        <span className="font-weight-bold fs-13"> {t("Qty Delivered")}</span>
      ),
      selector: (row) => row.qty_delivered || "-",
    },

    {
      name:  
      <CustomInput
        className="form-control"
        placeholder="Product"
        value={filters.product_name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'product_name',
          value: e.target.value
        })}
        data-filtered={!!filters.product_name}
      />,
      selector: (row) =>  row.product ? (
          <span
            className=" cursor-pointer"
            onClick={() =>
              history.push(`/dashboard/products/update/${row.product_id}`)
            }
            title={row.product}
          >
            <b>{row.product}</b>
          </span>
        ) : (
          "-"
        ),
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Unit")}</span>,
      selector: (row) => row.unit || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: (row) => row.status || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Problem")}</span>,
      selector: (row) => row.problem ? <span title={row.problem}>{row.problem}</span> : "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Weight")}</span>,
      selector: (row) => row.weight || "-",
    },

    {
      name:        
      <CustomInput
        className="form-control"
        placeholder="Batch"
        value={filters.batch}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'batch',
          value: e.target.value
        })}
        data-filtered={!!filters.batch}
      />,
      selector: (row) => row.batch || "-",
    },

    {
      name:          
      <CustomInput
        className="form-control"
        placeholder="Lot"
        value={filters.lot}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'lot',
          value: e.target.value
        })}
        data-filtered={!!filters.lot}
      />,
      selector: (row) => <span title={row.lot || "-"}>{row.lot || "-"}</span>,
      grow: 3,
    },

    {
      name:    
      <Flatpickr
        className="form-control"
        placeholder="Created"
        options={{
          mode: "range",
        }}
        value={[filters.date_from, filters.date_to]}
        onChange={(date) => handleMultipleFilterChanges({
          type: 'cached',
          data: {
            date_from: moment(date[0]).format("YYYY-MM-DD"),
            date_to: date[1] ? moment(date[1]).format("YYYY-MM-DD") : null
          }
        })}
        data-filtered={!!filters.date_from && !!filters.date_to}
      />,
      selector: (row) => row.created_at ? moment(row.created_at).format("D MMM, YYYY") : "-",
      grow: 2,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Date exp")}</span>,
      selector: (row) => row.date_exp ? moment(row.date_exp).format("D MMM, YYYY") : "-",
      grow: 2,
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default LotMovementTable;
