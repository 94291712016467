import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../Common/DataTable";
import StatusToggle from "../../Common/StatusToggle";
import CustomOption from "../../Custom/CustomOption";
import CustomInput from "../../Custom/CustomInput";
import { useSelector } from "react-redux";

const ProductsTable = ({ filters, handleFilterChange, ...props }) => {
  
  const { t } = useTranslation();

  const { 
    vats: { list: vats },
    locations: {list: locations}, 
   } = useSelector(state => state.products);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Number / Matricule")}</span>
      ),
      selector: row => row.matricule,
      sortable: true,
      sortField: 'matricule'
    },

    {
      name:       
      <CustomInput
        className="form-control"
        placeholder="Name"
        value={filters.name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        })}
        data-filtered={!!filters.name}
      />,
      selector: row => row.name,
      sortable: true,
      sortField: 'name'
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Category")}</span>,
      selector: row => <span title={row.categories || ''}>{row.categories || ''}</span>,
    },

    {
      name:  
      <select
        className="form-select"
        value={filters.tax}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'tax',
          value: e.target.value
        })}
        data-filtered={!!filters.tax}
      >
        <CustomOption value="">Tax</CustomOption>
        {vats.map((vat) => (
          <option key={vat.id} value={vat.id}>
            {vat.name} {vat.percentage}
          </option>
        ))}
      </select>,
      selector: row => row.tax || '-',
    },

    {
      name:  
      <select
        className="form-select"
        value={filters.location}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'location',
          value: e.target.value
        })}
        data-filtered={!!filters.location}
      >
        <CustomOption value="">Location</CustomOption>

        {locations.map((location) => (
          <CustomOption key={location.id} value={location.id}>
            {location.zone}
          </CustomOption>
        ))}
      </select>,
      selector: row => row.stock_zone || '-',
    },

    {
      name:  
      <select
        className="form-select"
        value={filters.status}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'status',
          value: e.target.value
        })}
        data-filtered={!!filters.status}
      >
        <CustomOption value="">Status</CustomOption>
        <CustomOption value="1">Active</CustomOption>
        <CustomOption value="0">Inactive</CustomOption>
      </select>,
      selector: row => <StatusToggle type="products" id={row.id} field="status" status={row.status} />,
    },

    {
      name:  
      <select
        className="form-select"
        value={filters.is_main_publish}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'is_main_publish',
          value: e.target.value
        })}
        data-filtered={!!filters.is_main_publish}
      >
        <CustomOption value="">View in main page</CustomOption>
        <CustomOption value="1">Yes</CustomOption>
        <CustomOption value="0">No</CustomOption>
      </select>,
      selector: row => <StatusToggle type="products" id={row.id}  field="is_main_publish" status={row.is_main_publish} />,
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default ProductsTable;
