import React from 'react'
import VatForm from '../../../Components/Products/VAT/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateVAT = () => {

  return (
      <PageContainer> 
          <BreadCrumb title="Create" pageTitle="VAT" to="/dashboard/products/vat" />
          <VatForm type="create" />
      </PageContainer>
  )
}

export default CreateVAT