import { settingsActions } from "../slices/settings";
import settingsApi from "../../apis/settings/api";
import toastAlert from "../../helpers/toastAlert";

// CRUD

export const getAllSettingsDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await settingsApi[payload.field].getAll(payload?.query);
    dispatch(
        settingsActions.setData({
        field: payload.field,
        data: response.data,
        total: response.meta?.total || null,
      })
    );
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const createSettingsDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await settingsApi[payload.field].create(payload.data);
    dispatch(
        settingsActions.createData({
        field: payload.field,
        data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload.onError) {
        payload?.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const updateSettingsDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await settingsApi[payload.field].update(payload.data.id, payload.data.data);
    dispatch(
        settingsActions.updateData({
        field: payload.field,
        id: payload.id,
        data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const deleteSettingsDataAction = (payload) => async (dispatch) => {
  try {
    await settingsApi[payload.field].delete(payload.data);
    dispatch(
        settingsActions.deleteData({
        field: payload.field,
        data: payload.data
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess();
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

// Web Settings

export const getWebSettingsAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await settingsApi.web.get(payload?.type || payload.field);
    dispatch(
        settingsActions.setSettings({
          field: payload.field,
          data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
  } catch (error) {
      console.log("error", error);
  }
};

export const setWebSettingsAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await settingsApi.web.update(payload.data);
    dispatch(
        settingsActions.setSettings({
          field: payload.field,
          data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
      payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};
