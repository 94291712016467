import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "./../../../helpers/objectFilter";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import { getOrderSettingsAction, setOrderSettingsAction } from "../../../redux/actions/orders";
import SaveButton from "../../Common/Buttons/SaveButton";

const Active = () => {
  
  const dispatch = useDispatch();

  const { active } = useSelector((state) => state.orders.settings);

  const { register, handleSubmit, reset } = useForm();

  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();

  const onSubmit = (data) => {
    setSubmitting(true)
    dispatch(
      setOrderSettingsAction({
        field: 'active',
        type: "order_active",
        data: {
          ...data,
        },
        onSuccess: () => setSubmitting(false),
        onError: () => setSubmitting(false),
      })
    );
  };

  useEffect(() => {
    if (active) reset(active);
  }, [active, reset]);

  useEffect(() => {
    dispatch(getOrderSettingsAction({ field: "active" }));
  }, [dispatch]);

  if (isEmpty(active)) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <Row className="gy-3">

        <Col md={6}>
          <h5>{t("Businesses")}</h5>
          <div className="d-flex flex-column gap-2">

           <div className="form-check form-switch">
              <input 
                {...register('companies_active')} 
                  className="form-check-input fs-16" 
                  type="checkbox" 
                  role="switch" 
              />
              <CustomLabel>Transport</CustomLabel>
           </div>

           <div className="form-check form-switch">
              <input 
                {...register('companies_pickup')} 
                  className="form-check-input fs-16" 
                  type="checkbox" 
                  role="switch" 
              />
              <CustomLabel>Self-Pickup</CustomLabel>          
           </div>
           
          </div>
        </Col>

        <Col md={6}>
          <h5>{t("Private")}</h5>
          <div className="d-flex flex-column gap-2">

           <div className="form-check form-switch">
               <input 
                {...register('private_active')} 
                  className="form-check-input fs-16" 
                  type="checkbox" 
                  role="switch" 
              />
              <CustomLabel>Transport</CustomLabel>   
           </div>

           <div className="form-check form-switch">
              <input 
                {...register('private_pickup')} 
                  className="form-check-input fs-16" 
                  type="checkbox" 
                  role="switch" 
              />
              <CustomLabel>Self-Pickup</CustomLabel>
           </div>

          </div>
        </Col>
      </Row>

      <div className="hstack gap-2 mt-3">
        <SaveButton 
          isLoading={submitting}
        />
      </div>
      
    </form>
  );
};

export default Active;
