import React from "react";
import SliderForm from '../../../Components/Settings/Sliders/Form';
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const CreateNewSlider = () => {

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Home Sliders" title="Create" to="/dashboard/settings/home-sliders" />
      <SliderForm type='create' />
    </PageContainer>
  )
};

export default CreateNewSlider;
