import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { useFileManager } from "../../../../context/FileManagerContext";
import { isImage } from "../../../../helpers/isImage";
import useSetFileExt from "../../../../hooks/useSetFileExt";
import SelectFileButton from "../../../Common/SelectFileButton";
import ImageItem from "../../../Common/ImageItem";

const Files = ({ initialValues, setValue }) => {

  const { openFileManagerModal } = useFileManager();

  const [productFiles, setProductFiles] = useState([]);

  useSetFileExt({
    whatFor: "product-gallery",
    cb: (file, cb) => {
      if (!isImage(file.ext)) return
      setProductFiles((prev) => {
        const imgExists = prev.some((fl) => +fl.id === +file.id);
        return [
          ...prev,
          !imgExists && {
            id: file.id,
            name: file.name,
            ext: file.ext,
            link: `${file.uri}/${file.name}`
          }    
        ];
      });
      cb();
    },
  });

  useSetFileExt({
    whatFor: "product-pdf",
    cb: (file, cb) => {
      if (file.ext !== 'pdf') return
      setProductFiles((prev) => {
        const pdfExists = prev.some(fl => +fl.id === +file.id);
        return [
          ...prev,
          !pdfExists && {
            id: file.id,
            name: file.name,
            ext: file.ext,
            link: `${file.uri}/${file.name}`
          } 
        ]
      });
      cb();
    },
  });

  const removeFile = (id) => {
    setProductFiles(prev => prev.filter((item) => item.id !== id));
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues && initialValues?.files?.length) {
      setProductFiles(initialValues?.files.map(file => ({
        id: file.id,
        name: file.name,
        ext: file.ext,
        link: `${file.uri}/${file.name}`
      })))
    }
  }, [initialValues])

  useEffect(() => {
    if (productFiles) {
      setValue('files', productFiles.map(item => item.id));
    }
  }, [productFiles])


  return (
    <Row>
      <Col md={12}>
        <h6>{t("Gallery")}</h6>
        <SelectFileButton
          text="Select image"
          onClick={() => openFileManagerModal("product-gallery")}
        />
        <div className="d-flex gap-2 flex-wrap mt-3">
          {
            productFiles?.filter(item => isImage(item.ext))?.map((file, index) => (
              <ImageItem
                key={index}
                file={file}
                removeFile={removeFile}
              />
            ))
          }
        </div>
        <h6 className="mt-4">{t("PDF")}</h6>

        <SelectFileButton
          text="Select image"
          onClick={() => openFileManagerModal("product-pdf")}
        />

        <div className="d-flex flex-wrap gap-2 mt-3">
          {productFiles?.filter(item => item.ext === 'pdf').map(file => (
            <div key={file.id} className="pdf-file-preview">
              <i className="ri-file-line"></i>
                {file.name}
                <i
                  className="ri-close-line cursor-pointer"
                  onClick={() => removeFile('pdfs', file.id)}
                ></i>
            </div>
          ))}

        </div>
      </Col>
    </Row>
  );
};

export default Files;
