import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  Input,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import ZoneOrderDay from "./ZoneOrderDay";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import createZoneSchema from "../../../helpers/validation/Users/createZoneValidation";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "../../Common/CustomButton";
import { formatTime } from "../../../helpers/formatTime";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextarea from "../../Custom/CustomTextarea";
import CustomButton from "../../Common/CustomButton";
import { createUsersDataAction, deleteUsersDataAction, updateUsersDataAction } from "../../../redux/actions/users";
import SaveButton from "../../Common/Buttons/SaveButton";

const ZoneForm = ({ type, initialValues }) => {

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(createZoneSchema),
  });

  const dispatch = useDispatch();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "delivery_days",
  });

  const history = useHistory();

  const submitForm = (data) => {
    setIsLoading(true);
    if (type === "create") {
      const requestBody = {
        ...data,
        delivery_days: data.delivery_days.map((day) => {
          return {
            ...day,
            time: formatTime(day.time),
          };
        }),
      };

      dispatch(
        createUsersDataAction({
          field: 'zones',
          data: requestBody,
          onSuccess: () => history.push("/dashboard/users/zones"),
        })
      );
    } else {
      const { id, created_at, deleted, delivery_days, ...rest } = data;

      const requestBody = {
        ...rest,
        new_delivery_days: delivery_days
          .filter((day) => day?.isNew === true)
          .map((day) => {
            return {
              order_day: day.order_day,
              delivery_day: day.delivery_day,
              time: formatTime(day.time),
            };
          }),
        old_delivery_days: delivery_days
          .filter((day) => !day?.isNew)
          .map((day) => ({
            id: day.id,
            order_day: day.order_day,
            delivery_day: day.delivery_day,
            time: formatTime(day.time),
          })),
      };

      dispatch(
        updateUsersDataAction({
          field: 'zones',
          data: {
            id,
            data: requestBody,
          },
          onSuccess: () => setIsLoading(false),
        })
      );
    }
  };

  const deleteZone = () => {
    if (!id) return;
    setIsDeleting(true);
    dispatch(
      deleteUsersDataAction({
        field: 'zones',
        data: {
          ids: [id],
        },
        onSuccess: () => history.push("/dashboard/users/zones"),
      })
    );
  };

  const AddItem = () => {
    append({
      delivery_day: "",
      order_day: "",
      time: "",
      isNew: true,
    });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Card>
        <CardBody>
          <Form
            onSubmit={() => {
              handleSubmit(submitForm);
            }}
          >
            <Input type="hidden" id="id-field" />
            <Row className="g-3">
              <Col lg={6}>
                <div>
                  <CustomLabel htmlFor="zone_name-field" className="form-label">
                    Name
                  </CustomLabel>
                  <input
                    id="zone_name-field"
                    className="form-control"
                    placeholder="Enter Zone Name"
                    type="text"
                    {...register("name")}
                  />
                  {errorMessage(errors, "name")}
                </div>
              </Col>

              <Col lg={6} className="d-flex align-items-center">
                <div className="form-check form-switch mt-4">
                  <input 
                    {...register('status')} 
                    className="form-check-input fs-16" 
                    type="checkbox" 
                    role="switch" 
                    id="status" 
                  />
                  <CustomLabel 
                    className="form-check-label" 
                    htmlFor="status"
                  >
                    Active
                  </CustomLabel>
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <CustomLabel htmlFor="zone_note-field" className="form-label">
                    Note
                  </CustomLabel>
                  <CustomTextarea
                    id="zone_note-field"
                    className="form-control"
                    placeholder="Enter Note"
                    type="textarea"
                    rows={3}
                    {...register("description")}
                  />
                  {errorMessage(errors, "description")}
                </div>
              </Col>

              {fields.map((field, index) => (
                <ZoneOrderDay
                  key={field.id}
                  index={index}
                  control={control}
                  remove={remove}
                  getValues={getValues}
                />
              ))}
            </Row>
          </Form>

          <CardFooter>
            <div className="hstack gap-2 justify-content-end">
              <CustomButton
                type="button"
                className="btn btn-primary"
                onClick={AddItem}
              >
                Add Day
              </CustomButton>

              <SaveButton 
                isLoading={isLoading}
              />
            </div>
          </CardFooter>

        </CardBody>
      </Card>
    </form>
  );
};

export default ZoneForm;
