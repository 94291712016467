import { useState } from "react"

export default function usePagePagination() {
    const [currentPage, setCurrentPage] = useState(1);

    const navigateToPage = (page) => {
        setCurrentPage(page)
    }

    return {
        currentPage, setCurrentPage, navigateToPage
    }
}