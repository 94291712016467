import React from "react";
import CreateUserTabs from "./CreateUser/CreateUserTabs";

const UserForm = ({ type, initialValues, refetchTracking }) => {
  
  return (
      <CreateUserTabs 
        type={type} 
        initialValues={initialValues} 
        refetchTracking={refetchTracking} 
      />
  );
};

export default UserForm;
