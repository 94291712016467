import axios from "axios";
import API_URL from "../../config/api";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "dinengrosToken"
      )}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
