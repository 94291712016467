import React from "react";
import {
  Col,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { useNotificationsValue } from "../../context/notificationsContext";
import CustomButton from "../Common/CustomButton";
import NotificationItem from "./NotificationItem";
import { useTranslation } from "react-i18next";

const NotificationsWrapper = () => {

  const { meta, notifications, isRefetching, changePageSize } = useNotificationsValue();

  const { t } = useTranslation();

  return (
    <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
      <div className="dropdown-head bg-primary bg-pattern rounded-top">
        <div className="p-3">
          <Row className="align-items-center">
            <Col>
              <h6 className="m-0 fs-16 fw-semibold text-white">
                {t("Notifications")}
              </h6>
            </Col>
            <div className="col-auto dropdown-tabs">
              <span className="badge badge-soft-light fs-13">
                {meta.unseen} {t("New")}
              </span>
            </div>
          </Row>
        </div>

        <div className="px-2 pt-2">
          <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: true })}
                onClick={() => {
                  // toggleTab("1");
                }}
              >
                {t("All")} ({meta.total})
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>

      <TabContent activeTab={"1"}>
        <TabPane tabId="1" className="py-2 ps-2">
          <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
            {notifications.map((notification) => {
              return (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              );
            })}

            <div className="my-3 text-center">
              <CustomButton
                type="button"
                isLoading={isRefetching}
                onClick={() => changePageSize(10)}
                className="btn btn-soft-success waves-effect waves-light"
              >
                {t("View More")}{" "}
                <i className="ri-arrow-right-line align-middle"></i>
              </CustomButton>
            </div>
          </SimpleBar>
        </TabPane>
      </TabContent>
    </DropdownMenu>
  );
};

export default NotificationsWrapper;
