import * as yup from "yup";


export const createLocationSchema = yup.object().shape({
  code: yup.string().required(),
  description: yup.string().required(),
  corridor: yup.string().required(),
  rack: yup.string().required(),
  export_id: yup.string().required(),
  label_transport: yup.string().required(),
  temp_transport: yup.string().required(),
  sort: yup.string().required()
});
