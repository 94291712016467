export const ADMIN_AND_ROLES_PERMISSIONS = {
    read: ["admins_and_roles-read"],
    update: ["admins_and_roles-update"],
    delete: ["admins_and_roles-delete"],
    create: ["admins_and_roles-create"],
  };
  
export const STOCK_STAFF = {
    read: ["stock_staff-read"],
    update: ["stock_staff-update"],
    delete: ["stock_staff-delete"],
    create: ["stock_staff-create"],
};

export const FILE_MANAGER = {
    read: ["file_manager-read"],
    update: ["file_manager-update"],
    delete: ["file_manager-delete"],
    create: ["file_manager-create"],
};

  