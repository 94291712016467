import React from "react";
import DataTable from "../../Common/DataTable";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import { Controller } from "react-hook-form";
import { isEmpty } from "./../../../helpers/objectFilter";
import { useTranslation } from "react-i18next";

const OpeningHoursTable = ({ control, weekdays, register, ...props }) => {

  const { t } = useTranslation();

  const { openingHours } = useSelector((state) => state.settings.webSettings);

  const rows = weekdays.map((day) => {
    let lowerCaseDate = day.toLowerCase();
    return {
      name: day,
      nameLower: lowerCaseDate,
      start: openingHours[`opening_hour_${lowerCaseDate}_start`],
      end: openingHours[`opening_hour_${lowerCaseDate}_end`],
      status: openingHours[`opening_hour_${lowerCaseDate}_status`],
    };
  });

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Day")}</span>,
      selector: (row) => t(row.name),
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Active")}</span>,
      selector: (row) => (
        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            {...register(`opening_hour_${row.nameLower}_status`)}
            defaultChecked={row.status === "1"}
          />
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Opening time")}</span>,
      selector: (row) => {
        return (
          <Controller
            name={`opening_hour_${row.nameLower}_start`}
            control={control}
            value={row.start}
            defaultValue={row.start}
            render={({ name, field: { onChange } }) => {
              return (
                <Flatpickr
                  className="form-control"
                  autoFocus={false}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                    mode: "time",
                  }}
                  control={control}
                  name={name}
                  value={row.start}
                  onChange={(e) => {
                    onChange(e[0].toLocaleTimeString().slice(0, 5));
                  }}
                />
              );
            }}
          />
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Closing time")}</span>,
      selector: (row) => {
        return (
          <Controller
            name={`opening_hour_${row.nameLower}_end`}
            control={control}
            defaultValue={row.end}
            render={({ field }) => {
              return (
                <Flatpickr
                  className="form-control"
                  autoFocus={false}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                    mode: "time",
                  }}
                  control={control}
                  name={field.name}
                  value={row.end}
                  onChange={(e) => {
                    field.onChange(
                      e[0].toLocaleTimeString("en-GB").slice(0, 5)
                    );
                  }}
                />
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      {rows.length > 0 && !isEmpty(openingHours) && (
        <DataTable 
          data={rows} 
          columns={columns} 
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default OpeningHoursTable;
