import { objectFilter } from "../../objectFilter";


export const createOfferBody = (data) => {

    return objectFilter({
        ...data,
        status: data.status,
        products: data.products.map(prod => ({
            product_id: prod.product_id,
            units: prod.units.map(unit => ({
                unit_id: unit.unit_id,
                price: unit.price
            }))
        }))
      })
}


export const updateOfferBody = (data) => {

    return objectFilter({
        title: data.title,
        status: data.status,
        start_at: data.start_at,
        end_at: data.end_at,
        file_id: data.file_id,
        products: data.products.map(prod => ({
            product_id: prod.product_id,
            units: prod.units.map(unit => ({
                unit_id: unit.unit_id,
                price: unit.price
            }))
        }))
      })
}



