import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, CardFooter, Col, Form, Row } from "reactstrap";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Link } from "react-router-dom";
import ConsultSchema from "../../../helpers/validation/WebSettings/ConsultValidation";
import { useDispatch, useSelector } from "react-redux";
import useSetFile from "../../../hooks/useSetFile";
import { useFileManager } from "../../../context/FileManagerContext";
import SelectFileButton from "./../../Common/SelectFileButton";
import { WEB_SETTINGS_PERMISSIONS } from "./../../../permissions/webSettings";
import Restricted from "./../../Guard/Restricted";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import CustomButton from "../../Common/CustomButton";
import { useTranslation } from "react-i18next";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";

const Consult = () => {

  const [consultImage, setConsultImage] = useState(null);

  const FOR_CONSULT = "consult-image";

  const { openFileManagerModal } = useFileManager();

  useSetFile({
    whatFor: FOR_CONSULT,
    cb: (file) => {
      setConsultImage(file.path);
    },
  });

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { consult } = useSelector((state) => state.settings.webSettings);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ConsultSchema),
    defaultValues: consult,
  });

  //
  const submitConsult = (data) => {
    const requestBody = {
      type: "consult",
      data: {
        ...data,
        consult: consultImage === null ? consult.consult : consultImage,
      },
    };
    dispatch(setWebSettingsAction({
      field: 'consult',
      data: {
        type: 'consult',
        data: requestBody
      }
    }));
  };

  useEffect(() => {
    if (consult) {
      setConsultImage(consult.consult);
      reset(consult);
    }
  }, [consult]);

  useEffect(() => {
    dispatch(getWebSettingsAction({
      field: 'consult',
    }));
  }, []);

  return (
    <Form onSubmit={handleSubmit(submitConsult)}>
      <input type="submit" hidden />
      <Row className="my-6">
        <Col lg={9} className="mb-3">
          <div>
            <CustomLabel htmlFor="Address-input" className="form-label">
              Address
            </CustomLabel>

            <CustomInput
              type="text"
              className="form-control"
              id="Address-input"
              {...register("address")}
            />

            {errorMessage(errors, "address")}
          </div>
        </Col>

        <Col lg={3} className="mb-3">
          <CustomLabel>Address Type</CustomLabel>
          <Controller
            name="address_type"
            control={control}
            defaultValue={consult?.address_type}
            render={({ name, field: { onChange } }) => (
              <Select
                name={name}
                options={[
                  { value: "English", label: t("English") },
                  { value: "Norwegian", label: t("Norwegian") },
                ]}
                defaultValue={[
                  {
                    value: consult?.address_type,
                    label: t(consult?.address_type),
                    Controller,
                  },
                ]}
                onChange={(value) => {
                  return onChange(value.value);
                }}
              />
            )}
          />

          {errorMessage(errors, "address_type")}
        </Col>

        <Col lg={6} className="mb-3">
          <CustomLabel htmlFor="latitude-input" className="form-label">
            latitude
          </CustomLabel>

          <CustomInput
            type="number"
            className="form-control"
            id="latitude-input"
            {...register("latitude")}
            step="any"
          />

          {errorMessage(errors, "latitude")}
        </Col>

        <Col lg={6} className="mb-3">
          <CustomLabel htmlFor="longitude-input" className="form-label">
            longitude
          </CustomLabel>

          <CustomInput
            type="number"
            className="form-control"
            id="longitude-input"
            {...register("longitude")}
            step="any"
          />

          {errorMessage(errors, "longitude")}
        </Col>

        <Col lg={6}>
          <div className="mb-3 mb-xl-0">
            <CustomLabel htmlFor="cleave-phone" className="form-label">
              Phone
            </CustomLabel>

            <CustomInput
              placeholder="xxxx xxx xxx"
              defaultValue={consult.phone}
              className="form-control"
              {...register("phone")}
            />

            {errorMessage(errors, "phone")}
          </div>
        </Col>

        <Col lg={6} className="mb-3">
          <div>
            <CustomLabel htmlFor="Email-input" className="form-label">
              Email
            </CustomLabel>

            <CustomInput
              defaultValue={consult.email}
              type="text"
              className="form-control"
              id="Email-input"
              {...register("email")}
            />
            {errorMessage(errors, "email")}
          </div>
        </Col>

        <Col lg={6} className="mb-3">
          <div>
            <CustomLabel htmlFor="consult-image" className="form-label">
              Consult
            </CustomLabel>
            <SelectFileButton
              text="Select Consult Image"
              onClick={() => openFileManagerModal(FOR_CONSULT)}
            />
            <p className="my-2">
              {t("The consult picture's background image")}
            </p>
            {errorMessage(errors, "consult")}
          </div>
          {consultImage && (
            <div style={{ position: "relative", maxWidth: "150px" }}>
              <img className="img-thumbnail mt-4" alt="" src={consultImage} />
              <i
                className="ri-delete-bin-fill"
                style={{
                  position: "absolute",
                  top: "25%",
                  right: "5%",
                  fontSize: "1.1rem",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => setConsultImage("")}
              ></i>
            </div>
          )}
        </Col>
        <CardFooter className="px-0 mt-5">
          <div className="d-flex align-items-center gap-2">
            <Link to="/dashboard/">
              <CustomButton type="button" className="btn btn-light">
                Close
              </CustomButton>
            </Link>
            <Restricted permission={WEB_SETTINGS_PERMISSIONS.update}>
              <Button type="submit" color="success">
                <i className="ri-save-line align-bottom me-1"></i> {t("Save")}
              </Button>
            </Restricted>
          </div>
        </CardFooter>
      </Row>
    </Form>
  );
};

export default Consult;
