import React from 'react'
import TransportForm from '../../../Components/Orders/Transports/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateTransport = () => {

  return (
      <PageContainer> 
            <BreadCrumb title="Create" pageTitle="Transport" to="/dashboard/orders/transport" />
            <TransportForm
              type='create'
            />
      </PageContainer>
  )
}

export default CreateTransport