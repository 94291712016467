import React, { useState } from "react";
import { Col, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Prices from "./Prices";
import Files from "./Files";
import Notifier from "./Notifier";
import ProductLotMovement from "./ProductLotMovement";
import ProductVars from "./ProductVars";
import CustomNavLink from "../../../Custom/CustomNavLink";
import Discounts from "./Discounts/Discounts";

const TABS = [
  {
    label: 'Prices',
    component: Prices
  },
  {
    label: 'Files',
    component: Files
  },

  {
    label: 'Notifier',
    component: Notifier
  },

  {
    label: 'Lot Movement',
    component: ProductLotMovement
  },

  {
    label: 'Vars',
    component: ProductVars
  },

  {
    label: 'Discounts',
    component: Discounts
  },
]

const CreateProductTabs = ({ initialValues, register, setValue, watch }) => {

  const [activeTab, setactiveTab] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  return (
    <Col md={12} className="mt-4">

      <Nav tabs className="nav-tabs mb-3">

        {TABS.map((item, index) => (
          <NavItem key={index}>
            <CustomNavLink
              style={{ cursor: "pointer" }}
              className={classnames({ active: activeTab === index })}
              onClick={() => {
                toggle(index);
              }}
            >
              {item.label}
            </CustomNavLink>
          </NavItem>
        ))}

      </Nav>

      <TabContent activeTab={activeTab} className="text-muted">

        {TABS.map((item, index) => (
          <TabPane key={index} tabId={index} id={item.label}>
              <item.component
                initialValues={initialValues}
                register={register}
                setValue={setValue}
                watch={watch}
              />
          </TabPane>
        ))}

      </TabContent>
    </Col>
  );
};

export default CreateProductTabs;
