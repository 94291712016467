import React, { useEffect, useState } from "react";
import DataTable from "../../Common/DataTable";
import { Card, Nav, NavItem } from "reactstrap";
import storageApi from "../../../apis/storage/api";
import toastAlert from "../../../helpers/toastAlert";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import CustomButton from "../../Common/CustomButton";
import { useHistory } from "react-router-dom";
import CustomNavLink from "../../Custom/CustomNavLink";
import { useTranslation } from "react-i18next";
import { getAllProductsDataAction } from "../../../redux/actions/products";
import CustomInput from "../../Custom/CustomInput";

const ControlTable = ({ filters, handleFilterChange, data, ...props }) => {
  
  const [loading, setLoading] = useState(false);
  
  const [submittedProdId, setSubmittedProdId] = useState("");

  const { t } = useTranslation();

  const [activeTab, setactiveTab] = useState("all");
  
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const [differences, setDifferences] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();

  const handleChange = (product, value) => {
    setDifferences((prev) =>
      prev.map((prod) => {
        if (prod.id === product.id) return { ...prod, difference: value };
        return prod;
      })
    );
  };

  const submitChange = async (product) => {
    setLoading(true);
    try {
      setSubmittedProdId(product.id);
      const requestBody = {
        product_id: product.id,
        qty: product.total_stock,
        difference: differences.find((df) => df.id === product.id).difference || 0,
        control: 1,
        stock_id: 1,
      };
      await storageApi.control.create(product.id, requestBody);
      toastAlert({ type: 'success'})
      dispatch(getAllProductsDataAction({
        field: 'products',
        query: {
          ...filters,
          for: 'control'
        }
      }));
      setactiveTab("all");
      setSubmittedProdId("");
    } catch (error) {
      toastAlert({ type: 'error' })
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product Number")}</span>
      ),
      selector: (row) => row.matricule,
    },

    {
      name:  
      <CustomInput
        className="form-control"
        placeholder="Product"
        value={filters.name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        })}
        data-filtered={!!filters.name}
      />,
      selector: (row) => (
        <span
          className="cursor-pointer"
          title={row.name}
          onClick={() => history.push(`/dashboard/products/update/${row.id}`)}
        >
          <b>{row.name}</b>
        </span>
      ),
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Unit")}</span>,
      selector: (row) => row.units || "-",
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Total in stock")}</span>
      ),
      selector: (row) => row.total_stock,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Control")}</span>,
      selector: (row) => (
        <input
          className="form-control"
          value={differences.find((df) => df.id === row.id)?.difference || 0}
          onChange={(e) => handleChange(row, e.target.value)}
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Checked")}</span>,
      selector: (row) => (
        <CustomButton
          type="button"
          className="btn btn-primary btn-sm "
          isLoading={loading && submittedProdId === row.id}
          onClick={() => submitChange(row)}
        >
          <i className="ri-check-line"></i>
        </CustomButton>
      ),
    },
  ];

  useEffect(() => {
    setDifferences(
      data?.map((prod) => ({
        id: prod.id,
        difference: prod.stock_controls?.difference,
      }))
    );
  }, [data]);

  const filterProducts = (prods) => {
    if (prods.length === 0) return [];
    if (activeTab === "all") return prods;
    if (activeTab === "controlled")
      return prods.filter((prod) => !!prod.stock_controls);
    if (activeTab === "notControlled")
      return prods.filter((prod) => !prod.stock_controls);
  };

  return (
    <div>
        <Card className="mb-2">
          <Nav tabs className="nav-tabs p-2">
            <NavItem>
              <CustomNavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "all" })}
                onClick={() => toggleTab("all")}
              >
                All
              </CustomNavLink>
            </NavItem>
            <NavItem>
              <CustomNavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "controlled" })}
                onClick={() => toggleTab("controlled")}
              >
                Controlled
              </CustomNavLink>
            </NavItem>

            <NavItem>
              <CustomNavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "notControlled" })}
                onClick={() => toggleTab("notControlled")}
              >
                Not Controlled
              </CustomNavLink>
            </NavItem>
          </Nav>
      </Card>

      <Card>
        <DataTable
          data={filterProducts(data)}
          columns={columns}
          {...props}
        />
      </Card>

    </div>
  );
};

export default ControlTable;
