import React from 'react'
import DeliveryMethodForm from '../../../Components/Orders/DeliveryMethods/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateDeliveryMethod = () => {

  return (
      <PageContainer> 
          <BreadCrumb title="Create" pageTitle="Delivery Methods" to="/dashboard/orders/delivery-methods" />
          <DeliveryMethodForm 
            type='create'
          />
      </PageContainer>
  )
}

export default CreateDeliveryMethod