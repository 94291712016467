import html2pdf from "html2pdf-jspdf2";
import { calculatePrices, extractQuantityBasedonUnit } from "../../calculateOrderPrices";

  const opt = {
    margin: 2,
    filename: 'myfile.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      dpi: 192,
      scale:4,
      letterRendering: true,
      useCORS: true
    },
  };

const exportOrderTransportPdf = async ({ data, order, type = 'order', vats, adminData, generateFile }) => {

  let mvaTotal = {};

  let list = [];
  if (order?.merged === 1 || order?.merged === true) {
    Object.values(order.items).forEach((items) => {
      items.filter(item => !item.no_stock).forEach((item) => {
        list.push(item);
      });
    });
  } else {
    if (type === "order") {
      list = order.items.filter(item => !item.no_stock);
    } else {
      list = order.products.filter(item => !item.no_stock);
    }
  }

  list.forEach((item) => {
    const vat = vats.find((vat) => vat.id === item.product.tax)?.percentage;
    if (!vat) return
    const promo = (+item.price * +item.promo) / 100;

    const qty = extractQuantityBasedonUnit(item);

    const netPrice = +item.price * +qty - +promo * +qty;
    const tax = +vat * +netPrice / 100;
    const afterTax = +netPrice + +tax;

    if (!mvaTotal[vat]) {
      mvaTotal[vat] = {
        netPrice,
        tax,
        afterTax
      }
    } else {
      mvaTotal[vat] = {
        ...mvaTotal[vat],
        netPrice: mvaTotal[vat].netPrice + netPrice,
        tax:  mvaTotal[vat].tax + tax,
        afterTax:  mvaTotal[vat].afterTax + afterTax
      }
    }
  });

  const taxTotal = Number(Object.entries(mvaTotal).reduce((acc, cur) => {
    let currentTax = {
      ...cur[1]
    }

    return acc + +currentTax.tax 
  }, 0)).toFixed(2)


  const deliveryTotal = Number(calculatePrices({order, type}).extraCost).toFixed(2)

  const netPrice = Number(calculatePrices({order, type}).netPrice).toFixed(2);

  const promoTotal = Number(calculatePrices({order, type}).discountPrice).toFixed(2);

  const afterPromo = Number(+netPrice - +promoTotal).toFixed(2)

  const totalPrice = Number(+calculatePrices({order, type}).sellingPrice + +taxTotal).toFixed(2)


  const transport = data.transport;

  const htmlData = /*html*/`
  <!DOCTYPE >
  <html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>pdf-html</title>
    <style>
      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      body {
        width: 701px;
        margin: 0 auto;
        font: 8px "Arial";
        background-color: #e6e2e2;
      }

      table td {
        font: 9px "Arial";
      }
      
      .w-595 {
        width: 700px;
        background-color: white;
        margin: 0 auto;
      }
      .w-half {
        width: 175px;
      }
      .w-100 {
        width: 100%;
      }
      .w-6 {
        width: 6.6%;
      }
      .w-20 {
        width: 20%;
      }
      .w-25 {
        width: 25%;
      }
      .w-30 {
        width: 30%;
      }
      .w-50 {
        width: 50%;
      }
      .w-60 {
        width: 60%;
      }
      .w-64 {
        width: 64%;
      }
      .W-70 {
        width: 70%;
      }
      .w-15 {
        width: 15%;
      }
      .title_fak {
        text-align: left;
        padding-left: 69px;
        margin-top: 0px;
        margin-bottom: 0px;
        white-space: nowrap;
        font: bold 19px "Arial";
        line-height: 22px;
      }
      .text-left {
        text-align: left;
      }
      .text-right {
        text-align: right;
      }
      .text-center {
        text-align: center;
      }
      .border-right {
        border-right: 1px solid black;
      }
      .border-left {
        border-left: 1px solid black;
      }
      .border-top {
        border-top: 2px solid black;
      }
      .border-bottom {
        border-bottom: 2px solid black;
      }

      .border-bottom-d {
        border-bottom: 1px dashed black;
      }
      .border-top-d {
        border-top: 1px dashed black;
      }
      .border-left-d {
        border-left: 1px dashed black;
      }
      .border-right-d {
        border-right: 1px dashed black;
      }
      .d-block {
        display: block;
      }
      .inline-block {
        display: inline-block;
      }
      .pl2 {
        padding-left: 2px;
      }
      .pl5 {
        padding-left: 5px;
      }
      .pl10 {
        padding-left: 10px;
      }
      .pb2 {
        padding-bottom: 2px;
      }
      .pr4 {
        padding-right: 4px;
      }
      .pr30 {
        padding-right: 30px;
      }
      .f9 {
        font-size: 9px;
        line-height: 12px;
        /* padding-top: 2px; */
        /* margin-bottom: 2px; */
      }
      .f12 {
        font-size: 12px;
        line-height: 15px;
      }
      .f15 {
        font-size: 15px;
        line-height: 17px;
      }
      .barImg {
        width: 200px;
        height: 50px;
        padding-bottom: 4px;
      }

      .sq {
        width: 14px;
        height: 14px;
        display: block;
        border: 1px solid black;
      }
      .sq_l {
        width: 14px;
        height: 14px;
        display: inline-block;
        border: 1px solid black;
        vertical-align: text-bottom;
        text-align: center;
      }
      .first_sq {
        margin: 0 auto;
        margin-right: 36px;
      }
      .tb-prod td p {
        padding-left: 2px;
      }
      .p38 {
        padding-top: 10px;
        height: 100px;
        padding-left: 2px;
      }
      .p8 {
        padding: 8px 0px;
      }
      .p4 {
        padding: 4px 2px;
      }
      .gray {
        background-color: #dddddd;
        font-weight: 900;
        font-size: 14px;
      }
      .f8 {
        font-size: 8px;
      }
      .pt10 {
        padding-top: 10px;
      }
      .pt2 {
        padding-top: 2px;
      }

      .p11 {
        padding-bottom: 3rem;
      }

      .sq1 {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 1px solid;
        text-align: center;
        margin-left: 1px;
      }

      .parent-table {
        border: 2px solid black;
      }

    </style>
  </head>

  <body>
    <table class="w-595 parent-table" cellpadding="0" cellspacing="0">
      <tr>
        <td>
          <table class="w-595" cellpadding="0" cellspacing="0">
            <tr>
              <td colspan="2" class="w-50">
                <span class="title_fak">FRAKTBREV</span>
              </td>
            </tr>
            <tr>
              <td class="border-right border-top" colspan="2">
                <span class="d-block f9 pl2">1 Senders navn/kode</span>
                <span class="d-block f12 pl2">Din Engros AS</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-top-d border-right">
                <span class="d-block f9 pl2">2 Adresse</span>
                <span class="d-block f12 pl2">Heggstadmoen 5</span>
              </td>
              <td class="border-top">
                <span class="d-block f9 pl2">17 Utstedelsedato</span>
                <span class="d-block f12 pl2">${data.date.actual_pickup || ' '}</span>
              </td>
            </tr>

            <tr>
              <td class="w-15 border-top-d">
                <span class="d-block f9 pl2">3 Stedsnr</span>
                <span class="d-block f12 pl2">7080</span>
              </td>
              <td class="border-top-d border-right">
                <span class="d-block f9 pl2 border-left">Sted</span>
                <span class="d-block f12 pl2">HEIMDAL</span>
              </td>

              <td class="border-top border-right">
                <span class="d-block f9 pl2"> 16 Senders referanse</span>
                <span class="d-block f12 pl2">${order.matricule}</span>
              </td>
              <td class="border-top">
                <span class="d-block f9 pl2">18 Booking referanse</span>
                <span class="d-block f12 pl2">&nbsp;</span>
              </td>
            </tr>

            <tr>
              <td colspan="2" class="border-top-d border-right">
                <span class="d-block f9 pl2">4 Mottakers navn/kode</span>
                <span class="d-block f12 pl2">${
                  order.company.profile.company
                }</span>
              </td>
              <td class="border-top">
                <span class="d-block f9 pl2 border-right">
                  19 Annen fraktbetalers navn/kode</span
                >
                <span class="d-block f12 pl2">Din Engros AS</span>
              </td>
              <td class="border-top">
                <span class="d-block f9 pl2">20 Kundenr.</span>
                <span class="d-block f12 pl2">${
                  order?.company?.profile?.matricule || '-'
                }</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-top-d border-right">
                <span class="d-block f9 pl2">5 Adresse </span>
                <span class="d-block f12 pl2">${
                  order?.company?.address?.street_name || '-'
                }</span>
              </td>
              <td colspan="2" class="border-top">
                <span class="d-block f9 pl2"> 21 Adresse </span>
                <span class="d-block f12 pl2">&nbsp;</span>
              </td>
            </tr>
            <tr>
              <td class="w-15 border-top-d">
                <span class="d-block f9 pl2">6 Stedsnr</span>
                <span class="d-block f12 pl2">${
                  order?.company?.address?.street_zip_code || '-'
                }</span>
              </td>
              <td class="border-top-d border-right">
                <span class="d-block f9 pl2 border-left">Sted</span>
                <span class="d-block f12 pl2">${order?.company?.address?.post_name}</span>
              </td>
              <td colspan="2">
                <table class="w-100" cellpadding="0" cellspacing="0">
                  <td class="w-30 border-top-d">
                    <span class="d-block f9 pl2">22 Stedsnr.</span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                  <td class="border-top-d">
                    <span class="d-block f9 pl2 border-left">Sted</span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-top border-right">
                <span class="d-block f9 pl2">7 Leveres til: navn/kode</span>
                <span class="d-block f12 pl2">${
                  order?.company?.profile?.company
                }</span>
              </td>
              <td class="border-top border-right">
                <span class="d-block f9 pl2">
                  23 Mottakers kundenr. hos transportør</span
                >
                <span class="d-block f12 pl2">&nbsp;</span>
              </td>
              <td class="border-top">
                <span class="d-block f9 pl2">25 Mottakers referanse</span>
                <span class="d-block f12 pl2">&nbsp;</span>
              </td>
            </tr>

            <tr>
              <td colspan="2" class="border-top-d border-right">
                <span class="d-block f9 pl2">8 Leveringsadresse</span>
                <span class="d-block f12 pl2">${
                  order?.company?.address?.post_name
                }</span>
              </td>
              <!-- tables in -->
              <td class="border-right border-top">
                <table class="w-100" cellpadding="0" cellspacing="0">
                  <td class="w-70">
                    <span class="d-block f9 pl2 border-right"
                      >24 Vareforsikringsverdi
                    </span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                  <td>
                    <span class="d-block f9 pl2 text-right pr4">-kategori</span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                </table>
              </td>
              <td class="border-top">
                <table class="w-100" cellpadding="0" cellspacing="0">
                  <td class="w-60">
                    <span class="d-block f9 pl2">26 Polisenr. </span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                  <td>
                    <span class="d-block f9 pl2">Skal forsikres</span>
                    <span class="d-block f12 pl2">
                      <span class="sq first_sq"></span>
                    </span>
                  </td>
                </table>
              </td>
            </tr>
            <tr>
              <td class="w-15 border-top">
                <span class="d-block f9 pl2">9 Stedsnr. </span>
                <span class="d-block f12 pl2">${
                  order?.company?.address?.post_zip_code
                }</span>
              </td>
              <td class="border-top border-right">
                <span class="d-block f9 pl2">Utleveringssted</span>
                <span class="d-block f12 pl2">${order?.company?.address?.post_name}</span>
              </td>
              <td colspan="2" class="border-top">
                <span class="d-block f9 pl2">27 Leveringsbetingelse </span>
                <span class="d-block f12 pl2">&nbsp;</span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-top border-right">
                <span class="d-block f9 pl2">10 Transportør</span>
                <span class="d-block f12 pl2">${
                  transport?.name || "Not provided"
                }</span>
              </td>
              <td colspan="2" class="border-top">
                <span class="d-block f9 pl2">28 Frakt og omkostninger</span>
                <span class="f9 pl2 inline-block w-20">Belastes :</span>
                <span class="f9 pl2 inline-block w-20">
                  <span class="sq_l"></span>
                  Sender
                </span>
                <span class="f9 pl2 inline-block w-25">
                  <span class="sq_l"></span>
                  Mottaker
                </span>
                <span class="f9 pl2 inline-block">
                  <span class="sq_l">X</span>
                  Annen fraktbetaler
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-top border-right">
                <table class="w-100" cellpadding="0" cellspacing="0">
                  <td class="w-50 border-right">
                    <span class="d-block f9 pl2">11 Over </span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                  <td class="w-25 border-right">
                    <span class="d-block f9 pl2">12 Utvekls. paller</span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                  <td class="w-25">
                    <span class="d-block f9 pl2">13 Andre paller</span>
                    <span class="d-block f12 pl2">&nbsp;</span>
                  </td>
                </table>
              </td>
              <td colspan="2" class="border-top">
                <table class="w-100" cellpadding="0" cellspacing="0">
                  <td class="w-20">
                    <span class="inline-block f9 pl2">29 Transport koder</span>
                  </td>
                  <td class="text-center f12 border-right border-left">
                    <span class="d-block">A</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12">
                    <span class="d-block">B</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">C</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">D</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">E</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">F</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">G</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">H</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">I &nbsp;</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">K</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">L</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                  <td class="text-center f12 border-left">
                    <span class="d-block">M</span>
                    <span class="d-block">&nbsp;</span>
                  </td>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="border-top border-right">
                <span class="d-block f9 pl2">14 Transportprodukt </span>
                <span class="d-block f12 pl2">&nbsp;</span>
                <span class="d-block f12 pl2">&nbsp;</span>
                <span class="d-block f12 pl2">&nbsp;</span>
              </td>
              <td colspan="2" class="border-top">
                <table class="w-100" cellpadding="0" cellspacing="0">
                  <tr>
                    <td>
                      <span class="d-block f9 pl2">30 Leveringsinstruks </span>
                      <span class="d-block f12 pl2">&nbsp;</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-top border-right w-50">
                      <span class="d-block f9 pl2"
                        >31 Senders post/bank kontonr.
                      </span>
                      <span class="d-block f12 pl2">&nbsp;</span>
                    </td>
                    <td class="border-top">
                      <span class="d-block f9 pl2">32 Girobeløp </span>
                      <span class="d-block f12 pl2">&nbsp;</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <table
            class="w-595 tb-prod border-top border-bottom"
            cellpadding="0"
            cellspacing="0"
          >
          <tr>
              <td class="w-25 border-right">
                <p class="p12 ft10">33 Merking </p>
              </td>
              <td class="border-right">
                <p class="p13 ft10">34 Ant. kolli</p>
              </td>
              <td class="border-right">
                <p class="p13 ft10">35 Type pakning - godsslag</p>
              </td>

              <td class="border-right">
                <p class="p5 ft10">36 Bruttovekt kg</p>
              </td>

              <td class="w-25">
                <p class="p14 ft10">37 Mål (l x b x h /m3)</p>
              </td>
            </tr>

            ${data?.units?.map(
              (ele) => /*html*/`
              <tr>
              <td class="w-25 border-right">
                <p class="p12 ft10">${ele.marks}</p>
              </td>
              <td class="border-right">
                  <p class="p13 ft10">${ele.units}</p>
              </td>
              <td class="border-right">
                  <p class="p13 ft10">${ele.unit_type.label}</p>
              </td>
              <td class="border-right">
                <p class="p5 ft10">${ele.weight}</p>
              </td>
          
              <td class="w-25">
                <p class="p14 ft10">
                ${`
                (
                  ${+ele.length} x ${+ele.width} x ${+ele.height}
                ) =
                ${(+ele.length * +ele.width * +ele.height).toFixed(2)}`}</p>
              </td>
              </tr>
          `
            )}
  
            <!-- last tr for result -->
            <tr>
              <td class="w-25 border-right border-top">
                <p class="p12 ft10"></p>
              </td>
              <td class="border-right border-top">
                <p class="p13 ft10">${data.units.reduce((accum, val)=> {
                    return +accum + +val.units
                }, 0)}</p>
              </td>
              <td class="border-right border-top">
                <p class="p13 ft10"></p>
              </td>

              <td class="border-right border-top">
                <p class="p5 ft10">${data.units.reduce((accum, val)=> {
                  return +accum + +val.weight
              }, 0)}</p>
              </td>

              <td class="w-25 border-top">
                <p class="p14 ft10"></p>
              </td>
            </tr>
          </table>

          <table
            class="w-595 p38"
            cellpadding="0"
            cellspacing="0"
          >
            <tr>
              <td style="vertical-align: top">
                <div class="p11 ft10">38</div>
              </td>
            </tr>

            <tr>
              <td class="border-top border-bottom p4">
                <div>Kontaktperson:</div>
                <div> ${order?.company?.first_name + ' ' + order?.company?.last_name} ${order?.company?.profile?.phone || ' '}</div>
              </td>
            </tr>


          </table>

          <table
            class="w-595"
            cellpadding="0"
            cellspacing="0"
            style="margin-top: 30%"
          >
            <tr>
              <td colspan="3" rowspan="6"></td>
              <td colspan="2" class="border-top border-left text-center p8">
                <span>39 Fraktberegningsvekt (kg.)</span>
              </td>
            </tr>
            <tr>
              <td class="border-top border-left p4 pl2">
                <span>40 tekst</span>
              </td>
              <td class="border-top border-left p4 pl2">
                <span>41 beløp</span>
              </td>
            </tr>
            <tr>
              <td class="border-top border-left p4 pl2">
                <span>&nbsp;</span>
              </td>
              <td class="border-top border-left p4 pl2">
                <span>&nbsp;</span>
              </td>
            </tr>
            <tr>
              <td class="border-top border-left p4 pl2">
                <span>${order.description || 'Leveranse'}</span>
              </td>
              <td class="border-top border-left p4 pl2">
                <span>${deliveryTotal}</span>
              </td>
            </tr>
            <tr>
              <td class="border-top border-left p4 pl2">
                <span>Utkjøring</span>
              </td>
              <td class="border-top border-left p4 pl2">
                <span></span>
              </td>
            </tr>
            <tr>
              <td class="border-top border-left p4 pl2">
                <span>sum</span>
              </td>
              <td class="border-top border-left p4 pl2">
                <span>${afterPromo}</span>
              </td>
            </tr>
            <tr>
              <td class="text-center gray border-right border-top w-25">
                SENDERSTED
              </td>
              <td class="text-center gray border-right border-top w-25">
                UTLEVERINGSSTED
              </td>
              <td class="text-center gray border-top w-25">GODSET MOTTAT</td>
              <td class="border-top border-left p4">MVA</td>
              <td class="border-top border-left p4">
                <span>${taxTotal}</span>
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="border-right">
                <span class="d-block f8 pt2 pl2"
                  >42 Datostempel/betalt stempel/ sign.</span
                >
                <span class="d-block F9 pt10 pl2">${data.actual_pickup || ' '}</span>
                <span class="d-block F9 pl2">${
                  adminData?.name || ''
                }</span>
              </td>
              <td rowspan="2" class="border-right">
                <span class="d-block pt2 f8 pl2"
                  >43 Datostempel/betalt stempel/ sign.</span
                >
                <span class="d-block pt10 pl2">&nbsp;</span>
                <span class="d-block pl2">&nbsp;</span>
              </td>
              <td rowspan="2">
                <span class="d-block pt2 f8 pl2">44 Dato</span>
                <span class="d-block pt10 pl2">&nbsp;</span>
                <span class="d-block border-top-d pt2 pl2"
                  >Kvittering (fullt navn)</span
                >
              </td>
              <td class="border-top border-left p4 pl2">
                <span>&nbsp;</span>
              </td>
              <td class="border-top border-left p4 pl2">
                <span>&nbsp;</span>
              </td>
            </tr>
            <tr>
              <td class="border-top border-left p4 pl2">Total ></td>
              <td class="border-top border-left p4 pl2">
                <span>${totalPrice}</span>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>

`;

  if (generateFile) {
    const file = await html2pdf().set(opt).from(htmlData).outputPdf('blob', 'my-invoice.pdf');
    return file
  }

  return html2pdf().set(opt).from(htmlData).toPdf().get('pdf').then(function (pdf) {
    window.open(pdf.output('bloburl'), `_blank`);
  });
};

const exportOrderPalletsPdf = ({ data, order }) => {

  const transport = data.transport;

  const htmlData2 = /*html*/`
      <!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
      <html>
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <title>pdf-html</title>
          <style type="text/css">

            body {
              margin-top: 0px;
              margin-left: 0px;
            }

            .container {
              width: 100%;
              height: 1080px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            .surrounding-border {
              border: 2px solid black;
              width: 500px;
              min-height: 600px;
            }

            .inner-container {
              display: flex;
              flex-direction: column;
            }

            .part {
              border-block: 1px solid black;
              padding: 1rem 1.5rem;
              height: min-content;
            }

            .part span {
              font-size: 20px;
            }

            .part p {
              font-weight: bolder;
              font-size: 20px;
            }

            .smaller p {
              font-size: 16px;
              margin-block: 0;
            }

            .smaller span {
              font-size: 16px;
            }

            .bigger span {
              font-size: 30px;
            }

            .bigger p {
              font-size: 25px;
            }

          </style>
        </head>

        <body>
        ${data?.units?.map((item, index) => {
              return /*html*/`
              <div class="container">
              <div class="surrounding-border">
                <div class="inner-container"> 
                
                    <div class="part smaller">
                      <span>Fra:</span>
                      <p>Din Engros AS</p>
                      <p>Heggstadmoen 5</p>
                      <p>7800 HEIMDAL</p>
                    </div>

                    <div class="part bigger">
                      <span>Til:</span>
                      <p>${order.company?.profile?.company || ''}</p>
                      <p>${order?.company?.address?.street_name || ''}</p>
                      <p>${order?.company?.address?.post_zip_code || ''} ${order?.company?.address?.post_name || ''}</p>
                    </div>

                    <div class="part">
                      <span>Via:</span>
                      <p>${transport?.name || ''}</p>
                    </div>

                    <div class="part">
                      <p>Bruttovekt: ${item.weight}Kg</p>
                      ${(item.chargable_weight?.label === 'FRYS' || item.chargable_weight?.label === 'KJØL') ?
                      /*html*/`<p> Temp. Krav:
                        ${item.chargable_weight?.label === 'FRYS' ? ' -18 ℃' : '0-4 ℃'}
                     </p>`
                     : `<p><br /></p>`
                     }
                    </div>
                </div>  
              </div>
          </div>
              `
        })}
        </body>
      </html>
`;

  html2pdf().set(opt).from(htmlData2).toPdf().get('pdf').then(function (pdf) {
    window.open(pdf.output('bloburl'), `_blank`);
  });
};

export { exportOrderTransportPdf, exportOrderPalletsPdf };
