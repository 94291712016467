import React, { useState } from "react";
import { Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import NewslettersTable from "../../../Components/Orders/Newsletters/Table";
import { deleteOrdersDataAction, getAllOrdersDataAction } from "../../../redux/actions/orders";
import CustomButton from "../../../Components/Common/CustomButton";
import NewNewsletterModal from "../../../Components/Orders/Newsletters/Modal";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { useTranslation } from "react-i18next";
import { useSearch } from "../../../hooks/useSearch";
import PageContainer from "../../../Components/Layout/PageContainer";

const Newsletters = () => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);

  const { newsletters: { list: newsletters, total } } = useSelector(state => state.orders);

  const { create, modalType, update } = useCreateUpdateModal({
    group: {
      value: "",
      label: "",
    },
    note: "",
    sended_at: "",
  });

  const { t } = useTranslation();

  const { load, callback, deleteModal, isDeleting, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const searchFunc = (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllOrdersDataAction({
      field: 'newsletters',
      query,
      onSuccess: () => setLoading(false),
    }));
  };

  const { searchValues, setPageSize, navigateToPage, onSort, search } = useSearch({
    name: 'newsletters',
    searchFunc
  });

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  const openCreateModal = () => {
    create();
    setModalOpen(true);
  };

  const openUpdateModal = (row) => {
    update({
      id: row.id,
      group: {
        value: row.user_group.id,
        label: row.user_group.name,
      },
      note: row.note,
      sended_at: row.sended_at || "",
    });
    setModalOpen(true);
  };

  const handleDelete = () => {
    load();
    dispatch(deleteOrdersDataAction({ 
      field: 'newsletters',
      data: {
        ids: itemsToDelete.selectedRows.map((item) => item.id)
      },
      onSuccess() {
        callback();
        search();
      }
     }));
  };

  return (
      <PageContainer>

        <BreadCrumb title="Newsletters" pageTitle="Orders" to="/dashboard/orders" />

        <NewNewsletterModal
          modalType={modalType}
          isOpen={modalOpen}
          toggleModal={toggleModal}
        />

        <div className="hstack gap-2">

            <CustomButton
              onClick={openCreateModal}
              className="btn-success"
            >
              Create
            </CustomButton>

            <CustomButton
              className="btn-danger"
              onClick={() => setDeleteModal(true)}
              disabled={!itemsToDelete.selectedRows?.length > 0}
            >
              <i className="ri-delete-bin-line align-bottom me-1"></i> 
              {t("Delete")}
            </CustomButton>

        </div>

        <Card className="mt-3">
          <NewslettersTable
            data={newsletters}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            loading={loading}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            isDeleting={isDeleting}
            handleDelete={handleDelete}
            openUpdateModal={openUpdateModal}
            selectableRows
            itemsToDelete={itemsToDelete}
            filters={searchValues}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            sortDirection={searchValues.order_value}
            fetching={loading}
            onSort={onSort}
            toggleModal={toggleModal}
          />
        </Card>

      </PageContainer>
  );
};

export default Newsletters;
