export const palletsNote = `
    <p>Hei,</p>

    <p></p>

    <p>
        Denne eposten er sendt ut automatisk. Ta gjerne kontakt ved å sende epost til post@dinengros.no om dere har spørsmål.
    </p>

    <p></p>

    <p>Vennlig hilsen</p>
    <p>Din Engros AS</p>
`