import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createStatusSchema } from "../../../helpers/validation/Orders/createStatus";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import { createOrdersDataAction, updateOrdersDataAction } from "../../../redux/actions/orders";
import SaveButton from "../../Common/Buttons/SaveButton";

const StatusForm = ({ type, initialValues }) => {
  
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createStatusSchema),
    defaultValues: initialValues
  });

  const dispatch = useDispatch();

  const { id } = useParams();
  
  const history = useHistory();

  const submitForm = async (data) => {
    setIsLoading(true);
    if (type === "create") {
      dispatch(
        createOrdersDataAction({
          field: 'statuses',
          data: data,
          onSuccess: () => history.push("/dashboard/orders/statuses"),
        })
      );
    } else {
      if (!id) return;
      dispatch(
        updateOrdersDataAction({
          field: 'statuses',
          data: {
            id,
            data: data,
          },
          onSuccess: () => setIsLoading(false),
        })
      );
    }
  };

  const { t } = useTranslation();

  return (
    <div>
      <Col md={12}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card className="p-3">
            <Row className="gx-4 gy-2">
              <Col md={6}>
                <CustomLabel for="inputName">{t("Name")}</CustomLabel>
                <input
                  {...register("name")}
                  className="form-control"
                  id="inputName"
                />
                {errorMessage(errors, "name")}
              </Col>
              <Col md={6} className="d-flex align-items-center">
                <div className="form-check form-switch mt-4">
                  <input 
                    {...register('status')} 
                    className="form-check-input fs-16" 
                    type="checkbox" 
                    role="switch" 
                    id="status" 
                  />
                  <CustomLabel 
                    className="form-check-label" 
                    htmlFor="status"
                  >
                    Active
                  </CustomLabel>
                </div>
              </Col>
            </Row>
          </Card>

          <div className="d-flex justify-content-end">
            <SaveButton
              isLoading={isLoading}
            />
          </div>

        </form>
      </Col>
    </div>
  );
};

export default StatusForm;
