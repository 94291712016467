import React from "react";
import StockStaffForm from "../../../Components/Settings/StockStaff/Form";
import PageContainer from "../../../Components/Layout/PageContainer";

const CreateStockStaff = () => {
  return (
    <PageContainer>
      <StockStaffForm type="create" />
    </PageContainer>
  )
};

export default CreateStockStaff;
