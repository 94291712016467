import { useEffect, useState } from "react";
import productsApi from "../../../apis/products/api";
import { useQuery } from "@tanstack/react-query";
import usePagePagination from "../../../hooks/usePagePagination";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import SelectedProductsTab from "./SelectedProductsTab";
import AllProductsTab from "./AllProductsTab";
import toastAlert from "../../../helpers/toastAlert";

const ProductsTable = ({ name, selectedProducts, setData, activeTab }) => {
  const [pageSize, setPageSize] = useState(10);
  const { currentPage, navigateToPage } = usePagePagination();
  const { t } = useTranslation();

  const [productsToAdd, setProductsToAdd] = useState({
    total: 0,
    list: [],
  });

  const { isLoading, isRefetching, refetch } = useQuery(
    ["products-newsletter"],
    () => productsApi.products.getAll(currentPage, pageSize, name),
    {
      onSuccess(data) {
        const { data: list, meta } = data.data;
        setProductsToAdd({
          total: meta.total,
          list: list.map((item) => {
            const price =
              item.unit_prices[0]?.groups_prices?.find(
                (group) => group.user_group_id === 1
              )?.price || 0;
            return {
              ...item,
              price,
            };
          }),
        });
      },
    }
  );

  const onDeleteProduct = async ({ id, onLoading, onFinally }) => {
    try {
      onLoading();
      await productsApi.catalogue.products.delete(id);
      setData((currentData) => {
        return {
          ...currentData,
          products: currentData.products.filter((product) => product.id !== id),
        };
      });
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      onFinally();
    }
  };

  const onAddProduct = async ({ data, onLoading, onFinally }) => {
    try {
      onLoading();
      const { data: response } = await productsApi.catalogue.products.create(data);
      setData((currentData) => {
        return {
          ...currentData,
          products: [...currentData.products, response.data],
        };
      });
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      onFinally();
    }
  };

  useEffect(() => {
    refetch();
  }, [name, currentPage, pageSize]);

  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId={1}>
        <AllProductsTab
          setPageSize={setPageSize}
          navigateToPage={navigateToPage}
          selectedProducts={selectedProducts}
          productsToAdd={productsToAdd}
          setProductsToAdd={setProductsToAdd}
          onDeleteProduct={onDeleteProduct}
          pageSize={pageSize}
          onAddProduct={onAddProduct}
          isLoading={isLoading || isRefetching}
        />
      </TabPane>
      <TabPane tabId={2}>
        <SelectedProductsTab
          selectedProducts={selectedProducts}
          setData={setData}
          onDeleteProduct={onDeleteProduct}
          // isLoading={}
        />
      </TabPane>
    </TabContent>
  );
};

export default ProductsTable;
