import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardFooter,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OpeningHoursTable from "../../../Components/Settings/OpeningHours/Table";
import { useForm } from "react-hook-form";
import { openingHoursBody } from "../../../helpers/RequestBodies/OpeningHours/openingHoursRequestBody";
import {
  OPENING_HOURS_PERMISSIONS,
} from "../../../permissions/webSettings";
import Restricted from "../../../Components/Guard/Restricted";
import { useTranslation } from "react-i18next";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";
import PageContainer from "../../../Components/Layout/PageContainer";

const OpeningHours = () => {

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const { register, handleSubmit, control } = useForm();

  const [loading, setLoading] = useState(true);

  const submitFormOpeningHours = (data) => {
    const requestBody = openingHoursBody(data);
    dispatch(setWebSettingsAction({
      field: 'openingHours',
      data: {
        type: 'opening_hours',
        data: requestBody
      }
    }));
  };
  const dispatch = useDispatch();
  
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading) setLoading(true);
    dispatch(getWebSettingsAction({
      field: 'openingHours',
      type: 'opening_hour',
      onSuccess: () => setLoading(false)
    }));
  }, []);

  return (
      <PageContainer isLoading={loading}>

        <BreadCrumb pageTitle="Settings" title="Opening Hours" />

        <Card>

          <OpeningHoursTable
            control={control}
            weekdays={weekdays}
            register={register}
            fetching={loading}
            noPagination
          />

          <CardFooter>
            <div className="hstack gap-2 justify-content-end">
              <Restricted permissions={OPENING_HOURS_PERMISSIONS.update}>
                <Button
                  type="submit"
                  color="success"
                  className="w-15"
                  onClick={handleSubmit(submitFormOpeningHours)}
                >
                  <i className="ri-save-line align-bottom me-1"></i>{" "}
                  {t("Save")}
                </Button>
              </Restricted>
            </div>
          </CardFooter>

        </Card>

      </PageContainer>
  );
};

export default OpeningHours;
