export const sortByStockZone = (list, locations) => {

    const sortedList = list.sort((a, b) => {
      
      const indexOfA = locations.find(loc => loc.id === a.product.stock_zone_id)?.sort || null;
      const indexOfB = locations.find(loc => loc.id === b.product.stock_zone_id)?.sort || null;

      if (indexOfA === indexOfB) {
        return 0
      }

      if (indexOfA === null) {
          return 1
      }

      if (indexOfB === null) {
          return -1
      }

      if (indexOfA < indexOfB) {
          return -1
      }

      if (indexOfA > indexOfB) {
          return 1
      }

      return 0
  })
    
    return sortedList
}