import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Col } from "reactstrap";
import productsApi from "../../../apis/products/api";
import toastAlert from "../../../helpers/toastAlert";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import CustomButton from "../../Common/CustomButton";
import DataTable from "../../Common/DataTable";
import CustomLabel from "../../Custom/CustomLabel";

const VendorProductsTable = ({ list, email_text, setError, clearErrors, register, errors }) => {

  const [products, setProducts] = useState(list);

  const { id } = useParams();

  const { t } = useTranslation();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [productsToSend, setProductsToSend] = useState(() =>
    list.map((item) => {
      return {
        product_id: item.id,
        qty: 0,
        product_number: item.supplier_product_number || "",
        unit_id: findGreatest(item.units)?.id || "",
      };
    })
  );

  const findProduct = (id) => {
    return productsToSend.find((prod) => +prod.product_id === +id);
  };

  const handleChange = (product, field, value) => {
    setProductsToSend((prev) => {
      return prev.map((prod) => {
        if (+prod.product_id === +product.id) {
          return {
            ...prod,
            [field]: value,
          };
        }
        return prod;
      });
    });
  };

  const handleSave = async () => {
    clearErrors("email_text");
    if (!email_text) {
      return setError(
        "email_text",
        { type: "custom", message: "Required" },
        { shouldFocus: true }
      );
    }
    setLoading(true);
    const requestBody = {
      suuplier_id: +id,
      observation: email_text || "",
      products: productsToSend.filter((product) => product.qty > 0),
    };
    try {
      await productsApi.vendors.askForProducts(requestBody);
      setProductsToSend((prev) => {
        return prev.map((item) => {
          return {
            ...item,
            qty: 0,
          };
        });
      });
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setLoading(false);
    }
  };

  function findGreatest(units) {
    let value = null;
    let max = 0;
    units.forEach((unit) => {
      if (unit.piece > max) {
        max = unit.piece;
        value = unit;
      }
    });
    return value;
  }

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product Number")}</span>
      ),
      selector: (row) => row.matricule,
      grow: -1,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: (row) => 
      <span 
        className="cursor-pointer fw-bold fs-14"
        onClick={() => history.push(`/dashboard/products/update/${row.id}`)}
        title={row.name}
      >
        {row.name}
      </span>,
      grow: 2,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Unit")}</span>,
      selector: (row) => (
        <select
          className="form-select"
          value={
            findProduct(row.id)?.unit_id || findGreatest(row.units)?.id || ""
          }
          onChange={(e) => handleChange(row, "unit_id", +e.target.value)}
          style={{minWidth: '100px'}}
        >
          {row.units.map((unit) => (
            <option key={unit.id} value={unit.id}>
              {unit.name}
            </option>
          ))}
        </select>
      ),
      grow: 2,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Total Input")}</span>,
      selector: (row) => row.total_input || 0,
      grow: -1,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Total Production")}</span>
      ),
      selector: (row) => row.total_production || 0,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Total")}</span>,
      selector: (row) =>
        row.units.find((unit) => +findProduct(row.id)?.unit_id === +unit.id)
          ?.piece || 0,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">
          {t("Vendor product number")}
        </span>
      ),
      selector: (row) => (
        <input
          className="form-control"
          value={findProduct(row.id)?.product_number || ""}
          onChange={(e) => handleChange(row, "product_number", +e.target.value)}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Quantity")}</span>,
      selector: (row) => (
        <input
          className="form-control"
          type="number"
          value={findProduct(row.id)?.qty || 0}
          onChange={(e) => handleChange(row, "qty", +e.target.value)}
          step="any"
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <DataTable data={products} columns={columns} />
      
      <Col md={12} className="px-4">
        <CustomLabel for="inputIcon">Email Text</CustomLabel>
        <textarea
          {...register("email_text")}
          type="textarea"
          className="form-control"
          style={{
            minHeight: "100px",
            resize: "none",
          }}
        />
        {errorMessage(errors, "email_text")}
      </Col>
      <Col className="px-4">
        <CustomButton
          type="button"
          onClick={handleSave}
          className="btn btn-success my-4"
          isLoading={loading}
        >
          Send Email
        </CustomButton>
      </Col>
    </React.Fragment>
  );
};

export default VendorProductsTable;
