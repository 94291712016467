import { useEffect } from 'react'
import { useContext, createContext, useState } from 'react'
import settingsApi from '../apis/settings/api'
import getAdminPermissions from '../helpers/getAdminPermissions'
import { grantPermission } from '../helpers/grantPermission';

const authContext = createContext({})

const AuthProvider = ({ children }) => {

    const [adminData, setAdminData] = useState(null);
    const [isGettingAdmin, setIsGettingAdmin] = useState(true);

    const [accessToken, setAccessToken] = useState(
        localStorage.getItem('dinengrosToken') || null
    )

    const logout = () => {
        setAccessToken(null)
        setAdminData(null)
        localStorage.removeItem("dinengrosToken")
        localStorage.removeItem('dinengrosAdminID')
    }

    const getAdminData = async () => {
        try {
          const ID = localStorage.getItem('dinengrosAdminID')
          if (!ID) return;
          const { data } = await settingsApi.admins.getOne(ID);
          setAdminData(data.data)
          setIsGettingAdmin(false)
        } catch (error) {
          if (error.response?.status === 401) logout();
        }
    }

    const updateAdminData = async (newData) => {
        const { data } = await settingsApi.admins.updateProfile(newData);
        setAdminData(data.data)
        setIsGettingAdmin(false)
    }

    const isAllowedTo = (permission = []) => {
        if (!adminData) return [];
        const permissions = getAdminPermissions(adminData.roles);
        const isSuperAdmin = adminData.roles.some(role => role.name === 'super-admin');
        return grantPermission({permissionToGrant: permission, allPermissions: permissions, isSuperAdmin});
    }

    useEffect(() => {
        if(!accessToken) return;
        getAdminData()
    }, [accessToken])

    return (
      <authContext.Provider
        value={{
          isGettingAdmin,
          isAllowedTo,
          adminData,
          setAdminData,
          updateAdminData,
          accessToken,
          setAccessToken,
          logout,
        }}
      >
        {children}
      </authContext.Provider>
    );
}

export default AuthProvider

export const useAuthState = () => {
    return useContext(authContext)
}