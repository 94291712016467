import React from "react";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import FileManagerProvider from "./context/FileManagerContext";
import FileManagerModal from "./Components/Settings/FileManager/FileManagerModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotificationsProvider from "./context/notificationsContext";

function App() {

  return (
    <React.Fragment>
      <NotificationsProvider>
        <FileManagerProvider>
          <Route />
          <FileManagerModal />
          <ToastContainer limit={1} />
        </FileManagerProvider>
      </NotificationsProvider>
    </React.Fragment>
  );
}

export default App;
