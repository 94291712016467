import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import SelectFileButton from "../../Common/SelectFileButton";
import FileManagerMediaReader from "../FileManager/FileManagerMediaReader";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import CustomButton from "../../Common/CustomButton";
import { useFileManager } from "../../../context/FileManagerContext";
import useSetFile from "../../../hooks/useSetFile";
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import { createSettingsDataAction, updateSettingsDataAction } from "../../../redux/actions/settings";

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  code: yup.string().required("Required"),
  flag_image: yup.mixed().required("Required"),
});

const LanguageForm = ({ type, initialValues }) => {

  const { openFileManagerModal } = useFileManager();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError: setFormError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: type === "Update" ? initialValues : {},
  });

  const flagImage = watch("flag_image");

  const onSubmit = (data) => {
    setIsLoading(true);
    setError(null);
    let requestBody = {
      name: data.name,
      flag_image: data?.flag_image?.id || null,
      code: data.code,
    };
    if (type === "Create") {
      dispatch(
        createSettingsDataAction({
          field: 'languages',
          data: requestBody,
          onSuccess() {
            push("/dashboard/web-settings/languages");
          },
          onError(error) {
            setIsLoading(false);
            setError(error?.data?.error);
          },
        })
      );
    } else {
      requestBody["code"] = initialValues.code;
      dispatch(
        updateSettingsDataAction({
          field: 'languages',
          data: {
            id: initialValues.code,
            data: requestBody,
          },
          onSuccess() {
            setIsLoading(false);
          },
          onError(error) {
            setIsLoading(false);
            setError(error?.data?.error);
          },
        })
      );
    }
  };

  useSetFile({
    whatFor: "lang-flag",
    cb(file) {
      setFormError("flag_image", { message: undefined });
      setValue("flag_image", file);
    },
  });

  return (
    <div>
        <BreadCrumb
          to="/dashboard/web-settings/languages"
          pageTitle="Languages"
          title={type}
        />
        <Card className="p-4">
          <Row className="g-3">
            <Col lg={6}>
              <div>
                <CustomLabel htmlFor="language-name-field" className="form-label">
                  Name
                </CustomLabel>
                <CustomInput
                  id="language-name-field"
                  className="form-control"
                  placeholder="Enter Language Name"
                  type="text"
                  {...register("name")}
                />
                {errorMessage(errors, "name")}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <CustomLabel htmlFor="language-code-field" className="form-label">
                  Code
                </CustomLabel>
                <CustomInput
                  name="language-code"
                  id="language-code-field"
                  className="form-control"
                  placeholder="Enter a unique code for this language"
                  type="text"
                  disabled={type === "Update"}
                  {...register("code")}
                />
                {errorMessage(errors, "code")}
              </div>
            </Col>
            <Col lg={6} className="mb-4">
              <div>
                <CustomLabel htmlFor="homeslider-media" className="form-label">
                  Flag
                </CustomLabel>
                <SelectFileButton
                  text="Select flag"
                  onClick={() => openFileManagerModal("lang-flag")}
                />
                {errorMessage(errors, "flag_image")}
              </div>
              {flagImage && (
                <FileManagerMediaReader
                  className="mt-2"
                  src={flagImage?.path}
                  ext={flagImage?.ext}
                  style={{ maxWidth: "150px" }}
                />
              )}
            </Col>
            {error && <div className="invalid-feedback d-block">Error</div>}
          </Row>
            <div className="hstack gap-2 justify-content-end">
              <CustomButton
                type="submit"
                className="btn btn-success"
                id="add-btn"
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </CustomButton>
            </div>
        </Card>
    </div>
  );
};

export default LanguageForm;
