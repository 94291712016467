import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

const CustomOption = ({ children, ...rest }) => {
  const { t } = useTranslation();

  return <option {...rest}>{t(children)}</option>;
};

export default CustomOption;
