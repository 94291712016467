import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, Col, Form } from "reactstrap";
import settingsApi from "../../../apis/settings/api";
import toastAlert from "../../../helpers/toastAlert";
import { WEB_SETTINGS_PERMISSIONS } from "../../../permissions/webSettings";
import CustomButton from "../../Common/CustomButton";
import Restricted from "../../Guard/Restricted";

const About = () => {

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      about: "",
    },
  });

  useQuery(["settings", "about"], () => settingsApi.web.get('about'), {
    onSuccess({ data: { data }}) {
      setValue("about", data?.about || "");
    },
  });

  const submitAbout = async (data) => {
    setIsLoading(true);
    try {
      await settingsApi.web.update(data.about);
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Col lg={12}>
      <Card>
        <Form onSubmit={handleSubmit(submitAbout)}>

          <CardBody>
            <Controller
              name="about"
              control={control}
              render={({ name, field: { onChange, value } }) => (
                <CKEditor
                  data={value}
                  name={name}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    onChange(editor.getData());
                  }}
                />
              )}
            />
          </CardBody>

          <CardFooter className="px-0 mt-5">
            <div className="d-flex align-items-center gap-2">
              <Link to="/dashboard/">
                <CustomButton type="button" className="btn btn-light">
                  Close
                </CustomButton>
              </Link>

              <Restricted permissions={WEB_SETTINGS_PERMISSIONS.update}>
                <CustomButton
                  type="submit"
                  className="btn btn-success"
                  isLoading={isLoading}
                >
                  Save
                </CustomButton>
              </Restricted>
            </div>
          </CardFooter>

        </Form>
      </Card>
    </Col>
  );
};

export default About;
