export const STORAGE_PERMISSIONS = {
    read: ["storage-read"],
    update: ["storage-update"],
    delete: ["storage-delete"],
    create: ["storage-create"],
  };
  
export const LOT_MOVEMENT_PERMISSIONS = {
    read: ["lot_movement-read"],
    update: ["lot_movement-update"],
    delete: ["lot_movement-delete"],
    create: ["lot_movement-create"],
};

export const PRODUCTS_NUMBERS_PERMISSIONS = {
    read: ["products_numbers-read"],
    update: ["products_numbers-update"],
    delete: ["products_numbers-delete"],
    create: ["products_numbers-create"],
};

export const STORAGE_PROBLEMS_PERMISSIONS = {
    read: ["storage_problems-read"],
    update: ["storage_problems-update"],
    delete: ["storage_problems-delete"],
    create: ["storage_problems-create"],
};

export const STORAGE_CONTROL_PERMISSIONS = {
    read: ["storage_control-read"],
    update: ["storage_control-update"],
    delete: ["storage_control-delete"],
    create: ["storage_control-create"],
};

export const NEW_STOCK_PERMISSIONS = {
    read: ["new_stock-read"],
    update: ["new_stock-update"],
    delete: ["new_stock-delete"],
    create: ["new_stock-create"],
};
  