import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageModal from "../../Common/ImageModal";

const Files = ({ files }) => {
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");

  const toggleModal = () => {
    setModal((prev) => !prev);
  };

  const openModalWithImage = (file) => {
    setModal(true);
    setImage(file.file_info?.uri);
  };

  const { t } = useTranslation();

  return (
    <>
      <ImageModal open={modal} toggleModal={toggleModal} image={image} />
      <div className="mt-4">
        <h4>{t("Files")}</h4>
        {files.length === 0 ? (
          <span>{t("No Files to show")}</span>
        ) : (
          files.map((file, index) => (
            <img
              key={index}
              alt={file.file_info?.name}
              style={{ maxWidth: "150px" }}
              className="img-thumbnail mt-4 cursor-pointer"
              src={file.file_info?.uri}
              onClick={() => openModalWithImage(file)}
            />
          ))
        )}
      </div>
    </>
  );
};

export default Files;
