import React from "react";
import { useTranslation } from "react-i18next";
import { formatParagraph } from "../../../helpers/formatParagraph";
import DataTable from "../../Common/DataTable";
import StatusIcon from "../../Common/StatusIcon";
import CustomInput from "../../Custom/CustomInput";

const ProductsNumbersTable = ({ filters, handleFilterChange, ...props }) => {

  const { t } = useTranslation();

  const columns = [
    
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },

    {
      name: (
        <CustomInput
          className="form-control"
          placeholder={`${t('Matricule')} / ${t('Number')}`}
          value={filters.matricule}
          onChange={(e) => handleFilterChange({
            type: 'debounced',
            field: 'matricule',
            value: e.target.value
          })}
          data-filtered={!!filters.matricule}
        />
      ),
      selector: (row) => row.matricule,
      sortable: true,
      sortField: 'matricule',
    },

    {
      name: (
        <span
          className="font-weight-bold fs-13 d-flex align-items-center gap-1 cursor-pointer"
        >
          {t("Product Name")}
        </span>
      ),
      selector: (row) => row.name,
      sortable: true,
      sortField: 'name',
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Category")}</span>,
      selector: (row) => formatParagraph(row?.categories) || "None",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Total qty")}</span>,
      selector: (row) => row.total_qty,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Total Weight")}</span>,
      selector: (row) => row.total_weight,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: (row) =>  <StatusIcon status={row.status} />,
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default ProductsNumbersTable;
