import * as yup from "yup";

const PolicySchema = yup.object().shape({
  status: yup.boolean().notRequired(),
  policy: yup.string().notRequired("Please fill in the policy text"),
});

// .oneOf([true], "Don't forget to check it !")

export default PolicySchema;
