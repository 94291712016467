import React from "react";

const CatalogueImage = ({ src, onRemove }) => {
  return (
    src && (
      <div style={{ position: "relative" }} className="mt-2">
        <img
          className="img-thumbnail"
          src={src}
          alt={""}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: ".5rem",
            right: ".5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, .2)",
            padding: 2,
          }}
        >
          <i
            className="ri-delete-bin-fill"
            style={{
              fontSize: "1rem",
              cursor: "pointer",
              color: "white",
            }}
            onClick={onRemove}
          ></i>
        </div>
      </div>
    )
  );
};

export default CatalogueImage;
