import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { getWebSettingsAction } from '../redux/actions/settings';

const Footer = () => {

    const { webSettings: { footer }} = useSelector((state) => state.settings);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getWebSettingsAction({
            field: 'footer',
        }));
    }, [dispatch])

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <img src={footer.logo} alt="" height="45" />
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                {t(footer.copyright || '')}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;