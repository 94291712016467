export const PRODUCT_PERMISSIONS = {
  read: ["products-read"],
  update: ["products-update"],
  delete: ["products-delete"],
  create: ["products-create"],
};

export const PRODUCT_OFFER_PERMISSIONS = {
  read: ["products_offer-read"],
  update: ["products_offer-update"],
  delete: ["products_offer-delete"],
  create: ["products_offer-create"],
};

export const PRODUCT_CATEGORIES_PERMISSIONS = {
  read: ["products_categories-read"],
  update: ["products_categories-update"],
  delete: ["products_categories-delete"],
  create: ["products_categories-create"],
};

export const PRODUCT_PROMO_CAMPS_PERMISSIONS = {
  read: ["products_promo_camps-read"],
  update: ["products_promo_camps-update"],
  delete: ["products_promo_camps-delete"],
  create: ["products_promo_camps-create"],
};

export const PRODUCT_LOCATION_PERMISSIONS = {
  read: ["products_location-read"],
  update: ["products_location-update"],
  delete: ["products_location-delete"],
  create: ["products_location-create"],
};

export const PRODUCT_UNITS_PERMISSIONS = {
  read: ["products_units-read"],
  update: ["products_units-update"],
  delete: ["products_units-delete"],
  create: ["products_units-create"],
};

export const PRODUCT_VAT_PERMISSIONS = {
  read: ["products_vat-read"],
  update: ["products_vat-update"],
  delete: ["products_vat-delete"],
  create: ["products_vat-create"],
};

export const PRODUCT_VENDORS_PERMISSIONS = {
  read: ["products_vendors-read"],
  update: ["products_vendors-update"],
  delete: ["products_vendors-delete"],
  create: ["products_vendors-create"],
};

export const PRODUCT_INVALID_PRODUCTS_PERMISSIONS = {
  read: ["products_invalid_products-read"],
  update: ["products_invalid_products-update"],
  delete: ["products_invalid_products-delete"],
  create: ["products_invalid_products-create"],
};

export const PRODUCT_CODEBAR_PERMISSIONS = {
  read: ["products_codebar-read"],
  update: ["products_codebar-update"],
  delete: ["products_codebar-delete"],
  create: ["products_codebar-create"],
};
