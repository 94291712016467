export const SLIDER_PERMISSIONS = {
  read: ["slider-read"],
  update: ["slider-update"],
  delete: ["slider-delete"],
  create: ["slider-create"],
};

export const PAGES_PERMISSIONS = {
  read: ["page-read"],
  update: ["page-update"],
  delete: ["page-delete"],
  create: ["page-create"],
};

export const CONTACTS_PERMISSIONS = {
  read: ["contact-read"],
  update: ["contact-update"],
  delete: ["contact-delete"],
  create: ["contact-create"],
};
