import moment from "moment-timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ordersApi from "../../../apis/orders/api";
import { calculatePrices } from "../../../helpers/calculateOrderPrices";
import { handleExportPdf } from "../../../helpers/Pdfs/Orders/OrderPdf";
import { handleExportProductsPdf } from "../../../helpers/Pdfs/Orders/OrderProductsPdf";
import CoverSpinner from "../../Common/CoverSpinner";
import DataTable from "../../Common/DataTable";
import CustomInput from "../../Custom/CustomInput";
import CustomOption from "../../Custom/CustomOption";
import Flatpickr from 'react-flatpickr'

const OrdersTable = ({ openModalUpdate, filters, handleFilterChange, handleMultipleFilterChanges, ...props }) => {
  
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const { webSettings: settings } = useSelector((state) => state.settings);
  
  const { 
    vats: { list: vats }, 
    locations: { list: locations } 
  } = useSelector((state) => state.products);

  const { 
    statuses: { list: statuses },
    deliveryMethods: { list: deliveryMethods } 
  } = useSelector((state) => state.orders);


  const handleExports = async (id, callback) => {
    setIsLoading(true);
    const {data: { data: response }} = await ordersApi.orders.getOne(id);
    if (callback) callback(response)
    setIsLoading(false);
  }

  const columns = [
    {
      name:            
      <CustomInput
        className="form-control"
        placeholder="Matricule"
        value={filters.matricule}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'matricule',
          value: e.target.value
        })}
        data-filtered={!!filters.matricule}
      />,
      selector: (row) => row.matricule,
      sortable: true,
      sortField: 'matricule'
    },

    {
      name:            
      <CustomInput
        className="form-control"
        placeholder="Company"
        value={filters.company}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'company',
          value: e.target.value
        })}
        data-filtered={!!filters.company}
      />,
      selector: (row) => {
        return (
          <Link
            className="cursor-pointer fw-bold text-dark"
            title={row.company?.name || '-'}
            to={`/dashboard/users/update/${row.company?.id}`}
          >
            {row?.company.name || '-'} 
          </Link>
        );
      },
      grow: 1,
      sortable: true,
      sortField: 'company'
    },

    {
      name: (
        <select
          name="delivery_type_id"
          className="form-select"
          value={filters?.delivery_type_id}
          onChange={(e) => handleFilterChange({
            type: 'cached',
            field: 'delivery_type_id',
            value: e.target.value
          })}
          data-filtered={!!filters.delivery_type_id}
        >
          <CustomOption value={""}>Shipping Type</CustomOption>
          {deliveryMethods.map((method) => (
            <CustomOption key={method.id} value={method.id}>
              {method.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => t(row.delivery_type) || "None",
    },

    {
      name:        
      <select
        name="status_id"
        className="form-select"
        value={filters?.status_id}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'status_id',
          value: e.target.value
        })}
        data-filtered={!!filters.status_id}
      >
      <CustomOption value={""}>Status</CustomOption>
        {statuses.map((status) => (
          <CustomOption key={status.id} value={status.id}>
            {status.name}
          </CustomOption>
        ))}
      </select>,
      selector: (row) => t(row?.current_status) || "None",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Paid")}</span>,
      selector: (row) => t(row?.payment) || "None",
      grow: -1,
    },

    {
      name: 
      <div className="d-flex gap-2">
        <Flatpickr
          className="form-control"
          placeholder="Created"
          value={[filters.date_from, filters.date_to]}
          options={{
            mode: "range",
          }}
          onChange={(date) => handleMultipleFilterChanges({
            type: 'cached',
            data: {
              date_from: moment(date[0]).format("YYYY-MM-DD"),
              date_to: date[1] ? moment(date[1]).format("YYYY-MM-DD") : null
            }
          })}
          onClick={(e) => e.stopPropagation()}
          data-filtered={!!filters.date_from && !!filters.date_to}
        />
        {(!!filters.date_from || !!filters.date_to) && 
          <i 
            className="ri-filter-3-fill align-self-center cursor-pointer" 
            onClick={(e) => {
              e.stopPropagation();
              handleMultipleFilterChanges({
                type: 'cached',
                data: {
                  date_from: '',
                  date_to:''
                }
              })
            }
            }
          ></i>
        }
      </div>
      ,
      selector: (row) => row.created_at ? moment(row.created_at).format("D MMM, YYYY, HH:mm") : "-",
      sortable: true,
      sortField: 'created_at'
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Delivery date")}</span>
      ),
      selector: (row) => row.delivered_at ? moment(row.delivered_at).format("D MMM, YYYY") : "-",
      sortable: true,
      sortField: 'delivered_at'
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Actions")}</span>,
      selector: (row) => {
        return (
          <div className="d-flex gap-2">
            <i
              className="ri-eye-line fs-16 cursor-pointer"
              onClick={() => history.push(`/dashboard/orders/change-order/${row.id}/order`)
              }
            ></i>
            {row.current_status !== "Fakturert" && row.current_status !== "Kanselert" && (
                <i
                  onClick={() => 
                  handleExports(row.id, (order) => openModalUpdate(order))}
                  className="ri-pencil-line fs-16 cursor-pointer"
                ></i>
              )}

            <i
              className="ri-file-pdf-line fs-16 cursor-pointer"
              onClick={() =>
                handleExports(row.id, (order) => handleExportPdf({
                  order: order,
                  type: "order",
                  vats,
                  calculatePrices,
                  settings,
                  locations,
                }))
              }
            ></i>

            <i
              className="ri-car-line fs-16 cursor-pointer"
              onClick={() =>
                handleExports(row.id, (order) => handleExportProductsPdf({
                  order: order,
                  type: "order",
                  settings,
                  locations,
                }))
              }
            ></i>

            <i
              className="ri-barcode-line cursor-pointer"
              onClick={() =>
                window.open(`/dashboard/orders/${row.id}/transport`)
              }
            ></i>
          </div>
        );
      },
      grow: 1.1,
    },
  ];

  return (
    <React.Fragment>
        <DataTable
          columns={columns}
          {...props}
        />
      <CoverSpinner loading={isLoading} />
    </React.Fragment>
  );
};

export default OrdersTable;
