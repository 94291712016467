import React from 'react'
import MeasurementUnitForm from '../../../Components/Products/MeasurementUnits/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateUnit = () => {

  return (
      <PageContainer> 
          <BreadCrumb  title="Create" pageTitle="Measurement Units"  to="/dashboard/products/measurement-units" />
          <MeasurementUnitForm 
            type="create"
          />
      </PageContainer>
  )
}

export default CreateUnit