import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../../Custom/CustomLabel';
import CustomInput from '../../../Custom/CustomInput';

const ExpandedRow = ({ selectedProduct, initialValues, errors, unitsRef }) => {

  const [unitsData, setUnitsData] = useState([]);

  const handleChange = (field, id, value) => {
    if (field === 'include')  return setUnitsData(prev => prev.map(unit => {
            if (unit.id === id) return {
                    ...unit,
                    include: value
                }
            return unit
        })
    )

    return setUnitsData(prev => prev.map(unit => {
            if (unit.id === id) return {
                ...unit,
                price: value
            }
            return unit
        })
    )
  }

  useEffect(() => {
    if (selectedProduct) {
      setUnitsData(selectedProduct.unit_prices.map(unit => {

        const grp = unit.groups_prices.find(grp => grp.user_group_id === (initialValues?.profile.user_group.id || 1));

        return {
            id: unit.unit_id,
            name: unit.unit_name,
            price: grp ? grp.price : 0,
            total_qty: unit.pieces,
            price_purchase: unit.price_purchase,
            include: true
        }
    }))
    }
  }, [selectedProduct])
  
  useEffect(() => {
    unitsRef.current = unitsData.filter(unit => unit.include).map(unit => ({price: unit.price, unit_id: unit.id}))
  }, [unitsData])

  return unitsData.map(unit => (
      <Row key={unit.id} className='p-3 px-4 align-items-center border-bottom'>

          <Col>
              <CustomLabel>
                  Unit
              </CustomLabel>
              <CustomInput
                  value={unit.name}
                  disabled
                  step="any"
              />
          </Col>

          <Col>
              <CustomLabel>
                Purchase Price
              </CustomLabel>
              <CustomInput
                  type="number"
                  value={unit.price_purchase}
                  disabled
                  step="any"
              />
          </Col>

          <Col>
              <CustomLabel>
                Discount
              </CustomLabel>
              <CustomInput
                name="promo"
                value={unit.price}
                onChange={e => handleChange('price', unit.id, e.target.value)}
                type="number"
                className={`form-control ${errors.price[0] && 'is-invalid'}`}
                step="any"
              />
          </Col>

          <Col>
              <CustomLabel>
                Included
              </CustomLabel>
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                style={{ display: 'block' }}
                checked={unit.include}
                onChange={e => handleChange('include', unit.id, e.target.checked)}
              />
          </Col>
      </Row>
    ))
}

export default ExpandedRow