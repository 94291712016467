import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import BreadCrumb from "../../Common/BreadCrumb";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createVendorSchema,
  updateVendorSchema,
} from "../../../helpers/validation/Products/createVendor";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import { setFormErrors } from "../../../helpers/setFormErrors";
import CustomButton from "../../Common/CustomButton";
import VendorProductsTable from "./VendorProductsTable";
import CustomLabel from "../../Custom/CustomLabel";
import { createProductsDataAction, updateProductsDataAction } from "../../../redux/actions/products";
import SaveButton from "../../Common/Buttons/SaveButton";

const VendorForm = ({ type, initialValues }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      type === "create" ? createVendorSchema : updateVendorSchema
    ),
    defaultValues: initialValues,
  });

  const formState = watch();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  
  const { push } = useHistory();

  const onSubmit = async (data) => {
    setLoading(true);
    if (type === "create") {
      dispatch(
        createProductsDataAction({
          field: 'vendors',
          data,
          onSuccess: () => {
            setLoading(false);
            push("/dashboard/products/vendors");
          },
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    } else if (type === "update") {
      dispatch(
        updateProductsDataAction({
          field: 'vendors',
          data: {
            data: data,
            id: initialValues?.id,
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    }
  };

  return (
    <div>
      <Col md={12}>

        <form onSubmit={handleSubmit(onSubmit)}>

          <Card className="p-3">
            <Row className="gx-4 gy-2">
              <Col md={6}>
                <CustomLabel>Name</CustomLabel>
                <input
                  name="name"
                  {...register("name")}
                  className="form-control"
                />
                {errorMessage(errors, "name")}
              </Col>

              <Col md={6}>
                <CustomLabel>Supplier Number</CustomLabel>
                <input
                  name="number"
                  {...register("number")}
                  className="form-control"
                />
                {errorMessage(errors, "number")}
              </Col>

              <Col md={6}>
                <CustomLabel>Address</CustomLabel>
                <input
                  name="address"
                  {...register("address")}
                  className="form-control"
                />
                {errorMessage(errors, "address")}
              </Col>

              <Col md={6}>
                <CustomLabel>Telephone</CustomLabel>
                <input
                  name="phone"
                  {...register("phone")}
                  className="form-control"
                />
                {errorMessage(errors, "phone")}
              </Col>

              <Col md={6}>
                <CustomLabel>Email</CustomLabel>
                <input
                  name="email"
                  {...register("email")}
                  type="email"
                  className="form-control"
                />
                {errorMessage(errors, "email")}
              </Col>


            </Row>
          </Card>

          <div className="my-2 d-flex justify-content-end">
            <SaveButton
              isLoading={loading}
            />
          </div>

          {initialValues && (
            <Card>
              <VendorProductsTable
                email_text={formState.email_text}
                list={initialValues.products}
                setError={setError}
                clearErrors={clearErrors}
                register={register}
                errors={errors}
              />
            </Card>
          )}

        </form>

      </Col>
    </div>
  );
};

export default VendorForm;
