import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import settingsApi from "../../../apis/settings/api";
import StockStaffForm from "../../../Components/Settings/StockStaff/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateStockStaff = () => {
  
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["stockStaffs", id], () => settingsApi.stockStaff.getOne(id), {
    refetchOnWindowFocus: false,
    onSuccess({ data: { data }}) {
      setInitialValues(data);
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching} >
      <BreadCrumb pageTitle="Home Sliders" title="Update" to="/dashboard/settings/home-sliders" />
      {
        initialValues && 
          <StockStaffForm 
            type="update" 
            initialValues={initialValues} 
          />
      }
    </ PageContainer>
  );
};

export default UpdateStockStaff;
