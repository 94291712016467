import React, { useEffect } from "react";
import { Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ProblemsTable from "../../../Components/Storage/Problems/Table";
import { useDispatch } from "react-redux";
import { getAllStorageDataAction } from "../../../redux/actions/storage";
import PageContainer from "../../../Components/Layout/PageContainer";

const StorageProblems = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStorageDataAction({
      field: 'problems',
      query: {
        page_size: 'all'
      }
    }));
  }, [dispatch]);

  return (
      <PageContainer>
        <BreadCrumb
          title="Problems"
          pageTitle="Storage"
          to="/dashboard/storage"
        />
        <Card>
          <ProblemsTable />
        </Card>
      </PageContainer>
  );
};

export default StorageProblems;
