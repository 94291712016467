import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, CardFooter, Col, Form, Row } from "reactstrap";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import footerSchema from "../../../helpers/validation/WebSettings/footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFileManager } from "../../../context/FileManagerContext";
import useSetFile from "./../../../hooks/useSetFile";
import SelectFileButton from "./../../Common/SelectFileButton";
import { WEB_SETTINGS_PERMISSIONS } from "./../../../permissions/webSettings";
import Restricted from "./../../Guard/Restricted";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import CustomButton from "../../Common/CustomButton";
import { useTranslation } from "react-i18next";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";
import SaveButton from "../../Common/Buttons/SaveButton";

const Footer = () => {

  const { footer } = useSelector((state) => state.settings.webSettings) ;

  const [footerLogo, setFooterLogo] = useState();

  const [selectedLinks, setSelectedLinks] = useState(null);

  const [selectedCategories, setSelectedCategories] = useState(null);

  const FOR_FOOTER_LOGO = "footer-logo";

  const { openFileManagerModal } = useFileManager();

  useSetFile({
    whatFor: FOR_FOOTER_LOGO,
    cb: (file) => {
      setFooterLogo(file.path);
    },
  });
  const dispatch = useDispatch();

  const { t } = useTranslation();

  //
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(footerSchema),
    defaultValues: footer,
  });

  const submitFooter = (data) => {
    const requestBody = {
      copyright: data.copyright,
      logo: footerLogo,
      links: [
        ...(selectedCategories?.map((cat) => cat.value) || []),
        ...(selectedLinks?.map((link) => link.value) || []),
      ],
    };
    dispatch(setWebSettingsAction({
      field: 'footer',
      data: {
        type: 'footer',
        data: requestBody
      }
    }));
  };

  useEffect(() => {
    if (footer) {
      setFooterLogo(footer.logo);
      setSelectedLinks(
        footer.links
          ?.filter((item) => item.type === "Link")
          .map((item) => {
            return { value: item.id, label: item.title };
          })
      );
      setSelectedCategories(
        footer.links
          ?.filter((item) => item.type === "Category")
          .map((item) => {
            return { value: item.id, label: item.title };
          })
      );
      reset(footer);
    }
  }, [footer]);

  useEffect(() => {
    dispatch(getWebSettingsAction({
      field: 'footer',
    }));
  }, []);


  return (
    <Form onSubmit={handleSubmit(submitFooter)}>
      <input type="submit" hidden />
      <Row className="mt-2">
        <Col lg={6}>
          <div>
            <CustomLabel htmlFor="copyright-input" className="form-label">
              Copyright
            </CustomLabel>

            <CustomInput
              name="copyright"
              type="text"
              className="form-control"
              id="copyright-input"
              {...register("copyright")}
            />
            {errorMessage(errors, "copyright")}
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <CustomLabel htmlFor="formFile" className="form-label">
              Footer logo
            </CustomLabel>
            <SelectFileButton
              text="Select Footer Logo"
              onClick={() => openFileManagerModal(FOR_FOOTER_LOGO)}
            />

            {errorMessage(errors, "logo")}
          </div>
          {footerLogo && (
            <div style={{ position: "relative", maxWidth: "250px" }}>
              <img className="img-thumbnail mt-4" alt="" src={footerLogo} />
              <i
                className="ri-delete-bin-fill"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "5%",
                  fontSize: "1.1rem",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => setFooterLogo("")}
              ></i>
            </div>
          )}
        </Col>

        <Restricted permissions={WEB_SETTINGS_PERMISSIONS.update}>
          <CardFooter className="mt-3">
            <div className="d-flex justify-content-end">
                <SaveButton />
            </div>
          </CardFooter>
        </Restricted>
        
      </Row>
    </Form>
  );
};

export default Footer;
