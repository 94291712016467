import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import PageContainer from "../../../Components/Layout/PageContainer";
import AdminsTable from "../../../Components/Settings/Admins/Table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CustomButton from "../../../Components/Common/CustomButton";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { Card, Col, Row } from "reactstrap";
import { getAllSettingsDataAction, deleteSettingsDataAction } from "../../../redux/actions/settings";
import { useSearch } from "../../../hooks/useSearch";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import AdminModal from "../../../Components/Settings/Admins/Modal";

const Admins = () => {

  const {  admins: { list: admins, total } } = useSelector((state) => state.settings);

  const { load, callback, deleteModal, isDeleting, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const { create,  modalType: { type, currentValues }, update, toggleModal, isOpen: isModalOpen } = useCreateUpdateModal({
    name: "",
    email: "",
    password: "",
    role: ""
  });

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const searchFunc = async (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllSettingsDataAction({
      field: 'admins',
      query,
      onSuccess: () => setLoading(false)
    }));
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, onSort, search } = useSearch({
    name: 'admins',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const handleDelete = () => {
    load();
    dispatch(
      deleteSettingsDataAction({
        field: 'admins',
        data: {
          ids: itemsToDelete.selectedRows.map((item) => item.id)
        },
        onSuccess() {
          callback();
          search();
        },
      })
    );
  };

  return (
     <PageContainer>

        <BreadCrumb title="Admins" pageTitle="Settings" />

        <AdminModal 
          isOpen={isModalOpen}
          toggle={toggleModal}
          type={type}
          initialValues={currentValues}
        />

        <Row>
          <Col className="hstack gap-2">
              <CustomButton
                className="btn-success"
                onClick={create}
              >
                <i className="ri-add-fill me-1 align-bottom"></i>
                Create
              </CustomButton>

              <CustomButton
                className="btn-danger"
                disabled={!itemsToDelete.selectedRows?.length > 0}
                onClick={() => setDeleteModal(true)}
              >
                <i className="ri-delete-bin-line align-bottom"></i>{" "}
                Delete
              </CustomButton>

          </Col>
        </Row>

        <Card className="mt-3">
          <AdminsTable
              data={admins}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              handleDelete={handleDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              onSort={onSort}
              sortDirection={searchValues.order_value}
              fetching={loading}
              isDeleting={isDeleting}
              handleClick={(row) => update({
                id: row.id,
                name: row.name,
                email: row.email,
                role: row.roles?.[0]?.name ?? ""
              })}
          />
        </Card>
      </PageContainer>
  );
};

export default Admins;
