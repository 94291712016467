import React, { useState } from "react";
import { Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import GroupsTable from "../../../Components/Users/Groups/Table";
import { useDispatch, useSelector } from "react-redux";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { USER_GROUP_PERMISSIONS } from "../../../permissions/users";
import Restricted from "./../../../Components/Guard/Restricted";
import { getAllUsersDataAction, deleteUsersDataAction } from "../../../redux/actions/users";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const Groups = () => {

  const [groupsModal, setGroupsModal] = useState(false);

  const [loading ,setLoading] = useState(true);

  const { create, modalType, update } = useCreateUpdateModal({
    name: "",
    code: "",
    description: "",
  });

  const searchFunc = (query) => {
    if (loading === false) setLoading(true);
    dispatch(getAllUsersDataAction({
      field: 'groups',
      query,
      onSuccess() {
        setLoading(false);
      }
    }));
  }

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'groups',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const { callback, load, deleteModal, isDeleting, itemsToDelete, setDeleteModal,onError, setItemsToDelete } = useDeleteMultiple();

  const { groups: { list: groups, total } } = useSelector((state) => state.users);

  const toggleGroupsModal = () => {
    return setGroupsModal(!groupsModal);
  };

  const openModalCreate = () => {
    create();
    setGroupsModal(true);
  };

  const dispatch = useDispatch();

  const handleDelete = () => {
    load();
    dispatch(
      deleteUsersDataAction({
        field: 'groups',
        data: {
          ids:  itemsToDelete.selectedRows.map((item) => item.id)
        } ,
          onSuccess() {
            callback();
            search();
          },
          onError: (error) => {
            onError(error);
          },
      })
    );
  };

  return (
    
      <PageContainer>

        <BreadCrumb title="Groups" pageTitle="Users" to="/dashboard/users" />

        <div className="hstack gap-2">

            <Restricted permissions={USER_GROUP_PERMISSIONS.create}>
              <CustomButton
                className="btn-success"
                onClick={openModalCreate}      
              >
                <i className="ri-add-line align-bottom me-1"></i>
                Create
              </CustomButton>
            </Restricted>

            <Restricted permissions={USER_GROUP_PERMISSIONS.delete}>
              <CustomButton
                className="btn-danger"
                onClick={() => setDeleteModal(true)}
                disabled={!itemsToDelete?.selectedRows?.length > 0}
              >
                <i className="ri-add-line align-bottom me-1"></i>
                Delete
              </CustomButton>
            </Restricted>

        </div>

        
        <Card className="mt-3">
            <GroupsTable
                data={groups}
                paginationServer
                paginationTotalRows={total}
                navigateToPage={navigateToPage}
                selectableRows
                itemsToDelete={itemsToDelete}
                setItemsToDelete={setItemsToDelete}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                handleDelete={handleDelete}
                filters={searchValues}
                handleFilterChange={handleChange}
                pageSize={searchValues.page_size}
                setPageSize={setPageSize}
                sortDirection={searchValues.order_value}
                fetching={loading}
                isDeleting={isDeleting}
                selectableRowDisabled={(row) => row.id === 1}
                groupsModal={groupsModal}
                toggleGroupsModal={toggleGroupsModal}
                modalType={modalType}
                setGroupsModal={setGroupsModal}
                update={update}
            />
        </Card>

      </PageContainer>
  );
};

export default Groups;
