import { toast } from 'react-toastify';
import i18n from "../i18n"

const messages = {
    success: 'Operation was successful',
    error: 'Something went weong'
}

export default function toastAlert ({ type , message , ...rest }) {
    const theme = 'colored';
    return toast[type || 'success'](
        i18n.t(message || messages[type || 'success']), 
        { 
            autoClose: 3000, 
            theme, 
            ...rest 
        }
    );
}
