import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import toastAlert from "../../../helpers/toastAlert";
import CoverSpinner from "../../Common/CoverSpinner";
import DataTable from "../../Common/DataTable";
import { deleteSettingsDataAction } from "../../../redux/actions/settings";

const LanguagesTable = ({ data }) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteLanguage = (id) => {
    setIsDeleting(true);
    dispatch(
      deleteSettingsDataAction({
        field: 'languages',
        data: {
          ids: [id]
        },
        onSuccess() {
          setIsDeleting(false);
          toastAlert({ type: 'success'})
        },
        onError() {
          setIsDeleting(false);
        },
      })
    );
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: (row) => row.id,
      grow: -1,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: (row) => row.name || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Code")}</span>,
      selector: (row) =>
        row.code ? <span className="text-uppercase">{row.code}</span> : "-",
    },
    {
      name: <span className="font-weight-bold fs-13 ">{t("Actions")}</span>,
      selector: (row) => (
        <div className="d-flex align-items-center gap-3 mx-auto">
          <Link to={`/dashboard/web-settings/languages/keys/${row.code}`}>
            <Button className="rounded-pill  btn-soft-primary">
              <i className="ri-translate"></i>
            </Button>
          </Link>

          <Link to={`/dashboard/web-settings/languages/update/${row.code}`}>
            <Button className="rounded-pill btn-soft-success">
              <i className="ri-file-edit-line"></i>
            </Button>
          </Link>
          {row.code !== "en" && (
            <>
              <Button
                onClick={() => onDeleteLanguage(row.code)}
                className="rounded-pill  btn-soft-danger"
              >
                <i className="ri-delete-bin-line"></i>
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DataTable
        data={data}
        columns={columns}
      />
      <CoverSpinner loading={isDeleting} />
    </React.Fragment>
  );
};

export default LanguagesTable;
