import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import NewStockTable from "../../../Components/Storage/NewStock/Table";
import { objectFilter } from "../../../helpers/objectFilter";
import { useTranslation } from "react-i18next";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { CSVLink } from "react-csv";
import { getNewStockCSVData } from "../../../helpers/CSV/newStockCSV";
import CustomButton from "../../../Components/Common/CustomButton";
import storageApi from "../../../apis/storage/api";
import { getAllStorageDataAction } from "../../../redux/actions/storage";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useSearch } from "../../../hooks/useSearch";

const NewStock = () => {

  const { itemsToDelete, setItemsToDelete } = useDeleteMultiple();

  const { newStock: { list: newStock, total } } = useSelector((state) => state.storage);

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [CSVLoading, setCSVLoading] = useState(false);

  const [exportAllData, setExportAllData] = useState([]);

  const CSVRef = useRef();

  const dispatch = useDispatch();

  const searchFunc = async (query) => {
    setLoading(true);
    dispatch(
      getAllStorageDataAction({
        field: 'newStock',
        query,
        onSuccess: () => setLoading(false),
      })
    );
  }

  const { searchValues, handleChange, handleMultipleChanges, setPageSize, navigateToPage, onSort, clearFilter } = useSearch({
    name: 'newStock',
    cachedValues: {
      date_from: "",
      date_to: "",
    },
    debouncedValues: {
      product_name: "",
      batch: "",
      lot: "",
    },
    searchFunc
  });

  const getNewStocksList = async () => {
    setCSVLoading(true);
    try {
      const {data: { data: lotMovementsData }} = await storageApi.newStock.getAll(objectFilter({
        ...searchValues,
        page_size: 'all'
      }))
      setExportAllData(getNewStockCSVData({data: lotMovementsData}))
    } catch (error) {
      console.log('error', error);
    } finally {
      setCSVLoading(false);
    }
  }

  useEffect(() => {
    if (CSVRef.current && exportAllData.length) {
       CSVRef.current.link?.click();
    }
  }, [CSVRef, exportAllData])


  return (
      <PageContainer>

        <BreadCrumb
          pageTitle="Storage"
          title="New stock"
          to="/dashboard/storage"
        />

        <Row className="gy-3">
          <Col md={12} className="d-flex gap-2">

              <CSVLink
                  data={getNewStockCSVData({
                    data: itemsToDelete.selectedRows,
                  })}
                  type="button"
                  className={`btn btn-primary ${!itemsToDelete?.selectedRows?.length && "disabled"}`}
                  filename="New Stock"
                >
                  Export CSV
              </CSVLink>

              <CustomButton
                isLoading={CSVLoading}
                className='btn btn-success'
                type="button"
                onClick={getNewStocksList}
              >
                Export All CSV
              </CustomButton>

              <CSVLink
                    data={exportAllData}
                    filename={t('New Stock')}
                    asyncOnClick={true}
                    ref={CSVRef}
                    hidden
                  >
              </CSVLink>

              
              <CustomButton
                className="btn-secondary"
                onClick={clearFilter}
              >
                Clear Filters
              </CustomButton>
              
          </Col>
        </Row>

        <Card className="mt-3">
            <NewStockTable
              data={newStock}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              handleMultipleFilterChanges={handleMultipleChanges}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              onSort={onSort}
              sortDirection={searchValues.order_value}
              fetching={loading}
            />
        </Card>

      </PageContainer>
  );
};

export default NewStock;
