import { ordersActions } from "../slices/orders";
import ordersApi from "../../apis/orders/api";
import toastAlert from "../../helpers/toastAlert";

export const getAllOrdersDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await ordersApi[payload.field].getAll(payload?.query);
    dispatch(
        ordersActions.setData({
          field: payload.field,
          data: response.data,
          total: response.meta?.total || null,
        })
    );
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const createOrdersDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await ordersApi[payload.field].create(payload.data);
    dispatch(
        ordersActions.createData({
          field: payload.field,
          data: response.data,
        })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload.onError) {
        payload?.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const updateOrdersDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await ordersApi[payload.field].update(payload.data.id, payload.data.data);
    dispatch(
        ordersActions.updateData({
          field: payload.field,
          id: payload.id,
          data: response.data,
        })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const deleteOrdersDataAction = (payload) => async (dispatch) => {
  try {
    await ordersApi[payload.field].delete(payload.data);
    dispatch(
        ordersActions.deleteData({
          field: payload.field,
          data: payload.data
        })
    );
    if (payload?.onSuccess) {
        payload.onSuccess();
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const getOrderSettingsAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await ordersApi.settings.get(payload.field);
    dispatch(
        ordersActions.setSettings({
          field: payload.field,
          data: response.data,
    }));
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const setOrderSettingsAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await ordersApi.settings.update({
      type: payload.type,
      data: payload.data
    });
    dispatch( ordersActions.setSettings({
          field: payload.field,
          data: response.data,
    }));
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
    toastAlert({ type: 'success' })
  } catch (error) {
    toastAlert({ type: 'error' })
    console.log("error", error);
  }
};


