import React from "react";
import ZoneForm from "../../../Components/Users/Zones/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const CreateZone = () => {
  
  return (
    <PageContainer>
        <BreadCrumb pageTitle="Zones" title="Create" to="/dashboard/users/zones" />
        <ZoneForm 
            type="create"
        />
    </PageContainer>
  );
};

export default CreateZone;
