import React from "react";
import DataTable from "../../Common/DataTable";
import StatusModal from "./Modal";
import { useTranslation } from "react-i18next";
import CustomInput from "../../Custom/CustomInput";
import StatusToggle from "../../Common/StatusToggle";

const UsersStatusesTable = ({
  statusModal,
  toggleStatusModal,
  update,
  modalType,
  filters,
  handleFilterChange,
  ...props
}) => {

  const { t } = useTranslation();

  const openUpdateModal = (data) => {
    update({
      id: data.id,
      name: data.name,
      status: data.status,
    });
    toggleStatusModal();
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },

    {
      name:            
      <CustomInput
        className="form-control"
        placeholder="Name"
        value={filters.name}
        onChange={(e) => handleFilterChange(({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        }))}
        data-filtered={!!filters.name}
      />,
      selector: row => row.name,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Active")}</span>,
      selector: row =>  <StatusToggle type="statues" id={row.id} field="status" status={row.status} />,
    },

  ];

  const handleClick = (row) => {
    openUpdateModal(row);
  };

  return (
    <React.Fragment>

      <StatusModal
        modal={statusModal}
        toggleModal={toggleStatusModal}
        modalType={modalType}
      />

      <DataTable
        columns={columns}
        handleClick={handleClick}
        {...props}
      />
      
    </React.Fragment>
  );
};

export default UsersStatusesTable;
