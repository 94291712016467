import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ordersApi from "../../../apis/orders/api";
import DeliveryMethodForm from "../../../Components/Orders/DeliveryMethods/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateDeliveryMethod = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["orders-delivery-method", id], () => ordersApi.deliveryMethods.getOne(id),
    {
      onSuccess({data: {data}}) {
        setInitialValues(data);
      }
    }
  );

  return (
    <PageContainer isLoading={isLoading || isRefetching} >
      <BreadCrumb title="Update" pageTitle="Delivery Methods" to="/dashboard/orders/delivery-methods" />
      {initialValues && (
        <DeliveryMethodForm 
          type="update" 
          initialValues={initialValues} 
        />
      )}
    </PageContainer>
  );
};

export default UpdateDeliveryMethod;
