import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import usersApi from "../../../../../apis/users/api";
import toastAlert from "../../../../../helpers/toastAlert";
import useDeleteMultiple from "../../../../../hooks/useDeleteMultiple";
import usePagePagination from "../../../../../hooks/usePagePagination";
import DataTable from "../../../../Common/DataTable";
import ProductUnitRow from "./ProductUnitRow";

const ProductDiscounts = ({ initialValues }) => {

  const { t } = useTranslation();

  const { navigateToPage } = usePagePagination();

  const [discounts, setDiscounts] = useState(null);

  const unitsRef = useRef([]);

  const { load, callback,deleteModal, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("User")}</span>
      ),
      selector: (row) => row.user_name,
    },
  ];

  const handleDelete = async () => {
    load();
    const ids = itemsToDelete.selectedRows.map((item) => item.id);
    await usersApi.users.discounts.delete({ ids });
    setDiscounts(prev => prev.filter(item => !ids.includes(item.id)));
    callback();
    toastAlert({ type: 'success'})
  };


  useEffect(() => {
    if (initialValues) {
      setDiscounts(initialValues?.discounts);
    }
  }, [initialValues]);

  return (
    <div>

      <button
        type="button"
        className="btn btn-dark btn-sm"
        disabled={!itemsToDelete.selectedRows?.length > 0}
        onClick={() => setDeleteModal(true)}
      >
        <i className="ri-delete-bin-line align-bottom"></i>{" "}
        {t("Delete Selected")}
      </button>

      <DataTable
        itemsToDelete={itemsToDelete}
        setItemsToDelete={setItemsToDelete}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDelete={handleDelete}
        paginationServer
        navigateToPage={navigateToPage}
        data={discounts || []}
        columns={columns}
        expandableRows
        expandableRowComponent={({ data }) => {
          return (
            <ProductUnitRow 
              data={data} 
              unitsRef={unitsRef} 
              initialValues={initialValues}
            />
          );
        }}
        selectableRows
      />

    </div>
  );
};

export default ProductDiscounts;
