const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "settings",
  initialState: {

    webSettings: {
        footer: {},
        socialMedia: {},
        setup: {},
        consult: {},
        policy: {},
        about: "",
        seo: {},
        version: {},
        email: {},
        openingHours: {},
    },

    languages: {
      list: [],
      total: null,
      active: 'en'
    },

    closingHours: {
        list: [],
        total: null
    },

    homeSliders: {
        list: [],
        total: null
    },

    pages: {
        list: [],
        total: null
    },
    
    contacts: {
        list: [],
        total: null
    },

    admins: {
        list: [],
        total: null
    },

    roles: {
      list: [],
      total: null
    },

    stockStaff: {
      list: [],
      total: null
    },
  },

  reducers: {

    setSettings: (state, { payload }) => {
        if (state.webSettings[payload.field]) {
          state.webSettings[payload.field] = payload.data;
        }
      },

    setData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: payload.data,
          total: payload.total,
        };
      }
    },

    createData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: [...state[payload.field].list, payload.data],
        };
      }
    },

    updateData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: state[payload.field].list.map((item) =>
            item.id === payload.id ? payload.data : item
          ),
        };
      }
    },

    deleteData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          total: state[payload.field].total - payload.data.ids.length,
          list: state[payload.field].list.filter(
            (item) => !payload.data.ids.includes(item.id)
          ),
        };
      }
    },

    setActiveLanguage: (state, { payload }) => {
      state.languages.active = payload
    }
  },
});

export const settingsActions = slice.actions;

export default slice.reducer;
