import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "cleave.js/dist/addons/cleave-phone.in";
import "quill/dist/quill.snow.css";
import WebSettingsTabs from "../../../Components/Settings/Web/Tabs";
import PageContainer from "../../../Components/Layout/PageContainer";

const WebSettings = () => {

  return (
      <PageContainer>
        <BreadCrumb pageTitle="Settings" title="Web" />
        <WebSettingsTabs />
      </PageContainer>
  );
  
};

export default WebSettings;
