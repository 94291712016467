import { usersActions } from "../slices/users";
import usersApi from "../../apis/users/api";
import toastAlert from "../../helpers/toastAlert";

export const getAllUsersDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await usersApi[payload.field].getAll(payload?.query);
    dispatch(
        usersActions.setData({
          field: payload.field,
          data: response.data,
          total: response.meta?.total || null,
      })
    );
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const createUsersDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await usersApi[payload.field].create(payload.data);
    dispatch(
        usersActions.createData({
          field: payload.field,
          data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload.onError) {
        payload?.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const updateUsersDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await usersApi[payload.field].update(payload.data.id, payload.data.data);
    dispatch(
        usersActions.updateData({
          field: payload.field,
          id: payload.id,
          data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const deleteUsersDataAction = (payload) => async (dispatch) => {
  try {
    await usersApi[payload.field].delete(payload.data);
    dispatch(usersActions.deleteData({
        field: payload.field,
        data: payload.data
    }));
    if (payload?.onSuccess) {
        payload.onSuccess();
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};
