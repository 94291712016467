import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  CardFooter,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TransportPalletRow from "../../../Components/Orders/OrderTransportDetails/TransportPalletRow";
import {
  exportOrderTransportPdf,
  exportOrderPalletsPdf,
} from "../../../helpers/Pdfs/Orders/OrderTransportPdf";
import { useParams } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import ordersApi from "../../../apis/orders/api";
// Images
import OrderImg1 from "../../../assets/images/Order/order1.png";
import OrderImg2 from "../../../assets/images/Order/order2.png";
import { useAuthState } from "../../../context/authContext";
import { orderTransportDetailsBody } from "../../../helpers/RequestBodies/Orders/OrderTransportDetails";
import toastAlert from "../../../helpers/toastAlert";
import CustomButton from "../../../Components/Common/CustomButton";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../../Components/Custom/CustomLabel";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { palletsNote } from "../../../Components/constants/palletsNote";
import { getAllOrdersDataAction } from "../../../redux/actions/orders";
import { getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";

const OrderTransportDetails = () => {

  const { t } = useTranslation();

  const { id } = useParams();

  const [order, setOrder] = useState(null);

  const [pallets, setPallets] = useState(null);

  const [isExport, SetIsExport] = useState(false);
  
  const [data, SetData] = useState(null);

  const [selectedTransport, setSelectedTransport] = useState(null);

  const [loading, setLoading] = useState(false);

  const [emailLoading, setEmailLoading] = useState(false);

  const [note, setNote] = useState(palletsNote);

  const { adminData } = useAuthState();

  const [{ isLoading: loadingOrder }, { isLoading: loadingPallets }] = useQueries({
    queries: [
      {
        queryKey: ["order", id],
        queryFn: () => ordersApi.orders.getOne(id),
        onSuccess({data: { data }}) {
          setOrder(data);
        }
      },
      {
        queryKey: ["order-pallets", id],
        queryFn: () => ordersApi.orders.transport.get(id),
        onSuccess({data: { data }}) {
          setPallets(data);
        }
      },
    ]
  })

  const dispatch = useDispatch();

  const {
    orders: { transports: { list: transports }, units: { list: units } },
    products: { vats: { list: vats }, locations: { list: locations } },
  } = useSelector((state) => state);

  const { control, register, handleSubmit, reset, watch, setValue } = useForm();

  const formState = watch();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "units",
  });

  const submitForm = async (data) => {
    try {
      setLoading(true);
      const requestBody = orderTransportDetailsBody(data);
      await ordersApi.orders.transport.update(id, requestBody);
      toastAlert({ type: 'success'})
      SetIsExport(true);
      SetData(data);
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setLoading(false);
    }
  };

  const sendPdfToMail = async () => {
    setEmailLoading(true);
    try {
        const pdfFile = await exportOrderTransportPdf({
          data,
          order,
          vats,
          adminData,
          transports,
          generateFile: true
        })
        const formData = new FormData();
        formData.append("file", pdfFile);
        formData.append("delivery_id", formState.transport?.id);
        formData.append("note", note);
        await ordersApi.orders.transport.sendPdfToMail(id, formData);
        toastAlert({ type: 'success' });
    } catch(error) {
        toastAlert({ type: 'error' });
    } finally {
      setEmailLoading(false)
    }
  }

  useEffect(() => {
    if (pallets && transports.length && locations.length && units.length) {
      const transport = transports?.find(transport => transport?.id === pallets.delivery_id);
      reset({
        date: {
          estimated_pickup: pallets.estimated_pickup || "",
          actual_pickup: pallets.actual_pickup || "",
          estimated_delivery: pallets.estimated_delivery || "",
          actual_delivery: pallets.actual_delivery || "",
        },
        units:
          pallets.palets?.map((pallet) => ({
            ...pallet,
            chargable_weight: {
              label: locations?.find(
                (location) => location?.id === pallet.stock_zone_id
              )?.zone,
              value: pallet.stock_zone_id,
            },
            unit_type: {
              label: units.find((unit) => unit?.id === pallet.transport_unit_id)
                ?.name,
              value: pallet.transport_unit_id,
            },
          })) || [],
        transport: transport,
      });
      setSelectedTransport({
        label: transport?.name,
        value: transport?.id,
      });
      if (pallets?.palets.length) {
        SetData({
          date: {
            estimated_pickup: pallets.estimated_pickup || "",
            actual_pickup: pallets.actual_pickup || "",
            estimated_delivery: pallets.estimated_delivery || "",
            actual_delivery: pallets.actual_delivery || "",
          },
          units:
            pallets.palets.map((pallet) => ({
              ...pallet,
              chargable_weight: {
                label: locations?.find(
                  (location) => location?.id === pallet.stock_zone_id
                )?.zone,
                value: pallet.stock_zone_id,
              },
              unit_type: {
                label: units.find(
                  (unit) => unit?.id === pallet.transport_unit_id
                )?.name,
                value: pallet.transport_unit_id,
              },
            })) || [],
          transport
        });

        SetIsExport(true);
      }
    }
  }, [pallets, transports, locations, units]);


  useEffect(() => {
    dispatch(getAllOrdersDataAction({
      field: 'transports',
      query: {
        page_size: 'all'
      }
    }))
    dispatch(getAllOrdersDataAction({
      field: 'units',
      query: {
        page_size: 'all'
      }
    }))
    dispatch(getAllProductsDataAction({
      field: 'locations',
      query: {
        page_size: 'all'
      }
    }));
    dispatch(getAllProductsDataAction({
      field: 'vats',
      query: {
        page_size: 'all'
      }
    }));
  }, [dispatch]);

  return (
    <PageContainer isLoading={loadingOrder || loadingPallets}>

        <BreadCrumb
          pageTitle="Units"
          title="Order detail"
          to="/dashboard/orders/units"
        />

        <Form onSubmit={handleSubmit(submitForm)}>
          <Col md={12}>

            <Card>
              <CardHeader className="border-0">{t("Addresse")}</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <InputGroup>
                      <span
                        className="input-group-text"
                        id="inputGroupPrepend2"
                      >
                        <i className="ri-chat-forward-line" />
                      </span>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder={t("Sender")}
                        disabled={true}
                      />
                    </InputGroup>

                    <Card
                      className="mt-3 p-3"
                      style={{ backgroundColor: "#eff2f7" }}
                    >
                      <p className="card-text">Din Engros AS</p>
                      <p className="card-text">Heggstadmoen 5</p>
                      <p className="card-text">7080 HEIMDAL NO</p>
                    </Card>
                  </Col>
                  <Col>
                    <InputGroup>
                      <span
                        className="input-group-text"
                        id="inputGroupPrepend2"
                      >
                        <i
                          className="ri-chat-forward-line"
                          style={{ transform: "scaleX(-1)" }}
                        />
                      </span>
                      <Input
                        type="text"
                        placeholder={order?.company?.profile?.company}
                        disabled={true}
                      />
                    </InputGroup>

                    <Card
                      className="mt-3 p-3"
                      style={{ backgroundColor: "#eff2f7" }}
                    >
                      <p className="card-text">
                        {order?.company?.profile?.company}
                      </p>
                      <p className="card-text">
                        {order?.company?.address?.street_name}
                      </p>
                      <p className="card-text">
                        {order?.company?.address?.street_zip_code}{" "}
                        {order?.company?.address?.street_name}
                      </p>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardFooter>{t("Details")}</CardFooter>
              <CardBody>
                <Row md={12} lg={12} xl={12}>
                  <Col md={6} lg={6} xl={6} className="px-3">
                    <Row className="mb-2">
                      <Col md={6}>
                        <CustomLabel>Order Number</CustomLabel>
                        <Input
                          type="text"
                          placeholder={order?.matricule}
                          disabled={true}
                        />
                      </Col>
                      <Col md={6}>
                        <CustomLabel>Status</CustomLabel>
                        <Input
                          type="text"
                          placeholder={order?.current_status.name}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={6}>
                        <CustomLabel>Estimated Pickup</CustomLabel>
                        <Controller
                          name="date"
                          control={control}
                          render={({ name }) => (
                            <input
                              name={name}
                              type="date"
                              className="p-2 rounded input-group mb-3 no-border border"
                              {...register("date.estimated_pickup")}
                            />
                          )}
                        />
                      </Col>
                      <Col md={6}>
                        <CustomLabel>Estimated Delivery</CustomLabel>
                        <Controller
                          name="date"
                          control={control}
                          render={({ name }) => (
                            <input
                              name={name}
                              type="date"
                              className="p-2 rounded input-group mb-3 no-border border"
                              {...register("date.estimated_delivery")}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={6}>
                        <CustomLabel>Actual Pickup</CustomLabel>
                        <Controller
                          name="date"
                          control={control}
                          render={({ name }) => (
                            <input
                              name={name}
                              type="date"
                              className="p-2 rounded input-group mb-3 no-border border"
                              {...register("date.actual_pickup")}
                            />
                          )}
                        />
                      </Col>
                      <Col md={6}>
                        <CustomLabel>Actual Delivery</CustomLabel>
                        <Controller
                          name="date"
                          control={control}
                          render={({ name }) => (
                            <input
                              name={name}
                              type="date"
                              className="p-2 rounded input-group mb-3 no-border border"
                              {...register("date.actual_delivery")}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    xl={6}
                    className="px-5"
                    style={{ borderLeft: "1px dotted #2d3e50" }}
                  >
                    <Row className="mb-2">
                      <CustomLabel className="p-0">Clerk</CustomLabel>
                      <Input
                        type="text"
                        placeholder={adminData?.name || ""}
                        disabled={true}
                      />
                    </Row>
                    <Row className="mb-2">
                      <CustomLabel className="p-0">Transporter</CustomLabel>
                      {selectedTransport && transports.length && (
                        <Controller
                          name="transport"
                          control={control}
                          render={({ name, field: { onChange } }) => (
                            <Select
                              name={name}
                              className="p-0"
                              defaultValue={
                                selectedTransport && selectedTransport
                              }
                              options={transports.map((transport) => {
                                return {
                                  value: transport.id,
                                  label: transport.name,
                                };
                              })}
                              onChange={(e) => {
                                onChange(
                                  transports.find(
                                    (transport) => transport.id === +e.value
                                  )
                                );
                                
                              }}
                            />
                          )}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="">
                {t("FreightLines")}
              </CardHeader>

              {pallets &&
                fields?.map((field, index) => (
                  <TransportPalletRow
                    key={field.id}
                    control={control}
                    register={register}
                    remove={remove}
                    index={index}
                    reset={reset}
                    locations={locations}
                    data={data}
                    formState={formState}
                    setValue={setValue}
                    pallet={pallets?.palets[index]}
                  />
                ))}

              <CardFooter className="d-flex justify-content-end gap-2">
                <button
                  className="btn btn-sm btn-success"
                  type="button"
                  onClick={() =>
                    append({
                      chargable_weight: {
                        label: "",
                        value: "",
                      },
                      marks: "",
                      weight: "",
                      units: "",
                      unit_type: {
                        label: "",
                        value: "",
                      },
                      length: "",
                      width: "",
                      height: "",
                      volume: "",
                    })
                  }
                >
                  <i className="align-bottom"></i> {t("Add")}
                </button>
                <CustomButton
                  isLoading={loading}
                  className="btn btn-md btn-primary"
                  type="submit"
                >
                  <i className="ri-file-pdf-line align-bottom"></i>{" "}
                  {t("Export Pdf")}
                </CustomButton>
              </CardFooter>
            </Card>

          </Col>
        </Form>

        {isExport && (
          <Card>
            <CardHeader className="d-flex justify-content-between">
              {t("Documents External")}
              <CustomButton
                  isLoading={emailLoading}
                  className="btn btn-secondary"
                  onClick={() => sendPdfToMail()}
                >
                  {t('Send Mail')}
                </CustomButton>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                    <label className="form-label">{t("Note")}</label>
                    <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => setNote(editor.getData())}
                        data={note}
                      />
                </Col>
                <Col
                  style={{ cursor: "pointer" }}
                  md={2}
                  onClick={() =>
                    exportOrderTransportPdf({
                      data,
                      order,
                      vats,
                      adminData,
                      transports,
                    })
                  }
                >
                  <img id="orderPdf" src={OrderImg1} alt="order 1" />
                  <p>{t("Norwegian waybill")}</p>
                </Col>
                <Col
                  style={{ cursor: "pointer" }}
                  md={2}
                  onClick={() =>
                    exportOrderPalletsPdf({
                      data,
                      order,
                      vats,
                      adminData,
                      transports,
                    })
                  }
                >
                  <img id="orderPdf" src={OrderImg2} alt="order 2" />
                  <p>{t("Package Labels")}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
    </PageContainer>
  );
};

export default OrderTransportDetails;
