import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSettingsDataAction, getWebSettingsAction } from '../../redux/actions/settings';

const AppWrapper = ({children}) => {

    const dispatch = useDispatch();

    const { webSettings: { setup } } = useSelector((state) => state.settings);
    
    useEffect(() => {
        dispatch(getWebSettingsAction({
          field: 'setup',
          type: 'setup'
        }));

        dispatch(getWebSettingsAction({
          field: 'consult',
          type: 'consult'
        }));

        dispatch(getAllSettingsDataAction({
          field: 'languages',
        }));
    }, [dispatch]);

    useEffect(() => {
    if (setup) {
        document.title = setup.website_name || "Stock";
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = setup.fav_icon;
    }
    }, [setup]);

    useEffect(() => {
      window.history.scrollRestoration = 'manual'
    }, []);

  return (
    <React.Fragment>
        {children}
    </React.Fragment>
  )
}

export default AppWrapper