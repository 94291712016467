import React from 'react'
import { Container, Spinner } from 'reactstrap'

const PageContainer = ({children, isLoading}) => {

  return (
    <Container fluid>
      {isLoading ?     
        <div
          style={{
            height: "75vh",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div> 
    : children}
    </Container>
  )
}

export default PageContainer