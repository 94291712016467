import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "reactstrap";
import { errorMessage } from "./../../../../helpers/validation/errorMessage";
import { userAddressBody } from "../../../../helpers/RequestBodies/Users/usersRequestBody";
import { useParams } from "react-router-dom";
import { userAddressSchema } from "../../../../helpers/validation/Users/createUser";
import { yupResolver } from "@hookform/resolvers/yup";
import { setFormErrors } from "../../../../helpers/setFormErrors";
import CustomInput from "../../../Custom/CustomInput";
import CustomLabel from "../../../Custom/CustomLabel";
import toastAlert from "../../../../helpers/toastAlert";
import usersApi from "../../../../apis/users/api";
import SaveButton from "../../../Common/Buttons/SaveButton";

const DeliveryAddress = ({ type, initialValues, refetchTracking }) => {

  const [loading, setLoading] = useState(false);

  const { register,  handleSubmit, formState: { errors }, setError } = useForm({
    resolver: yupResolver(userAddressSchema),
    defaultValues: initialValues,
  });

  const { id } = useParams();

  const onSubmit = async (data) => {
    setLoading(true);
    const requestBody = userAddressBody(data);
    try {
      if (type === 'create') {
        await usersApi.users.address.create({ ...requestBody, user_id: id });
      } else {
        await usersApi.users.address.update(initialValues.id, { ...requestBody, user_id: id });
      }
      await refetchTracking();
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
      setFormErrors(error.data?.error, setError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-3">

        <Col className="mb-3" lg={6}>
          <CustomLabel
            className="form-label"
            htmlFor="create-user-street-name-input"
          >
            Street name
          </CustomLabel>
          <CustomInput
            type="text"
            className="form-control"
            placeholder="Enter Street Name"
            id="create-user-street-name-input"
            name="street_name"
            {...register("street_name")}
          />
          {errorMessage(errors, "street_name")}
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel
            className="form-label"
            htmlFor="create-user-street-zip-code-input"
          >
            Street zip code
          </CustomLabel>
          <CustomInput
            type="text"
            className="form-control"
            placeholder="Enter Street Name"
            id="create-user-street-zip-code-input"
            name="street_zip_code"
            {...register("street_zip_code")}
          />
          {errorMessage(errors, "street_zip_code")}
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel
            className="form-label"
            htmlFor="create-user-post-name-input"
          >
            Post name
          </CustomLabel>
          <CustomInput
            type="text"
            className="form-control"
            placeholder="Enter Postal Office"
            id="create-user-post-name-input"
            name="post_name"
            {...register("post_name")}
          />
          {errorMessage(errors, "post_name")}
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel
            className="form-label"
            htmlFor="create-user-postal-zipe-code-input"
          >
            Post zip code
          </CustomLabel>
          <CustomInput
            type="number"
            className="form-control"
            placeholder="Enter Postal Code"
            id="create-user-postal-zipe-code-input"
            name="post_zip_code"
            {...register("post_zip_code")}
            step="any"
          />
          {errorMessage(errors, "post_zip_code")}
        </Col>

        <div className="d-flex justify-content-end">
          <SaveButton 
            isLoading={loading}
          />
        </div>

      </Row>
    </form>
  );
};

export default DeliveryAddress;
