import React from "react";

const ImageItem = ({ file, removeFile}) => {

  return (
    <div className="file-preview-container">
        <div className="file-title-container">
            <div className="faded-overlay"></div>
            <div className="file-info-container">
              <div className="remove-file" onClick={() => removeFile(file.id)}>
                <i className="ri-close-line"></i>
              </div>
              {file.name ? 
                <span className="file-title">
                  {file.name || 'Image'}
                </span>
             : <></>}
            </div>
        </div>
        <div className="file-preview">
            <img 
              src={file.link}
              alt={file.link}
            />
        </div>
    </div>
  );
};

export default ImageItem;
