import React from "react";
import CatalogueButton from "./CatalogueButton";
import useLoading from "../../../hooks/useLoading";

const DeleteCatalogueProductButton = ({ id, onDeleteClick }) => {
  const { isLoading, onLoading, onStopLoading } = useLoading();
  return (
    <CatalogueButton
      icon="ri-close-line"
      color="danger"
      isLoading={isLoading}
      onSubmit={() =>
        onDeleteClick({ id, onLoading, onFinally: onStopLoading })
      }
    />
  );
};

export default DeleteCatalogueProductButton;
