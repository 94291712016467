import * as yup from "yup";


export const createOrderUnitSchema = yup.object().shape({
  code: yup.string().required(),
  name: yup.string().required(),
  width: yup.string().required(),
  height: yup.string().required(),
  length: yup.string().required(),
  status: yup.string().required(),
});
