import React from 'react'
import ProductForm from '../../../Components/Products/Products/Form'
import PageContainer from '../../../Components/Layout/PageContainer';
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const CreateProduct = () => {
  
  return (
    <PageContainer>
      <BreadCrumb title="Create" pageTitle="Products"  to="/dashboard/products" />
      <ProductForm 
        type='create'
      />
    </PageContainer>
  )
}

export default CreateProduct;