import React from 'react'
import usePermission from '../../hooks/usePermission'

const Restricted = ({permissions, children, loadingComponent = <></>, render = null}) => {

    const { allowed, loading } = usePermission(permissions);

    if (permissions === undefined || permissions === null) {
        return children
    }

    if (loading) {
        return loadingComponent
    }

    if (allowed) {
        return children
    }

    return render
}

export default Restricted