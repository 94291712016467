import React, { useState } from "react";
import DataTable from "../../Common/DataTable";
import moment from "moment";

const ContactsTable = ({
  data
}) => {

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">ID</span>,
      selector: (row) => row.id,
      grow: -1
    },
    {
      name: <span className="font-weight-bold fs-13">User</span>,
      selector: (row) => row.fullname || '-',
    },
    {
      name: <span className="font-weight-bold fs-13">Company</span>,
      selector: (row) => row.company || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row.email || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">Message</span>,
      selector: (row) => row.message || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">Created at</span>,
      selector: (row) => row.created_at ? moment(row.created_at).format("D MMM, YYYY") : "-",
    },
  ];

  return (
    <DataTable
      data={data}
      columns={columns}
      path="/dashboard/settings/contacts"
      pathType="view"
    />
  );
};

export default ContactsTable;
