import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import createGroupSchema from "../../../helpers/validation/Users/createGroupValidation";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import { useGetValues } from "../../../hooks/useCreateUpdateModal";
import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import CustomTextarea from "../../Custom/CustomTextarea";
import CustomButton from "../../Common/CustomButton";
import { createUsersDataAction, updateUsersDataAction } from "../../../redux/actions/users";
import SaveButton from "../../Common/Buttons/SaveButton";

const GroupsModal = ({ modal, toggleModal, modalType }) => {

  const { t } = useTranslation();

  const { type, currentValues } = modalType;

  const { register, handleSubmit, setValue, formState: { errors }, clearErrors } = useForm({
    defaultValues: {
      name: currentValues.name,
      code: currentValues.code,
      description: currentValues.description,
    },
    resolver: yupResolver(createGroupSchema),
  });

  const [submitting, setSubmitting] = useState(false);

  useGetValues({ currentValues, type, clearErrors, setValue });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setSubmitting(true);
    if (type === 'create') {
      dispatch(createUsersDataAction({
        field: 'groups',
        data,
        onSucces() {
          toggleModal();
          setSubmitting(false);
        },
        onError() {
          setSubmitting(false);
        }
      }));
    } else if (type === 'update') {
      const { id, ...rest } = data;
      dispatch(
        updateUsersDataAction({
          field: 'groups',
          data: {
            id: id,
            data: rest,
          },
          onSuccess() {
            toggleModal();
            setSubmitting(false);
          },
          onError() {
            setSubmitting(false);
          }
        })
      );
    }
  };

  return (
    <Modal modalClassName="zoomIn" id="showModal" isOpen={modal} toggle={toggleModal} centered>

      <ModalHeader className="bg-light text-capitalize p-3" toggle={toggleModal}>
        {t(`${type}`)}
      </ModalHeader>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="submit" hidden />
        <ModalBody>
          <Row className="g-3">
            <Col lg={12}>
              <CustomLabel className="form-label" htmlFor="groupname-field">
                Name
              </CustomLabel>
              <CustomInput
                type="text"
                className="form-control"
                placeholder="Enter Group Name"
                id="groupname-field"
                name="name"
                {...register("name")}
              />
              {errorMessage(errors, "name")}
            </Col>
            <Col lg={12}>
              <CustomLabel className="form-label" htmlFor="group_code-field">
                Code
              </CustomLabel>
              <CustomInput
                id="group_code-field"
                className="form-control"
                placeholder="Enter a unique code used to identify this group."
                type="text"
                name="code"
                {...register("code")}
              />
              {errorMessage(errors, "code")}
            </Col>
            <Col lg={12}>
              <CustomLabel
                htmlFor="group_description-field"
                className="form-label"
              >
                Description
              </CustomLabel>
              <CustomTextarea
                name="description"
                id="group_description-field"
                className="form-control"
                placeholder="Enter Group Description"
                {...register("description")}
              />
              {errorMessage(errors, "description")}
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">

            <CustomButton
              type="button"
              className="btn"
              onClick={toggleModal}
            >
              Close
            </CustomButton>

            <SaveButton 
              isLoading={submitting}
            />

          </div>
        </ModalFooter>
        
      </Form>
    </Modal>
  );
};

export default GroupsModal;
