import { useQueries } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import ordersApi from "../../../apis/orders/api";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  calculatePrices,
  calculatePercentage,
} from "../../../helpers/calculateOrderPrices";
import NewsletterProducts from "../../../Components/Orders/Newsletters/NewsletterProducts";
import NewsletterDetails from "../../../Components/Orders/Newsletters/NewsletterDetails";
import toastAlert from "../../../helpers/toastAlert";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import CustomButton from "../../../Components/Common/CustomButton";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import EmailHistory from "./EmailHistory";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateNewsletter = () => {

  const { id } = useParams();

  const [visibleColumns, setVisibleColumns] = useState({
    purchasePrice: true,
  });

  const { t } = useTranslation();

  const [newsletter, setNewsletter] = useState(null);

  const [note, setNote] = useState("");

  const [sendedAt, setSendedAt] = useState("");

  const [loading, setLoading] = useState(false);

  const [emailHistory, setEmailHistory] = useState(null);

  const [{ isLoading: loadingNewsletter, refetch: refetchNewsletter }, { isLoading: loadingEmailHistory, isRefetching: refetchingEmailHistory, refetch: refetchEmailHistory }] = useQueries({
    queries: [
      {
        queryKey: ["newsletter", id],
        cacheTime: 0,
        queryFn: () => ordersApi.newsletters.getOne(id),
        onSuccess({ data: { data }}) {
          setNewsletter(data);
        } 
      },
      {
        queryKey: ["email-history", id],
        queryFn: () => ordersApi.newsletters.email.getHistory({
          type: 'newsletter',
          type_id: id
        }),
        onSuccess({ data: { data }}) {
          setEmailHistory(data);
        } 
      },
    ]
  })

  const toggleVisibleColumns = () => {
    setVisibleColumns({
      ...visibleColumns,
      purchasePrice: !visibleColumns.purchasePrice,
    });
    localStorage.setItem("showPurchasePrice", !visibleColumns.purchasePrice);
  };

  const updateNl = async () => {
    setLoading(true);
    await ordersApi.newsletters.update(id, {
      note,
      sended_at: sendedAt,
    });
    toastAlert({ type: 'success'})
    setLoading(false);
  };

  useEffect(() => {
    if (newsletter) {
      setNote(newsletter.note);
      setSendedAt(newsletter.sended_at);
    }
  }, [newsletter]);

  useEffect(() => {
    const showPurchasePrice = JSON.parse(
      localStorage.getItem("showPurchasePrice")
    );
    setVisibleColumns({
      ...visibleColumns,
      purchasePrice: showPurchasePrice || false,
    });
  }, []);

  return (
    <PageContainer isLoading={loadingNewsletter}>

        <BreadCrumb
          to="/dashboard/orders/newsletters"
          title={t("update")}
          pageTitle="Newsletters"
        />

        {newsletter && (
          <>

            <Row className="justify-content-end">

              <Col md={12}>
                
                <NewsletterDetails
                  refetchNewsletter={refetchNewsletter}
                  newsletter={newsletter}
                  calculatePrices={calculatePrices}
                  calculatePercentage={calculatePercentage}
                />

                <NewsletterProducts
                  newsletter={newsletter}
                  refetchNewsletter={refetchNewsletter}
                  items={newsletter?.items || []}
                  visibleColumns={visibleColumns}
                  toggleVisibleColumns={toggleVisibleColumns}
                  refetchEmailHistory={refetchEmailHistory}
                />

              </Col>

            </Row>

            <Row className="mb-4 mt-4 justify-content-end">
              <Col lg={12}>
                
                <CustomLabel className="form-label">
                  {t("Sends at")}
                </CustomLabel>

                <div className="d-flex justify-content-between mb-3">
                  <Col md="4">
                    <Flatpickr
                      className="form-control"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      value={sendedAt && moment(sendedAt).format("D MMM, YYYY")}
                      onChange={(date) =>
                        setSendedAt(moment(date[0]).format("YYYY-MM-DD"))
                      }
                    />
                  </Col>

                  <CustomButton
                    onClick={updateNl}
                    type="button"
                    className={`btn btn-sm btn-secondary`}
                    isLoading={loading}
                  >
                    <i className="ri-save-2-line cursor-pointer fs-16"></i>
                  </CustomButton>
                </div>

                <Col>
                  <CustomLabel className="form-label">{t("Note")}</CustomLabel>
                  <textarea
                    className="form-control"
                    rows={5}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </Col>

              </Col>

              <Col md={4} className="mt-4">
                <Card>
                  <CardBody className="d-flex flex-column gap-3">
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("NET")}</span>
                      <p className="fs-20 font-weight-bold">
                        {calculatePrices({ order: newsletter }).netPrice}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("Discount")}</span>
                      <p className="fs-20 font-weight-bold">
                        {calculatePrices({ order: newsletter }).discountPrice}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                      <span>{t("Total")}</span>
                      <p className="fs-3 font-weight-bold">
                        {calculatePrices({ order: newsletter }).sellingPrice}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </>
        )}

        <Row>
          <Col md={12}>
              <EmailHistory 
                data={emailHistory}
                loading={loadingEmailHistory || refetchingEmailHistory}
              />
          </Col>
        </Row>

    </PageContainer>
  );
};

export default UpdateNewsletter;
