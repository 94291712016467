import React from "react";
import FileManagerWrapper from "../../../Components/Settings/FileManager/FileManagerWrapper";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";

const FileManager = () => {

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Settings" title="File Manager" />
      <FileManagerWrapper />
    </PageContainer>
  );
};

export default FileManager;
