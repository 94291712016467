import axiosInstance from "../axios";

export const statisticsApi = {

    getAll: () => {
        return axiosInstance.get("/stats");
    },

    revenue: () => {
        return axiosInstance.get("/diagram");
    },
    customers: () => {
        return axiosInstance.get("/customerStats");
    }

}