import moment from "moment";
import { extractQuantityBasedonUnit } from "../calculateOrderPrices";
import { sortByStockZone } from "../sortByStockZone";

export const getOrdersCSVData = ({order, vats, locations}) => {
  
    if (!order) return []

    let allProducts = [];

    if (order?.merged === 1 || order?.merged === true) {

      allProducts = Object.values(order.items).reduce((accm, items)  => {
        return [
          ...accm, 
          ...sortByStockZone(items, locations),
        ...(order.description && order.delivery_qty && order.delivery_price ? [{
          type: 'delivery',
          price: order.delivery_price,
          qty: order.delivery_qty,
          description: order.description,
          matricule: order.matricule,
          customer_no: order.company?.profile?.matricule,
          created_at: order.created_at,
          delivered_at: order.delivered_at,
        }] : [])
      ]
    }, [])

    } else {

      allProducts = [
        ...sortByStockZone(order.items, locations),
        ...(order.description && order.delivery_qty && order.delivery_price ? [{
          type: 'delivery',
          price: order.delivery_price,
          qty: order.delivery_qty,
          description: order.description,
          matricule: order.matricule,
          customer_no: order.company?.profile?.matricule,
          created_at: order.created_at,
          delivered_at: order.delivered_at,
        }] : [])
      ]
    }

    return allProducts?.map((product) => {
        if (product.type === 'delivery') {
          return {
            'ORDER NO': order.matricule.slice(1) || '-',
            'ORDER DATE': moment(product.created_at).format('YYYY/MM/DD') || '-',
            'CUSTOMER NO': product.customer_no || '-',
            'DELIVERY DATE': moment(product.delivered_at).format('YYYY/MM/DD') || '-',
            'ORDER LINE - PROD NO': 10030,
            'ORDER LINE - UNIT PRICE': product.price || '-',
            'ORDER LINE - COUNT': product.qty || '-',
            'ORDER LINE - DISCOUNT': `0%`,
            'ORDER LINE - VAT CODE': 3,
          }
        } else {
          const tax = vats.find(vat => vat.id === product.product.tax);
          const qty = extractQuantityBasedonUnit(product);

          return {
            'ORDER NO': order?.matricule.slice(1) || '-',
            'ORDER DATE': moment(order.created_at).format('YYYY/MM/DD'),
            'CUSTOMER NO': order?.company?.profile?.matricule || '-',
            'DELIVERY DATE': moment(order.delivered_at).format('YYYY/MM/DD'),
            'ORDER LINE - PROD NO': product.product.matricule || '-',
            'ORDER LINE - UNIT PRICE': product.price || '-',
            'ORDER LINE - COUNT': qty || '-',
            'ORDER LINE - DISCOUNT': `${product.promo || 0}%`,
            'ORDER LINE - VAT CODE': tax?.percentage === 15 ? 31 : tax?.percentage === 25 ? 3 : 5,
          }
        }
      }) || []

}