import React, { useState } from "react";
import DataTable from "../../Common/DataTable";
import { useSelector } from "react-redux";
import ProblemModal from "./Modal";
import { useTranslation } from "react-i18next";

const ProblemsTable = () => {

  const { t } = useTranslation();

  const [modal, setModal] = useState(false);

  const [details, setDetails] = useState(null);

  const toggleModal = () => {
    setModal((current) => !current);
  };

  const handleModal = (row) => {
    setModal(true);
    setDetails(row);
  };

  const handleClick = (row) => handleModal(row);

  const { problems: { list: problems } } = useSelector((state) => state.storage);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("User")}</span>,
      selector: (row) => row.user,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Description")}</span>,
      selector: (row) => row.description,
      grow: 5,
    },
  ];

  return (
    <React.Fragment>
      {details && (
        <ProblemModal
          open={modal}
          toggleModal={toggleModal}
          details={details}
        />
      )}
      <DataTable data={problems} columns={columns} handleClick={handleClick} />
    </React.Fragment>
  );
};

export default ProblemsTable;
