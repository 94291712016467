import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DataTable from "../../Common/DataTable";
import CustomInput from "../../Custom/CustomInput";
import Flatpickr from "react-flatpickr";

const NewStockTable = ({ filters, handleFilterChange, handleMultipleFilterChanges, ...props }) => {

  const history = useHistory();

  const { t } = useTranslation();

  const getUnitQuantites = (data) => {

    if (data.product_unit_prices?.[0]?.unit_name === 'Kg') {
      return `${data.qty_delivered} Kg`
    }

    const unit_prices = [...(data.product_unit_prices || [])].sort((a, b) => b.pieces - a.pieces).map(unit => ({
      pieces: unit.pieces,
      name: unit.unit_name,
    }));

    const quantity = data.qty_delivered;

    let unitQuantites = [];

    unit_prices?.reduce((accum, unit) => {
        const remainder = +accum % +unit.pieces;
        const quantity = Math.floor((+accum - +remainder) / +unit.pieces);
        if (quantity) unitQuantites.push(`${quantity} ${unit.name}`)
        return remainder
    }, quantity)

    if (unitQuantites.length) {
      return (
        <div className="py-1">
          {unitQuantites.map((item, index) => (
            <div className="my-1" key={index}>{item}</div>
          ))}
        </div>
      )
    } else return '-'

  }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: (row) => row.id,
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("User")}</span>,
      selector: (row) => row.user || "-",
    },

    {
      name:  
      <CustomInput
        type="text"
        className="form-control"
        id="iconInput"
        placeholder="Product"
        value={filters.product_name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'product_name',
          value: e.target.value
        })}
        data-filtered={!!filters.product_name}
    />,
      selector: (row) =>
        row.product ? (
          <span
            className=" cursor-pointer"
            onClick={() =>
              history.push(`/dashboard/products/update/${row.product_id}`)
            }
            title={row.product}
          >
            <b>{row.product}</b>
          </span>
        ) : (
          "-"
        ),
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Quantity")}</span>,
      selector: (row) => getUnitQuantites(row),
      grow: 2,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: (row) => row.status || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Weight")}</span>,
      selector: (row) => row.weight || "-",
    },

    {
      name:         
      <CustomInput
        type="text"
        className="form-control"
        id="iconInput"
        placeholder="Batch"
        value={filters.batch}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'batch',
          value: e.target.value
        })}
        data-filtered={!!filters.batch}
      />,
      selector: (row) => row.batch || "-",
      grow: 2,
    },

    {
      name:         
      <CustomInput
        className="form-control"
        placeholder="Lot"
        value={filters.lot}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'lot',
          value: e.target.value
        })}
        data-filtered={!!filters.lot}
      />,
      selector: (row) => <span title={row.lot || "-"}>{row.lot || "-"}</span>,
      grow: 2,
    },

    {
      name:      
      <Flatpickr
        className="form-control"
        placeholder="Created"
        options={{
          mode: "range",
        }}
        value={[filters.date_from, filters.date_to]}
        onChange={(date) => handleMultipleFilterChanges({
          type: 'cached',
          data: {
            date_from: moment(date[0]).format("YYYY-MM-DD"),
            date_to: date[1] ? moment(date[1]).format("YYYY-MM-DD") : null
          }
        })}
        data-filtered={!!filters.date_from && !!filters.date_to}
      />,
      selector: (row) =>
        row.created_at ? moment(row.created_at).format("D MMM, YYYY") : "-",
        grow: 2,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Date exp")}</span>,
      selector: (row) =>
        row.date_exp ? moment(row.date_exp).format("D MMM, YYYY") : "-",
      grow: 2,
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default NewStockTable;
