import { objectFilter } from "../../objectFilter";

export const createUserHistoryBody = (data) => {
  return objectFilter({
    user_id: parseInt(data.user_id),
    status_id: parseInt(data.status),
    description: data.description,
  });
};

export const updateUserHistoryBody = (data) => {
  return objectFilter({
    user_id: parseInt(data.user_id),
    status_id: parseInt(data.status),
    description: data.description,
  });
};
