import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DataTable from "../../Common/DataTable";

const PriceOffersTable = ({ openUpdateModal ,...props }) => {

  const { t } = useTranslation();
  
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: (row) =>
        row.user?.profile?.company ||
        `${row.user.first_name} ${row.user.last_name}`,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Note")}</span>,
      selector: (row) => <span className="ellipsis-container">
        {row.note || "-"}
      </span>,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Created At")}</span>,
      selector: (row) => moment(row.created_at).format("D MMM, YYYY") || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Actions")}</span>,
      selector: (row) => {
        return (
          <div className="d-flex gap-2">
            <Link to={`/dashboard/orders/price-offers/update/${row.id}`}>
              <i className="ri-eye-line fs-16"></i>
            </Link>
            <i
              onClick={() => openUpdateModal(row)}
              className="ri-pencil-line fs-16 cursor-pointer"
            ></i>
          </div>
        );
      },
    },
  ];

  return (
      <DataTable
        columns={columns}
        {...props}
      />
  );
};

export default PriceOffersTable;
