import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productsApi from "../../../apis/products/api";
import ProductForm from "../../../Components/Products/Products/Form";
import Tracking from "../../../Components/Tracking";
import useTracking from "../../../hooks/useTracking";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateProduct = () => {

  const [initialValues, setInitialValues] = useState(null);

  const { id } = useParams();

  const { trackings, refetch: refetchTracking } = useTracking("product", id);

  const { isLoading, isRefetching } = useQuery(["product"], () => productsApi.products.getOne(id), {
    onSuccess({data: {data}}) {
      setInitialValues({
        ...data,
        taxes: data.taxes[0]?.id,
        supplyer_id: data.supplyer,
        weight: data.product_vars?.[0]?.weight,
        groups: data.user_groups,
      });
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb title="Update" pageTitle="Products"  to="/dashboard/products" />
      {initialValues && (
        <ProductForm
          type="update"
          initialValues={initialValues}
          refetchTracking={refetchTracking}
        />
      )}
      <Tracking data={trackings || []} />
    </PageContainer>
  );
};

export default UpdateProduct;
