import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotificationOrder = ({ notification }) => {
  
  const { t } = useTranslation();

  return (
    <div className="d-flex">
      <div className="avatar-xs me-3">
        <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
          <i className="bx bx-badge-check"></i>
        </span>
      </div>
      <div className="flex-1">
        <Link
          to={`/dashboard/orders/change-order/${notification.record_id}/order`}
          className="stretched-link"
        >
          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
            {notification.user.company}
          </h6>
        </Link>
        <div className="fs-13 text-muted">
          <p className="mb-1">{t("New Order")}</p>
        </div>
        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
          <span>
            <i className="mdi mdi-clock-outline"></i>{" "}
            {notification.created_at?.slice(0, 19)?.replace("T", " ")}
          </span>
        </p>
      </div>
      <div className="px-2 fs-15">
        {/* <div className="form-check notification-check">
          <input className="form-check-input" type="checkbox" value="" id="all-notification-check02" />
          <label className="form-check-label" htmlFor="all-notification-check02"></label>
      </div> */}
      </div>
    </div>
  );
};

export default NotificationOrder;
