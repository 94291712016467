import * as yup from "yup";

const ConsultSchema = yup.object().shape({
  address: yup.string().required("Please specify an address"),
  // address_type: yup.string().required("Please specify the address type"),
  latitude: yup
    .number()
    .typeError("Latitude is missing")
    .required("Please specify the latitude"),
  longitude: yup
    .number()
    .typeError("Longitude is missing")
    .required("Please specify the latitude"),
  phone: yup
    .number()
    .typeError("Number is missing")
    .required("Please specify the phone number"),
  email: yup.string().required("Please specify the email"),
  consult: yup.mixed().notRequired(),
});

export default ConsultSchema;
