import * as yup from "yup";

const socialMediaSchema = yup.object().shape({
  facebook: yup.string().notRequired(),
  twitter: yup.string().notRequired(),
  instagram: yup.string().notRequired(),
  youtube: yup.string().notRequired(),
  whatsapp: yup
    .string()
    .notRequired(),
});

export default socialMediaSchema;
