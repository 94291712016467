export const extractProductPrices = ({ product, userInfo, userDiscounts }) => {
    
    // Get all the units
  const unit_prices = product.unit_prices.map(unit_price => {
      // Destructure Necessary data from unit
      const {unit_name: name, unit_id: id, pieces: quantity, price_purchase: purchase_price} = unit_price;
      // Get the base price depending on the user's group 
      const price = unit_price.groups_prices.find(group => +group.user_group_id === userInfo.profile?.user_group?.id)?.price || unit_price.groups_prices.find(group => +group.user_group_id === 1)?.price;
      // Check if the user has a discount on this product
      const discount = (() => {
          if (userDiscounts?.length > 0) {
            const discountObject = userDiscounts.find((discount) => discount.product_id === product.id);
            if (discountObject) {
              const price = discountObject.units.find(ut => ut.unit_id === unit_price.unit_id)?.price;
              return price
            } else return null
          } else return null
      })();
      // Return final object for each unit
      return {
        id,
        name,
        quantity,
        purchase_price,
        price: {
          base: price,
          discount
        }
      }
    })
    // Auto select the Eske unit if it exists
    const product_unit = unit_prices.find((unit) => unit.name.toLowerCase() === "eske") || unit_prices[0];
    // Set the state to the final object
    return {
      product_id: product.id,
      product_number: product.matricule,
      product_name: product.name,
      // If the product's unit is kg, show the product's total weight, else calculate the quantity 
      total_weight: product.total_weight,
      total_qty: product_unit.id === 1 ? product.total_weight : Math.floor(product.total_qty / (product_unit.pieces || 1)) || "0",
      unit_prices,
      price_purchase: product_unit.purchase_price,
      qty: 1,
      promo: "",
      product_unit: product_unit.id,
      // Prioratize discount over base price
      price: product_unit.price.discount ?? product_unit.price.base,
      // Display the base price if a discount exists
      ...(product_unit.price.discount !== null ? {base_price: product_unit.price.base} : {})
    }
}

export const extractProductGroupPrices = ({ product, group_id }) => {

  const unit_prices = product.unit_prices.map(unit_price => {
    // Destructure Necessary data from unit
    const {unit_name: name, unit_id: id, pieces: quantity, price_purchase: purchase_price} = unit_price;
    // Get the base price depending on the user's group 
    const price = unit_price.groups_prices.find(group => +group.user_group_id === group_id)?.price || unit_price.groups_prices.find(group => +group.user_group_id === 1)?.price;
    // Check if the user has a discount on this product
    // Return final object for each unit
    return {
      id,
      name,
      quantity,
      purchase_price,
      price: {
        base: price,
        discount: null
      }
    }
  })
  // Auto select the Eske unit if it exists
  const product_unit = unit_prices.find((unit) => unit.name.toLowerCase() === "eske") || unit_prices[0];
  // Set the state to the final object
  return {
    product_id: product.id,
    product_number: product.matricule,
    product_name: product.name,
    // If the product's unit is kg, show the product's total weight, else calculate the quantity 
    total_weight: product.total_weight,
    total_qty: product_unit.id === 1 ? product.total_weight : Math.floor(product.total_qty / (product_unit.pieces || 1)) || "0",
    unit_prices,
    price_purchase: product_unit.purchase_price,
    qty: 1,
    promo: "",
    product_unit: product_unit.id,
    // Prioratize discount over base price
    price: product_unit.price.discount ?? product_unit.price.base,
    // Display the base price if a discount exists
    ...(product_unit.price.discount !== null ? {base_price: product_unit.price.base} : {})
  }
}

export const getColumnName = (units, selectedUnit) => {
    const unit = units.find((ut) => ut.id === selectedUnit);
    return unit?.name?.toLowerCase() === "kg" ? "Total Weight" : "Total in stock";
}