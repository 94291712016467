import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from 'reactstrap';

export const widgetList = [
    {
        icon: "ri-shopping-cart-2-fill",
        label: "Total Orders",
        selector: "total_orders",
        separator: ","
    },
    {
        icon: "ri-cake-2-fill",
        label: "Total Products",
        selector: "total_food",
        separator: ","
    },
    {
        icon: "ri-user-3-fill",
        label: "Total Customers",
        selector: "total_customer",
        separator: ","
    },
];

const Widgets = ({data}) => {

    const { t } = useTranslation();

    return (
        <Row>
            {widgetList.map((item, key) => (
                <Col lg={4} md={6} key={key}>
                    <Card>
                        <CardBody>
                            <div className="d-flex align-items-center gap-3">

                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                        <i className={"align-middle " + item.icon}></i>
                                    </span>
                                </div>

                                <div className="flex-grow-1">
                                    <p className="text-uppercase fw-bold fs-13 text-muted mb-1">{t(item.label)}</p>
                                        <h4 className=" mb-0"> <CountUp start={0} end={data[item.selector]} separator={item.separator} duration={3} />
                                    </h4>
                                </div>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}

export default Widgets