import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../Common/DataTable";

const AdminsTable = ({ filters, handleFilterChange, ...props }) => {
  
  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: (row) => row.id,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: (row) => row.name,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Email")}</span>,
      selector: (row) => row.email,
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default AdminsTable;
