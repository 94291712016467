import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import productsApi from '../../../apis/products/api';
import VendorForm from '../../../Components/Products/Vendors/Form'
import PageContainer from '../../../Components/Layout/PageContainer';
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const UpdateVendor = () => {

  const [initialValues, setInitialValues] = useState(null);

  const { id } = useParams();

  const { isLoading, isRefetching } = useQuery(["vendor"], () => productsApi.vendors.getOne(id), {
    onSuccess(data) {
      const { data: vendorData } = data.data;
      setInitialValues({
        ...vendorData,
        number: vendorData.matricule,
      });
  }
  });

  return (
      <PageContainer isLoading={isLoading || isRefetching}> 
          <BreadCrumb title="Update" pageTitle="Products"  to="/dashboard/products" />
          {initialValues && 
            <VendorForm 
                type='update'
                initialValues={initialValues}
            />
          }
      </PageContainer>
  )
}

export default UpdateVendor