import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import UsersTable from "../../../Components/Users/Users/Table";
import { useDispatch, useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import usersApi from "../../../apis/users/api";
import { USER_PERMISSIONS } from "./../../../permissions/users";
import Restricted from "./../../../Components/Guard/Restricted";
import { CSVLink } from "react-csv";
import { getUsersCSVData } from "../../../helpers/CSV/usersCSV";
import { useTranslation } from "react-i18next";
import { deleteUsersDataAction, getAllUsersDataAction } from "../../../redux/actions/users";
import { usersActions } from "../../../redux/slices/users";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const Users = () => {

  const { load, callback, itemsToDelete, deleteModal, isDeleting, setItemsToDelete, setDeleteModal } = useDeleteMultiple();

  const [loading, setLoading] = useState(true);

  const searchFunc = async (values) => {
    setLoading(true);
    const { data } = await usersApi.users.getAll(values);
    dispatch(usersActions.setData({
      field: 'users',
      data: data.data, 
      total: data.meta.total 
    }));
    setLoading(false);
  }

  const { searchValues, handleChange, setPageSize, navigateToPage, onSort, clearFilter, search } = useSearch({
    name: 'users',
    cachedValues: {
      zone: '',
      group: '',
      active: '',
      order_by: 'name',
      order_value: 'desc',
    },
    debouncedValues: {
      name: '',
      company: '',
      email: '',
      phone: ''
    },
    searchFunc
  });

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { users: { list: users, total } } = useSelector((state) => state.users);

  const handleDelete = () => {
    load();
    dispatch(
      deleteUsersDataAction({
        field: 'users',
        data: { ids: itemsToDelete.selectedRows?.map((row) => row.id) },
        onSuccess: () => {
          search();
          callback();
        }
      })
    );
  };

  useEffect(() => {
    dispatch(getAllUsersDataAction({
      field: 'groups',
      query: {
        page_size: 'all'
      }
    }));
    dispatch(getAllUsersDataAction({
      field: 'zones',
      query: {
        page_size: 'all'
      }
    }));
  }, [dispatch])

  return (
      <PageContainer>

        <BreadCrumb pageTitle="Users" title="View" to="/dashboard/users" />

        <div className="hstack gap-2">

          <Restricted permissions={USER_PERMISSIONS.create}>
            <Link to="/dashboard/users/create">
              <CustomButton
                className="btn-success"
              >
                <i className="ri-add-line align-bottom me-1"></i> 
                  Create
              </CustomButton>
            </Link>
          </Restricted>

          <Restricted permissions={USER_PERMISSIONS.delete}>
            <CustomButton
              className="btn-danger"
              disabled={!itemsToDelete.selectedRows?.length > 0}
              onClick={() => setDeleteModal(true)}
            >
              <i className="ri-delete-bin-line me-1 align-bottom"></i>
              Delete
            </CustomButton>
          </Restricted>

          <Restricted permissions={USER_PERMISSIONS.read}>
            <CSVLink
              data={getUsersCSVData({ users })}
              type="button"
              className={`btn btn-primary ${!itemsToDelete?.selectedRows?.length && "disabled"}`}
              id="delete-record"
              filename="Users"
            >
              {t("Export CSV")}
            </CSVLink>
          </Restricted>

          <CustomButton
            className="btn-secondary"
            onClick={() => clearFilter()}
          >
            Clear Filters
          </CustomButton>

        </div>
      
        <Card className="mt-3">
          <UsersTable
            data={users}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            selectableRows
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDelete={handleDelete}
            filters={searchValues}
            handleFilterChange={handleChange}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            onSort={onSort}
            sortDirection={searchValues.order_value}
            fetching={loading}
            isDeleting={isDeleting}
            path="/dashboard/users"
          />
        </Card>
      </PageContainer>
  );
};

export default Users;
