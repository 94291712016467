import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productsApi from "../../../apis/products/api";
import VatForm from "../../../Components/Products/VAT/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateVAT = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["taxes", id], () => productsApi.vats.getOne(id), {
    cacheTime: 0,
    onSuccess({data: {data}}) {
      const { products, ...rest } = data;
      setInitialValues(rest);
    }
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb title="Update" pageTitle="VAT" to="/dashboard/products/vat" />
      {initialValues && 
        <VatForm 
          type="update" 
          initialValues={initialValues} 
        />
      }
    </PageContainer>
  );
};

export default UpdateVAT;
