import React from "react";
import DataTable from "../../Common/DataTable";
import { useTranslation } from "react-i18next";

const StockStaffTable = ({ ...props }) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: (row) => row.name,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Username")}</span>,
      selector: (row) => row.user_name || "None",
    },
  ];

  return (
    <React.Fragment>
        <DataTable
          columns={columns}
          {...props}
        />
    </React.Fragment>
  );
};

export default StockStaffTable;
