import React, { useContext, useState } from "react";
import { createContext } from "react";
import Pusher from "pusher-js";
import { useRef } from "react";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getNotificationsApi,
  seenAllNotificationsApi,
} from "../apis/notificationsApi";
import toastAlert from "../helpers/toastAlert";
import { useHistory } from "react-router-dom";
import { useAuthState } from "./authContext";

const NotificationContext = createContext({});

const CONFIG = {
  APP_KEY: "0623aeed348dde198feb", // process.env.PUCHER_APP_KEY,
  CLUSTER: "eu", // process.env.PUCHER_CLUSTER,
};

const NotificationsProvider = ({ children }) => {
  
  const [notifications, setNotifications] = useState([]);

  const [meta, setMeta] = useState({
    total: 0,
    unseen: 0,
  });

  const { accessToken } = useAuthState();

  const mountRef = useRef(false);

  const [pageSize, setPageSize] = useState(10);

  const history = useHistory();

  const { refetch, isRefetching } = useQuery(
    ["notifications"],
    () => getNotificationsApi(pageSize),
    {
      onSuccess(data) {
        setNotifications(data.data.data.notifications);
        setMeta({
          total: data.data.data["total-notifications"],
          unseen: data.data.data["not-seen-notifications"],
        });
      },
      enabled: !!accessToken,
    }
  );

  const changePageSize = (pageSize) => {
    setPageSize((prev) => prev + pageSize);
  };

  const handleSeenNotifications = async () => {
    setMeta((prev) => {
      return {
        ...prev,
        unseen: 0,
      };
    });
    await seenAllNotificationsApi();
  };

  useEffect(() => {
    if (!accessToken) return;

    const pusher = new Pusher(CONFIG.APP_KEY, {
      cluster: CONFIG.CLUSTER,
      forceTLS: true,
      encrypted: true,

    });

    const channel = pusher.subscribe("dinengros-channel");

    channel.bind("user-order-created", (data) => {
      const orderID = data?.data?.order_id;
      const notification = {
        id: Math.random(),
        type: "new_order",
        user: {
          company: data?.data?.user || "Unknown",
        },
        record_id: orderID,
        created_at: data?.data?.created_at,
      };
      setNotifications((prev) => {
        return [notification, ...prev];
      });
      setMeta((prev) => {
        return {
          total: prev.total + 1,
          unseen: prev.unseen + 1,
        };
      });

      toastAlert({
        type: "success",
        message: "New Order Created",
        autoClose: 6000,
        onClick: () => {
          history.push(`/dashboard/orders/change-order/${orderID}/order`);
        },
      });
    });

    return () => {
      pusher.unsubscribe("user-order-created");
    };

  }, [accessToken]);

  useEffect(() => {
    if (!accessToken) return;
    if (!mountRef.current) {
      mountRef.current = true;
    } else {
      refetch();
    }
  }, [accessToken, pageSize]);

  return (
    <NotificationContext.Provider
      value={{
        meta,
        notifications,
        isRefetching,
        handleSeenNotifications,
        changePageSize,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationsProvider;

export const useNotificationsValue = () => {
  return useContext(NotificationContext);
};
