import jwtDecode from 'jwt-decode';
import React from 'react'
import { Redirect } from 'react-router-dom';
import { useAuthState } from '../context/authContext';
  
const PublicRoute = (props) => {
    const { accessToken } = useAuthState();
  
    if (accessToken) {
      return (
          <Redirect to={{ pathname: "/",  }} />
      );
    }
  
    
    return props.children
    
    
};


export default PublicRoute