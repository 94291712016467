import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DataTable from "../../../Common/DataTable";

const ProductLotMovement = ({ initialValues }) => {

  const { t } = useTranslation();

  const lotMovements = initialValues?.product_vars?.reduce((accum, val) => {
    return [...accum, ...val.lot_movements];
  }, []);

  const history = useHistory();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Order code")}</span>,
      selector: (row) => row.order_code || "-",
      grow: -1,
    },


    {
      name: <span className="font-weight-bold fs-13">{t("Admin")}</span>,
      selector: (row) => row.user || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Customer")}</span>,
      selector: (row) => (
        <span 
          className="cursor-pointer" 
          onClick={() => history.push(`/dashboard/users/update/${row.customer_id}`)} 
          title={row.customer}>{row.customer || "-"}
        </span>
      ),
      grow: 3,
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Quantity Ordered")}</span>
      ),
      selector: (row) => row.qty_ordered || "-",
      grow: -1,
    },

    {
      name: (
        <span className="font-weight-bold fs-13">
          {t("Quantity Delivered")}
        </span>
      ),
      selector: (row) => row.qty_delivered || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Unit")}</span>,
      selector: (row) => row.unit || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: (row) => row.status || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Weight")}</span>,
      selector: (row) => row.weight || "-",
    },

    
    {
      name: <span className="font-weight-bold fs-13">{t("Batch")}</span>,
      selector: (row) => row.batch || "-",
      grow: 2,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Lot")}</span>,
      selector: (row) => row.lot || "-",
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Date exp")}</span>,
      selector: (row) => row.date_exp ? <span title={moment(row.date_exp).format("D MMM, YYYY")}>{moment(row.date_exp).format("D MMM, YYYY")}</span> : "-",
      grow: 2,
    },
  ];


  return (
    <DataTable 
      data={lotMovements || []} 
      columns={columns} 
      pageSize={200} 
    />
  );
};

export default ProductLotMovement;
