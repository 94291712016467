import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'

const ImageModal = ({open, toggleModal, image}) => {

  return (
    <Modal modalClassName="zoomIn" size="xl" isOpen={open} toggle={toggleModal} centered>
        <i 
          className="ri-close-line cursor-pointer" style={{position: 'absolute', right: '10px', fontSize: '1.7rem'}}
          onClick={toggleModal}
        >
        </i>
        <div className='pb-3'></div>
        <img className='img-thumbnail mt-4' src={image} style={{maxHeight: '600px', objectFit: "cover"}}/>
    </Modal>
  )
}

export default ImageModal