import * as yup from "yup";
import { phoneRegExp } from "../regex";

// Regex pattern for phone number

export const createUserSchema = yup.object().shape({
  first_name: yup
    .string("")
    .required("Please provide a valid name"),
  last_name: yup
    .string()
    .required("Please provide a valid name"),
  status: yup
    .string()
    .required("Please select user's type"),
  active: yup
    .string()
    .required("Please select user's status"),
  // promo: yup.string().max(100, 'Please enter a valid number').required("Please enter a valid number"),

  email: yup
    .string()
    .email()
    .required("Please enter a valid email"),
  send_invitation: yup.boolean(),
  // age: yup.number().positive().integer().required(),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(15, "Your Password is more than 15 characters")
    .required("Please enter a valid and strong password"),
  password_confirmation: yup
    .string()
    .typeError("")
    .oneOf([yup.ref("password"), null], "Passwords must match"),


  // createUserPostalCode: yup.number().positive().integer(),
});

export const updateUserSchema = createUserSchema.shape({
  password: yup
  .string(),
  // .min(4, "Password must be at least 4 characters")
  // .max(15, "Your Password is more than 15 characters")
  // .required("Please enter a valid and strong password"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
})


export const userProfileSchema = yup.object().shape({
  region_id: yup.string().notRequired().nullable(),
  group_id: yup.string().notRequired().nullable(),
  phone: yup.string().notRequired().nullable(),
  // .matches(phoneRegExp, 'Please provide a valid phone number'),
  company: yup.string().notRequired().nullable(),
  company_number: yup.string().notRequired().nullable(),
  matricule: yup.string().notRequired().nullable(),
})

export const userAddressSchema = yup.object().shape({
    street_name: yup.string().notRequired(),
    street_zip_code: yup.string().notRequired(),
    post_name: yup.string().notRequired(),
    post_zip_code: yup.string().notRequired(),
})
