import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import SelectFileButton from "../../../Components/Common/SelectFileButton";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import { useFileManager } from "../../../context/FileManagerContext";
import NewsletterImage from "../../../Components/Products/Catalogue/CatalogueImage";
import useSetFile from "../../../hooks/useSetFile";
import CustomNavLink from "../../../Components/Custom/CustomNavLink";
import classnames from "classnames";
import useDebounceValue from "../../../hooks/useDebounceValue";
import ProductsTable from "../../../Components/Products/Catalogue/ProductsTable";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomButton from "../../../Components/Common/CustomButton";
import useLoading from "../../../hooks/useLoading";
import toastAlert from "../../../helpers/toastAlert";
import productsApi from "../../../apis/products/api";
import { useQuery } from "@tanstack/react-query";
import PageContainer from "../../../Components/Layout/PageContainer";

const tabs = [
  {
    id: 1,
    name: "All Products",
  },
  {
    id: 2,
    name: "Selected Products",
  },
];

const handleResponse = (data) => {
  const { firstImage, lastImage, products } = data;
  return {
    products,
    first_image: firstImage
      ? { ...firstImage, path: `${firstImage.uri}/${firstImage.name}` }
      : null,
    last_image: lastImage
      ? { ...lastImage, path: `${lastImage.uri}/${lastImage.name}` }
      : null,
  };
};

const ProductsCatalogue = () => {

  const { openFileManagerModal } = useFileManager();

  const { isLoading, onLoading, onStopLoading } = useLoading();

  const [activeTab, setActiveTab] = useState(1);

  const [productName, setProductName] = useState("");

  const name = useDebounceValue({ value: productName });

  const [data, setData] = useState({
    first_image: null,
    last_image: null,
    products: [],
  });

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  const onFileChange = (fileProp, file) => {
    setData((current) => {
      return {
        ...current,
        [fileProp]: file,
      };
    });
  };

  const onFileRemove = (fileProp) => {
    setData((current) => {
      return {
        ...current,
        [fileProp]: null,
      };
    });
  };

  const onSaveImagesClick = async () => {
    onLoading();
    try {
      const { data: response } = await productsApi.catalogue.updateFiles({
        first_file_id: data.first_image?.id || null,
        last_file_id: data.last_image?.id || null,
      });
      toastAlert({ type: 'success'})
      setData(handleResponse(response.data));
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      onStopLoading();
    }
  };

  useQuery(["catalog"], productsApi.catalogue.get, {
    onSuccess(data) {
      const response = data.data.data;
      setData(handleResponse(response));
    },
  });

  useSetFile({
    whatFor: "newsletter-first-image",
    cb(file) {
      onFileChange("first_image", file);
    },
  });

  useSetFile({
    whatFor: "newsletter-last-image",
    cb(file) {
      onFileChange("last_image", file);
    },
  });

  return (
    <PageContainer>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <CustomLabel>First Image</CustomLabel>
              <SelectFileButton
                onClick={() => openFileManagerModal("newsletter-first-image")}
              />
              <NewsletterImage
                src={data.first_image?.path}
                onRemove={() => onFileRemove("first_image")}
              />
            </Col>
            <Col md={6}>
              <CustomLabel>Last Image</CustomLabel>
              <SelectFileButton
                onClick={() => openFileManagerModal("newsletter-last-image")}
              />
              <NewsletterImage
                src={data.last_image?.path}
                onRemove={() => onFileRemove("last_image")}
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <CustomButton
            isLoading={isLoading}
            onClick={onSaveImagesClick}
            className="btn btn-success"
          >
            Save
          </CustomButton>
        </CardFooter>
      </Card>
      <div className="d-flex justify-content-end mb-2">
        <div>
          <CustomInput
            className="form-control"
            placeholder="Name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </div>
      </div>
      <Card>
        <CardHeader>
          {/* <CardTitle>Products</CardTitle> */}
          <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
            {tabs.map((tab) => {
              return (
                <NavItem key={tab.id}>
                  <CustomNavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === tab.id,
                    })}
                    onClick={() => {
                      toggle(tab.id);
                    }}
                  >
                    {tab.name}
                  </CustomNavLink>
                </NavItem>
              );
            })}
          </Nav>
        </CardHeader>
        <ProductsTable
          selectedProducts={data.products}
          setData={setData}
          activeTab={activeTab}
          name={name}
        />
      </Card>
    </PageContainer>
  );
};

export default ProductsCatalogue;
