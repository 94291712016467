import React from "react";
import DataTable from "../../Common/DataTable";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomOption from "../../Custom/CustomOption";
import { useSelector } from "react-redux";
import CustomInput from "../../Custom/CustomInput";
import StatusToggle from "../../Common/StatusToggle";

const UsersTable = ({ filters, handleFilterChange, ...props }) => {

  const {  zones: { list: zones },  groups: { list: groups } } = useSelector(state => state.users);

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Number")}</span>,
      selector: (row) => row.profile?.matricule || "-",
      sortable: true,
      sortField: 'matricule',
      grow: -1,
    },
    {
      name:       
      <CustomInput
        className="form-control"
        placeholder="Company"
        value={filters.company}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'company',
          value: e.target.value
        })}
        data-filtered={!!filters.company}
    />,
      selector: (row) => row.profile?.company || "-",
      sortable: true,
      sortField: 'company',
    },

    {
      name:       
      <CustomInput
        className="form-control"
        placeholder="Name"
        value={filters.name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        })}
        data-filtered={!!filters.name}
      />,
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
      sortField: 'first_name',
    },

    {
      name: 
      <CustomInput
        className="form-control"
        placeholder="Email"
        value={filters.email}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'email',
          value: e.target.value
        })}
        data-filtered={!!filters.email}
      />,
      selector: (row) => row.email || "-",
    },

    {
      name:   
      <CustomInput
        className="form-control"
        placeholder="Telephone"
        value={filters.phone}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'phone',
          value: e.target.value
        })}
        data-filtered={!!filters.phone}
      />,
      selector: (row) => row.phone || "-",
    },
  
    {
      name:         
      <select
        name="zone"
        className="form-select"
        value={filters?.zone}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'zone',
          value: e.target.value
        })}
        data-filtered={!!filters.zone}
      >
        <CustomOption value={""}>Zone</CustomOption>
        {zones.map((zone) => (
          <CustomOption key={zone.id} value={zone.id}>
            {zone.name}
          </CustomOption>
        ))}
      </select>,
      selector: (row) => row.profile?.region?.name || "-",
    },

    {
      name:  
      <select
        name="group"
        className="form-select"
        value={filters?.group}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'group',
          value: e.target.value
        })}
        data-filtered={!!filters.group}
      >
      <CustomOption value="">Group</CustomOption>
      {groups.map((Group) => (
        <CustomOption key={Group.id} value={Group.id}>
          {Group.name}
        </CustomOption>
      ))}
    </select>,
      selector: (row) => row.profile?.user_group?.name || "-",
    },

    {
      name:         
      <select
        name="active"
        className="form-select"
        value={filters?.active}
        onChange={(e) => handleFilterChange({
          type: 'cached',
          field: 'active',
          value: e.target.value
        })}
        data-filtered={!!filters.active}
      >
        <CustomOption value={""}>Active</CustomOption>
        <CustomOption value="1">Yes</CustomOption>
        <CustomOption value="0">No</CustomOption>
      </select>,
      selector: (row) => <StatusToggle type="users" id={row.id} field="active" status={row.active} />,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Created at")}</span>,
      selector: (row) => moment(row.created_at).format("D MMM, YYYY, HH:mm") || "-",
      sortable: true,
      sortField: 'created_at',
    },

  ];

  return (
      <DataTable
        columns={columns}
        {...props}
      />
  );
};

export default UsersTable;
