import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";
import DataTable from "../../../Components/Common/DataTable";

const EmailHistory = ({ data, loading }) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Admin")}</span>,
      selector: (row) => row.user || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: (row) => row.status || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Date")}</span>,
      selector: (row) => moment(row.date).format("D MMM, YYYY, HH:mm"),
    },
  ];

  return (
    <div
      className="tracking-wrapper mb-3"
    >
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">
              {t("Email History")}
            </h5>
          </div>
        </CardHeader>
        <CardBody className="pt-0">
          <div></div>
          <DataTable data={data || []} columns={columns} loading={loading} />
        </CardBody>
      </Card>
    </div>
    // </Container>
  );
};

export default EmailHistory;
