import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import usersApi from "../../../../apis/users/api";
import toastAlert from "../../../../helpers/toastAlert";
import useDeleteMultiple from "../../../../hooks/useDeleteMultiple";
import DataTable from "../../../Common/DataTable";
import ProductUnitRow from "./ProductUnitRow";

const DiscountedProductsTable = ({ discountedProducts, isLoading, refetch, refetchTracking }) => {

  const { t } = useTranslation();

  const unitsRef = useRef([]);

  const { load, callback, deleteModal, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Product Number")}</span>,
      selector: (row) => row.product.matricule,
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Product")}</span>,
      selector: (row) => (
        <div style={{ width: "400px" }}>
          <Select
            value={{
              label: row.product.name,
              value: row.product.id,
            }}
            isDisabled
          />
        </div>
      ),
    },
  ];

  const handleDelete = async () => {
    load();
    const ids = itemsToDelete.selectedRows.map((item) => item.id);
    await usersApi.users.discounts.delete({ ids });
    refetch();
    callback();
    toastAlert({ type: 'success'})
  };

  return (
    <div className="mt-5">

      <button
        className="btn btn-dark btn-sm"
        disabled={!itemsToDelete.selectedRows?.length > 0}
        onClick={() => setDeleteModal(true)}
      >
        <i className="ri-delete-bin-line align-bottom"></i>{" "}
        {t("Delete Selected")}
      </button>

      <DataTable
        itemsToDelete={itemsToDelete}
        setItemsToDelete={setItemsToDelete}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDelete={handleDelete}
        data={discountedProducts}
        fetching={isLoading}
        columns={columns}
        noPagination
        expandableRows
        expandableRowComponent={({ data }) => <ProductUnitRow data={data} refetch={refetch} refetchTracking={refetchTracking} unitsRef={unitsRef} />}
        selectableRows
      />
      
    </div>
  );
};

export default DiscountedProductsTable;
