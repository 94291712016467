import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ordersApi from "../../../apis/orders/api";
import TransportForm from "../../../Components/Orders/Transports/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateTransport = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["one-order-transport", id], () => ordersApi.transports.getOne(id),
    {
      onSuccess({data: {data}}) {
        setInitialValues(data);
      }
    }
  );
  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb title="Update" pageTitle="Transport" to="/dashboard/orders/transport" />
      {initialValues && 
      <TransportForm 
          type="update" 
          initialValues={initialValues} 
        />
      }
    </PageContainer>
  );
};

export default UpdateTransport;
