import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import DeleteModal from "./DeleteModal";

const Table = ({
  selectableRows,
  data,
  setItemsToDelete,
  deleteModal,
  setDeleteModal,
  columns,
  handleDelete,
  isDeleting,
  paginationServer,
  navigateToPage,
  paginationTotalRows,
  handleClick,
  path,
  pathType,
  noPagination,
  expandableRows,
  expandableRowComponent,
  expandableRowExpanded,
  selectableRowDisabled,
  paginationResetDefaultPage,
  fetching,
  pageSize,
  setPageSize,
  conditionalRowStyle,
  onSort,
  sortDirection
}) => {

  const selectItemsToDelete = (items) => {
    setItemsToDelete(items);
  };

  const { t } = useTranslation();

  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const customStyles = () => {
    if (path || handleClick)
      return {
        rows: {
          style: {
            cursor: "pointer",
            "&:hover": {
              filter: "brightness(95%)",
            },
          },
        },
      };
  };

  const loadingState = () => {
    if (typeof fetching === "boolean") {
      setLoading(fetching);
    } else if (data?.length === 0) {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    } else {
      setLoading(false);
    }
    return loading;
  };

  useEffect(() => {
    loadingState();
  }, [data, fetching]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={handleDelete}
        isDeleting={isDeleting}
      />
      <DataTable
        columns={columns}
        pagination={!noPagination}
        paginationServer={paginationServer}
        onChangePage={(page) => {
          if (paginationServer) {
            navigateToPage(page);
            window.scrollTo({
              top: 50,
              behavior: "smooth",
            });
          }
        }}
        noDataComponent={
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              padding: 24,
            }}
          >
            <p style={{ margin: 0, marginBottom: 0 }}>
              {t("There are no records to display")}
            </p>
          </div>
        }
        expandableRowsComponent={(row) =>
          expandableRowComponent && expandableRowComponent(row)
        }
        expandableRowExpanded={(row) => {
          if (expandableRowExpanded) return expandableRowExpanded(row);
        }}
        data={data}
        paginationTotalRows={paginationTotalRows || undefined}
        expandableRows={expandableRows}
        selectableRows={selectableRows}
        onSelectedRowsChange={(items) => {
          if (setItemsToDelete) selectItemsToDelete(items);
        }}
        selectableRowDisabled={(row) => {
          if (selectableRowDisabled) return selectableRowDisabled(row);
        }}
        customStyles={customStyles()}
        onRowClicked={(row) => {
          if (!handleClick && !path) return;
          if (handleClick) return handleClick(row);
          return history.push(`${path}/${pathType || 'update'}/${row.id}`);
        }}
        progressPending={loading}
        progressComponent={<Spinner color="primary" className="my-4" />}
        paginationPerPage={pageSize || undefined}
        conditionalRowStyles={conditionalRowStyle}
        onChangeRowsPerPage={
          setPageSize &&
          ((e) => {
            setPageSize(e);
            window.scrollTo({
              top: 50,
              behavior: "smooth",
            });
          })
        }
        sortServer={!!onSort}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 50, 75, 100, 200]}
        onSort={onSort && onSort}
        defaultSortAsc={sortDirection}
        persistTableHead
        // paginationResetDefaultPage={paginationResetDefaultPage}
      />
    </React.Fragment>
  );
};

export default Table;
