import React from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef((props, ref) => {

  const { t } = useTranslation();

  return (
    <input 
      className={`form-control ${props.className ?? ''}`}
      ref={ref} 
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClick && typeof props.onClick === 'function') props.onClick(e);
      }}
      {...props} 
      placeholder={props.placeholder ? t(props.placeholder) : ""} 
     />
  );
});

export default CustomInput;
