export const generateSortableCategories = (categories) => {
    if (!categories?.length) return []

    const generateTree = (categories, nestingLevel = 1) => {

        if (!categories.length) return []
        return categories.map(category => {
            return {
                id: category.id, class: `nested-${nestingLevel}`, title: category.name, children: generateTree(category.children, nestingLevel + 1)
            }
        })
    }

    const list = generateTree(categories);

    return list
}

export const categoriesSortingData = (data) => {
    let list = [];

    const getData = ({parent_id = null, cats}) => {
        cats.forEach((cat, index) => {
            list.push({
                parent_id,
                category_id: cat.id,
                sort: index + 1
            })
            if (cat.children?.length) getData({parent_id: cat.id, cats: cat.children});
        })
    }
    getData({parent_id: null, cats: data})

    return list
}
