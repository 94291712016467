import React from "react";
import DataTable from "../../Common/DataTable";
import { useTranslation } from "react-i18next";
import DeleteCatalogueProductButton from "./DeleteCatalogueProductButton";
import AddCatalogueProductButton from "./AddCatalogueProductButton";

const AllProductsTab = ({
  selectedProducts,
  productsToAdd,
  pageSize,
  setProductsToAdd,
  setPageSize,
  navigateToPage,
  onDeleteProduct,
  onAddProduct,
  isLoading,
}) => {
  const { t } = useTranslation();

  const getProduct = (productID) => {
    return selectedProducts.find((product) => product.product_id === productID);
  };

  const onProductUnitChange = (productID, unitID) => {
    setProductsToAdd((current) => {
      return {
        total: current.total,
        list: current.list.map((item) => {
          if (productID === item.id) {
            const price =
              item.unit_prices
                .find((unit) => unit.id == unitID)
                ?.groups_prices?.find((group) => group.user_group_id === 1)
                ?.price || 0;
            return {
              ...item,
              price,
            };
          }
          return item;
        }),
      };
    });
  };

  const onPriceChange = (productID, price) => {
    setProductsToAdd((current) => {
      return {
        total: current.total,
        list: current.list.map((item) => {
          if (productID === item.id) {
            return {
              ...item,
              price,
            };
          }
          return item;
        }),
      };
    });
  };

  const columns = [
    {
      name: <span className="fw-bold fs-13">{t("Name")}</span>,
      selector: (row) => row.name,
    },
    {
      name: <span className="fw-bold fs-13">{t("Units")}</span>,
      selector: (row) => (
        <select
          className="form-select"
          disabled={!!getProduct(row.id)}
          onChange={(e) => {
            onProductUnitChange(row.id, e.target.value);
          }}
        >
          {row.unit_prices.map((unitPrice) => {
            return (
              <option key={unitPrice.id} value={unitPrice.id}>
                {unitPrice.unit_name}
              </option>
            );
          })}
        </select>
      ),
    },
    {
      name: <span className="fw-bold fs-13">{t("Price")}</span>,
      selector: (row) => {
        const product = getProduct(row.id);
        return (
          <input
            type="number"
            className="form-control"
            value={!!product ? product.price : row.price}
            disabled={!!product}
            onChange={(e) => onPriceChange(row.id, e.target.value)}
          />
        );
      },
    },

    {
      name: <span className="fw-bold fs-13">{t("Actions")}</span>,
      selector: (row) => {
        const product = getProduct(row.id);
        return !product ? (
          <AddCatalogueProductButton data={row} onAddProduct={onAddProduct} />
        ) : (
          <DeleteCatalogueProductButton
            id={product.id}
            onDeleteClick={onDeleteProduct}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      data={productsToAdd.list}
      columns={columns}
      navigateToPage={navigateToPage}
      paginationServer
      paginationTotalRows={productsToAdd.total}
      pageSize={pageSize}
      setPageSize={setPageSize}
      fetching={isLoading}
    />
  );
};

export default AllProductsTab;
