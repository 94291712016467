import React, { useEffect,  useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import { errorMessage } from "../../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CustomButton from "../../../Common/CustomButton";
import { useQuery } from "@tanstack/react-query";
import usersApi from "../../../../apis/users/api";
import ordersApi from "../../../../apis/orders/api";
import Select from "react-select";
import UpdateOrderInputs from "../EditOrder/UpdateOrderInputs";
import { useHistory } from "react-router-dom";
import toastAlert from "../../../../helpers/toastAlert";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../../Custom/CustomLabel";
import CustomOption from "../../../Custom/CustomOption";
import { createOrdersDataAction, getAllOrdersDataAction, updateOrdersDataAction } from "../../../../redux/actions/orders";
import SaveButton from "../../../Common/Buttons/SaveButton";
import moment from "moment";

const NewOrderModal = ({
  modalOpen,
  modalType,
  toggleModal,
  refetchOrder,
  refetchTracking,
  whatFor,
  userDisabled
}) => {

  const { type, currentValues } = modalType;

  const { push } = useHistory();

  const [companies, setCompanies] = useState([]);

  const [companyValue, setCompanyValue] = useState(null);

  const { t } = useTranslation();

  const { refetch: refetchCompanies, isLoading: companiesLoading, isRefetching: companiesRefetching } = useQuery(["users"], () => usersApi.users.getAll({
    page_size: 'all',
    for: 'order'
  }),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess({data: {data}}) {
        setCompanies(data);
      }
    }
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      delivered_at: moment(Date.now()).format('YYYY-MM-DD')
    }
  });

  const { 
    deliveryMethods: { list: deliveryMethods }, 
    transports: { list: transports } 
  } = useSelector((state) => state.orders);

  const dispatch = useDispatch();

  const isDelivery = deliveryMethods.find((method) => method.id === +watch('delivery_type_id'))?.name?.toLowerCase() === "delivery";

  const [isLoading, setIsLoading] = useState(false);

  const userChangeDisabled = () =>  whatFor || userDisabled || type === 'update';

  const resetForm = () => {
    setCompanyValue(null);
    reset({
      delivery_type_id: "",
      delivery_id: "",
      delivered_at: moment(Date.now()).format('YYYY-MM-DD')
    })
    clearErrors();
  }

  const onSubmit = async (data) => {

    if (!companyValue?.value) return toastAlert({ type: 'error', message: 'Please fill out all necessary fields' })

    const { delivery_id, ...rest } = data;

    let requestBody = {
      ...rest,
      user_company_id: companyValue.value,
    };

    if (isDelivery) {
      requestBody = {
        ...requestBody,
        delivery_id,
      };
    } 

    setIsLoading(true);

    if (type === "create") {

      if (!!whatFor && whatFor === 'makeOrder') {
        try {

          const { data: { data: response }} = await ordersApi.priceOffers.toOrder({
            user_id: requestBody.user_company_id,
            offer_price_id: currentValues.newsletter_id,
            ...requestBody,
          });

          toastAlert({ type: 'success'})
          push(`/dashboard/orders/change-order/${response.id}/order`);
        } catch (error) {
          toastAlert({ type: 'error'})
        } finally {
          setIsLoading(false);
        }
      } else {
        dispatch(
          createOrdersDataAction({
            field: 'orders',
            data: requestBody,
            onError: () => {
              setError(
                "user_company_id",
                { type: "custom", message: "Something went wrong" },
                { shouldFocus: true }
              );
              setIsLoading(false);
            },
            onSuccess: (data) => {
              setIsLoading(false);
              push(`/dashboard/orders/change-order/${data.id}/order`);
            },
          })
        );
      }
    } else if (type === 'update') {

      const { id, user_company_name, ...data } = requestBody;

      dispatch(
        updateOrdersDataAction({
          field: 'orders',
           data: {
            id,
            data
           },
           onSuccess: async () => {
            if (refetchOrder) {
              await refetchOrder();
            }
            if (refetchTracking) {
              await refetchTracking();
            }
            setIsLoading(false);
            toggleModal();
          },
          onError: () => {
            setIsLoading(false);
            setError( "user_company_id", { type: "custom", message: "Something went wrong" },{ shouldFocus: true });
          },
        })
      );
    }
  };

  useEffect(() => {
    if (
      type === "update" || (type === "create" && currentValues?.user_company_id)
    ) {
      setCompanyValue({
        value: currentValues.user_company_id,
        label: currentValues.user_company_name,
      });
    } else if (type === "create" && whatFor === "makeOrder") {
      setCompanyValue({
        value: currentValues.user_company_id,
        label: currentValues.user_company_name,
      });
    }
  }, [type, currentValues]);

  useEffect(() => {
    if (type === 'update' && currentValues) {
      reset(currentValues);
    }
  }, [type, currentValues])

  useEffect(() => {
    if (modalOpen) {

      dispatch(getAllOrdersDataAction({
        field: 'deliveryMethods',
      }))

      dispatch(getAllOrdersDataAction({
        field: 'transports',
      }))

      if (type === 'update') {
        dispatch(getAllOrdersDataAction({
          field: 'statuses',
        }))
      }

      if (!userChangeDisabled() && whatFor !== 'makeOrder') {
        refetchCompanies();
      }
    } else resetForm();

  }, [modalOpen, dispatch]);

  return (
    <Modal
      modalClassName="zoomIn"
      isOpen={modalOpen}
      toggle={toggleModal}
      centered
    >
      <ModalHeader className="bg-light text-capitalize p-3" toggle={toggleModal}>
        {t(`${type} Order`)}
      </ModalHeader>

      <ModalBody>
        <form>
          <div className="mb-3">

            <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
              Company
            </CustomLabel>
            <Select
              value={companyValue && companyValue}
              onChange={(value) => !userChangeDisabled() && setCompanyValue(value) }
              placeholder={t('Search')}
              options={companies.map((company) => ({
                label: company.name,
                value: company.id,
              }))}
              isDisabled={companiesLoading || companiesRefetching || userChangeDisabled()}
              isLoading={(companiesLoading || companiesRefetching) && !userChangeDisabled()}
            />
            {errorMessage(errors, "user_company_id")}
          </div>

          <div className="mb-3">
            
            <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
              Delivery Method
            </CustomLabel>

            <div className="d-flex gap-2 cursor-pointer flex-wrap">
              {deliveryMethods.map((method) => (
                <div 
                  key={method.id} 
                  className={`p-2 border ${watch('delivery_type_id') === method.id ? 'bg-primary text-white' : ''}`} 
                  onClick={() => setValue('delivery_type_id', method.id)}
                >
                  {method.name}
                </div>
              ))}
            </div>

            {errorMessage(errors, "delivery_type_id")}
          </div>

          {isDelivery && (
            <div className="mb-3">
              <CustomLabel
                htmlFor="exampleInputPassword1"
                className="form-label"
              >
                Delivery Transport
              </CustomLabel>
              <select
                name="delivery_id"
                {...register("delivery_id")}
                className="form-select mb-3"
                aria-label="Default select example"
              >
                <CustomOption value="">Select transport...</CustomOption>
                {transports.map((transport) => (
                  <option key={transport.id} value={transport.id}>
                    {transport.name}
                  </option>
                ))}
              </select>
              {errorMessage(errors, "delivery_id")}
            </div>
          )}

          {type === "update" && 
            <UpdateOrderInputs register={register} />
          }

          <div className="mb-3">
            <div>
              <CustomLabel className="form-label mb-3">
                Delivered At
              </CustomLabel>
              <Controller
                name="delivered_at"
                control={control}
                render={({ name, field: { onChange, value } }) => {
                  return (
                    <Flatpickr
                      name={name}
                      className="form-control"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      value={moment(value).format('D MMM, YYYY')}
                      onChange={(date) => onChange(moment(date[0]).format('YYYY-MM-DD'))}
                    />
                  );
                }}
              />
              {errorMessage(errors, "delivered_at")}
            </div>
            
          </div>

          <div className="d-flex gap-2 justify-content-end">

            <CustomButton
              type="button"
              onClick={toggleModal}
              className="btn-light"
            >
                Close
            </CustomButton>

            <SaveButton 
              type="button"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
     
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default NewOrderModal;
