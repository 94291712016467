import html2pdf from "html2pdf-jspdf2";
import { extractQuantityBasedonUnit } from "../../calculateOrderPrices";
import { sortByStockZone } from "../../sortByStockZone";

export const handleExportProductsPdf = ({ order, type, settings, locations }) => {

  let list = [];

  if (order?.merged === 1 || order?.merged === true) {
    Object.values(order.items).forEach((items) => {
      items.filter(item => !item.no_stock).forEach((item) => {
        list.push(item);
      });
    });
  } else {
    if (type === "order") {
      list = order.items.filter(item => !item.no_stock);
    } else {
      list = order.products.filter(item => !item.no_stock);
    }
  }


  if (locations?.length) {
    list = sortByStockZone(list, locations);
  }


  const telephone = settings.consult?.phone;
  const email = settings.consult?.email;
  const logo = settings.setup?.logo;

  const mainSignature = order?.signatures?.find(item => item.matricule === order.matricule);

  const previousSignatures = order.signatures?.filter(item => (item.matricule !== order.matricule) && (item.name || item.signature));

  const htmlData = /*html*/`
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="no" lang="no">
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <title>Document</title>
          <style>

            .container {
              min-height: 100vh;
              display: grid;
            }

            .w-100{
                width: 100%;
            }

            .w-50{
                width: 50%;
            }
            .card-header {
                background-color: #EEE !important;
            }

            .card-title {
                background-color: #EEE !important;
                font-size: 15px;
                padding: 10px 20px;
                letter-spacing: 1px;
                margin-bottom: 0px;
            }

            .card-body {
                background-color: white;
            }

            .card-text {
                font-size: .8em;
                color: #666;
                line-height: 1.2em;
                margin-bottom: 4px !important;
            }

            .card {
                position: relative;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                min-width: 0;
                word-wrap: break-word;
                background-color: #fff;
                background-clip: border-box;
                border: 1px solid rgba(0,0,0,.125);
                border-radius: .25rem;
            }

            .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
                margin-bottom: .5rem;
                font-family: inherit;
                font-weight: 500;
                line-height: 1.2;
                color: inherit;
            }

            hr {
                margin-top: 0.5rem;
                margin-bottom: 0.8rem;
                border: 0;
                border-top: 3px dashed rgba(0, 0, 0, .1);
            }

            table {
                width: 100%;
                border-collapse: collapse;
            }

            td {
              font-size: .7rem;
            }

            .tabletitle {
                padding: 5px;
                background: #EEE;
            }

            .service {
                border: 1px solid #EEE;
            }

            .itemtext {
                font-size: .9em;
            }

            #table h2 {
                font-size: .9em;
            }

            .table_page{
                width:750px;
                margin: auto;

            }
            .font-bold{
                font-weight: 700;
            }

            .w-50{
                width: 50%;
            }
            .w-100{
                width: 100%;
            }
            .float-left{
                float: left;
            }
            .float-right{
                float: right;
            }
            .rightdir{
                text-align: right;
            display: inline-block;
            }
            .leftdir{
                text-align: left;
            display: inline-block;
            }
            .text-right{
                text-align: right;
            }
            .tabletitle h2{
                font-size: 16px;
            }



      
            #customers {
              font-family: Arial, Helvetica, sans-serif;
              border-collapse: collapse;
              width: 60%;
            }

            #customers td, #customers th {

              padding: 8px;
            }
            #customers .head{
                border-bottom: 2px solid black;
            }

            #customers th {
              padding-top: 12px;
              padding-bottom: 12px;
              text-align: left;
              color: black;
            }

            .pdf-logo {
              width: 150px;
            }

            .pdf-header {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding-top: 1rem;
            }

            .right-header {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }

            .company, .right-section-details {
              display: flex;
              flex-direction: column;
            }

            .company span {
              font-size: 12px;
            }

            .right-section-details span {
              font-size: 12px;
            }

            .right-section-details .order-number {
              font-weight: bolder;
              font-size: 14px;
            }

            .bold {
              font-weight: 550;
              color: black;
            }

            table {
              page-break-inside:auto;
            }

            table tr {
              page-break-inside:avoid; 
              page-break-after:auto;
            }

            .signature {
              min-height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              padding-left: .2rem;
              page-break-inside:avoid; 
              margin-block: 1rem;
            }

            .signatures {
              display: flex;
              gap: 1rem;
            }

            .signatures > div {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              padding-left: .2rem;
              page-break-inside:avoid; 
              margin-block: 1rem;
            }


          </style>
        </head>

        <body>
           <div class="container">

              <div>
                <div class="pdf-header">
                  <div>
                      <div class="card bg-light"
                      style="max-width: 100%; border: none;">
                      <div class="card-body">
                          <h4 class="font-bold">Din Engros AS</h4>
                          <p class="card-text ">Heggstadmoen 5 NO-7080 Heimdal</p>
                          <p class="card-text">
                            <span class="w-50">
                              <span>Telefon: </span> 
                              <span style="margin-left: 3px;">${telephone.slice(2) || '73916548'}</span> 
                            </span>
                          </p>
                          <p class="card-text">E-post: ${email || 'fakutura@dinengros.no'}</p>
                          <p class="card-text">Org.nr: 999 261 493</p>
                          <p class="company">
                            <span>
                              <b>${order?.company?.profile?.company || ''}</b>
                            </span>
                            <span>
                              ${order?.company?.address?.street_name || ''}
                            </span>
                            <span>
                              ${ order?.company?.address?.post_zip_code || ''} ${ order?.company?.address?.post_name || ''}
                            </span> 
                          </p>
                        </div>
                      </div>
                  </div>

                  <div class="right-header">
                          <img
                            class="pdf-logo"
                            src=${logo}
                          />    
                      <div class="right-section-details">
                        <span class="order-number">
                            Ordre: ${order?.matricule}
                        </span>

                        <span>
                          Bestillingsdato: ${new Date(order?.created_at).toLocaleDateString()}
                        </span>

                        <span>
                          Leveringgsdato: ${new Date(order?.delivered_at).toLocaleDateString()}
                        </span>
                      </div>
                </div>
            </div>

            <p
              style="
                text-indent: 0pt;
                text-align: left;
              "
            >
              Kontaktperson: ${order.company?.first_name + ' ' + order.company?.last_name} ${order?.company?.profile?.phone || ' '}
            </p>

            <table
              class="table-data"
              style="border-collapse: collapse; margin-left: 8pt, padding: 5rem"
              cellspacing="0"
            >
              <tr style="height: 13pt">
                <td style="width: 56pt" bgcolor="#EDEDED">
                  <p
                    class="bold"
                  >
                    Produktnr.
                  </p>
                </td>
                <td style="width: 181pt" bgcolor="#EDEDED">
                  <p
                    class="bold"
                  >
                    Produktbeskrivelse
                  </p>
                </td>
                <td style="width: 50pt" bgcolor="#EDEDED">
                  <p
                    class="bold"
                  >
                    Antall
                  </p>
                </td>
                <td style="width: 41pt" bgcolor="#EDEDED">
                  <p
                    class="bold"
                  >
                    Type
                  </p>
                </td>
              </tr>

              ${list.map((item) => /*html*/`
                  <tr>
                    <td class="bold">
                        ${item.product?.matricule || ''}
                    </td>
                    <td class="bold">
                      ${item.product?.name || ''}
                    </td>
                    <td class="bold">
                      ${extractQuantityBasedonUnit(item)}
                    </td>
                    <td class="bold">
                        ${item.product_unit?.name || ''}
                    </td>
                  </tr>
                `
              ).join('')}
            </table>
              </div>
            
              <div class="signature">

                  ${mainSignature?.name ? 
                      /*html*/`
                      <div>
                          <h6>Navn:</h6>
                          <p style="margin-left: 10px;">${mainSignature?.name || ''}</p>
                      </div>
                      `
                    : ''}

    
                    ${mainSignature?.signature ? 
                      /*html*/
                        `<div>
                          <h6>Signatur:</h6>
                          <img crossOrigin="Anonymous" src=${mainSignature?.signature + "?not-from-cache-please"} alt="signature" height="65"/>
                         </div>
                        ` 
                        : ''
                      }
          
                  ${!!previousSignatures?.length && /*html*/`
                    <div class="signatures">
                        ${previousSignatures?.map(signature => /*html*/`
                          <div>
                            <div>
                                <h6>Ordre: ${signature?.matricule || ''}</h6>
                            </div>
                            <div>
                                <h6>Navn: ${signature?.name || ''}</h6>
                            </div>
                            <div>
                               <h6>Signatur:</h6>
                                <img  style="margin-left: 10px;" crossOrigin="Anonymous" src=${signature?.signature + "?not-from-cache-please"} alt="signature" height="40"/>
                            </div>
                          </div>
                          `
                        ).join("")}
                    </div>
                  `
                }

              </div>

           </div>
        </body>
      </html>
  `;

  const opt = {
    margin: [0, 5],
    filename: 'myfile.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      dpi: 192,
      scale:4,
      letterRendering: true,
      useCORS: true
    },
  };
  
  html2pdf().set(opt).from(htmlData).toPdf().get('pdf').then(function (pdf) {
    let totalPages = pdf.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.setTextColor(150);
      if (i === 1) {
        pdf.text('Side: ' + i + ' / ' + totalPages, 
        pdf.internal.pageSize.getWidth() - 45.5, 39
      );
      } else {
        pdf.text('Side: ' + i + ' / ' + totalPages, 
        pdf.internal.pageSize.getWidth() - 45.5, 10
      );
      }
    }

    window.open(pdf.output('bloburl', '_blank'));
  });

};
