export const WEB_SETTINGS_PERMISSIONS = {
  read: ["websettings-read"],
  update: ["websettings-update"],
};

export const OPENING_HOURS_PERMISSIONS = {
  read: ["opening_hours-read"],
  update: ["opening_hours-update"],
};

export const CLOSE_DATE_PERMISSIONS = {
  read: ["close_dates-read"],
  update: ["close_dates-update"],
  delete: ["close_dates-delete"],
  create: ["close_dates-create"],
};
