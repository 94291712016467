import { productsActions } from "../slices/products";
import productsApi from "../../apis/products/api";
import toastAlert from "../../helpers/toastAlert";

export const getAllProductsDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await productsApi[payload.field].getAll(payload?.query);
    dispatch(
        productsActions.setData({
        field: payload.field,
        data: response.data,
        total: response.meta?.total || null,
      })
    );
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const createProductsDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await productsApi[payload.field].create(payload.data);
    dispatch(
        productsActions.createData({
        field: payload.field,
        data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload.onError) {
        payload?.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const updateProductsDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await productsApi[payload.field].update(payload.data.id, payload.data.data);
    dispatch(
        productsActions.updateData({
        field: payload.field,
        id: payload.id,
        data: response.data,
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess(response.data);
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};

export const deleteProductsDataAction = (payload) => async (dispatch) => {
  try {
    await productsApi[payload.field].delete(payload.data);
    dispatch(
        productsActions.deleteData({
        field: payload.field,
        data: payload.data
      })
    );
    if (payload?.onSuccess) {
        payload.onSuccess();
    }
    toastAlert({ type: 'success' });
  } catch (error) {
    if (payload?.onError) {
        payload.onError(error.response);
    }
    toastAlert({ type: 'error' });
  }
};
