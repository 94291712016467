import * as yup from "yup";

const footerSchema = yup.object().shape({
  copyright: yup.string().required("Copyright is required"),
  logo: yup.mixed("You must upload your footer logo"),
});

export default footerSchema;


