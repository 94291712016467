import * as yup from "yup";

export const updateProfileSchema = yup.object().shape({
  name: yup.string("").required("Please provide a valid name"),
  email: yup.string().email().required("Please enter a valid email"),
  password_old: yup
    .string()
    .required("Please enter your old password"),
  password: yup
    .string()
    .notRequired(),
  password_confirmation: yup
    .string()
    .typeError("")
    .oneOf([yup.ref("password"), null], "Passwords must match"),

  // createUserPostalCode: yup.number().positive().integer(),
});
