import { useState } from "react";
import { useAuthState } from "../context/authContext";

export default function usePermission(permission) {

    
    const [loading, setLoading] = useState(true);

    // const [allowed, setAllowed] = useState(false);

    const { isAllowedTo, isGettingAdmin } = useAuthState()

    const allowed = isAllowedTo(permission);

    return {loading: isGettingAdmin, allowed}
}