import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TransportsTable from "../../../Components/Orders/Transports/Table";
import { useDispatch, useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import Restricted from "./../../../Components/Guard/Restricted";
import { ORDER_TRANSPORT_PERMISSIONS } from "../../../permissions/orders";
import { deleteOrdersDataAction, getAllOrdersDataAction } from "../../../redux/actions/orders";
import CustomButton from "../../../Components/Common/CustomButton";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useSearch } from "../../../hooks/useSearch";

const Transports = () => {
  
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const { load, callback, deleteModal, isDeleting, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const { transports: { list: transports, total } } = useSelector((state) => state.orders);

  const searchFunc = (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllOrdersDataAction({
      field: 'transports',
      query,
      onSuccess: () => setLoading(false),
    }));
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, onSort, search } = useSearch({
    name: 'transports',
    debouncedValues: {
      name: ''
    },
    searchFunc
  });


  const handleDelete = () => {
    load();
    dispatch(
      deleteOrdersDataAction({
        field: 'transports',
        data: {
          ids: itemsToDelete.selectedRows.map((item) => item.id)
        },
        onSuccess() {
          callback();
          search();
        },
      })
    );
  };

  return (
      <PageContainer>

        <BreadCrumb title="Transports" pageTitle="Orders" to="/dashboard/orders" />

        <div className="hstack gap-2">

          <Restricted permissions={ORDER_TRANSPORT_PERMISSIONS.create}>
            <Link to="/dashboard/orders/transports/create">
              <CustomButton type="button" className="btn btn-success">
                <i className="ri-add-fill me-1 align-bottom"></i>
                  Create
              </CustomButton>
            </Link>
          </Restricted>

          <Restricted permissions={ORDER_TRANSPORT_PERMISSIONS.delete}>
            <CustomButton
              className="btn btn-danger"
              disabled={!itemsToDelete.selectedRows?.length > 0}
              onClick={() => setDeleteModal(true)}
            >
              <i className="ri-delete-bin-line align-bottom me-1"></i>
                Delete
            </CustomButton>
          </Restricted>

        </div>

        <Card className="mt-3">
          <TransportsTable
            data={transports}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            loading={loading}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            isDeleting={isDeleting}
            handleDelete={handleDelete}
            selectableRows
            itemsToDelete={itemsToDelete}
            filters={searchValues}
            handleFilterChange={handleChange}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            sortDirection={searchValues.order_value}
            fetching={loading}
            onSort={onSort}
            path="/dashboard/orders/transports"
          />
        </Card>
      </PageContainer>
  );
};

export default Transports;
