import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import BreadCrumb from "../../Common/BreadCrumb";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createUnitSchema } from "../../../helpers/validation/Products/createMeasurementUnit";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import CustomButton from "../../Common/CustomButton";
import { useDispatch } from "react-redux";
import CustomLabel from "../../Custom/CustomLabel";
import { createProductsDataAction, deleteProductsDataAction, updateProductsDataAction } from "../../../redux/actions/products";
import SaveButton from "../../Common/Buttons/SaveButton";

const MeasurementUnitForm = ({ type, initialValues }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(createUnitSchema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const submitForm = async (data) => {
    setIsLoading(true);
    if (type === "create") {
      dispatch(
        createProductsDataAction({
          field: 'measurementUnits',
          data,
          onSuccess: () => history.push("/dashboard/products/measurement-units"),
        })
      );
    } else {
      const { name, status } = data;
      dispatch(
        updateProductsDataAction({
          field: 'measurementUnits',
          data: {
            id,
            data: {
              name,
              status,
            },
          },
          onSuccess: () => setIsLoading(false)
        })
      );
    }
  };

  const deleteUnit = () => {
    setIsDeleting(true)
    dispatch(
      deleteProductsDataAction({
        field: 'measurementUnits',
        data: {
          ids: [id],
        },
        onSuccess: () => history.push("/dashboard/products/measurement-units"),
      })
    );
  };

  return (
    <div>
      <Col md={12}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card className="p-3">
            <Row className="gx-4 gy-2">
              <Col md={6}>
                <CustomLabel for="inputName">Name</CustomLabel>
                <input
                  {...register("name")}
                  className="form-control"
                />
                {errorMessage(errors, "name")}
              </Col>
              <Col md={6} className="d-flex align-items-center">
                  <div className="form-check form-switch mt-4">
                      <input 
                        {...register('status')} 
                        className="form-check-input fs-16" 
                        type="checkbox" 
                        role="switch" 
                        id="status" 
                      />
                      <CustomLabel 
                        className="form-check-label" 
                        htmlFor="status"
                      >
                        Status
                      </CustomLabel>
                  </div>
              </Col>
            </Row>
          </Card>

          <div className="my-2 d-flex justify-content-end">

            <SaveButton
              isLoading={isLoading}
            />

          </div>

        </form>
      </Col>
    </div>
  );
};

export default MeasurementUnitForm;
