import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import { useFileManager } from "../../../context/FileManagerContext";
import useSetFile from "../../../hooks/useSetFile";
import SelectFileButton from "../../Common/SelectFileButton";
import BreadCrumb from "../../Common/BreadCrumb";
import FileManagerMediaReader from "../FileManager/FileManagerMediaReader";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomButton from "../../Common/CustomButton";
import CustomOption from "../../Custom/CustomOption";
import CustomTextarea from "../../Custom/CustomTextarea";
import { createSettingsDataAction, updateSettingsDataAction } from "../../../redux/actions/settings";

const SliderForm = ({ type, initialValues }) => {

  const { openFileManagerModal } = useFileManager();

  const [isLoading, setIsLoading] = useState(false);
  
  const [picture, setPicture] = useState(() => {
    if (initialValues?.attachements) {
      const file = initialValues?.attachements[0]?.file_info;
      if (!file) return file;
      return {
        ...file,
        path: `${file.uri}/${file.name}`,
      };
    }
    return null;
  });

  useSetFile({
    whatFor: "slider-picture",
    cb: (file) => {
      setPicture(file);
    },
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: type === "create" ? {} : initialValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    setIsLoading(true)
    if (!picture?.id) {
      return setError("picture", { type: "validate", message: "Required" });
    }
    if (type === "create") {
      dispatch(
        createSettingsDataAction({
          field: 'homeSliders',
          data: {
            ...data,
            attachements: [
              {
                type: "media",
                data_id: picture.id,
                status: "active",
              },
            ],
          },
          onSuccess: () => history.push("/dashboard/home/home-slider"),
        })
      );
    } else {
      const requestBody = {
        ...data,
        attachements: [
          {
            type: "media",
            data_id: picture.id,
            status: "active",
          },
        ],
      };

      dispatch(
        updateSettingsDataAction({
          field: 'homeSliders',
          data: {
            id: initialValues.id,
            data: requestBody,
          },
          onSuccess() {
            setIsLoading(false);
          },
          onError() {
            setIsLoading(false);
          }
        })
      );
    }
  };

  return (
    <div>
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="g-3">
                <Col lg={6}>
                  <div>
                    <CustomLabel className="form-label">Title</CustomLabel>
                    <CustomInput
                      name="home-slider-name"
                      id="home-slider-name-field"
                      className="form-control"
                      placeholder="Enter Slider Name"
                      type="text"
                      {...register("name")}
                    />
                  </div>
                </Col>
                
                <Col md={1} xs={3}>
                    <div>
                        <CustomLabel htmlFor="title-color" className="form-label">Color</CustomLabel>
                        <input type="color"  {...register("name_color")}  className="form-control form-control-color w-100" id="title-color"/>
                    </div>

                </Col>

                <Col lg={6} className="mb-4">
                  <div>
                    <CustomLabel
                      htmlFor="homeslider-media"
                      className="form-label"
                    >
                      Picture Or Video
                    </CustomLabel>
                    <SelectFileButton
                      text="Select picture or video"
                      onClick={() => openFileManagerModal("slider-picture")}
                    />
                    {errorMessage(errors, "picture")}
                  </div>
                  {picture && (
                    <FileManagerMediaReader
                      className="mt-2"
                      src={picture?.path}
                      ext={picture?.ext}
                      style={{ maxWidth: "150px" }}
                    />
                  )}
                </Col>
              </Row>
              
              <Row className="gy-3">
                <Col lg={3}>
                  <div>
                    <CustomLabel className="form-label">
                      Button Title
                    </CustomLabel>
                    <CustomInput
                      name="home-slider-name"
                      id="home-slider-name-field"
                      className="form-control"
                      placeholder="Enter Button Title"
                      type="text"
                      {...register("btn_name")}
                    />
                  </div>
                </Col>

                <Col md={1} xs={3}>
                    <div>
                        <CustomLabel htmlFor="btn-text-color" className="form-label">Color</CustomLabel>
                        <input type="color" {...register("btn_name_color")} className="form-control form-control-color w-100" id="btn-text-color"/>
                    </div>
                </Col>

                
                <Col md={1} xs={3}>
                    <div>
                        <CustomLabel htmlFor="btn-background-color" className="form-label">Background</CustomLabel>
                        <input type="color" {...register("btn_bg_color")} className="form-control form-control-color w-100" id="btn-background-color"/>
                    </div>
                </Col>

              </Row>

               <Row className="mt-2 gy-3">
                <Col lg={6}>
                    <div>
                      <CustomLabel className="form-label">
                        Button Link Type
                      </CustomLabel>
                      <select
                        className="form-select"
                        {...register("btn_link_type")}
                      >
                        <CustomOption value="internal">Internal</CustomOption>
                        <CustomOption value="external">External</CustomOption>
                      </select>
                      {errorMessage(errors, "btn_link_type")}
                    </div>
                  </Col>

                  <Col lg={3}>
                    <div>
                      <CustomLabel className="form-label">
                        Button Link
                      </CustomLabel>
                      <CustomInput
                        name="home-slider-name"
                        id="home-slider-name-field"
                        className="form-control"
                        placeholder="Enter Button Link"
                        type="text"
                        {...register("btn_link")}
                      />
                      {errorMessage(errors, "btn_link")}
                    </div>
                  </Col>
               </Row>

                <Row className="mt-2 gy-3">
                  <Col lg={10}>
                    <div>
                      <CustomLabel className="form-label">
                        Description
                      </CustomLabel>
                      <CustomTextarea
                        name="home-slider-name"
                        id="home-slider-name-field"
                        className="form-control"
                        placeholder="Enter Description"
                        type="text"
                        rows={3}
                        {...register("description")}
                      />
                      {errorMessage(errors, "description")}
                    </div>
                  </Col>

                  <Col md={1} xs={3}>
                      <div>
                          <CustomLabel htmlFor="description-color" className="form-label">Color</CustomLabel>
                          <input {...register("description_color")} type="color" className="form-control form-control-color w-100" id="description-color" />
                      </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <div
                      className="form-check form-switch form-switch-md mb-3"
                      dir="ltr"
                    >
                      <CustomInput
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        {...register("status")}
                        defaultChecked={initialValues?.status}
                      />
                      <CustomLabel
                        className="form-check-label"
                        htmlFor="customSwitchsizemd"
                      >
                        Active
                      </CustomLabel>
                    </div>
                  </Col>
                </Row>
                
              <CardFooter className="px-0">
                <div className="hstack gap-2 justify-content-end">
                  <Link to={"/dashboard/home/home-slider"}>
                    <CustomButton type="button" className="btn btn-light">
                      Close
                    </CustomButton>
                  </Link>
                  <CustomButton
                    type="submit"
                    className="btn btn-success"
                    id="add-btn"
                    isLoading={isLoading}
                  >
                    Save
                  </CustomButton>
                </div>
              </CardFooter>
            </Form>
          </CardBody>
        </Card>
    </div>
  );
};

export default SliderForm;
