import React from 'react'
import OfferForm from '../../../Components/Products/Offers/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateOffer = () => {

  return (
      <PageContainer>
        <BreadCrumb title="Create" pageTitle="Offers" to="/dashboard/products/offers" />
        <OfferForm 
          type="create"
        />
      </PageContainer>
  )
}

export default CreateOffer