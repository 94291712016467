import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import DataTable from "../../Common/DataTable";
import { useHistory } from "react-router-dom";
import calculateTotalPrice from "../../../helpers/calculateTotalPrice";
import CoverSpinner from "../../Common/CoverSpinner";
import CustomButton from "../../Common/CustomButton";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import toastAlert from "../../../helpers/toastAlert";
import { extractQuantityBasedonUnit } from "../../../helpers/calculateOrderPrices";
import AddProductToOffer from "./AddProductToOffer";
import ordersApi from "../../../apis/orders/api";
import { useTranslation } from "react-i18next";
import usersApi from "../../../apis/users/api";

const PriceOfferProducts = ({
  items,
  read,
  priceOffer,
  refetchPriceOffer,
  visibleColumns,
  toggleVisibleColumns,
  userDiscounts,
  refetchUserDiscounts
}) => {

  const [list, setList] = useState(items || []);

  const [isUpdating, setIsUpdating] = useState(false);

  const { t } = useTranslation();

  const { load, callback, deleteModal, isDeleting, itemsToDelete, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const history = useHistory();

  const handleChange = (id) => (e) => {
    setList((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [e.target.name]: e.target.value,
          };
        }
        return item;
      });
    });
  };

  const handleDelete = async () => {
    try {
      load();
      const ids = itemsToDelete.selectedRows.map((item) => item.id);
      await ordersApi.priceOffers.products.delete({ ids });
      toastAlert({ type: 'success'})
      refetchPriceOffer();
      setList((prev) => {
        return prev.filter((item) => {
          if (ids.includes(item.id)) return false;
          return true;
        });
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      callback();
    }
  };

  const updateItem = async (row) => {
    setIsUpdating(true);
    const id = row.id;
    const requestBody = {
      product_unit: row.product_unit?.id || row.product_unit,
      promo: row.promo,
      price: row.price,
      qty: row.qty,
      price_purchase: row.price_purchase,
    };
    try {
      await ordersApi.priceOffers.products.update(id, requestBody);
      refetchPriceOffer();
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsUpdating(false);
    }
  };

  const updateAll = async () => {
    setIsUpdating(true);
    const allItems = list.map((row) => ({
      id: row.id,
      product_unit: row.product_unit?.id || row.product_unit,
      promo: row.promo,
      price: row.price,
      qty: row.qty,
      price_purchase: row.price_purchase,
    }));
    try {
      if (list.length > 0) {
        await ordersApi.priceOffers.products.updateMultiple({
          items: allItems
        });
        toastAlert({ type: 'success'})
        refetchPriceOffer();
      } else {
        toastAlert({ type: 'success'})
      }
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsUpdating(false);
    }
  };

  const toggleDiscount = async (product) => {
    try {
      const discountExists = userDiscounts.find(prod => product.product.id === prod.product_id);

      if (discountExists) {
        await usersApi.users.discounts.delete({ ids: [discountExists.id] });
      } else {
        await usersApi.users.discounts.create({
          user_id: priceOffer.user.id,
          product_id: product.product.id,
          units: [
            {
              unit_id: product.product_unit.id,
              price: (+product.price - (+product.price * +product.promo) / 100).toFixed(2),
            },
          ],
        });
      }

      toastAlert({ type: 'success'})
      refetchUserDiscounts();
    } catch (error) {
      console.log('error', error);
      toastAlert({ type: 'error' })
    }
  };

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product number")}</span>
      ),
      selector: (row) => row.product?.matricule || "",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Product Name")}</span>,
      selector: (row) =>
        (
          <span
            onClick={() =>
              history.push(`/dashboard/products/update/${row.product?.id}`)
            }
            title={row.product?.name}
            className="cursor-pointer"
          >
            <b>{row.product?.name}</b>
          </span>
        ) || "-",
      sortable: true,
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Qty")}</span>,
      selector: (row) => (
        <input
          onChange={handleChange(row?.id)}
          name="qty"
          value={row.qty || ""}
          type="number"
          className="form-control"
          step="any"
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Type")}</span>,
      selector: (row) =>
        `${row.product_unit?.name} ${row.weight ? `(${row.weight})` : ""}`,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Price")}</span>,
      selector: (row) => (
        <input
          onChange={handleChange(row.id)}
          name="price"
          value={row.price || ""}
          type="number"
          className="form-control"
          step="any"
        />
      ),
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Purchase Price")}</span>
      ),
      selector: (row) => (
        <input
          type="number"
          className="form-control"
          disabled
          value={row?.price_purchase || ""}
          step="any"
        />
      ),
      id: "pruchase_price",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Discount")}</span>,
      selector: (row) => (
        <input
          onChange={handleChange(row.id)}
          name="promo"
          value={row.promo || ""}
          className="form-control"
        />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Total")}</span>,
      selector: (row) =>
        Number(
          calculateTotalPrice(
            row.price,
            row.promo,
            extractQuantityBasedonUnit(row)
          )
        ).toFixed(2),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Actions")}</span>,
      selector: (row) => (

        <div className="d-flex gap-2">

          <button type="button" className={`btn btn-sm btn-success`}>
            <i
              onClick={() => updateItem(row)}
              className="ri-save-2-line cursor-pointer fs-16"
            ></i>
          </button>

          {userDiscounts.some(prod => row.product?.id === prod.product_id) ? (
            <button
              onClick={() => toggleDiscount(row)}
              type="button"
              className="btn btn-danger btn-sm"
            >
              <i className="ri-close-line cursor-pointer fs-16"></i>
            </button>
          ) : (
            <button
              onClick={() => toggleDiscount(row)}
              type="button"
              className={`btn btn-sm btn-success`}
            >
              <i className="ri-add-line cursor-pointer fs-16"></i>
            </button>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setList(items);
  }, [items])

  return (
    <>
      <Row className="mb-3">

        <div className="hstack gap-2">

          <button
            type="button"
            className={`btn btn-sm btn-${
              visibleColumns?.purchasePrice ? "success" : "danger"
            } d-flex align-items-center gap-1`}
            onClick={(e) => toggleVisibleColumns()}
          >
            {visibleColumns?.purchasePrice ? (
              <i className="ri-check-line"></i>
            ) : (
              <i className="ri-close-line"></i>
            )}
            {t("Purchase Price")}
          </button>

          <button
            className="btn btn-dark btn-sm"
            disabled={!itemsToDelete.selectedRows?.length > 0}
            onClick={() => setDeleteModal(true)}
          >
            <i className="ri-delete-bin-line align-bottom"></i>{" "}
            {t("Delete Selected")}
          </button>

        </div>
        
      </Row>

      <AddProductToOffer
        items={list}
        refetchPriceOffer={refetchPriceOffer}
        priceOffer={priceOffer}
        setList={setList}
        visibleColumns={visibleColumns}
        userDiscounts={userDiscounts}
      />

      <Row className="mt-3">
        <form>
          <Card>
            <DataTable
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              columns={
                visibleColumns.purchasePrice
                  ? columns
                  : columns.filter((col) => !col.id)
              }
              data={list}
              isDeleting={isDeleting}
              handleDelete={handleDelete}
              selectableRows={!read}
              selectableRowDisabled={(row) => row.isExtraCost}
              noPagination
            />
          </Card>

          <CustomButton
            type="button"
            isLoading={isUpdating}
            className="btn btn-secondary"
            onClick={updateAll}
          >
            Save
          </CustomButton>
        </form>

        <CoverSpinner loading={isUpdating} />
      </Row>
    </>
  );
};

export default PriceOfferProducts;
