import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import Nestable from "react-nestable";
import "react-nestable/dist/styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  generateSortableCategories,
  categoriesSortingData,
} from "../../../helpers/sortCategories";
import productsApi from "../../../apis/products/api";
import toastAlert from "../../../helpers/toastAlert";
import CustomButton from "../../../Components/Common/CustomButton";
import { useTranslation } from "react-i18next";
import { getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";

const SortCategories = () => {

  const renderItem = ({ item }) => item.title;

  const { t } = useTranslation();

  const [sortableCategories, setSortableCategories] = useState(null);

  const dispatch = useDispatch();

  const { categories: { list: categories } } = useSelector((state) => state.products);

  const handleSave = async () => {
    const data = categoriesSortingData(sortableCategories);
    await productsApi.categories.sort(data);
    toastAlert({ type: 'success'})
  };

  useEffect(() => {
    if (categories.length)
      setSortableCategories(generateSortableCategories(categories));
  }, [categories]);

  useEffect(() => {
    dispatch(
      getAllProductsDataAction({
        field: 'categories',
        query: {
          from: "client",
        },
      })
    );
  }, []);

  return (
      <PageContainer>
        <BreadCrumb title="Sort Records" pageTitle="Categories" />
        <Row>
          <Col className="d-flex justify-content-between">
            <Link to="/dashboard/products/categories">
              <button className="btn btn-primary fs-13">
                <i className="ri-arrow-left-line"></i>
                {t("Back to list")}
              </button>
            </Link>
            <CustomButton
              type="button"
              className="btn btn-outline-primary"
              onClick={handleSave}
            >
              Save
            </CustomButton>
          </Col>

          <Col md={12} className="mt-3">
            <Card className="p-3">
              {sortableCategories && (
                <Nestable
                  items={sortableCategories}
                  renderItem={renderItem}
                  onChange={({ items }) => {
                    setSortableCategories(items);
                  }}
                />
              )}
            </Card>
          </Col>
        </Row>
      </PageContainer>
  );
};

export default SortCategories;
