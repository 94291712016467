import React, { useState } from "react";
import { Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import ZonesTable from "../../../Components/Users/Zones/Table";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { USER_ZONES_PERMISSIONS } from "./../../../permissions/users";
import Restricted from "./../../../Components/Guard/Restricted";
import { deleteUsersDataAction, getAllUsersDataAction } from "../../../redux/actions/users";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const Zones = () => {

  const { callback, deleteModal, isDeleting, itemsToDelete, load, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const { zones: { list: zones, total } } = useSelector((state) => state.users);

  const searchFunc = (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllUsersDataAction({
      field: 'zones',
      query,
      onSuccess() {
        setLoading(false);
      }
    }));
  }

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'zones',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  
  const handleDelete = () => {
    load();
    dispatch(
      deleteUsersDataAction({
        field: 'zones',
        data: {
          ids: itemsToDelete.selectedRows.map((item) => item.id)
        },
        onSuccess() {
          callback();
          search();
        }
      })
    );
  };

  return (
    <PageContainer>
      
      <BreadCrumb title="Zones" pageTitle="Users" to="/dashboard/users" />
      
      <div className="hstack gap-2">

          <Restricted permissions={USER_ZONES_PERMISSIONS.create}>
            <Link to="/dashboard/users/zones/create">
              <CustomButton
                className="btn-success"
              >
                <i className="ri-add-line align-bottom me-1"></i>{" "}
                Create
              </CustomButton>
            </Link>
          </Restricted>

          <Restricted permissions={USER_ZONES_PERMISSIONS.delete}>
            <CustomButton
              className="btn-danger"
              onClick={() => setDeleteModal(true)}
              disabled={!itemsToDelete.selectedRows?.length > 0}
            >
              <i className="ri-delete-bin-line align-bottom me-1"></i>
              Delete
            </CustomButton>
          </Restricted>

      </div>

      <Card className="mt-3">
        <ZonesTable
            data={zones}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            selectableRows
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDelete={handleDelete}
            filters={searchValues}
            handleFilterChange={handleChange}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            sortDirection={searchValues.order_value}
            fetching={loading}
            isDeleting={isDeleting}
            path="/dashboard/users/zones"
        />
      </Card>

    </PageContainer>
  );
};

export default Zones;
