import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//i18n
import i18n from "../../i18n";
// import languages from "../../common/languages";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import toastAlert from "../../helpers/toastAlert";
import { settingsActions } from "../../redux/slices/settings";

const LanguageDropdown = () => {
  
  // Declare a new state variable, which we'll call "menu"

  const { languages: { active: activeLang, list: languages } } = useSelector((state) => state.settings);

  const activeLanguage =
    languages.find((lang) => lang.code === activeLang) || languages?.at(0);

  const dispatch = useDispatch();

  // SET_DEFAULT_LANGUAGE

  useEffect(() => {
    const handleDefaultLanguage = async () => {
      const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
      await i18n.changeLanguage(currentLanguage);
      dispatch(settingsActions.setActiveLanguage(currentLanguage));
    };
    handleDefaultLanguage();
  }, []);

  const changeLanguageAction = async (lang) => {
    //set language as i18n
    await i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    dispatch(settingsActions.setActiveLanguage(lang));
    toastAlert({ type: 'success'})
  };

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isLanguageDropdown}
        toggle={toggleLanguageDropdown}
        className="ms-1 topbar-head-dropdown header-item"
      >
        {activeLanguage && (
          <DropdownToggle
            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            tag="button"
          >
            {activeLanguage.flag_image ? (
              <img
                src={`${activeLanguage.flag_image?.uri}/${activeLanguage.flag_image?.name}`}
                alt="Header Language"
                height="20"
                className="rounded"
              />
            ) : (
              <p className="text-uppercase mb-0">{activeLanguage.code}</p>
            )}
          </DropdownToggle>
        )}
        <DropdownMenu className="notify-item language py-2">
          {languages.map((language) => {
            return (
              <DropdownItem
                key={language.id}
                onClick={() => changeLanguageAction(language.code)}
                className={`notify-item ${
                  activeLang === language.code ? "active" : "none"
                }`}
              >
                {language?.flag_image && (
                  <img
                    src={`${language.flag_image?.uri}/${language.flag_image?.name}`}
                    alt="Skote"
                    className="me-2 rounded"
                    height="18"
                  />
                )}
                <span className="align-middle">{language.name}</span>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LanguageDropdown;
