import React from "react";
import { Link } from "react-router-dom";
import NotificationOrder from "./NotificationOrder";

const NotificationItem = ({ notification }) => {

    const components = {
        'new_order': <NotificationOrder notification={notification} />
    }

    return <div className="text-reset notification-item d-block dropdown-item">
        {components[notification.type]}
    </div>;
};

export default NotificationItem;