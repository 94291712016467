import React from 'react'


export const VIDEO = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv', 
    'f4v',
    'swf',
    'mkv',
    'webm', 
    'html5'
]

export const IMAGE = [
    'jpg', 'ico', 'svg', 'jpeg', 'png', 'psd', 'gif'
]

const FileManagerMediaReader = ({ src, ext, alt, ...rest }) => {
    if (IMAGE.includes(ext)) {
        return <img 
            src={src} 
            alt={alt} 
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            {...rest}  />
    } else if (VIDEO.includes(ext)) {
        return <>
            <video 
                style={{ 
                    maxWidth: '100%', 
                    maxHeight: '100%',
                    objectFit: 'contain',
                }} 
                controls 
                {...rest}
            >
                <source src={src} type={`video/${ext}`} />
            </video>
        </>
    }

    return <p>Not Supported Format to read</p>
}

export default FileManagerMediaReader