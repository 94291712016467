import React, { useState } from "react";
import { Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import HistoryTable from "../../../Components/Users/History/Table";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import Restricted from "./../../../Components/Guard/Restricted";
import { USER_HISTORY_PERMISSIONS } from "./../../../permissions/users";
import { deleteUsersDataAction, getAllUsersDataAction } from "../../../redux/actions/users";
import CustomButton from "../../../Components/Common/CustomButton";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useSearch } from "../../../hooks/useSearch";

const History = () => {

  const dispatch = useDispatch();

  const { callback, itemsToDelete, deleteModal, isDeleting, load, setItemsToDelete, setDeleteModal } = useDeleteMultiple();

  const [loading, setLoading] = useState(true);

  const { history: { list: history, total } } = useSelector((state) => state.users);

  const searchFunc = (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllUsersDataAction({
      field: 'history',
      query,
      onSuccess() {
        setLoading(false);
      }
    }));
  }

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'history',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const handleDelete = () => {
    load();
    dispatch(
      deleteUsersDataAction({
        field: 'history',
        data: {
          ids: itemsToDelete.selectedRows.map((item) => item.id)
        },
        onSuccess() {
          callback();
          search();
        },
      })
    );
  };

  return (
      <PageContainer>

        <BreadCrumb pageTitle="Users" title="History" />

        <div className="hstack gap-2">

            <Restricted permissions={USER_HISTORY_PERMISSIONS.create}>
              <Link to="/dashboard/users/history/create">
                <CustomButton
                  className="btn-success"
                >
                  <i className="ri-add-line align-bottom me-1"></i>
                  Create
                </CustomButton>
              </Link>
            </Restricted>

            <Restricted permissions={USER_HISTORY_PERMISSIONS.delete}>
              <CustomButton
                className="btn-danger"
                disabled={!itemsToDelete?.selectedRows?.length > 0}
                onClick={() => setDeleteModal(true)}
              >
                <i className="ri-add-line align-bottom me-1"></i>
                Delete
              </CustomButton>
            </Restricted>

        </div>
        
        <Card className="mt-3">
            <HistoryTable
                data={history}
                paginationServer
                paginationTotalRows={total}
                navigateToPage={navigateToPage}
                selectableRows
                itemsToDelete={itemsToDelete}
                setItemsToDelete={setItemsToDelete}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                handleDelete={handleDelete}
                filters={searchValues}
                handleFilterChange={handleChange}
                pageSize={searchValues.page_size}
                setPageSize={setPageSize}
                sortDirection={searchValues.order_value}
                fetching={loading}
                isDeleting={isDeleting}
                path="/dashboard/users/history"
            />
        </Card>

      </PageContainer>
  );
};

export default History;
