import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import settingsApi from "../../../apis/settings/api";
import LanguageForm from "../../../Components/Settings/Language/LanguageForm";
import { Container } from "reactstrap";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateLanguage = () => {

  const [initialValues, setInitialValues] = useState(null);
  
  const { code } = useParams();

  const { isLoading } = useQuery(["languages", code], () => settingsApi.languages.getOne(code), {
      onSuccess({data: { data }}) {
        let flag_image = null;
        if (data.flag_image) {
          flag_image = {
            ...data.flag_image,
            path: `${data.flag_image.uri}/${data.flag_image.name}`,
          };
        }
        setInitialValues({
          ...data,
          flag_image,
        });
      },
    }
  );

  return (
    <PageContainer>
      {initialValues && 
        <LanguageForm 
          type="Update" 
          initialValues={initialValues} 
        />
      }
    </PageContainer>
  );
};

export default UpdateLanguage;
