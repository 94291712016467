import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

const CustomButton = ({ isLoading, children, onClick, className, ...rest }) => {

  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={`btn btn-primary ${className}`}
      onClick={onClick}
      disabled={isLoading}
      {...rest}
    >
      {isLoading ? <Spinner size="sm" />  : typeof children === "string" ?  t(children) : children}
    </button>
  );
};

export default CustomButton;
