import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productsApi from "../../../apis/products/api";
import MeasurementUnitForm from "../../../Components/Products/MeasurementUnits/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateUnit = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefeching } = useQuery(["measurement-units", id], () => productsApi.measurementUnits.getOne(id),
    {
      cacheTime: 0,
      onSuccess({data: {data}}) {
        const { products, ...rest } = data;
        setInitialValues(rest);
      }
    }
  );

  return (
    <PageContainer isLoading={isLoading || isRefeching}>
      <BreadCrumb  title="Update" pageTitle="Measurement Units"  to="/dashboard/products/measurement-units" />
      {initialValues && 
      <MeasurementUnitForm 
        type="update" 
        initialValues={initialValues} 
      />}
    </PageContainer>
  );
};

export default UpdateUnit;
