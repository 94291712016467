import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../Common/DataTable";
import CustomInput from "../../Custom/CustomInput";

const VendorsTable = ({
  filters,
  handleFilterChange,
  ...props
}) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Supplier Number")}</span>
      ),
      selector: (row) => row.matricule || "-",
    },
    {
      name:         
      <CustomInput
        className="form-control"
        placeholder="Name"
        value={filters.name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        })}
        data-filtered={!!filters.name}
      />,
      selector: (row) => row.name || "-",
    },
    {
      name: 
      <CustomInput
        className="form-control"
        placeholder="Name"
        value={filters.email}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'email',
          value: e.target.value
        })}
        data-filtered={!!filters.email}
    />,
      selector: (row) => row.email || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Telephone")}</span>,
      selector: (row) => row.phone || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Address")}</span>,
      selector: (row) => row.address || "-",
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default VendorsTable;
