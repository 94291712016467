import React from "react";
import { Col, Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OrderSettingsTabs from "../../../Components/Orders/Settings/Tabs";
import PageContainer from "../../../Components/Layout/PageContainer";

const Settings = () => {

  return (
      <PageContainer>

        <BreadCrumb
          to="/dashboard/orders"
          title="Settings"
          pageTitle="Orders"
        />

        <Col md={12}>
          <Card className="p-3">
            <OrderSettingsTabs />
          </Card>
        </Col>

    </PageContainer>
  );
};

export default Settings;
