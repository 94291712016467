import React, { useEffect } from "react";
import { Row, Col, CardFooter, Button } from "reactstrap";
import Select from "react-select";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import VersionSchema from "../../../helpers/validation/WebSettings/VersionValidation";
import { useDispatch, useSelector } from "react-redux";
import { WEB_SETTINGS_PERMISSIONS } from "./../../../permissions/webSettings";
import Restricted from "./../../Guard/Restricted";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import CustomButton from "../../Common/CustomButton";
import { useTranslation } from "react-i18next";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";
import SaveButton from "../../Common/Buttons/SaveButton";

const Version = () => {

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { version } = useSelector((state) => state.settings.webSettings);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(VersionSchema),
  });

  const submitVersion = (data) => {
    dispatch(
      setWebSettingsAction({
        field: 'version',
        data: {
          type: "version",
          data
        }
      })
    );
  };

  useEffect(() => {
    if (version) {
      reset(version);
    }
  }, [version]);

  useEffect(() => {
    dispatch(getWebSettingsAction({
      field: 'version',
    }));
  }, []);

  return (
    <form onSubmit={handleSubmit(submitVersion)}>
      <input type="submit" hidden />
      <Row>
        <Col lg={6}>
          <CustomLabel htmlFor="Version-number-input" className="form-label">
            Version number
          </CustomLabel>
          <CustomInput
            type="number"
            className="form-control"
            id="Version-number-input"
            {...register("number")}
            step="any"
          />
          {errorMessage(errors, "number")}
        </Col>
        <Col lg={3}>
          <CustomLabel>Active</CustomLabel>
          <Controller
            name="status"
            control={control}
            defaultValue={version.status}
            render={({ name, field: { onChange } }) => (
              <Select
                name={name}
                options={[
                  { value: "Active", label: t("Active") },
                  { value: "Not Active", label: t("Not Active") },
                ]}
                defaultValue={[
                  {
                    value: Number(version.status) ? "Active" : "Not Active",
                    label: t(Number(version.status) ? "Active" : "Not Active"),
                  },
                ]}
                onChange={(value) => {
                  return onChange(value.value);
                }}
              />
            )}
          />
          {errorMessage(errors, "status")}
        </Col>  
        
        <Restricted permissions={WEB_SETTINGS_PERMISSIONS.update}>
            <CardFooter className="mt-3">
              <div className="d-flex justify-content-end">
                  <SaveButton />
              </div>
            </CardFooter>
        </Restricted>

      </Row>
    </form>
  );
};

export default Version;
