import React from "react";
import CustomButton from "../../Common/CustomButton";

const CatalogueButton = ({ onSubmit, icon, color = "primary", ...props }) => {
  return (
    <CustomButton
      type="button"
      className={`btn btn-${color} btn-sm`}
      //   isLoading={loading && submittedProdId === row.id}
      onClick={onSubmit}
      {...props}
    >
      {/* icon */}
      <i className={icon}></i>
    </CustomButton>
  );
};

export default CatalogueButton;
