import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import usersApi from "../../../apis/users/api";
import HistoryForm from "../../../Components/Users/History/Form";
import { useParams } from "react-router-dom";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateHistory = () => {

  const [initialValues, setInitialValues] = useState({});
  
  const { id } = useParams();

  const { isLoading, isRefetching } = useQuery(["history"], () => usersApi.history.getOne(id), {
    onSuccess(data) {
      const { data: historyData } = data.data;
      if (historyData)
        setInitialValues({
          user_id: historyData.client?.id,
          status: historyData.status?.id,
          description: historyData.observation,
          displayUser: {
            value: historyData.client?.id,
            label: `${historyData.client.first_name} ${historyData.client.last_name} `,
          },
        });
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Update" title="Create" to="/dashboard/users/history" />
      {initialValues && (
        <HistoryForm 
          type="update" 
          initialValues={initialValues} 
        />
      )}
    </PageContainer>
  );
};

export default UpdateHistory;
