const imageExtensions = [
    "apng",
    "bmp",
    "gif",
    "ico",
    "cur",
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "png",
    "svg",
    "tif",
    "tiff",
    "webp"
]

export const isImage = (extension) => {
    if (!extension) return false;
    return imageExtensions.includes(extension);
}