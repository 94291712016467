import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Form, FormFeedback } from 'reactstrap';
import ParticlesAuth from "./ParticlesAuth";

//redux
import { useSelector } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import config
import CustomButton from '../../Components/Common/CustomButton';
import settingsApi from '../../apis/settings/api';
import { useAuthState } from '../../context/authContext';

const Login = () => {
    
    const [passwordVisible, setPasswordVisible] = useState(false)

    const { setAdminData, setAccessToken } = useAuthState()

    const validation = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Please provide a valid email").required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values, helpers) => {
            helpers.setSubmitting(true)
            try {
                const { data } = await settingsApi.admins.login(values)
                const { token, email, id, name, roles } = data.data
                setAccessToken(token)
                setAdminData({
                    id, email, name, roles
                })
                localStorage.setItem('dinengrosToken', token)
                localStorage.setItem('dinengrosAdminID', id)
            } catch (error) {
                helpers.setFieldError('password', 'Something went wrong or invalid credentials')
            }
        }
    });


    const togglePassword = () => {
        setPasswordVisible(current => !current);
    }

    const { setup } = useSelector((state) => state.settings.webSettings);
    
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={setup.logo} alt="" height="100" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to {setup?.website_name}.</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form>
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    {/* <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div> */}
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password}
                                                            type={passwordVisible ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button onClick={togglePassword} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <CustomButton
                                                        className="btn btn-success w-100"
                                                        type="button"
                                                        onClick={validation.handleSubmit}
                                                        isLoading={validation.isSubmitting}
                                                    >
                                                        Sign In
                                                    </CustomButton>
                                                </div>

                                               
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);