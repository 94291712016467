import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { isEmpty } from "./../../../helpers/objectFilter";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import { getOrderSettingsAction, setOrderSettingsAction } from "../../../redux/actions/orders";
import SaveButton from "../../Common/Buttons/SaveButton";

const Stripe = () => {

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { stripe } = useSelector((state) => state.orders.settings);

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();

  const onSubmit = (data) => {
    setSubmitting(true);
    dispatch(
      setOrderSettingsAction({
        field: 'stripe',
        type: "order_stripe",
        data: {
          ...data,
        },
        onSuccess: () => setSubmitting(false),
        onError: () => setSubmitting(false),
      })
    );
  };

  useEffect(() => {
    if (stripe) reset(stripe);
  }, [stripe, reset]);

  useEffect(() => {
    dispatch(getOrderSettingsAction({ field: "stripe" }));
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-4">
        <Col md={12}>

          {!isEmpty(stripe) && (

            <Row className="px-3">

              <Col className="form-check form-switch">
                  <input 
                    {...register('stripe_active')} 
                      className="form-check-input fs-16" 
                      type="checkbox" 
                      role="switch" 
                  />
                  <CustomLabel>Activate Stripe</CustomLabel>
              </Col>

              <Col className="form-check form-switch">
                <input 
                    {...register('test_mode_active')} 
                      className="form-check-input fs-16" 
                      type="checkbox" 
                      role="switch" 
                />
                <CustomLabel>Activate Test Mode</CustomLabel>
              </Col>

            </Row>
          )}
        </Col>
        <Col md={6} className="col-lg-push">

          <h5>{t("Dwell")}</h5>

          <div className="d-flex flex-column gap-3">

           <div>
              <CustomLabel>Publishable key</CustomLabel>
              <input
                name="publishable_key"
                {...register("publishable_key")}
                className="form-control"
                defaultValue={stripe.publishable_key}
              />
              {errors.publishable_key && (
                <div className="text-danger mt-2">
                  {t(errors.publishable_key.message)}
                </div>
              )}
           </div>

          <div>
            <CustomLabel>Secret key</CustomLabel>
            <input
              name="secret_key"
              {...register("secret_key")}
              className="form-control"
              defaultValue={stripe.secret_key}
            />
            {errors.secret_key && (
              <div className="text-danger mt-2">
                {t(errors.secret_key.message)}
              </div>
            )}
          </div>

          </div>
        </Col>

        <Col md={12}>

          <h5>{t("Test")}</h5>

          <div className="d-flex flex-column gap-3">

            <div>
              <label>{t("Publishable key")}</label>
              <input
                name="publishable_key_test"
                {...register("publishable_key_test")}
                className="form-control"
                defaultValue={stripe.publishable_key_test}
              />
              {errors.publishable_key_test && (
                <div className="text-danger mt-2">
                  {t(errors.publishable_key_test.message)}
                </div>
              )}
            </div>

            <div>
              <CustomLabel>Secret key</CustomLabel>
                <input
                  name="secret_key_test"
                  {...register("secret_key_test")}
                  className="form-control"
                  defaultValue={stripe.secret_key_test}
                />
                {errors.secret_key_test && (
                  <div className="text-danger mt-2">
                    {t(errors.secret_key_test.message)}
                  </div>
                )}
            </div>
           
           <div>
              <CustomLabel>Currency</CustomLabel>
              <input
                name="currency_test"
                {...register("currency_test")}
                className="form-control"
                defaultValue={stripe.currency_test}
              />
              {errors.currency_test && (
                <div className="text-danger mt-2">
                  {t(errors.currency_test.message)}
                </div>
              )}
           </div>

          </div>
        </Col>

      </Row>

      <div className="hstack gap-2 mt-3">
        <SaveButton 
            isLoading={submitting}
        />
      </div>

    </form>
  );
};

export default Stripe;
