import React, { useState } from "react";
import UserForm from "../../../Components/Users/Users/Form";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import usersApi from "../../../apis/users/api";
import Tracking from "../../../Components/Tracking";
import useTracking from "../../../hooks/useTracking";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateUser = () => {
  
  const [initialValues, setInitialValues] = useState(null);
  
  const { id } = useParams();

  const { trackings, refetch: refetchTracking } = useTracking("user", id);

  const { isLoading, isRefetching } = useQuery(["user"], () => usersApi.users.getOne(id), {
    onSuccess(data) {
      const { data: userData } = data.data;
      setInitialValues({
        ...userData,
        status: userData.is_company
          ? "company"
          : userData.is_supplier
          ? "supplier"
          : "individual",
      });
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>

      <BreadCrumb pageTitle="Users" title="Update" to="/dashboard/users" />

      {initialValues && (
        <UserForm
          type="update"
          initialValues={initialValues}
          refetchTracking={refetchTracking}
        />
      )}

      <Tracking data={trackings || []} />

    </PageContainer>
  );
};

export default UpdateUser;
