import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';
import { useForm } from 'react-hook-form';
import { objectFilter } from '../../../helpers/objectFilter';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../Common/CustomButton';
import { generatePassword } from '../../../helpers/generatePassword';
import { createSettingsDataAction, getAllSettingsDataAction, updateSettingsDataAction } from '../../../redux/actions/settings';
import CustomOption from '../../Custom/CustomOption';

const AdminModal = ({ type, isOpen, toggle, initialValues }) => {

    const { t } = useTranslation();

    const { register, handleSubmit, setValue, reset } = useForm();

    const { roles: { list: roles }} = useSelector(state => state.settings);

    const [showPassword, setShowPassword] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();

    const submitForm = (data) => {
        setIsSubmitting(true);
        const requestBody = objectFilter({
            name: data.name,
            email: data.email,
            password: data.password,
            roles: [data.role]
        });
        if (type === "create") {
            dispatch(
                createSettingsDataAction({
                    field: 'admins',
                    data: requestBody,
                    onSuccess() {
                        setIsSubmitting(false);
                        toggle();
                    },
                    onError: (error) => {
                        setIsSubmitting(false);
                    },
                })
            );
        } else {
            dispatch(
                updateSettingsDataAction({
                    field: 'admins',
                    data: {
                        id: data.id,
                        data: requestBody,
                    },
                    onSuccess() {
                        setIsSubmitting(false);
                        toggle();
                    },
                    onError: (error) => {
                        setIsSubmitting(false);
                    },
                })
            );
        }
    };

    const onClose = () => reset({
        name: '',
        email: '',
        password: '',
        role: ''
    })

    useEffect(() => {
        if (initialValues && isOpen) {
            reset(initialValues);
        }
    }, [initialValues, isOpen])

    useEffect(() => {
        if (isOpen) {
            dispatch(getAllSettingsDataAction({
                field: 'roles',
                query: {
                    page_size: 'all'
                },
              }));
        }
    }, [isOpen])

  return (
    <Modal 
        modalClassName="zoomIn"
        isOpen={isOpen} 
        toggle={toggle} 
        onClosed={onClose}
        centered
    >

    <ModalHeader className="bg-light p-3 text-capitalize" toggle={toggle}>
      {`${t(type)} Admin`}
    </ModalHeader>

    <form onSubmit={handleSubmit(submitForm)}>

      <ModalBody>

        <Row className="gy-3">

          <Col lg={12}>

            <CustomLabel className="form-label">
                Name
            </CustomLabel>

            <CustomInput
              className="form-control"
              {...register("name")}
            />

          </Col>

          <Col lg={12}>

            <CustomLabel className="form-label">
                Email
            </CustomLabel>

            <CustomInput
              className="form-control"
              {...register("email")}
            />

          </Col>

          <Col lg={12}>
            <div className="input-group d-flex flex-column">

              <CustomLabel
                className="form-label"
              >
                Password
              </CustomLabel>

                <div className="d-flex align-items-center gap-2">

                    <CustomInput
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                        {...register("password")}
                    />

                    <button
                        onClick={() => setShowPassword(!showPassword)}
                        className="btn btn-primary"
                        type="button"
                    >
                        <i
                            className={showPassword ? "ri-eye-line" : "ri-eye-off-line"}
                        ></i>
                    </button>

                    <button
                        className="btn btn-outline-primary"
                        type="button"
                        id="button-addon2"
                        onClick={() => setValue('password', generatePassword())}
                    >
                        <i className="ri-key-fill"></i>
                    </button>

                </div>

            </div>

        </Col>

          <Col lg={12}>

            <CustomLabel htmlFor="admin-email-field" className="form-label">
              Role
            </CustomLabel>

            <select {...register('role')} className='form-select'>
                <CustomOption>Select...</CustomOption>
                {roles.map(role => (
                    <CustomOption key={role.id} value={role.name}>{role.name}</CustomOption>
                ))}
            </select>

          </Col>

        </Row>
      </ModalBody>

      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">

          <CustomButton
            type="button"
            className="btn btn-light"
            onClick={toggle}
          >
            Close
          </CustomButton>

          <CustomButton
            type="submit"
            className="btn btn-success"
            isLoading={isSubmitting}
          >
            Save
          </CustomButton>

        </div>
      </ModalFooter>

    </form>

  </Modal>
  )
}

export default AdminModal