import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Row } from "reactstrap";
import usersApi from "../../../../apis/users/api";
import AddDiscountedProductTable from "./AddDiscountedProductTable";
import DiscountedProductsTable from "./DiscountedProductsTable";

const Discounts = ({ type, initialValues, refetchTracking }) => {

  const [discountedProducts, setDiscountedProducts] = useState([]);

  const { isLoading, isRefetching, refetch } = useQuery(["user_discounts"], () => usersApi.users.discounts.getAll({user_id: initialValues.id}), {
    onSuccess({data: {data}}) {
        setDiscountedProducts(data);
    },
  });

  return (
    <Row className="mt-3">
      
        <AddDiscountedProductTable 
          initialValues={initialValues}
          refetch={refetch}
          refetchTracking={refetchTracking}
        />

        <DiscountedProductsTable 
          type={type}
          discountedProducts={discountedProducts}
          isLoading={isLoading || isRefetching}
          refetch={refetch}
          refetchTracking={refetchTracking}
        />
    </Row>
  );
};

export default Discounts;
