import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, CardFooter, Col, Row } from "reactstrap";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import SetupSchema from "../../../helpers/validation/WebSettings/SetupValidation";
import { useDispatch, useSelector } from "react-redux";
import { useFileManager } from "../../../context/FileManagerContext";
import useSetFile from "./../../../hooks/useSetFile";
import SelectFileButton from "../../Common/SelectFileButton";
import { WEB_SETTINGS_PERMISSIONS } from "./../../../permissions/webSettings";
import Restricted from "./../../Guard/Restricted";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import CustomButton from "../../Common/CustomButton";
import { useTranslation } from "react-i18next";
import useSetFileExt from "../../../hooks/useSetFileExt";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";

const Setup = () => {
  // useStates for images
  const [catalogue, setCatalogue] = useState(null);

  const [logo, setLogo] = useState(null);

  const [favIcon, setFavIcon] = useState(null);

  const [productImage, setProductImage] = useState(null);
  // what for images
  const FOR_CATALOGUE = "setupCatalogueImage";
  const FOR_LOGO = "setupLogoImage";
  const FOR_FAV_ICON = "setupFavIcon";
  const FOR_PRODUCT_IMAGE = "setupProductImage";

  const { openFileManagerModal } = useFileManager();

  const { t } = useTranslation();

  useSetFileExt({
    whatFor: FOR_CATALOGUE,
    cb: (file, cb) => {
      if (file.ext !== 'pdf') return
      setCatalogue({
        name: file.name,
        path: file.path,
        id: file.id
      });
      cb();
    },
  });

  useSetFile({
    whatFor: FOR_LOGO,
    cb: (file) => {
      setLogo(file.path);
    },
  });

  useSetFile({
    whatFor: FOR_FAV_ICON,
    cb: (file) => {
      setFavIcon(file.path);
    },
  });

  useSetFile({
    whatFor: FOR_PRODUCT_IMAGE,
    cb: (file) => {
      setProductImage(file.path);
    },
  });

  const dispatch = useDispatch();

  const { setup } = useSelector((state) => state.settings.webSettings);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(SetupSchema),
    defaultValues: setup,
  });

  const submitSetup = (data) => {
    const requestBody = {
      type: "setup",
      data: {
        ...data,
        catalogue: catalogue === null ? setup.catalogue : catalogue,
        logo: logo === null ? setup.logo : logo,
        fav_icon: favIcon === null ? setup.fav_icon : favIcon,
        product_image:
          productImage === null ? setup.product_image : productImage,
      },
    };

    dispatch(setWebSettingsAction({
      field: 'setup',
      data: {
        type: 'setup',
        data: requestBody
      }
    }));
  };

  useEffect(() => {
    if (setup) {
      setCatalogue(setup.catalogue);
      setLogo(setup.logo);
      setFavIcon(setup.fav_icon);
      setProductImage(setup.product_image);
    }
  }, [setup]);

  useEffect(() => {
    if (setup) {
      reset(setup);
    }
  }, [setup]);

  useEffect(() => {
    dispatch(getWebSettingsAction({
      field: 'setup',
    }));
  }, []);

  return (
    <form onSubmit={handleSubmit(submitSetup)}>
      <input type="submit" hidden />
      <Row>
        <Col lg={6}>
          <div>
            <CustomLabel htmlFor="Website-name-input" className="form-label">
              Website Name
            </CustomLabel>

            <CustomInput
              type="text"
              className="form-control"
              id="Website-name-input"
              name="website_name"
              {...register("website_name")}
            />
            {errorMessage(errors, "website_name")}
          </div>
        </Col>

        <Col lg={6}>
          <div>

            <CustomLabel htmlFor="formFile" className="form-label">
              Catalogue
            </CustomLabel>

            {catalogue?.path && (
              <div 
                className="d-flex gap-2 align-items-center mb-2"
                style={{ 
                  padding: '.2rem',
                  border: '1px solid gray',
                  width: 'max-content'
                }}
              >
                  <i className="ri-file-line"></i>
                  {catalogue.name}
                  <i 
                    className="ri-close-line cursor-pointer"
                    onClick={() => setCatalogue({})}
                  ></i>
              </div>
            )}

            <SelectFileButton
              onClick={() => openFileManagerModal(FOR_CATALOGUE)}
              text="Select Catalogue"
            />
            {errorMessage(errors, "catalogue")}
          </div>
        </Col>

        <Row>
          <h4 className="border-bottom my-4 pb-2">{t("Website Logos")}</h4>

          <Col lg={6} className="mb-4">
            <div>
              <CustomLabel htmlFor="formFile" className="form-label">
                Logo
              </CustomLabel>
              <div>
                <SelectFileButton
                  text="Select Logo"
                  onClick={() => openFileManagerModal(FOR_LOGO)}
                />
              </div>
              {errorMessage(errors, "logo")}
            </div>
            {logo && (
              <div style={{ position: "relative", maxWidth: "150px" }}>
                <img className="img-thumbnail mt-4" alt="" src={logo} />
                <i
                  className="ri-delete-bin-fill"
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "5%",
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => setLogo("")}
                ></i>
              </div>
            )}
          </Col>

          <Col lg={6}>
            <div>
              <CustomLabel htmlFor="formFile" className="form-label">
                Fav icon
              </CustomLabel>
              <SelectFileButton
                onClick={() => openFileManagerModal(FOR_FAV_ICON)}
                text="Select fav icon image"
              />
              {errorMessage(errors, "fav_icon")}
            </div>
            {favIcon && (
              <div style={{ position: "relative", maxWidth: "150px" }}>
                <img className="img-thumbnail mt-4" alt="" src={favIcon} />
                <i
                  className="ri-delete-bin-fill"
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "5%",
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => setFavIcon("")}
                ></i>
              </div>
            )}
          </Col>

          <Col lg={6}>
            <div>
              <CustomLabel htmlFor="formFile" className="form-label">
                Product image
              </CustomLabel>
              <SelectFileButton
                text="Select Product Image"
                onClick={() => openFileManagerModal(FOR_PRODUCT_IMAGE)}
              />
              {errorMessage(errors, "product_image")}
            </div>
            {productImage && (
              <div style={{ position: "relative", maxWidth: "150px" }}>
                <img className="img-thumbnail mt-4" src={productImage} />
                <i
                  className="ri-delete-bin-fill"
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "5%",
                    fontSize: "1.1rem",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => setProductImage("")}
                ></i>
              </div>
            )}
          </Col>
        </Row>
        <CardFooter className="px-0 mt-5">
          <div className="d-flex align-items-center gap-2">
            <Link to="/dashboard/">
              <CustomButton type="button" className="btn btn-light">
                Close
              </CustomButton>
            </Link>

            <Restricted permissions={WEB_SETTINGS_PERMISSIONS.update}>
              <Button type="submit" color="success">
                <i className="ri-save-line align-bottom me-1"></i> {t("Save")}
              </Button>
            </Restricted>
          </div>
        </CardFooter>
      </Row>
    </form>
  );
};

export default Setup;
