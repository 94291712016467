import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { useDispatch } from "react-redux";
import createStatusSchema from "../../../helpers/validation/Users/createStatusValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetValues } from "../../../hooks/useCreateUpdateModal";
import capitalizeFirstLetter from "../../../helpers/capitalizeFirstLetter";
import CustomButton from "../../Common/CustomButton";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import { createUsersDataAction, deleteUsersDataAction, updateUsersDataAction } from "../../../redux/actions/users";
import SaveButton from "../../Common/Buttons/SaveButton";

const StatusModal = ({ modal, toggleModal, modalType }) => {
  
  const { t } = useTranslation();

  const { type, currentValues } = modalType;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(createStatusSchema),
  });

  const [isDeleting, setIsDeleting] = useState(false);

  useGetValues({ clearErrors, currentValues, setValue, type });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const { id, ...rest } = data;
    const requestBody = {
      ...rest,
      status: rest.status ? 1 : 0,
    };

    if (type === "create") {
      dispatch(createUsersDataAction({
        field: 'statuses',
        data: requestBody
      }));
    } else {
      dispatch(
        updateUsersDataAction({
          field: 'statuses',
          data: {
            id,
            data: requestBody,
          }
        })
      );
    }
    toggleModal();
  };

  const deleteStatus = () => {
    if (!currentValues.id) return;
    setIsDeleting(true);
    dispatch(
      deleteUsersDataAction({
        field: 'statuses',
        data: {
          ids: [currentValues.id],
        },
        onSuccess: () => {
          setIsDeleting(false);
          toggleModal();
        },
      })
    );
  };

  return (
    <Modal modalClassName="zoomIn" id="showModal" isOpen={modal} toggle={toggleModal} centered>
      <ModalHeader className="bg-light text-capitalize p-3" toggle={toggleModal}>
        {t(`${type}`)}
      </ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="submit" hidden />
        <ModalBody>
          <Row className="g-3">
            <Col lg={12}>
              <div>
                <CustomLabel htmlFor="status_name-field" className="form-label">
                  Name
                </CustomLabel>
                <CustomInput
                  id="status_name-field"
                  className="form-control"
                  placeholder="Enter Status Name"
                  type="text"
                  {...register("name")}
                />
                {errorMessage(errors, "name")}
              </div>
            </Col>
            <Col lg={12}>
               <div className="form-check form-switch">
                  <input 
                    {...register('status')} 
                    className="form-check-input fs-16" 
                    type="checkbox" 
                    role="switch" 
                    id="status" 
                  />
                  <CustomLabel 
                    className="form-check-label" 
                    htmlFor="status"
                  >
                    Active
                  </CustomLabel>
                </div>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>

          <div className="hstack gap-2 justify-content-end">

            <CustomButton
              type="button"
              className="btn btn-light"
              onClick={toggleModal}
            >
              Close
            </CustomButton>

            {type !== "create" && (
              <CustomButton
                type="button"
                className="btn btn-danger"
                onClick={deleteStatus}
                isLoading={isDeleting}
              >
                Delete
              </CustomButton>
            )}
            
            <SaveButton />

          </div>

        </ModalFooter>

      </Form>
    </Modal>
  );
};

export default StatusModal;
