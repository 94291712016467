import React from "react";
import DataTable from "../../Common/DataTable";
import GroupsModal from "./Modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomInput from "../../Custom/CustomInput";

const GroupsTable = ({
  filters,
  handleFilterChange,
  update,
  setGroupsModal,
  modalType,
  groupsModal,
  toggleGroupsModal,
  ...props
}) => {

  const { t } = useTranslation();

  const openEditModal = (data) => {
    update({
      id: data.id,
      name: data.name,
      code: data.code,
      description: data.description,
    });
    setGroupsModal(true);
  };

  const handleClick = (row) => openEditModal(row);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },

    {
      name:           
      <CustomInput
        className="form-control"
        placeholder="Name"
        value={filters.name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        })}
        data-filtered={!!filters.name}
      />,
      selector: row => row.name,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Code")}</span>,
      selector: row => row.code || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Created at")}</span>,
      selector: row => row.created_at ? moment(row.created_at).format("D MMM, YYYY") : "-",
    },

  ];

  return (
    <React.Fragment>

      <GroupsModal
        modalType={modalType}
        modal={groupsModal}
        toggleModal={toggleGroupsModal}
      />

      <DataTable
        columns={columns}
        handleClick={handleClick}
        {...props}
      />
      
    </React.Fragment>
  );
};

export default GroupsTable;
