import React from 'react'
import { useTranslation } from 'react-i18next';
import BootstrapTable from '../../Common/BootstrapTable';

const OfferProductsTable = ({offerProducts, setProductToUpdate, toggleModal, removeProduct}) => {

  const { t } = useTranslation();

  const columns = [
        {
          name: <span className="font-weight-bold fs-13">{t("Product")}</span>,
          selector: (row) => row.product_name,
          style: {
            width: '95%'
          }
        },
        {
          name:<span className="font-weight-bold fs-13">{t("Actions")}</span>,
          selector: row => 
          <div className="d-flex gap-2">

            <button 
              type="button" 
              className='btn btn-success btn-sm'            
              onClick={() => {
                  setProductToUpdate(row.product_id);
                  toggleModal();
              }}
            >
              <i className="ri-pencil-line fs-16 cursor-pointer"></i>
            </button>

            <button 
                type="button" 
                className='btn btn-danger btn-sm'                 
                onClick={() => removeProduct(row.id)}
            >
              <i className="ri-close-line fs-16 cursor-pointer"></i>
            </button>

          </div>
        },
    ];

  return (
      <BootstrapTable 
        data={offerProducts}
        columns={columns}
        enableSelection
        extraRows={[
          <tr key={1}>
            <td className="d-flex justify-content-center align-items-center">
              <button 
                type="button" 
                className="btn btn-success btn-sm mx-2"
                onClick={toggleModal}
              >
                  <i className="ri-add-line fs-16"></i>
              </button>
            </td>
          </tr>
        ]}
    />
  )
}

export default OfferProductsTable