import * as yup from "yup";

const VersionSchema = yup.object().shape({
  number: yup.number().typeError("Please specify a version number"),
  status: yup
    .string()
    .required("Please choose if it is active or not"),
});

export default VersionSchema;
