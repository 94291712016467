import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, Card } from "reactstrap";

const NewsletterDetails = ({ newsletter }) => {

  const { t } = useTranslation();

  return (
    <Card>
  
      <CardHeader className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0">{t("Details")}</h5>
        <div className="d-flex gap-2">
        </div>
      </CardHeader>

      <CardBody className="d-flex gap-1 flex-wrap">
        <h6 className="text-muted">{t("Group")}:</h6>
        <h6 className="fs-13 mb-0">{newsletter.user_group?.name}</h6>
      </CardBody>

    </Card>
  );
};

export default NewsletterDetails;
