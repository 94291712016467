import React from 'react'
import LocationForm from '../../../Components/Products/Location/Form'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'


const CreateLocation = () => {

  return (
      <PageContainer> 
         <BreadCrumb  title="Create" pageTitle="Locations" to="/dashboard/products/locations" />
          <LocationForm 
            type="create"
          />
      </PageContainer>
  )
}

export default CreateLocation