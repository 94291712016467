import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LanguagesTable from "../../../Components/Settings/Language/LanguagesTable";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomButton from "../../../Components/Common/CustomButton";
import { useTranslation } from "react-i18next";
import PageContainer from "../../../Components/Layout/PageContainer";

const handleSearch = (data, query) => {
  const copied = [...data];
  if (!query) return data;
  return copied.filter((item) => item.name.toLowerCase().includes(query));
};

const LanguagesSettings = () => {
  
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");

  const { t } = useTranslation();

  const { languages: { list: languages } } = useSelector((state) => state.settings);

  const searchedData = handleSearch(languages, searchQuery);

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Settings" title="Languages" />
        <Card>
          <CardBody>
            <form>
              <Input type="hidden" id="id-field" />
              <Row className="g-3">
                <Col lg={6}>
                  <div className="mb-3">
                    <CustomLabel
                      htmlFor="choices-single-default"
                      className="form-label text-muted"
                    >
                      Search
                    </CustomLabel>
                    <CustomInput
                      className="form-control"
                      placeholder="ex: English..."
                      value={searchQuery}
                      onChange={(e) =>
                        setSearchQuery(e.target.value.toLowerCase())
                      }
                    />
                  </div>
                </Col>
                <Col
                  lg={6}
                  className="d-flex align-items-end justify-content-end"
                >
                  <div className="mb-3">
                    <Link to="/dashboard/web-settings/languages/create">
                      <CustomButton className="btn btn-success">
                        Add
                      </CustomButton>
                    </Link>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>{t("Languages")}</CardHeader>
          <CardBody>
            <div>
              <LanguagesTable data={searchedData} />
            </div>
          </CardBody>
        </Card>
    </PageContainer>
  );
};

export default LanguagesSettings;
