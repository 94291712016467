const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "orders",
  initialState: {

    orders: {
      list: [],
      total: 0
    },

    priceOffers: {
      list: [],
      total: 0
    },

    newsletters: {
      list: [],
      total: 0
    },

    statuses: {
        list: [],
        total: 0
    },

    deliveryMethods: {
      list: [],
      total: 0
    },

    units: {
        list: [],
        total: 0
    },
 
    transports: {
        list: [],
        total: 0
    },

    settings: {
      active: {},
      stripe: {},
    },
  },
  reducers: {
    setData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          list: payload.data,
          total: payload.total,
        };
      }
    },

    createData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: [...state[payload.field].list, payload.data],
        };
      }
    },

    updateData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: state[payload.field].list.map((item) =>
            item.id === payload.id ? payload.data : item
          ),
        };
      }
    },

    deleteData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          total: state[payload.field].total - payload.data.ids.length,
          list: state[payload.field].list.filter(
            (item) => !payload.data.ids.includes(item.id)
          ),
        };
      }
    },

    setSettings: (state, { payload }) => {
      if (state.settings[payload.field]) {
        state.settings[payload.field] = payload.data;
      }
    },
  },
});

export const ordersActions = slice.actions;

export default slice.reducer;
