import React from 'react'
import OrderUnitForm from '../../../Components/Orders/Units/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateOrderUnit = () => {

  return (
      <PageContainer> 
          <BreadCrumb title="Create" pageTitle="Units" to="/dashboard/orders/units" />
          <OrderUnitForm 
            type='create'
          />
      </PageContainer>
  )
}

export default CreateOrderUnit