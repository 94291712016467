import { useState } from "react";

export default function useLoading() {
  const [isLoading, setIsLoading] = useState(false);

  const onLoading = () => setIsLoading(true);
  const onStopLoading = () => setIsLoading(false);

  return {
    isLoading,
    onLoading,
    onStopLoading,
  };
}
