import React from "react";
import LanguageForm from "../../../Components/Settings/Language/LanguageForm";

const CreateLanguage = () => {
  return (
      <LanguageForm type="Create" />
  );
};

export default CreateLanguage;
