import React, { useState } from "react";
import DataTable from "../../Common/DataTable";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import StatusToggle from "../../Common/StatusToggle";

const SlidersTable = ({
  
  itemsToDelete,
  setItemsToDelete,
  deleteModal,
  setDeleteModal,
  onDeleteMultipleClick,
  isDeleting,

}) => {
  
  const { t } = useTranslation();

  const { homeSliders: { list: homeSliders } } = useSelector((state) => state.settings);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: row => row.name,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Active")}</span>,
      selector: row => <StatusToggle type="sliders" id={row.id} field="status" status={row.status} />,
    },
  ];

  return (
      <DataTable
        itemsToDelete={itemsToDelete}
        setItemsToDelete={setItemsToDelete}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDelete={onDeleteMultipleClick}
        isDeleting={isDeleting}
        data={homeSliders}
        columns={columns}
        path="home-sliders"
        selectableRows
      />
  );
};

export default SlidersTable;
