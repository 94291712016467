import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import RevenueCharts from "./Chart";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { useSelector } from "react-redux";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import CustomOption from "../../Custom/CustomOption";
import moment from 'moment';

const formatters = {
  Revenue: [
    {
      formatter: function (y) {
        return numeral(y).format('0.00a')
      },
    },
    {
      formatter: function (y) {
        return numeral(y).format('0.00a')
      },
    },
    {
      formatter: function (y) {
        return numeral(y).format('0.00a')
      },
    },
    {
      formatter: function (y) {
        return numeral(y).format('0.00a')
      },
    },
  ],

  Orders: [
    {
      formatter: function (y) {
        return numeral(y).format('0,')
      },
    },
    {
      formatter: function (y) {
        return numeral(y).format('0,')
      },
    },
    {
      formatter: function (y) {
        return numeral(y).format('0,')
      },
    },
  ],

}

const getYears = () => {
  // Define the starting year
  const startYear = 2019; // Change this to your desired starting year

  // Get the current year
  const currentYear = moment().year();

  // Create an array of years using Array.from and moment.js
  const yearsArray = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
  return yearsArray;
}

const Revenue = ({ data }) => {

  const [chartData, setChartData] = useState(null);

  const [allStatistics, setAllStatistics] = useState(null);

  const { paymentSettings } = useSelector(state => state.settings);

  const { t } = useTranslation();

  const {values: {
    statisticsFilter: localFilterValues
}, setNewValues: setLocalFilterValues} = useLocalStorage({
    statisticsFilter: {
      year: new Date().getFullYear(),
      month: '',
      week: '',
    }
})

  const isSelected = (key) => {
    return chartData.key === key
  }

  // useQuery(['statistics', () => console.log('local filter values', localFilterValues)])

  const formattingFunction = useCallback((value) => {
    return `${numeral(value).format('0.00a')} ${paymentSettings?.settings?.currency || 'Nok'}`
  }, [data])

  const handleFilter = (e) => {
    setLocalFilterValues({
      statisticsFilter: {
        [e.target.name]: e.target.value
      }
    })
  }

  useEffect(() => {
    if (data) {
      setAllStatistics({
        Orders: [
          {
            name: t('Total Orders'),
            type: 'bar',
            data: data.stats.map(item => item.orders).reverse(),
          },
          {
            name: t('Delivered'),
            type: 'area',
            data: data.stats.map(item => item.orders_finished).reverse(),
          },
          {
            name: t('Canceled'),
            type: 'line',
            data: data.stats.map(item => item.orders_canceled).reverse(),
          },
        ],
        // Revenue: [
        //   {
        //     name: t('Total Revenue'),
        //     type: 'bar',
        //     data: data.stats.map(item => item.total_income).reverse(),
        //   },
        //   {
        //     name: t('Earnings'),
        //     type: 'area',
        //     data: data.stats.map(item => item.total_finished).reverse(),
        //   },
        //   {
        //     name: t('Refunds'),
        //     type: 'line',
        //     data: data.stats.map(item => item.total_refund).reverse(),
        //   },
        //   {
        //     name: t('Taxes'),
        //     type: 'line',
        //     data: data.stats.map(item => item.taxes).reverse(),
        //   },
        // ]
      })
    }
  }, [data])

  useEffect(() => {
    if (allStatistics) {
      setChartData({
        key: 'Orders',
        data: allStatistics.Orders
      })
    }
  }, [allStatistics])

  return (
    <React.Fragment>
      <Card>

        <CardHeader className="border-0 align-items-center d-flex">
          
          <h4 className="card-title mb-0 flex-grow-1">{t('Statistics')}</h4>
          
          <div className="d-flex gap-1">
{/* 
            <div>
              <select 
                className="form-select"               
                name="year"
                value={localFilterValues.year}
                onChange={handleFilter}
                >
                  <CustomOption>Year</CustomOption>
                  {getYears().map((month, index) => (
                    <CustomOption key={index} value={index + 1}>{t(month)}</CustomOption>
                  ))}
              </select>
            </div>

            <div>
              <select 
                className="form-select"               
                name="month"
                value={localFilterValues.month}
                onChange={handleFilter}
                >
                  <CustomOption>Month</CustomOption>
                  {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
                    <CustomOption key={index} value={index + 1}>{t(month)}</CustomOption>
                  ))}
              </select>
            </div>

            <div>
              <select 
                className="form-select"               
                name="week"
                value={localFilterValues.week}
                onChange={handleFilter}
                >
                  <CustomOption>Week</CustomOption>
                  {[1, 2, 3, 4].map((week) => (
                    <CustomOption key={week} value={week}>{week}</CustomOption>
                  ))}
              </select>
            </div> */}

          </div>
        </CardHeader>

        <CardHeader className="p-0 border-0 bg-soft-light">

          <Row className="g-0 text-center">
            <Col>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp start={0} end={data.total_orders} duration={3} separator="," />
                </h5>
                <p className="text-muted mb-0">{t('Orders')}</p>
              </div>
            </Col>

            {/* <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  <h5 className="mb-1">
                    <CountUp
                      start={0}
                      decimals={2}
                      separator=","
                      end={data.total_earning}
                      formattingFn={formattingFunction}
                      duration={3}
                    />
                  </h5>
                </div>
                <p className="text-muted mb-0">{t("Earnings")}</p>
              </div>
            </Col> */}

            <Col>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp start={0} end={data.total_orders_finished} duration={3} />
                </h5>
                <p className="text-muted mb-0">{t('Orders Delivered')}</p>
              </div>
            </Col>

            <Col>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp start={0} end={data.total_orders_cancel} duration={3} />
                </h5>
                <p className="text-muted mb-0">{t('Cancels')}</p>
              </div>
            </Col>

          </Row>

          {(allStatistics && chartData) &&
            <div className="d-flex justify-content-center gap-2">
                  {Object.keys(allStatistics).map((key, index) => (
                    <div 
                      key={index} 
                      className={`border p-2 mt-3 cursor-pointer ${isSelected(key) ? 'bg-primary text-white' : ''}`} 
                      onClick={() => setChartData({
                        key,
                        data: allStatistics[key]
                      })}
                    >
                        {t(key)}
                    </div>
                  ))}
            </div>
          }

        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div dir="ltr">
              {!!chartData &&
                <RevenueCharts 
                  data={chartData.data} 
                  months={data.stats.map(item => item.month).reverse()}
                  formatters={formatters[chartData.key]}
                />
              }
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Revenue;
