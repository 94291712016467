import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import { errorMessage } from "./../../../../helpers/validation/errorMessage";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userProfileBody } from "../../../../helpers/RequestBodies/Users/usersRequestBody";
import { useGetValues } from "../../../../hooks/useCreateUpdateModal";
import { userProfileSchema } from "../../../../helpers/validation/Users/createUser";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { setFormErrors } from "../../../../helpers/setFormErrors";
import CustomButton from "../../../Common/CustomButton";
import CustomLabel from "../../../Custom/CustomLabel";
import CustomOption from "../../../Custom/CustomOption";
import CustomInput from "../../../Custom/CustomInput";
import { t } from "i18next";
import usersApi from "../../../../apis/users/api";
import toastAlert from "../../../../helpers/toastAlert";
import { getAllUsersDataAction } from "../../../../redux/actions/users";
import SaveButton from "../../../Common/Buttons/SaveButton";

const Profile = ({ type, initialValues, refetchTracking }) => {
  
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(userProfileSchema),
    defaultValues: {
      ...initialValues,
      region_id: initialValues?.region?.id,
      group_id: initialValues?.user_group?.id,
    },
  });

  useGetValues({ currentValues: initialValues, setValue, clearErrors });

  const { groups: { list: groups }, zones: { list: zones } } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const { id } = useParams();

  const onSubmit = async (data) => {
    setLoading(true);
   try {
     const requestBody = userProfileBody(data);
      if (!initialValues) {
        await usersApi.users.profile.create({ ...requestBody, user_id: id });
      } else {
        await usersApi.users.profile.update(initialValues.id, { ...requestBody, user_id: id });
      }
      await refetchTracking();
      toastAlert({ type: 'success' });
   } catch (error) {
      setFormErrors(error.data?.error, setError);
      toastAlert({ type: 'error' })
   } finally {
      setLoading(false);
   }
  };

  useEffect(() => {
    dispatch(getAllUsersDataAction({
      field: 'groups'
    }));
    dispatch(getAllUsersDataAction({
      field: 'zones'
    }));
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-3">
        <Col lg={6}>
          <div className="mb-3">
            <CustomLabel className="form-label" htmlFor="create-userZone-input">
              Zone
            </CustomLabel>
            {zones.length > 0 && (
              <select
                {...register("region_id")}
                name="region_id"
                className="form-select"
              >
                <CustomOption value="">Select Zone</CustomOption>
                {zones.map((zone) => (
                  <CustomOption key={zone.id} value={zone.id}>
                    {zone.name}
                  </CustomOption>
                ))}
              </select>
            )}
            {errorMessage(errors, "region_id")}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <CustomLabel
              className="form-label"
              htmlFor="create-userGroups-input"
            >
              Groups
            </CustomLabel>
            {groups.length > 0 && (
              <select
                {...register("group_id")}
                name="group_id"
                className="form-select"
              >
                {groups.map((group) => {
                  return (
                    <CustomOption key={group.id} value={group.id}>
                      {group.name}
                    </CustomOption>
                  );
                })}
              </select>
            )}
            {errorMessage(errors, "group_id")}
          </div>
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel className="form-label" htmlFor="create-user-phone-input">
            Phone Number
          </CustomLabel>
          <CustomInput
            type="number"
            className="form-control"
            placeholder="Enter phone number"
            id="create-user-phone-input"
            name="phone"
            {...register("phone")}
            step="any"
          />
          {errorMessage(errors, "phone")}
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel
            className="form-label"
            htmlFor="create-user-company-name-input"
          >
            Company name
          </CustomLabel>
          <CustomInput
            type="text"
            className="form-control"
            placeholder="Enter Company Name"
            id="create-user-company-name-input"
            name="company"
            {...register("company")}
          />
          {errorMessage(errors, "company")}
        </Col>

        <Col className="mb-3" lg={6}>
          <CustomLabel
            className="form-label"
            htmlFor="create-company-number-input"
          >
            Company number
          </CustomLabel>
          <CustomInput
            type="number"
            className="form-control"
            placeholder="Enter Company Number"
            id="create-company-number-input"
            name="company_number"
            {...register("company_number")}
            step="any"
          />
          {errorMessage(errors, "company_number")}
        </Col>

        <Col className="mb-3" lg={6}>

          <CustomLabel
            className="form-label"
            htmlFor="create-user-matricule-input"
          >
            Matricule
          </CustomLabel>

          <CustomInput
            type="number"
            className="form-control"
            placeholder="Enter matricule"
            id="create-user-matricule-input"
            name="matricule"
            {...register("matricule")}
            step="any"
          />
          {errorMessage(errors, "matricule")}
          
        </Col>

        <div className="d-flex justify-content-end">
          <SaveButton 
            isLoading={loading}
          />
        </div>

      </Row>
    </form>
  );
};

export default Profile;
