import { useEffect } from "react";
import { useFileManager } from "../context/FileManagerContext";

export default function useSetFileExt({whatFor, cb}) {

    const { selectedFile, selectedFor, closeFileManagerModal, selectFile } = useFileManager();

    useEffect(() => {
        if (!selectedFile || !selectedFor) return;
        if (selectedFor !== whatFor) return;


        cb(
            {
            ...selectedFile,
            path: `${selectedFile.uri}/${selectedFile.name}`
            },

            () => {
                closeFileManagerModal()
                selectFile(null) 
            }
        )

    
        
    }, [selectedFile, selectedFor, whatFor])
}