import React from 'react'
import { useState } from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import Select from "react-select";
import { useQuery } from '@tanstack/react-query';
import usersApi from '../../../apis/users/api';
import { useEffect } from 'react';
import CustomButton from '../../Common/CustomButton';
import toastAlert from '../../../helpers/toastAlert';
import ordersApi from '../../../apis/orders/api';
import { useHistory } from 'react-router-dom';
import CustomLabel from '../../Custom/CustomLabel';
import { useTranslation } from 'react-i18next';

const CopyPriceOfferModal = ({ isOpen, setIsOpen, priceOfferId }) => {

    const { t } = useTranslation();

    const [selectedCompany, setSelectedCompany] = useState({
        value: "",
        label: ""
    })

    const history = useHistory();

    const toggle = () => setIsOpen((current) => !current)

    const [loading, setLoading] = useState(false);

    const [companies, setCompanies] = useState([]);

    const { isLoading: loadingCompanies, isRefetching: refetchingComapnies, refetch: refetchCompanies } = useQuery(['companies'], () => usersApi.users.getAll({
        page_size: 'all',
        for: 'order'
    }), {
        enabled: false,
        onSuccess({ data: { data }}) {
            setCompanies(data)
        }
    })

    const handleSubmit = async () => {
        setLoading(true);
        const {data: {data: priceOfferData}} = await ordersApi.priceOffers.copy({
            offer_price_id: priceOfferId,
            user_id : selectedCompany.value
        })
        setLoading(false);
        toastAlert({ type: 'success' });
        setIsOpen(false)
        history.push(`/dashboard/orders/price-offers/update/${priceOfferData.id}`);
    }

    const onClosed = () => {
        setSelectedCompany({
            value: "",
            label: ""
        })
    }

    useEffect(() => {
        if (isOpen) {
            refetchCompanies();
        }
    }, [isOpen])

    return (
        <Modal 
            modalClassName="zoomIn"
            isOpen={isOpen} 
            toggle={toggle}
            centered
            onClosed={onClosed}
        >
            <ModalHeader className="bg-light text-capitalize p-3" toggle={toggle}>
                {t('Copy Price Offer')}
            </ModalHeader>

            <ModalBody>
                <Row>

                    <Col lg={12}>
                        <CustomLabel>Company</CustomLabel>
                        <Select 
                            value={selectedCompany.value && selectedCompany}
                            placeholder="Search"
                            onChange={(value) =>  setSelectedCompany(value)}
                            options={companies.map(company => ({
                                value: company.id,
                                label: company.name
                            }))}
                            isLoading={loadingCompanies || refetchingComapnies}
                            isDisabled={loadingCompanies || refetchingComapnies}
                        />
                    </Col>

                    <Col lg={12}>
                        <CustomButton
                            className="btn btn-success mt-2"
                            onClick={handleSubmit}
                            isLoading={loading}
                        >
                            Save
                        </CustomButton>
                    </Col>

                </Row>
            </ModalBody>
        </Modal>
    )
}

export default CopyPriceOfferModal