import React, { useState } from "react";
import {
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import Footer from "./Footer";
import SocialMedia from "./SocialMedia";
import Setup from "./Setup";
import Consult from "./Consult";
import About from "./About";
import Policy from "./Policy";
import Seo from "./Seo";
import Version from "./Version";
import CustomNavLink from "../../Custom/CustomNavLink";

const tabs = [
  {
    label: 'Footer',
    component: Footer
  },
  {
    label: 'Social Media',
    component: SocialMedia
  },
  {
    label: 'Setup',
    component: Setup
  },
  {
    label: 'Consult',
    component: Consult
  },
  {
    label: 'About',
    component: About
  },
  {
    label: 'Policy',
    component: Policy
  },
  {
    label: 'SEO',
    component: Seo
  },
  {
    label: 'Version',
    component: Version
  },
]

const WebSettingsTabs = () => {

  const [activeTab, setActiveTab] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  };

  return (
      <Card>

        <CardHeader>
          <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">

            {tabs.map((tab, index) => (
              <NavItem key={index}>
                <CustomNavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === index,
                  })}
                  onClick={() =>  toggle(index)}
                >
                  {tab.label}
                </CustomNavLink>
              </NavItem>
            ))}

          </Nav>
        </CardHeader>

        <CardBody>
          <TabContent activeTab={activeTab}>
            {tabs.map((tab, index) => (
              <TabPane key={index} tabId={index}>
                <tab.component />
              </TabPane>
            ))}
          </TabContent>
        </CardBody>

      </Card>
  );
};

export default WebSettingsTabs;
