export const ORDER_PERMISSIONS = {
    read: ['orders-read'],
    update: ['orders-update'],
    delete: ['orders-delete'],
    create: ['orders-create'],
}

export const ORDER_PRICE_OFFERS_PERMISSIONS = {
    read: ['orders_price_offers-read'],
    update: ['orders_price_offers-update'],
    delete: ['orders_price_offers-delete'],
    create: ['orders_price_offers-create'],
}

export const ORDER_NEWSLETTERS_PERMISSIONS = {
    read: ['orders_newsletters-read'],
    update: ['orders_newsletters-update'],
    delete: ['orders_newsletters-delete'],
    create: ['orders_newsletters-create'],
}

export const ORDER_STATUS_PERMISSIONS = {
    read: ['orders_status-read'],
    update: ['orders_status-update'],
    delete: ['orders_status-delete'],
    create: ['orders_status-create'],
}

export const ORDER_DELIVERY_PERMISSIONS = {
    read: ['orders_delivery-read'],
    update: ['orders_delivery-update'],
    delete: ['orders_delivery-delete'],
    create: ['orders_delivery-create'],
}

export const ORDER_DELIVERY_TYPES_PERMISSIONS = {
    read: ['orders_delivery_types-read'],
    update: ['orders_delivery_types-update'],
    delete: ['orders_delivery_types-delete'],
    create: ['orders_delivery_types-create'],
}

export const ORDER_UNITS_PERMISSIONS = {
    read: ['orders_units-read'],
    update: ['orders_units-update'],
    delete: ['orders_units-delete'],
    create: ['orders_units-create'],
}

export const ORDER_TRANSPORT_PERMISSIONS = {
    read: ['orders_transport-read'],
    update: ['orders_transport-update'],
    delete: ['orders_transport-delete'],
    create: ['orders_transport-create'],
}

export const ORDER_SETTINGS_PERMISSIONS = {
    read: ['orders_settings-read'],
    update: ['orders_settings-update'],
}