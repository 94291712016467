import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import usersApi from "../../../apis/users/api";
import CustomButton from "../../Common/CustomButton";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import { createOrdersDataAction, updateOrdersDataAction } from "../../../redux/actions/orders";
import SaveButton from "../../Common/Buttons/SaveButton";
import { useHistory } from "react-router-dom";

const NewPriceOfferModal = ({ modalOpen, modalType, toggleModal }) => {

  const { type, currentValues } = modalType;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const history = useHistory();

  const [companies, setCompanies] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [note, setNote] = useState("");

  const { isLoading: companiesLoading, isRefetching: companiesRefetching, refetch: refetchCompanies } = useQuery(["companies"], () => usersApi.users.getAll({
    page_size: 'all',
    for: 'order'
  }), {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess({data: {data}}) {
        setCompanies(data);
     }
  });

  const onSubmit = () => {
    setIsSubmitting(true);
    const requestBody = {
      user_id: selectedCompany.value,
      note,
    };
    if (type === "create") {
      dispatch(
        createOrdersDataAction({
          field: 'priceOffers',
          data: requestBody,
          onSuccess: ({ id }) => history.push(`/dashboard/orders/price-offers/update/${id}`),
          onError: () => {
            setIsSubmitting(false);
          },
        })
      );
    } else {
      dispatch(
        updateOrdersDataAction({
          field: 'priceOffers',
          data: {
            id: currentValues.id,
            data: requestBody,
          },
          onSuccess: () => {
            setIsSubmitting(false);
            toggleModal();
            setNote("");
          },
          onError: () => {
            setIsSubmitting(false);
          },
        })
      );
    }
  };

  const onClosed = () => {
    setSelectedCompany(null);
  }

  useEffect(() => {
    if (type === 'update') {
      setSelectedCompany(currentValues.company);
      setNote(currentValues.note);
    }
  }, [type, currentValues]);

  
  useEffect(() => {
    if (modalOpen) {
      refetchCompanies();
    }
  }, [modalOpen]);

  return (
    <Modal
      modalClassName="zoomIn"
      id="signupModals"
      tabIndex="-1"
      isOpen={modalOpen}
      toggle={toggleModal}
      onClosed={onClosed}
      centered
    >
      <ModalHeader className="bg-light text-capitalize p-3" toggle={toggleModal}>
        {t(`${type} price offer`)}
      </ModalHeader>
      <ModalBody>
        <form>

          <div className="mb-3 mt-3">
            <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
              Company Name
            </CustomLabel>
            <Select
              value={selectedCompany && selectedCompany}
              placeholder={t("Search")}
              onChange={(value) => setSelectedCompany(value)}
              options={companies.map((user) => ({
                value: user.id,
                label: user.name,
              }))
              }
              isLoading={(companiesLoading || companiesRefetching) && type !== 'update'}
              isDisabled={type === "update" || companiesLoading || companiesRefetching}
            />
          </div>

          <div className="mb-3 mt-3">
            <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
              Note
            </CustomLabel>
            <textarea
              className="form-control"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>

        </form>

        <div className="d-flex gap-2 justify-content-end">

          <CustomButton 
            className="btn-light"
            onClick={toggleModal} 
          >
              Cancel
          </CustomButton>

          <SaveButton
            type="button"
            onClick={onSubmit}
            isLoading={isSubmitting}
          />

        </div>
        
      </ModalBody>
    </Modal>
  );
};

export default NewPriceOfferModal;
