import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, Card } from "reactstrap";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import NewOrderModal from "../Orders/CreateOrder/Modal";
import { Link } from "react-router-dom";

const PriceOfferDetails = ({ priceOffer, refetchPriceOffer }) => {

  const { modalType } = useCreateUpdateModal();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen((prev) => !prev);

  const { t } = useTranslation();

  return (
    <Card>
      <NewOrderModal
        refetchPriceOffer={refetchPriceOffer}
        modalOpen={modalOpen}
        modalType={modalType}
        toggleModal={toggleModal}
      />
      <CardHeader className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0">{t("Details")}</h5>
        <div className="d-flex gap-2">
        </div>
      </CardHeader>

      <CardBody className="d-flex gap-1 flex-wrap">
        <div className="border border-dashed py-2 px-3">
          <h6 className="text-muted">{t("Company Name")}:</h6>
          <Link className="fs-14 mb-0 fw-bold text-dark cursor-pointer" to={`/dashboard/users/update/${priceOffer.user?.id}`}>
            {priceOffer?.user?.profile?.company || `${priceOffer.user.first_name} ${priceOffer.user.last_name}`}
          </Link>
        </div>

        {priceOffer.user?.profile?.user_group?.name && (
          <div className="border border-dashed py-2 px-3">
            <h6 className="text-muted">{t("Group")}:</h6>
            <h6 className="fs-13 mb-0">
              {priceOffer.user?.profile?.user_group?.name}
            </h6>
          </div>
        )}
      </CardBody>
      
    </Card>
  );
};

export default PriceOfferDetails;
