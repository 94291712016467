import React from "react";
import DataTable from "../../Common/DataTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
import productsApi from "../../../apis/products/api";
import toastAlert from "../../../helpers/toastAlert";
import CustomInput from "../../Custom/CustomInput";
import StatusToggle from "../../Common/StatusToggle";

const OffersTable = ({
  filters,
  handleFilterChange,
  ...props
}) => {

  const { t } = useTranslation();

  const sendOfferToMail = async (id) => {
    try {
      await productsApi.offers.sendToMail(id);
      toastAlert({ type: 'success' });
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      name:  
      <CustomInput
        className="form-control"
        placeholder="Title"
        value={filters.title}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'title',
          value: e.target.value
        })}
        data-filtered={!!filters.title}
      />,
      selector: row => row.title || '-',
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Start date")}</span>,
      selector: row =>
        row.start_at ? moment(row.start_at).format("D MMM, YYYY") : "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("End Date")}</span>,
      selector: row => row.end_at ? moment(row.end_at).format("D MMM, YYYY") : "-",

    },
    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: row => <StatusToggle type="offers" id={row.id} field="status" status={row.status} />,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Actions")}</span>,
      selector: row => 
      <i 
        className="ri-mail-send-fill cursor-pointer fs-16"
        onClick={() => sendOfferToMail(row.id)}
      ></i>
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default OffersTable;
