import { useEffect, useState } from "react"

export default function useCreateUpdateModal(initValues) {

    const [isOpen, setIsOpen] = useState(false);

    const [modalType, setModalType] = useState({
        type: 'create',
        currentValues: initValues
    })

    const create = (values) => {
        setIsOpen(true);
        setModalType({
            type: 'create',
            currentValues: values || initValues
        })
    }

    const update = (values) => {
        setIsOpen(true);
        setModalType({
            type: 'update',
            currentValues: values
        })
    }

    const toggleModal = () => setIsOpen(!isOpen);

    return { modalType, create, update, toggleModal, isOpen }

}

export const useGetValues = ({currentValues, type, clearErrors, setValue}) => {
    useEffect(() => {
        clearErrors()
        if (currentValues) {
            Object.entries(currentValues).forEach(([key, val]) => {
                setValue(key, val)
            })
        }
    }, [type, currentValues])
}
