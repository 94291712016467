import React from "react";
import UserForm from "../../../Components/Users/Users/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const CreateUser = () => {
  
  return (
    <PageContainer>
        <BreadCrumb pageTitle="Users" title="Create" to="/dashboard/users" />
        <UserForm 
          type="create"
        />
    </PageContainer>
  );
};

export default CreateUser;
