import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import DataTable from "../../../Common/DataTable";
import Select from "react-select";
import productsApi from "../../../../apis/products/api";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../../Custom/CustomLabel";
import CustomButton from "../../../Common/CustomButton";
import { useQuery } from "@tanstack/react-query";
import toastAlert from "../../../../helpers/toastAlert";

const CreateProductsModal = ({ productToUpdate, setProductToUpdate, modalOpen, toggleModal, offerProducts, setOfferProducts }) => {

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [products, setProducts] = useState(null);

  const [loadingProduct, setLoadingProduct] = useState(null);

  const { t } = useTranslation();

  const productOptions = products?.map((product) => ({
    label: product.name,
    value: product.id,
  }));

  const { isLoading: loadingProducts, isRefetching: refetchingPrdoucts, refetch: refetchProducts } = useQuery(['products'], () => productsApi.products.getAll({
    for: 'order',
    page_size: 'all'
  }), {
    enabled: false,
    cacheTime: 0,
    onSuccess({ data: { data } }) {
        setProducts(data);
    }
  })

  const fetchProduct = async (id) => {
    try {
      setLoadingProduct(true);
      const { data: { data: resData }} = await productsApi.products.getOne(id, {
        for: 'order'
      })
      return resData
    } catch (error) {
      toastAlert({ type: 'error', message: 'Trouble selecting product'})
    } finally {
      setLoadingProduct(false);
    }
  }

  const handleSelection = async (prodId) => {

    if (!prodId) return;

    const product = await fetchProduct(prodId);

    const existingProduct = offerProducts.find((prod) => +prod.product_id === +prodId);

    setSelectedProduct({
      ...product,
      unit_prices: product.unit_prices.map(unit => {
        const existingUnit = existingProduct?.units?.find(ut => ut.unit_id === unit.unit_id);
        return {
          ...unit,
          name: unit.unit_name,
          price: existingUnit?.price ?? ''
        }
      })
    })
  };

  const handleChange = ({ unit, value }) => {
      setSelectedProduct(prev => ({
        ...prev,
        unit_prices: prev.unit_prices.map(un => {
          if (un.unit_id === unit) return {
            ...un,
            price: value
          } 
          return un
        })
      }))
  };

  const handleSave = () => {

    if (!selectedProduct) return;

    const product = offerProducts.find((prod) => prod.product_id === selectedProduct.id);

    const newProduct = {
      product_id: selectedProduct.id,
      product_name: selectedProduct.name,
      units: selectedProduct.unit_prices.map(unit => ({
        unit_id: unit.unit_id,
        price: unit.price
      }))
    }

    if (product) {

      setOfferProducts(prev => prev.map((prod) => {
          if (prod.product_id === selectedProduct.id) return newProduct;
          return prod;
        }),
      );

    } else {
      setOfferProducts((prev) => [...prev, newProduct]);
    }
    toggleModal();
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Unit")}</span>,
      selector: (row) => row.name,
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Purcahse Price")}</span>,
      selector: (row) =>      
      <input
        value={row.price_purchase || ''}
        disabled
        onChange={(e) => e}
        className="form-control text-danger"
      />,
    },
     {
        name: <span className="font-weight-bold fs-13">{t("Price")}</span>,
        selector: (row) => (
            <input
              value={row.price}
              onChange={(e) =>
                handleChange({
                  unit: row.unit_id,
                  value: e.target.value,
                })
              }
              type="number"
              className="form-control"
              id="inputName"
              step="any"
            />
        ),
      }
  ];

  const onClosed = () => {
    setProductToUpdate(null);
    setSelectedProduct(null);
  }

  useEffect(() => {
    if (productToUpdate) {
      handleSelection(productToUpdate)
    }
  }, [productToUpdate]);

  useEffect(() => {
    if (modalOpen && products === null && !productToUpdate) {
      refetchProducts();
    }
  }, [modalOpen])

  return (
    <Modal
      modalClassName="zoomIn"
      id="signupModals"
      className="modal-lg"
      isOpen={modalOpen}
      toggle={toggleModal}
      onClosed={onClosed}
      centered
    >
      <ModalHeader className="bg-light text-capitalize p-3" toggle={toggleModal}>
        {t("Add Products")}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12}>
            <CustomLabel>Products</CustomLabel>
            <Select
              placeholder={t('Select')}
              value={selectedProduct?.id && {
                label: selectedProduct?.name,
                value: selectedProduct?.id,
              }}
              isDisabled={((loadingProducts || refetchingPrdoucts) && !productToUpdate) || loadingProduct}
              isLoading={((loadingProducts || refetchingPrdoucts) && !productToUpdate) || loadingProduct}
              onChange={(item) => handleSelection(item.value)}
              options={productOptions}
            />
          </Col>

          <Col md={12} className="mt-3">
            <DataTable data={selectedProduct?.unit_prices} columns={columns} />
          </Col>
        </Row>

        <div className="d-flex gap-3">

          <CustomButton
            type="button"
            onClick={handleSave}
            className="btn btn-dark"
          >
            Back
          </CustomButton>

          <CustomButton
            type="button"
            onClick={handleSave}
            className="btn btn-primary"
          >
            Save
          </CustomButton>
        </div>

      </ModalBody>

    </Modal>
  );
};

export default CreateProductsModal;
