import axiosInstance from "../axios";

const storageApi = {

  storage: {

    getAll: (query) => {
      return axiosInstance.get("/stocks", {
        params: query
      });
    },
    
  },

  lotMovement: {

    getAll: (query) => {
      return axiosInstance.get("/lot_movements", {
        params: query
      });
    },

  },

  newStock: {

    getAll: (query) => {
      return axiosInstance.get("/lot_movements/input", {
        params: query
      });
    },

  },

  productsNumbers: {

    getAll: (query) => {
      return axiosInstance.get("/products/product_states", {
        params: query
      });
    },

  },

  problems: {

    getAll: (query) => {
      return axiosInstance.get("/stocks/1/stock_issues", {
        params: query
      });
    },

  },

  control: {

    getAll: (query) => {
      return axiosInstance.get("/products/1/stock_controls", {
        params: query
      });
    },

    create: (id, data) => {
      return axiosInstance.post(`/products/${id}/stock_controls`, data);
    }
  },
}

export default storageApi
