import React from 'react'
import { useFileManager } from '../../../context/FileManagerContext';

import {
    getMaterialFileIcon,
    getMaterialFolderIcon,
  } from "file-extension-icon-js";
import { useAuthState } from '../../../context/authContext';
import { isSuperAdmin } from '../../../helpers/isSuperAdmin';

const FileManagerFileItemList = ({item, selectedFiles, setSelectedFiles, setSidebarData, fileSidebar, canSelectFor, sidebarOpen, deleteFile}) => {
    
    const { selectFile } = useFileManager();

    const { adminData } = useAuthState();

    const tryToSelect = () => {
        if (canSelectFor) {
            selectFile(item)
        }
    }

    const checkFile = (e) => {
        setSelectedFiles((current) => {
            if (e.target.checked) {
                return current.concat(item.id)
            } else {
                return current.filter(id => id !== item.id)
            }
        })
    }

    return (
        <tr onDoubleClick={tryToSelect} className={`${canSelectFor ? 'cursor-pointer' : ''}`}>
            {isSuperAdmin(adminData) && 
                <td>
                    <div className='d-flex align-items-center'>
                        <input 
                            type="checkbox"
                            id="select-files"
                            checked={selectedFiles.includes(item.id)}
                            onChange={checkFile}
                        />
                    </div>
                </td>
            }
            <td>
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 fs-17 me-2 filelist-icon">
                        {
                            item.ext ? (
                                <img src={`${getMaterialFileIcon('.' + item.ext)}`} alt={""} width="20" />
                            ) : (
                                <img src={`${getMaterialFolderIcon('folder', 1)}`} alt={""} width="20" />
                            )
                        }
                    
                    </div>
                    <div className="flex-grow-1 filelist-name">
                        {item.name}
                    </div>
                    <div className="d-none filelist-type">
                        {" "}
                        {item.ext}{" "}
                    </div>
                </div>
            </td>
            {/* <td></td> */}
            <td className="filelist-size">{(item.size / 1000).toFixed(2)} KB</td>
            {/* <td className="filelist-create"></td> */}
            <td>
                <div className="d-flex gap-1 justify-content-center">
                    <button
                        type="button"
                        className="btn btn-success btn-icon btn-sm "
                        onClick={() => {
                            setSidebarData(item);
                            fileSidebar();
                            sidebarOpen("file-detail-show");
                        }}
                    >
                        <i className="ri-eye-line fs-13 align-bottom"></i>
                    </button>
                    {
                        isSuperAdmin(adminData) &&
                        
                        <button
                            type="button"
                            className="btn btn-danger btn-icon btn-sm"
                            onClick={deleteFile}
                        >
                            <i className="ri-delete-bin-line fs-13 align-bottom"></i>
                        </button>
                    }
                    {/* <button
                        type="button"
                        className="btn btn-primary btn-icon btn-sm favourite-btn"
                        // onClick={(e) => favouriteBtn(e.target)}
                    >
                        <i className="ri-star-fill fs-13 align-bottom" />
                    </button> */}

                {/* <UncontrolledDropdown dir="start">
                    <DropdownToggle
                    tag="button"
                    className="btn btn-light btn-icon btn-sm dropdown"
                    id="dropdownMenuButton"
                    >
                    <i className="ri-more-fill align-bottom" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                        className="viewfile-list"
                        onClick={() => {
                        
                        }}
                    >
                        View
                    </DropdownItem>
                    <DropdownItem
                        className="edit-list"
                        onClick={() => handleFileClick(item)}
                    >
                        Rename
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                        className="remove-list"
                        onClick={() => onClickFileDelete(item)}
                    >
                        Delete
                    </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown> */}
                </div>
            </td>
        </tr>
    )
}

export default FileManagerFileItemList