import * as yup from "yup";
import { phoneRegExp } from "../regex";
import { emailRegExp } from "../regex";

export const createVendorSchema = yup.object().shape({
  name: yup.string().required(),
  number: yup.string().required(),
  address: yup.string().required(),
  phone: yup.string().required().matches(phoneRegExp, 'Please enter a valid phone number'),
  email: yup.string().required().matches(emailRegExp, 'Please enter a valid email address'),
});

export const updateVendorSchema = yup.object().shape({
  name: yup.string().required(),
  number: yup.string().required(),
  address: yup.string().required(),
  phone: yup.string().required().matches(phoneRegExp, 'Please enter a valid phone number'),
  email: yup.string().required().matches(emailRegExp, 'Please enter a valid email address'),
});