import * as yup from "yup";

const SetupSchema = yup.object().shape({
  website_name: yup.string().required("Please specify a website name"),
  catalogue: yup.mixed().notRequired(),
  logo: yup.mixed().notRequired(),
  fav_icon: yup.mixed().notRequired(),
  product_image: yup.mixed().notRequired(),
});

export default SetupSchema;
