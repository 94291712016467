import moment from "moment";

export const getNewStockCSVData = ({data}) => {
  if (!data) return []

  return data.map(obj => {
    return {
      'ADMIN': obj.user || '-',
      'QUANTITY': obj.qty_delivered || '-',
      'PRODUCT': obj.product || '-',
      'UNIT': obj.unit || '-',
      'STATUS': obj.status || '-',
      'WEIGHT': obj.weight || '-',
      'BATCH': obj.batch || '-',
      'LOT': obj.lot || '-',
      'CREATED AT': obj.created_at ? moment(obj.created_at).format("YYYY/MM/DD") : '-',
      'EXP DATE': obj.date_exp ? moment(obj.date_exp).format("YYYY/MM/DD") : '-',
    }
  })
}