export const USER_PERMISSIONS = {
    read: ['users-read'],
    update: ['users-update'],
    delete: ['users-delete'],
    create: ['users-create'],
}

export const USER_GROUP_PERMISSIONS = {
    read: ['groups-read'],
    update: ['groups-update'],
    delete: ['groups-delete'],
    create: ['groups-create'],
}

export const USER_ZONES_PERMISSIONS = {
    read: ['zones-read'],
    update: ['zones-update'],
    delete: ['zones-delete'],
    create: ['zones-create'],
}

export const USER_STATUS_PERMISSIONS = {
    read: ['status-read'],
    update: ['status-update'],
    delete: ['status-delete'],
    create: ['status-create'],
}

export const USER_HISTORY_PERMISSIONS = {
    read: ['history-read'],
    update: ['history-update'],
    delete: ['history-delete'],
    create: ['history-create'],
}