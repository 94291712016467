import React, { useEffect, useState } from 'react'
import usersApi from '../../../../apis/users/api';
import toastAlert from '../../../../helpers/toastAlert';
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../../Custom/CustomLabel';
import CustomInput from '../../../Custom/CustomInput';

const ProductUnitRow = ({ data, refetch, unitsRef, refetchTracking }) => {

  const [unitsData, setUnitsData] = useState([]);

  const handleChange = (id, value) => {
    return setUnitsData(prev => prev.map(unit => {
            if (unit.id === id) return {
                ...unit,
                price: value
            }
            return unit
        })
    )
  }

  const updateItem = async (row) => {
    
    await usersApi.users.discounts.units.update(row.id, {
      price: row.price,
      unit_id: row.unit_id
    }) 

    refetch()
    refetchTracking()
    toastAlert({ type: 'success' });
  }

  const deleteSingleItem = async (id) => {
    await usersApi.users.discounts.units.delete(id)
    refetch()
    refetchTracking()
    toastAlert({ type: 'success' });
  }

  useEffect(() => {
    setUnitsData(data.units.map(unit => {
        return {
            id: unit.id,
            unit_id: unit.unit_id,
            name: unit.unit_name,
            price: unit.price,
            purchasePrice: unit?.price_purchase,
        }
    }))
  }, [data])

  useEffect(() => {
    unitsRef.current = unitsData;
  }, [unitsData])

  return unitsData.map(unit => (
    <Row key={unit.id} className='p-3 px-5 align-items-center border-bottom'>

        <Col>
            <CustomLabel>
                Unit
            </CustomLabel>
            <CustomInput
                value={unit.name}
                disabled
                step="any"
            />
        </Col>

        <Col>
            <CustomLabel>
              Purchase Price
            </CustomLabel>
            <CustomInput
                type="number"
                className="form-control"
                disabled
                value={unit.purchasePrice || ''}
                step="any"
            />
        </Col>

        <Col>
            <CustomLabel>
              Discount
            </CustomLabel>
            <CustomInput
                name="promo"
                value={unit.price}
                onChange={e => handleChange(unit.id, e.target.value)}
                type="number"
                className='form-control'
                step="any"
            />
        </Col>

        <Col>

            <CustomLabel>
              Actions
            </CustomLabel>

            <div className='d-flex gap-2'>
              <i onClick={() => updateItem(unit)} className="ri-save-2-line fs-18 cursor-pointer"></i>
              <i onClick={() => deleteSingleItem(unit.id)} className="ri-delete-bin-line fs-18 cursor-pointer"></i>
            </div>

        </Col>

    </Row>
  ))
}

export default ProductUnitRow