import React, { useMemo, useState } from "react";
import {
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import DeliveryAddress from "./DeliveryAddress";
import Account from "./Account";
import Profile from "./Profile";
import Discounts from "./Discounts";
import CustomNavLink from "../../../Custom/CustomNavLink";
import { useTranslation } from "react-i18next";


const CreateUserTabs = ({ initialValues, type, refetchTracking }) => {
  
  const [customActiveTab, setcustomActiveTab] = useState(0);

  const { t } = useTranslation();

  const toggle = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const tabs = useMemo(() => {
    return [
      {
        label: 'Account',
        component: () => (
          <div className="d-flex flex-column">
            <Card className="mt-3">
                <CardHeader className="fs-16 fw-bold">
                  {t('General Information')}  
                </CardHeader>
                <CardBody>
                  <Account  
                    refetchTracking={refetchTracking}
                    initialValues={initialValues}
                    type={type}
                  />
                </CardBody>
            </Card>
            {type === 'update' && 
              <>
                <Card>
                  <CardHeader className="fs-16 fw-bold">
                      {t('Profile')}
                  </CardHeader>
                  <CardBody>
                    <Profile 
                      refetchTracking={refetchTracking}
                      initialValues={initialValues?.profile}
                      type={type}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader className="fs-16 fw-bold">
                      {t('Delivery Address')}
                  </CardHeader>
                  <CardBody>
                    <DeliveryAddress 
                      refetchTracking={refetchTracking}
                      initialValues={initialValues?.address}
                      type={type}
                    />
                  </CardBody>
                </Card>
              </>
            }
          </div>
        )
      },
      {
        label: 'Discounts',
        component: () => (
          <Card>
            <Discounts 
              refetchTracking={refetchTracking}
              initialValues={initialValues}
              type={type}
            />
          </Card>
        )
      },
    ]
  }, [type, initialValues, refetchTracking])

  return (
    <React.Fragment>

      {type === 'update' &&
          <Card className="p-3 mb-0">
            <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
              {tabs.map((tab, index) => (
                <NavItem key={index}>
                  <CustomNavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: customActiveTab === index })}
                    onClick={() => toggle(index)}
                  >
                    {tab.label}
                  </CustomNavLink>
                </NavItem>
              ))}
            </Nav>
        </Card>
      }

      <TabContent activeTab={customActiveTab}>
          {tabs.map((tab, index) => (
              <TabPane key={index} tabId={index}>
                  <tab.component />
              </TabPane>
          ))}
      </TabContent>
    </React.Fragment>
  );
};

export default CreateUserTabs;
