import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { useForm } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { Controller } from "react-hook-form";
import { createOfferBody,  updateOfferBody } from "../../../helpers/RequestBodies/Products/offerRequestBody";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setFormErrors } from "../../../helpers/setFormErrors";
import CreateProductsModal from "./CreateOffer/CreateProductsModal";
import CreateOfferImages from "./CreateOffer/CreateOfferImages";
import OfferProductsTable from "./OfferProductsTable";
import moment from "moment/moment";
import CustomLabel from "../../Custom/CustomLabel";
import { createProductsDataAction, updateProductsDataAction } from "../../../redux/actions/products";
import SaveButton from "../../Common/Buttons/SaveButton";

const OfferForm = ({ type, initialValues }) => {

  const [modalOpen, setModalOpen] = useState(false);

  const [offerImage, setOfferImage] = useState(null);

  const [productToUpdate, setProductToUpdate] = useState(null);

  const [offerProducts, setOfferProducts] = useState(initialValues?.products ?? []);

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, control, formState: { errors }, setError } = useForm({
    // resolver: yupResolver(createOfferSchema),
    defaultValues: initialValues,
  });

  const toggleModal = () => setModalOpen(!modalOpen);

  const dispatch = useDispatch();

  const history = useHistory();

  const onSubmit = (data) => {
    setLoading(true);
    if (type === "create") {
      const requestBody = createOfferBody(data);
      dispatch(
        createProductsDataAction({
          field: 'offers',
          data: requestBody,
          onSuccess: () => history.push("/dashboard/products/offers"),
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    } else {
      const requestBody = updateOfferBody(data);
      dispatch(
        updateProductsDataAction({
          field: 'offers',
          data: {
            id: initialValues?.id,
            data: requestBody,
          },
          onSuccess: () => setLoading(false),
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    }
  };

  const removeProduct = (id) => {
    setOfferProducts(offerProducts.filter((prod) => prod.id !== id));
  };

  useEffect(() => {
    setValue("products", offerProducts);
  }, [offerProducts]);

  return (
    <div>

      <CreateProductsModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        offerProducts={offerProducts}
        setOfferProducts={setOfferProducts}
        productToUpdate={productToUpdate}
        setProductToUpdate={setProductToUpdate}
      />
      
      <form onSubmit={handleSubmit(onSubmit)}>

        <Card className="p-3">

          <Row className="gx-4 gy-3">

            <Col md={6}>
              <CustomLabel for="offer-title">Title</CustomLabel>
              <input
                name="title"
                {...register("title")}
                className="form-control"
                id="offer-title"
              />
              {errorMessage(errors, "title")}
            </Col>

            <Col md={6} className="d-flex align-items-center">
              <div className="form-check form-switch mt-4">

                  <input 
                      {...register('status')} 
                      className="form-check-input fs-16" 
                      type="checkbox" 
                      role="switch" 
                      id="status" 
                  />

                  <CustomLabel 
                    className="form-check-label" 
                    htmlFor="status"
                  >
                    Active
                  </CustomLabel>

              </div>
            </Col>

            <Col md={6} className="mt-3">
              <div>
                <CustomLabel className="form-label mb-0">Starts At</CustomLabel>
                <Controller
                  name="start_at"
                  control={control}
                  render={({ name, field: { onChange } }) => (
                    <Flatpickr
                      name={name}
                      className="form-control"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(date) => {
                        onChange(moment(date[0]).format("YYYY-MM-DD"));
                      }}
                      defaultValue={initialValues?.start_at ? moment(initialValues?.start_at).format(
                        "D MMM, YYYY"
                      ) : ''}
                    />
                  )}
                />
                {errorMessage(errors, "start_at")}
              </div>
            </Col>

            <Col md={6} className="mt-3">
              <div>
                <CustomLabel className="form-label mb-0">Ends At</CustomLabel>
                <Controller
                  name="end_at"
                  control={control}
                  render={({ name, field: { onChange } }) => (
                    <Flatpickr
                      name={name}
                      className="form-control"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(date) => {
                        onChange(moment(date[0]).format("YYYY-MM-DD"));
                      }}
                      defaultValue={initialValues?.end_at ? moment(initialValues?.end_at).format(
                        "D MMM, YYYY"
                      ) : ''}
                    />
                  )}
                />
                {errorMessage(errors, "end_at")}
              </div>
            </Col>

            <CreateOfferImages
              initialValues={initialValues}
              offerImage={offerImage}
              setOfferImage={setOfferImage}
              setValue={setValue}
              errors={errors}
            />           
          </Row>
        </Card>

        <Card>
            <OfferProductsTable
              offerProducts={offerProducts}
              setProductToUpdate={setProductToUpdate}
              toggleModal={toggleModal}
              removeProduct={removeProduct}
            />
        </Card>

        <div className="my-2 d-flex justify-content-end">

          <SaveButton 
            isLoading={loading}
          />

        </div>
        
      </form>
    </div>
  );
};

export default OfferForm;
