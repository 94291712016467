import * as yup from "yup";

const createHistorySchema = yup.object().shape({
  user_id: yup
    .string()
    .required("Please provide a valid user name"),
  status: yup
    .string()
    .typeError("Please select a status")
    .required("Please select a status"),
  description: yup.string().required("Please provide a description"),
});

export default createHistorySchema;
