import React from "react";
import SimpleBar from "simplebar-react";
import FileManagerMediaReader from "./FileManagerMediaReader";
import { useTranslation } from "react-i18next";

const FileManagerFileDetails = ({ file, sidebarClose }) => {
  
  const src = `${file.uri}/${file.name}`;

  const { t } = useTranslation();

  return (
    <div className="file-manager-detail-content p-3 py-0">
      <SimpleBar className="mx-n3 pt-3 px-3 file-detail-content-scroll">
        <div id="folder-overview">
          <div className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed">
            <h5 className="flex-grow-1 fw-semibold mb-0">{t("Overview")}</h5>
            <div>
              <button
                type="button"
                className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-overview"
                onClick={() => sidebarClose("file-detail-show")}
              >
                <i className="ri-close-fill align-bottom"></i>
              </button>
            </div>
          </div>
          <div className="mt-4">
            <ul className="list-unstyled vstack gap-4">
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-xs">
                      <div className="avatar-title rounded bg-soft-secondary text-secondary">
                        <i className="ri-file-text-line fs-17"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1 fs-15">Documents</h5>
                    <p className="mb-0 fs-12 text-muted">2348 files</p>
                  </div>
                  <b>27.01 GB</b>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-xs">
                      <div className="avatar-title rounded bg-soft-success text-success">
                        <i className="ri-gallery-line fs-17"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1 fs-15">Media</h5>
                    <p className="mb-0 fs-12 text-muted">12480 files</p>
                  </div>
                  <b>20.87 GB</b>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-xs">
                      <div className="avatar-title rounded bg-soft-primary text-primary">
                        <i className="ri-error-warning-line fs-17"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1 fs-15">Others</h5>
                    <p className="mb-0 fs-12 text-muted">9873 files</p>
                  </div>
                  <b>33.54 GB</b>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div id="file-overview" className="h-100">
          <div className="d-flex h-100 flex-column">
            <div className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2">
              <h5 className="flex-grow-1 fw-semibold mb-0">File Preview</h5>
              <div>
                <button
                  type="button"
                  className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-overview"
                  onClick={() => sidebarClose("file-detail-show")}
                >
                  <i className="ri-close-fill align-bottom"></i>
                </button>
              </div>
            </div>

            <div className="pb-3 border-bottom border-bottom-dashed mb-3">
              <div className="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3">
                <FileManagerMediaReader
                  alt={file?.name}
                  ext={file?.ext}
                  src={src}
                />
              </div>
              <h5 className="fs-16 mb-1 file-name">{file?.name}</h5>
              <p className="text-muted mb-0 fs-12">
                <span className="create-date">
                </span>
              </p>
            </div>
            <div>
              <h5 className="fs-12 text-uppercase text-muted mb-3">
                {t("File Description")} :
              </h5>

              <div className="table-responsive">
                <table className="table table-borderless table-nowrap table-sm">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: "35%" }}>
                        {t("File Name")} :
                      </th>
                      <td className="file-name">{file?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("File Type")} :</th>
                      <td className="file-type">{file?.ext}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("Size")} :</th>
                      <td className="file-size">
                        {file?.size && file.size / 1000} KB
                      </td>
                    </tr>       
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-auto border-top border-top-dashed py-3">
              <div className="hstack gap-2">
              </div>
            </div>
          </div>
        </div>
      </SimpleBar>
    </div>
  );
};

export default FileManagerFileDetails;
