import axiosInstance from "../axios";

const usersApi = {

    users: {

        getAll: (data) => {
            return axiosInstance.get('/users', {
                params: data
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/users/${id}`);
        },

        create: (data) => {
            return axiosInstance.post('/users', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/users/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/users', {
                data
            });
        },

        profile: {

            create: (data) => {
                return axiosInstance.post('/user_profiles', data);
            },

            update: (id, data) => {
                return axiosInstance.post(`/user_profiles/${id}`, data)
            }
        },

        address: {

            create: (data) => {
                return axiosInstance.post('/useraddress', data);
            },

            update: (id, data) => {
                return axiosInstance.post(`/useraddress/${id}`, data)
            }
        },

        discounts: {

            getAll: (data) => {
                return axiosInstance.get('/user_discounts', {
                    params: {
                        page_size: 'all',
                        ...data,
                    }
                })
            },
            create: (data) => {
                return axiosInstance.post('/user_discounts', data)
            },

            delete: (data) => {
                return axiosInstance.delete('/user_discounts', {
                    data
                })
            },

            units: {

                update: (id, data) => {
                    return axiosInstance.post(`/user_discount_unit/${id}`, data)
                },

                delete: (id) => {
                    return axiosInstance.delete(`/user_discount_unit/${id}`)
                }
            }
        },

        favProducts: {

            getAll: (data) => {
                return axiosInstance.get('/productfav', data);
            },
            
            create: (data) => {
                return axiosInstance.post("/productfav/add", data);
              },

            delete: (id) => {
                return axiosInstance.delete(`/productfav/${id}`);
              },
        }
    },

    groups: {

        getAll: (query) => {
            return axiosInstance.get(`/user_groups`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/user_groups/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/user_groups`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/user_groups/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/user_groups`, {
                data
            });
        }
    },

    history: {

        getAll: (query) => {
            return axiosInstance.get('/communication_records', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/communication_records/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post('/communication_records', data)
        },

        update: (id, data) => {
            return axiosInstance.post(`/communication_records/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/communication_records', {
                data
            })
        }
    },

    statuses: {

        getAll: (query) => {
            return axiosInstance.get('/statues', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/statues/${id}`)
        },

        create:  (data) => {
            return axiosInstance.post('/statues', data)
        },

        update: (id, data) => {
            return axiosInstance.post(`/statues/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete(`/statues`, {
                data
            });
        }
    },

    zones: {

        getAll: (query) => {
            return axiosInstance.get('/regions', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/regions/${id}`)
        },
        
        create: (data) => {
            return axiosInstance.post('/regions', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/regions/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete(`/regions`, {
                data
            });
        },

        deliveryDays: {
            delete: (id) => {
                return axiosInstance.delete(`/delivery_days/${id}`)
            }
        }

    }

}

export default usersApi