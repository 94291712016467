import { objectFilter } from "../../objectFilter"

export const orderTransportDetailsBody = (data) => {

    return {
        estimated_pickup: data.date.estimated_pickup || null,
        estimated_delivery: data.date.estimated_delivery || null,
        actual_pickup: data.date.actual_pickup || null,
        actual_delivery: data.date.actual_delivery || null,
        delivery_id: data.transport?.id || null,
        palets: objectFilter(data.units.map(unit => ({
            stock_zone_id: unit.chargable_weight.value,
            marks: unit.marks,
            weight: unit.weight,
            units: unit.units,
            transport_unit_id: unit.unit_type.value,
            length: unit.length,
            width: unit.width,
            height: unit.height,
            volume: unit.volume
        }))) || []
      }
}
