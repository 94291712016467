import { useState, useEffect } from "react";
import React from "react";
import { Col, Label } from "reactstrap";
import CustomLabel from "../../Custom/CustomLabel";

const ProductQuantity = ({ unit_prices, units, total_weight, total_qty }) => {
  const isWeight = units.find((unit) => unit.id === 1);

  const [otherUnits, setOtherUnits] = useState([]);

  const formatUnits = () => {
    let arr = [];
    units.forEach((unit) => {
      const unitPrice = unit_prices.find(
        (price) => price?.unit_id === unit?.id
      );
      if (unitPrice && !unit.is_weight) {
        arr.push({
          unitID: unit.id,
          unitName: unit.name,
          pieces: unitPrice.pieces,
        });
      }
    });

    arr = arr.sort((a, b) => (a.pieces < b.pieces ? 1 : -1));

    return arr;
  };

  const handleCalculateUnits = () => {
    const units = formatUnits();

    let finalResult = [];

    let q = total_qty;

    units.forEach((unit) => {
      const { unitName: name, unitID: id, pieces } = unit;
      if (pieces > 0) {
        let result = Math.floor(q / pieces);
        q = q - result * pieces;
        finalResult.push({
          id,
          name,
          qty: result,
        });
      } else {
        finalResult.push({
          id,
          name,
          qty: 0,
        });
      }
    });

    setOtherUnits(finalResult);
  };

  useEffect(() => {
    handleCalculateUnits();
  }, [unit_prices, units, total_weight, total_qty]);

  return (
    <>
      {isWeight ? (
        <>
          <Col md={2} xs={3}>
            <CustomLabel>Total Quantity</CustomLabel>
            <input
              value={total_qty}
              disabled
              className="form-control"
            />
          </Col>
          <Col md={2} xs={3}>
            <CustomLabel>Total Weight</CustomLabel>
            <input
              value={total_weight}
              disabled
              className="form-control"
            />
          </Col>
        </>
      ) : (
        <>
          <Col md={2} xs={3}>
            <CustomLabel>Total Quantity</CustomLabel>
            <input
              value={total_qty}
              onChange={() => {}}
              disabled
              className="form-control"
            />
          </Col>
          {otherUnits.map((unit) => (
            <Col md={2} xs={3} key={unit.id}>
              <CustomLabel>
                Total {unit?.name} Qty
              </CustomLabel>
              <input
                value={unit.qty}
                onChange={() => {}}
                disabled
                className="form-control"
              />
            </Col>
          ))}
        </>
      )}
    </>
  );
};

export default ProductQuantity;
