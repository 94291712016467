import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Card, CardHeader } from "reactstrap";

const Tracking = ({ data }) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Admin")}</span>,
      selector: (row) => <span title={row.user?.name || "-"}>{row.user?.name}</span>,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Action")}</span>,
      selector: (row) => row.action || "-",
      grow: 2,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Description")}</span>,
      selector: (row) => {
        return (
          <div className="py-3">
            <ul className="list-unstyled">
              {row.body
                ? Object.keys(row.body)
                    .filter((key) => key !== "id")
                    .filter((key) => typeof row.body[key] === "string")
                    .map((key) => {
                      return (
                        <li key={key} className="text-capitalize">
                          {" -- "}
                          {row.body[key]}
                        </li>
                      );
                    })
                : "-"}
            </ul>
          </div>
        );
      },
      wrap: true,
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Made At")}</span>,
      selector: (row) => moment(row.created_at).format("D MMM, YYYY, HH:mm"),
    },
  ];

  return (

    <div className="tracking-wrapper mb-2">
      <Card>
        <CardHeader className="card-header border-0">
          <div className="d-flex align-items-center">
            <h5 className="card-title mb-0 flex-grow-1">
              {t("Tracking History")}
            </h5>
          </div>
        </CardHeader>

        <DataTable 
          data={data} 
          columns={columns} 
        />

      </Card>
    </div>
  );
};

export default Tracking;
