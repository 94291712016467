import React from 'react'
import CategoryForm from '../../../Components/Products/Categories/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateCategory = () => {

  return (
    <PageContainer>
      <BreadCrumb title='Create' pageTitle="Categories" to="/dashboard/products/categories" />
      <CategoryForm type='create' />
    </PageContainer>
  )
}

export default CreateCategory