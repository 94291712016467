import { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { objectFilter } from "../helpers/objectFilter";

export const useSearch = ({ name, searchFunc, cachedValues = {}, debouncedValues = {} }) => {

    const [debouncedValuesState, setDebouncedValuesState] = useState(debouncedValues);

    const [queryValues, setQueryValues] = useState({
        page: 1
    })

    const [initialValues, setInitialValues] = useState(null);

    const timeoutRef = useRef();

    const {
        values: {
         [`${name}Filter`]: localFilterValues },
          setNewValues: setLocalFilterValues,
    } = useLocalStorage({
        [`${name}Filter`]: {
            page_size: 10,
            ...cachedValues,
        },
    });

    const searchValues = { ...localFilterValues, ...debouncedValuesState, ...queryValues };
    
    const search = (query) => searchFunc(objectFilter(query ? query : searchValues));

    const debounceSearch = (query) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => search(query), 800);
    }

    const searchAfterUpdate = (context, newValue) => {
        switch(context) {
            case 'cached': {
                search({
                    ...queryValues,
                    ...debouncedValuesState,
                    ...newValue
                })
                break
            }
            case 'debounced': {
                debounceSearch({
                    ...queryValues,
                    ...localFilterValues,
                    ...newValue
                })
                break
            }
            case 'query': {
                search({
                    ...debouncedValuesState,
                    ...localFilterValues,
                    ...newValue
                })
                break
            }
            default: {
                search({
                    ...initialValues.debounced,
                    ...initialValues.cached,
                    ...initialValues.query,
                })
                break
            }
        }
    }
    
    const handleChange = ({ type, field, value }) => {
        if (type === 'cached') {
            const newState = {
                ...localFilterValues,
                [field]: value
            }
            setLocalFilterValues({
                [`${name}Filter`]: newState,
            })
            searchAfterUpdate('cached', newState)
        } else if (type === 'debounced') {
            const newState = {
                ...debouncedValuesState,
                [field]: value
            }
            setDebouncedValuesState(newState);
            searchAfterUpdate('debounced', newState)
        } else if (type === 'query') {
            const newState = {
                ...queryValues,
                [field]: value
            }
            setQueryValues(newState);
            searchAfterUpdate('query', newState)
        }
    }

    const handleMultipleChanges = ({ type, data }) => {
        if (type === 'cached') {
            const newState = {
                ...localFilterValues,
                ...data
            }
            setLocalFilterValues({
                [`${name}Filter`]: newState,
            })
            searchAfterUpdate('cached', newState)
        } else if (type === 'debounced') {
            const newState = {
                ...debouncedValuesState,
                ...data
            }
            setDebouncedValuesState(newState);
            searchAfterUpdate('debounced', newState)
        }
    }

    const clearFilter = (field) => {
        setLocalFilterValues({
            [`${name}Filter`]: {
                ...initialValues.cached
            }
        });
        setDebouncedValuesState(initialValues.debounced);
        setQueryValues(initialValues.query);
        searchAfterUpdate();
    }

    const navigateToPage = (page) => handleChange({
        type: 'query',
        field: 'page',
        value: page
    })

    const setPageSize = (size) => handleChange({
        type: 'cached',
        field: 'page_size',
        value: size
    })

    const onSort = (column, sortDirection) => handleMultipleChanges({
        type: 'cached',
        data: {
            order_by: column.sortField,
            order_value: sortDirection,
        }
    })

    useEffect(() => {
        search();
    }, [])

    useEffect(() => {
        setInitialValues({
            cached: {
                page: 1,
                page_size: 10,
                ...cachedValues,
            },
            debounced: debouncedValues,
            query: queryValues
        })
    }, [])

    return { searchValues, handleChange, handleMultipleChanges, setPageSize, navigateToPage, onSort, search, clearFilter }
}