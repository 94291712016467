import axiosInstance from "../axios";

const productsApi = {

    products: {

        getAll: (data) => {
            return axiosInstance.get('/products', {
                params: data
            });
        },

        getOne: (id, params = {}) => {
            return axiosInstance.get(`/products/${id}`, {
                params
            });
        },

        create: (data) => {
            return axiosInstance.post('/products', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/products/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/products', {
                data
            });
        },
        
        vars: {

            create: (id, data) => {
                return axiosInstance.post(`/products/${id}/product_vars`, data);
            },

        },
    },

    offers: {

        getAll: (query) => {
            return axiosInstance.get(`/shop_offers`, {
                params: query
            })
        },

        getOne: (id) => {
            return axiosInstance.get(`/shop_offers/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post(`/shop_offers`, data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/shop_offers/${id}`, data);
        },

        delete: (data) => {
            return axiosInstance.delete(`/shop_offers`, {
                data
            });
        },
        sendToMail: (id) => {
            return axiosInstance.post(`/shop_offers/${id}/mail`);
        }
    },

    categories: {

        getAll: (query) => {
            return axiosInstance.get('/cats', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/cats/${id}`);
        },

        create:  (data) => {
            return axiosInstance.post('/cats', data)
        },

        update: (id, data) => {
            return axiosInstance.post(`/cats/${id}`, data)
        },

        sort: (data) => {
            return axiosInstance.post('/cats/fixtree', data);
        },

        delete: (data) => {
            return axiosInstance.delete('/cats', {
                data
            })
        },
    },

    locations: {

        getAll: (query) => {
            return axiosInstance.get('/stocks/1/stock_zones', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/stocks/1/stock_zones/${id}`)
        },

        create:  (data) => {
            return axiosInstance.post('/stocks/1/stock_zones', data)
        },

        update: (id, data) => {
            return axiosInstance.post(`/stocks/1/stock_zones/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete(`/stocks/1/stock_zones`, {
                data
            });
        }
    },

    measurementUnits: {

        getAll: (query) => {
            return axiosInstance.get('/units', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/units/${id}`)
        },

        create:  (data) => {
            return axiosInstance.post('/units', data)
        },

        update: (id, data) => {
            return axiosInstance.post(`/units/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete(`/units`, {
                data
            });
        }
    },

    vats: {

        getAll: (query) => {
            return axiosInstance.get('/taxes', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/taxes/${id}`)
        },

        create:  (data) => {
            return axiosInstance.post('/taxes', data)
        },

        update: (id, data) => {
            return axiosInstance.post(`/taxes/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete(`/taxes`, {
                data
            });
        }
    },

    vendors: {

        getAll: (query) => {
            return axiosInstance.get('/suppliers', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/suppliers/${id}`)
        },

        create:  (data) => {
            return axiosInstance.post('/suppliers', data)
        },

        update: (id, data) => {
            return axiosInstance.post(`/suppliers/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete(`/suppliers`, {
                data
            });
        },

        askForProducts: (data) => {
            return axiosInstance.post('/suppliers/demande', data);
        }
    },

    problems: {
        getAll: (query) => {
            return axiosInstance.get('/products', {
                params: query
            });
        },
    },

    catalogue: {

        get: () => {
            return axiosInstance.get('/catalog');
        },

        updateFiles: (data) => {
            return axiosInstance.post("/catalog", data);
        },

        products: {
            create: (data) => {
                return axiosInstance.post("/catalog/addproduct", data);
            },
    
            update: (data) => {
                return axiosInstance.post(`/catalog/updateproduct`, data);
            },
    
            delete: (id) => {
                return axiosInstance.delete(`/catalog/deleteproduct/${id}`);
            }
        }
    },

}

export default productsApi