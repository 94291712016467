import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import DataTable from "../../../Common/DataTable";
import { Link, useHistory } from "react-router-dom";
import calculateTotalPrice from "../../../../helpers/calculateTotalPrice";
import CoverSpinner from "../../../Common/CoverSpinner";
import CustomButton from "../../../Common/CustomButton";
import useDeleteMultiple from "../../../../hooks/useDeleteMultiple";
import toastAlert from "../../../../helpers/toastAlert";
import ExportToPdf from "./ExportToPdf";
import { useSelector } from "react-redux";
import ordersApi from "../../../../apis/orders/api";
import usersApi from "../../../../apis/users/api";
import { extractQuantityBasedonUnit } from "../../../../helpers/calculateOrderPrices";
import { sortByStockZone } from "../../../../helpers/sortByStockZone";
import { useTranslation } from "react-i18next";

const OrderDetailsTable = ({
  userDiscounts,
  setUserDiscounts,
  allItems,
  read,
  order,
  refetch,
  visibleColumns,
  exportToPdf,
  refetchTracking,
  exportProducts,
}) => {
  
  const { t } = useTranslation();

  const [isUpdating, setIsUpdating] = useState(false);

  const [orders, setOrders] = useState(null);

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const [itemsToDelete, setItemsToDelete] = useState({});

  const { load, callback, deleteModal, isDeleting, setDeleteModal } = useDeleteMultiple();

  const { locations: { list: locations } } = useSelector((state) => state.products);

  const history = useHistory();

  const [extraCost, setExtraCost] = useState({
    isExtraCost: true,
    description: "frakt",
    quantity: "",
    price: "",
  });

  const handleChange = (orderId, itemId) => (e) => {
    setOrders(prev => prev.map(ord => {
      if (ord.order === orderId) return {
        ...ord,
        items: ord.items.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          }
          return item;
        }),
      }
      return ord
    })
    );
  };

  const handleExtraCostChange = (field, value) => {
    return setExtraCost({
      ...extraCost,
      [field]: value,
    });
  };

  const handleDelete = async () => {
    const ids = Object.values(itemsToDelete).reduce((accum, val) => {
      return [...accum, ...val.selectedRows.map((item) => item.id)]
    }, [])
    load();
    await ordersApi.orders.products.delete(ids);
    refetch();
    refetchTracking();
    setItemsToDelete({});
    callback();
    toastAlert({ type: 'success' });
  };

  const updateItem = async (row) => {
    setIsUpdating(true);
    const id = row.id;
    const requestBody = {
      product_unit: row.product_unit?.id || row.product_unit,
      promo: row.promo,
      price: row.price,
      qty: row.qty,
    };
    try {
      await ordersApi.orders.products.update(id, requestBody);
      refetch();
      toastAlert({ type: 'success'})
      refetchTracking();
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsUpdating(false);
    }
  };

  const updateAll = async () => {
    setIsUpdating(true);
    const allItems = orders.reduce((accum, val) => {
        return [...accum, ...val.items.map((row) => ({
          id: row.id,
          product_unit: row.product_unit?.id || row.product_unit,
          promo: row.promo,
          price: row.price,
          qty: row.qty,
        }))]
    }, []);
    try {
      if (allItems?.length > 0) {
        await ordersApi.orders.products.updateMultiple({
          items: allItems
        });
        await ordersApi.orders.details.update(order.id, {
          delivery_price: extraCost.price,
          description: extraCost.description,
          delivery_qty: extraCost.quantity,
        });
        refetch();
        refetchTracking();
        
      } 
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      setIsUpdating(false);
    }
  };

  const toggleDiscount = async (product) => {
    const discountExists = userDiscounts.find((discount) => discount.product_id === product.product.id);
    if (discountExists) {
      await usersApi.users.discounts.delete({ ids: [discountExists.id] });
      setUserDiscounts(prev => prev.filter(discount => discount.product_id !== product.product.id));
      toastAlert({ type: 'success'})
    } else {
      const { data: { data: discountData }} = await usersApi.users.discounts.create({
        user_id: order.company.id,
        product_id: product.product.id,
        units: [
          {
            unit_id: product.product_unit.id,
            price: (
              +product.price -
              (+product.price * +product.promo) / 100
            ).toFixed(2),
          },
        ],
      });
      setUserDiscounts(prev => [...prev, {
        id: discountData.id,
        product_id: discountData.product?.id,
        units: discountData.units
      }]);
      refetchTracking();
      toastAlert({ type: 'success'})
    }
  };

  const toggleFavorite = async ({product_id, fav_id}) => {
    if (fav_id) {
      await usersApi.users.favProducts.delete(fav_id);
    } else if (product_id) {
      await  usersApi.users.favProducts.create({
        user_id: order.company.id,
        product_id: product_id,
      });
    }
    refetch();
    refetchTracking();
  };

  const refreshItem = async (row) => {
    setIsUpdating(true);
      try {
        await ordersApi.orders.products.refresh(row.id);
        await refetch();
        toastAlert({ type: 'success' });
    } catch (error) {
        toastAlert({ type: 'error' });
    } finally {
      setIsUpdating(false)
    }
  }


  const sendToEmail = async () => {
    setIsSendingEmail(true);
    try {
      await ordersApi.orders.sendOrderEmail(order.id);
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    }
    setIsSendingEmail(false);
  }

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product number")}</span>
      ),
      selector: (row) => row.product?.matricule || "",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Product Name")}</span>,
      selector: (row) =>
        row.isExtraCost ? (
          <input
            className="form-control"
            value={extraCost.description || ""}
            onChange={(e) => handleExtraCostChange("description", e.target.value)}
          />
        ) : (
          (
            <span
              onClick={() =>
                history.push(`/dashboard/products/update/${row.product?.id}`)
              }
              title={row.product?.name}
              className="cursor-pointer"
            >
              <b>{row.product?.name}</b>
            </span>
          ) || "-"
        ),
      sortable: true,
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Qty")}</span>,
      selector: (row) =>
        row.isExtraCost ? (
          <input
            type="number"
            className="form-control"
            name="qty"
            value={extraCost.quantity || ""}
            onChange={(e) => handleExtraCostChange("quantity", e.target.value)}
            step="any"
          />
        ) : (
          <input
            type="number"
            className="form-control"
            name="qty"
            value={row.qty || ""}
            onChange={handleChange(row.order, row?.id)}
            step="any"
          />
        ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Type")}</span>,
      selector: (row) =>
        !row.isExtraCost && (
          <>
            {`${row.product_unit?.name} ${(row.weight && row.product_unit?.id === 1) ? `(${row.weight})` : ""}`}
          </>
        ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Price")}</span>,
      selector: (row) =>
        row.isExtraCost ? (
          <input
            type="number"
            className="form-control"
            name='price'
            value={extraCost.price || ""}
            onChange={(e) => handleExtraCostChange("price", e.target.value)}
            step="any"
          />
        ) : (
          <input
            type="number"
            className="form-control"
            name='price'
            value={row.price || ""}
            onChange={handleChange(row.order, row.id)}
            step="any"
          />
        ),
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Purchase Price")}</span>
      ),
      selector: (row) =>
        !row.isExtraCost && (
          <input
            type="number"
            className="form-control"
            value={row?.price_purchase || ""}
            step="any"
            disabled
          />
        ),
      id: "pruchase_price",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Discount")}</span>,
      selector: (row) =>
        !row.isExtraCost && (
          <input
            type="number"
            className="form-control"
            name='promo'
            value={row.promo || ''}
            onChange={handleChange(row.order, row?.id)}
          />
        ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Total")}</span>,
      selector: (row) =>
        !row.isExtraCost &&
        Number(
          calculateTotalPrice(
            row.price,
            row.promo,
            extractQuantityBasedonUnit(row)
          )
        ).toFixed(2),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Scanned")}</span>,
      selector: (row) =>
        !row.isExtraCost && (
          <div className="d-flex gap-2">
            {row.scanned ? (
              <i className="ri-check-line fs-20" style={{ color: "green" }}></i>
            ) : (
              <i className="ri-close-line fs-20" style={{ color: "red" }}></i>
            )}
          </div>
        ),
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Actions")}</span>,
      selector: (row) =>
        !row.isExtraCost && (
          <div className="d-flex gap-2" style={{minWidth: '200px'}}>
            <button type="button" className={`btn btn-sm btn-success`} onClick={() => updateItem(row)}>
              <i
                className="ri-save-2-line cursor-pointer fs-16"
              ></i>
            </button>
            {userDiscounts.some((discount) => discount.product_id === row.product?.id) ? (
              <button
                onClick={() => toggleDiscount(row)}
                type="button"
                className="btn btn-danger btn-sm"
              >
                <i className="ri-close-line cursor-pointer fs-16"></i>
              </button>
            ) : (
              <button
                onClick={() => toggleDiscount(row)}
                type="button"
                className={`btn btn-sm btn-success`}
              >
                <i className="ri-add-line cursor-pointer fs-16"></i>
              </button>
            )}
            
            <button type="button" className={`btn btn-sm btn-success`} onClick={() => refreshItem(row)}>
                <i 
                  className="ri-refresh-line"
                >
                </i>
            </button>

          </div>
        ),
        grow: 2,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Favourite")}</span>,
      selector: (row) => !row.isExtraCost && (
          <div className="d-flex gap-2">
              {row.favorite === null ? 
                <i
                  onClick={() => toggleFavorite({
                    product_id: row.product.id
                  })}
                  className="ri-heart-line fs-20 cursor-pointer"
                >

                </i>
                :
                <i
                  onClick={() => toggleFavorite({
                    fav_id: row.favorite
                  })}
                  style={{
                    color: "red",
                  }}
                  className="ri-heart-fill fs-20 cursor-pointer"
                >
                </i>
              }
          </div>
      )
    },
  ];

  useEffect(() => {
    if (order) {
      setExtraCost({
        isExtraCost: true,
        description: order.description || "Frakt",
        quantity: order.delivery_qty || "",
        price: order.delivery_price || "",
      });
    }
  }, [order]);

  useEffect(() => {
    if (order && allItems) {
      if (order.merged) {
        setOrders(Object.keys(order.items).map(key => ({
          order: `#00${key || order.id}`,
          items: order.items[key]
        })).reverse())
      } else {
        setOrders([
          {
            order: order.matricule,
            items: order.items
          }
        ])
      }
    } 
  }, [order, allItems])

  return (
    <Row className="mt-3">
        {orders ? 
          <>
            <Col lg={12}>
              <div className="hstack gap-2 mb-3">
                <button
                  className="btn btn-dark btn-sm"
                  disabled={!Object.values(itemsToDelete).some(val => val.selectedRows?.length > 0)}
                  onClick={() => setDeleteModal(true)}
                >
                  <i className="ri-delete-bin-line align-bottom"></i>{" "}
                  {t("Delete Selected")}
                </button>

                <ExportToPdf onClick={exportToPdf} />

                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  onClick={exportProducts}
                >
                  <i className="ri-car-line"></i>
                </button>

                <CustomButton
                  className="btn btn-success btn-sm"
                  type="button"
                  onClick={sendToEmail}
                  isLoading={isSendingEmail}
                >
                  <i className="ri-mail-line"></i>
                </CustomButton>

                <Link
                  to={`/dashboard/orders/${order.id}/transport`}
                  target="_blank"
                >
                  <button className="btn btn-success btn-sm">
                    <i className="ri-barcode-line"></i>
                  </button>
                </Link>
              </div>
            </Col>

            <form>
                {orders.map((ord, index) => {
                  return (
                    <div
                      key={ord.order}
                    >
                     {order?.matricule !== ord.order &&
                         <h4>{ord.order}</h4>
                      }
                      <Card>
                        <DataTable
                          itemsToDelete={itemsToDelete[ord.order]}
                          setItemsToDelete={(items) => setItemsToDelete(prev => ({
                            ...prev,
                            [ord.order]: items
                          }))}
                          deleteModal={deleteModal}
                          setDeleteModal={setDeleteModal}
                          columns={visibleColumns.purchasePrice? columns: columns.filter((col) => !col.id)}
                          data={[
                            ...sortByStockZone(ord.items.map(item => ({
                              order: ord.order,
                              ...item
                            })), locations),
                            ...(ord.items?.length > 0 && index === 0 ? [extraCost] : []),
                          ]}
                          isDeleting={isDeleting}
                          handleDelete={handleDelete}
                          selectableRows={!read}
                          selectableRowDisabled={(row) => row.isExtraCost}
                          conditionalRowStyle={[
                            {
                              when: (row) => row.no_stock,
                              style: {
                                backgroundColor: "rgb(215, 50, 67)",
                                opacity: "1",
                                pointerEvents: "none",
                                color: "white",
                                input: {
                                  display: "none",
                                },
                              },
                            },
                          ]}
                          noPagination
                        />
                      </Card>
                   </div>
                  )
                })}

              <CustomButton
                type="button"
                isLoading={isUpdating}
                className="btn btn-secondary"
                onClick={updateAll}
              >
                Save
              </CustomButton>
            </form>
          </>
        : 
        <></>
        }
      <CoverSpinner loading={isUpdating} />
    </Row>
  );
};

export default OrderDetailsTable;
