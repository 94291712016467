import { useQueries } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import ordersApi from "../../../apis/orders/api";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OrderDetails from "../../../Components/Orders/Orders/EditOrder/OrderDetails";
import OrderDetailsTable from "../../../Components/Orders/Orders/EditOrder/OrderDetailsTable";
import Tracking from "../../../Components/Tracking";
import { useDispatch, useSelector } from "react-redux";
import usersApi from "../../../apis/users/api";
import { handleExportPdf } from "../../../helpers/Pdfs/Orders/OrderPdf";
import { handleExportProductsPdf } from "../../../helpers/Pdfs/Orders/OrderProductsPdf";
import {
  calculatePrices,
  calculatePercentage,
} from "../../../helpers/calculateOrderPrices";
import ReadOnlyTable from "../../../Components/Orders/Orders/EditOrder/ReadonlyTable";
import useTracking from "../../../hooks/useTracking";
import { useTranslation } from "react-i18next";
import { getOrdersCSVData } from "../../../helpers/CSV/ordersCSV";
import AddProductToOrder from "../../../Components/Orders/Orders/EditOrder/AddProduct";  
import { CSVLink } from "react-csv";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import OrderStatus from "../../../Components/Orders/Orders/EditOrder/Status";
import { getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateOrder = () => {

  const { id, type } = useParams();

  const { t } = useTranslation();

  const { trackings, refetch: refetchTracking } = useTracking("order", id);

  const [visibleColumns, setVisibleColumns] = useState({ purchasePrice: true });

  const [readOnly, setReadOnly] = useState(null);

  const [userDiscounts, setUserDiscounts] = useState(null);

  const [order, setOrder] = useState(null);

  const [allItems, setAllItems] = useState([]);
  
  const [orderStatus, setOrderStatus] = useState(null);
  
  const dispatch = useDispatch();

  const { webSettings: settings } = useSelector((state) => state.settings);

  const { locations: { list: locations }, vats: { list: vats } } = useSelector((state) => state.products);

  const [
    { isLoading: orderLoading, refetch }, 
    { isRefetching: discountRefetching, refetch: refetchDiscounts}, 
    ] = useQueries({
    queries: [
      {
        cacheTime: 0,
        queryKey: ['orders', id],
        queryFn: () => ordersApi.orders.getOne(id),
        onSuccess({data: {data}}) {
          setOrder(data);
        }
      },

      {
        cacheTime: 0,
        queryKey: ['user_discounts'],
        enabled: false,
        queryFn: () => usersApi.users.discounts.getAll({ 
          user_id: order?.company?.id, 
          for: 'order' 
        }),
        onSuccess({ data: { data } }) {
          setUserDiscounts(data);
        },
      },
    ],
  });

  const toggleVisibleColumns = () => {
    setVisibleColumns({
      ...visibleColumns,
      purchasePrice: !visibleColumns.purchasePrice,
    });
    localStorage.setItem("showPurchasePrice", !visibleColumns.purchasePrice);
  };

  useEffect(() => {
    if (order) {

      if (userDiscounts === null) {
        refetchDiscounts();
      }

      const status = order.current_status;
      if (status?.name.toLowerCase() === "fakturert" || status?.name.toLowerCase() === "kanselert") {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }

      if (order.merged) {
        setAllItems(
          Object.values(order.items).reduce((accum, items) => {
            return [...accum, ...items];
          }, [])
        );
      } else {
        if (type === "order") {
          setAllItems(order.items);
        } 
      }
 
    }
  }, [order]);

  useEffect(() => {
    const showPurchasePrice = JSON.parse(localStorage.getItem("showPurchasePrice"));
    setVisibleColumns({
      ...visibleColumns,
      purchasePrice: showPurchasePrice || false,
    });
  }, []);

  useEffect(() => {
    dispatch(getAllProductsDataAction({
      field: 'locations'
    }));
    dispatch(getAllProductsDataAction({
      field: 'vats'
    }));
  }, [dispatch]);

  return (
      <PageContainer isLoading={orderLoading || discountRefetching || !order || (userDiscounts === null)} >
          <BreadCrumb
            to="/dashboard/orders"
            title="Change Order"
            pageTitle="Orders"
          />

          {(order && (userDiscounts !== null)) && (
            <Row className="justify-content-end">

              <Col md={12}>

                <OrderDetails
                  refetch={refetch}
                  order={order}
                  status={orderStatus}
                  calculatePrices={calculatePrices}
                  calculatePercentage={calculatePercentage}
                  readOnly={readOnly}
                />

                <div className="hstack gap-3 mb-3 align-items-end">
                  
                    <button
                      type="button"
                      className={`btn btn-${visibleColumns?.purchasePrice ? "success" : "danger"} d-flex align-items-center gap-1`}
                      onClick={(e) => toggleVisibleColumns()}
                    >
                      {visibleColumns?.purchasePrice ? (
                        <i className="ri-check-line"></i>
                      ) : (
                        <i className="ri-close-line"></i>
                      )}
                      {t("Purchase Price")}
                    </button>

                    <div>
                      <CSVLink
                        data={getOrdersCSVData({
                          order,
                          vats,
                          locations,
                        })}
                        type="button"
                        className='btn btn-secondary'
                        id="delete-record"
                        filename={`Ordre${order?.matricule || ''}`}
                      >
                        {t("Export CSV")}
                      </CSVLink>
                    </div>

                    <div>
                      <CustomLabel>Status</CustomLabel>
                      <OrderStatus 
                        order={order}
                        status={orderStatus}
                        setStatus={setOrderStatus}
                        refetchTracking={refetchTracking}
                      />
                    </div>
                </div>

                {!readOnly && 
                  <AddProductToOrder
                    allItems={allItems}
                    setAllItems={setAllItems}
                    refetchOrder={refetch}
                    order={order}
                    visibleColumns={visibleColumns}
                    userDiscounts={userDiscounts}
                    refetchTracking={refetchTracking}
                  />
                }

                {readOnly ? 
                  <ReadOnlyTable
                    allItems={allItems}
                    exportProducts={() =>
                      handleExportProductsPdf({
                        order,
                        type,
                        locations,
                        settings,
                      })
                    }
                    refetch={refetch}
                    exportToPdf={() =>
                      handleExportPdf({
                        order,
                        type,
                        vats,
                        calculatePrices,
                        locations,
                        settings,
                      })
                    }
                    exportToCSV={() => getOrdersCSVData({
                      order,
                      vats,
                      locations,
                    })}
                    order={order}
                    visibleColumns={visibleColumns}
                    toggleVisibleColumns={toggleVisibleColumns}
                    index={1}
                  />
                : 
                  <OrderDetailsTable
                    allItems={allItems}
                    exportProducts={() =>
                      handleExportProductsPdf({
                        order,
                        type,
                        locations,
                        settings,
                      })
                    }
                    refetch={refetch}
                    exportToPdf={() =>
                      handleExportPdf({
                        order,
                        type,
                        vats,
                        calculatePrices,
                        locations,
                        settings,
                      })
                    }
                    exportToCSV={() => getOrdersCSVData({
                      order,
                      vats,
                      locations,
                    })}
                    order={order}
                    visibleColumns={visibleColumns}
                    toggleVisibleColumns={toggleVisibleColumns}
                    index={1}
                    userDiscounts={userDiscounts}
                    setUserDiscounts={setUserDiscounts}
                    refetchTracking={refetchTracking}
                  />
                }
              </Col>

              <Col md={4}>

                <Card>
                  <CardBody className="d-flex flex-column gap-3">
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("Shipping Type")}</span>
                      <p className="fs-18">{order.delivery_type?.name}</p>
                    </div>
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("Total Weight")}</span>
                      <p className="fs-18">{allItems.reduce((accum, val) => {
                        return accum + val.weight
                      }, 0)} Kg</p>
                    </div>
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("NET")}</span>
                      <p className="fs-20 font-weight-bold">
                        {calculatePrices({ order, type }).netPrice}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("Discount")}</span>
                      <p className="fs-20 font-weight-bold">
                        {calculatePrices({ order, type }).discountPrice}
                      </p>
                    </div>
                    {order.delivery_price ? (
                      <div className="d-flex justify-content-between border-bottom">
                        <span>{order.description}</span>
                        <p className="fs-20 font-weight-bold">
                          {calculatePrices({ order, type }).netExtraCost}
                        </p>
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                      <span>{t("Total")}</span>
                      <p className="fs-3 font-weight-bold">
                        {calculatePrices({ order, type }).netSellingPrice}
                      </p>
                    </div>
                  </CardBody>
                </Card>

              </Col>

              <Tracking data={trackings || []} />
              
            </Row>
          )}
      </PageContainer>
  );
};

export default UpdateOrder;
