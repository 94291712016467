import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../Common/DataTable";

const ProductVars = ({ initialValues }) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("Batch")}</span>,
      selector: (row) => <span title={row.batch}>{row.batch || ""}</span>,
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Quantity")}</span>,
      selector: (row) => row.qty || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Lot")}</span>,
      selector: (row) => row.lot || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Efta")}</span>,
      selector: (row) => row.lot || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Weight")}</span>,
      selector: (row) => row.weight || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Temperatue")}</span>,
      selector: (row) => row.temperature || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Expires")}</span>,
      selector: (row) => (
        <span title={row.exp_date}>{row.exp_date || "-"}</span>
      ),
      grow: 1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Created")}</span>,
      selector: (row) => (
        <span title={row.created_at}>{row.created_at || "-"}</span>
      ),
      grow: 1,
    },
  ];

  return (
    <DataTable 
      data={initialValues?.product_vars || []} 
      columns={columns} 
      pageSize={100} 
    />
  );
};

export default ProductVars;
