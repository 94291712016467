import { configureStore } from "@reduxjs/toolkit";
import layoutSlice from "./slices/layout";
import productsSlice from "./slices/products";
import usersSlice from "./slices/users";
import ordersSlice from "./slices/orders";
import storageSlice from "./slices/storage";
import settingsSlice from "./slices/settings";

const store = configureStore({
  reducer: {
    layout: layoutSlice,
    orders: ordersSlice,
    products: productsSlice,
    storage: storageSlice,
    users: usersSlice,
    settings: settingsSlice
  },
});

export default store;