import { objectFilter } from "../../objectFilter";

export const openingHoursBody = (data) => {
  return objectFilter({
    type: "opening_hours",
    data: Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (typeof value === "boolean") {
          return [key, value === true ? 1 : 0];
        }
        return [key, value];
      })
    ),
  });
};
