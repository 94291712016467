import React from "react";
import DataTable from "../../Common/DataTable";
import { CardFooter } from "reactstrap";
import CustomButton from "../../Common/CustomButton";
import { useTranslation } from "react-i18next";
import DeleteCatalogueProductButton from "./DeleteCatalogueProductButton";
import useLoading from "../../../hooks/useLoading";
import toastAlert from "../../../helpers/toastAlert";
import productsApi from "../../../apis/products/api";

const SelectedProductsTab = ({
  setData,
  onDeleteProduct,
  selectedProducts,
}) => {
  const { t } = useTranslation();

  const { isLoading, onLoading, onStopLoading } = useLoading();

  const onSelectedProductPriceChange = (productID, price) => {
    setData((currentData) => {
      return {
        ...currentData,
        products: currentData.products.map((product) => {
          if (product.id === productID) {
            return {
              ...product,
              price,
            };
          }
          return product;
        }),
      };
    });
  };

  const onSaveProductsClick = async () => {
    try {
      onLoading();
      const body = {
        products: selectedProducts.map((product) => {
          return {
            id: product.id,
            price: product.price,
          };
        }),
      };
      await productsApi.catalogue.products.update(body);
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error'})
    } finally {
      onStopLoading();
    }
  };

  const selectedProductsColumns = [
    {
      name: <span className="fw-bold fs-13">{t("Name")}</span>,
      selector: (row) => row.product,
    },
    {
      name: <span className="fw-bold fs-13">{t("Price")}</span>,
      selector: (row) => (
        <input
          type="number"
          className="form-control"
          value={row.price}
          onChange={(e) => onSelectedProductPriceChange(row.id, e.target.value)}
        />
      ),
    },
    {
      name: <span className="fw-bold fs-13">{t("Actions")}</span>,
      selector: (row) => (
        <DeleteCatalogueProductButton
          id={row.id}
          onDeleteClick={onDeleteProduct}
        />
      ),
    },
  ];

  return (
    <>
      <DataTable data={selectedProducts} columns={selectedProductsColumns} />
      <CardFooter>
        <CustomButton
          isLoading={isLoading}
          onClick={onSaveProductsClick}
          className="btn btn-success"
        >
          Save
        </CustomButton>
      </CardFooter>
    </>
  );
};

export default SelectedProductsTab;
