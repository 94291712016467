import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import CustomButton from "../../Common/CustomButton";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import { createOrdersDataAction, updateOrdersDataAction } from "../../../redux/actions/orders";
import { getAllUsersDataAction } from "../../../redux/actions/users";
import SaveButton from "../../Common/Buttons/SaveButton";

const NewNewsletterModal = ({ modalType, isOpen, toggleModal }) => {

  const { type, currentValues } = modalType;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [date, setDate] = useState("");

  const [note, setNote] = useState("");

  const { groups: { list: groups } } = useSelector((state) => state.users);

  const [loadingGroups, setLoadingGroups] = useState(false);

  const [group, setGroup] = useState(null);

  const onSubmit = () => {

    setIsSubmitting(true);

    const requestBody = {
      user_group_id: group.value,
      sended_at: date,
      note,
    };

    if (type === "create") {
      dispatch(
        createOrdersDataAction({
          field: 'newsletters',
          data: requestBody,
          onSuccess: () => {
            setIsSubmitting(false);
            toggleModal();
            setNote("");
          },
          onError: (error) => {
            setIsSubmitting(false);
          },
        })
      );
    } else {
      dispatch(
        updateOrdersDataAction({
          field: 'newsletters',
          data: {
            id: currentValues.id,
            data: requestBody,
          },
          onSuccess: () => {
            setIsSubmitting(false);
            toggleModal();
            setNote("");
          },
          onError: (error) => {
            setIsSubmitting(false);
            console.log(error);
          },
        })
      );
    }
  };

  const onClosed = () => {
    setGroup(null);
  }

  useEffect(() => {
    if (type === 'update') {
      setGroup(currentValues.group);
      setNote(currentValues.note);
      setDate(currentValues.sended_at);
      setIsSubmitting(false);
    }
  }, [type, currentValues]);

  useEffect(() => {
    if (isOpen && type === 'create') {
      if (!loadingGroups) setLoadingGroups(true);
      dispatch(getAllUsersDataAction({
        field: 'groups',
        query: {
          page_size: 'all'
        },
        onSuccess: () => setLoadingGroups(false)
      }));
    }
  }, [isOpen])

  return (

    <Modal
      id="signupModals"
      tabIndex="-1"
      isOpen={isOpen}
      toggle={toggleModal}
      onClosed={onClosed}
      modalClassName="zoomIn"
      centered
    >
      <ModalHeader className="bg-light text-capitalize p-3" toggle={toggleModal}>
        {t(`${type} Newsletter`)}
      </ModalHeader>

      <ModalBody>
        <form>
          <div className="mb-3 mt-3">
            <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
              User group
            </CustomLabel>
            <Select
              value={group}
              placeholder={t('Search')}
              onChange={(value) => setGroup(value)}
              isLoading={loadingGroups}
              isDisabled={type === "update" || loadingGroups}
              options={groups.map((group) => ({
                value: group.id,
                label: group.name,
              }))}
            />
          </div>

          <Col xs={12} md={12}>
            <CustomLabel>Sends at</CustomLabel>
            <Flatpickr
              className="form-control"
              options={{
                dateFormat: "d M, Y",
              }}
              value={date && moment(date).format("D MMM, YYYY")}
              onChange={(date) => setDate(moment(date[0]).format("YYYY-MM-DD"))}
            />
          </Col>

          <div className="mb-3 mt-3">
            <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
              Note
            </CustomLabel>
            <textarea
              className="form-control"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </form>

        <div className="d-flex gap-2 justify-content-end">

          <CustomButton  
            className="btn-light" 
            onClick={toggleModal}
          >
            Cancel
          </CustomButton>

          <SaveButton
            onClick={onSubmit}
            isLoading={isSubmitting}
          />

        </div>
        
      </ModalBody>
    </Modal>
  );
};

export default NewNewsletterModal;
