import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import RecentOrders from "../../Components/Home/RecentOrders/RecentOrders";
import Revenue from "../../Components/Home/Revenue/Revenue";
import Customers from "../../Components/Home/Customer/Customers";
import TopCategories from "../../Components/Home/TopCategories/TopCategories";
import TopCustomers from "../../Components/Home/TopCustomers/TopCustomers";
import TrendingMenus from "../../Components/Home/TrendingMenus/TrendingMenus";
import Welcome from "../../Components/Home/Welcome";
import Widgets from "../../Components/Home/Widgets/Widgets";
import PageContainer from "../../Components/Layout/PageContainer";
import { useQueries } from "@tanstack/react-query";
import { statisticsApi } from "../../apis/Statistics/api";

const Home = () => {

  const [statistics, setStatistics] = useState(null);

  const [revenue, setRevenue] = useState(null);
  
  const [customers, setCustomers] = useState(null);

  const {isLoading, isRefetching} = useQueries({
    queries: [
      {
        queryKey: ['statistics'],
        queryFn: statisticsApi.getAll,
        onSuccess({data: {data}}) {
          setStatistics(data);
        }
      },

      {
        queryKey: ['reveune'],
        queryFn: statisticsApi.revenue,
        onSuccess({data: {data}}) {
          setRevenue(data)
        }
      },

      {
        queryKey: ['customers'],
        queryFn: statisticsApi.customers,
        onSuccess({data: {data}}) {
          setCustomers(data)
        }
      },
    ],
    
  });

  return (
    <PageContainer
      isLoading={isLoading || isRefetching || !statistics || !revenue}
    >
      <Row>
        <Col lg={12}>
          <Welcome />
        </Col>

        <Col lg={7} md={12}>
          <Row className="h-100">

            <Col lg={12}>
              <Widgets 
                data={statistics}
              />
            </Col>

            <Col lg={12}>
              <Revenue 
                data={revenue}
              />
            </Col>

            <Col lg={12}>
              <Customers 
                data={customers}
              />
            </Col>

          </Row>
        </Col>

        <Col lg={5} md={12}>

          <Row>
            <Col lg={12}>
              <TopCategories 
                data={statistics}
              />
            </Col>

            <Col md={12} 
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '.6rem'
              }}
            >
                <TopCustomers 
                  data={statistics}
                />

                <TrendingMenus 
                  data={statistics}
                />
            </Col>

          </Row>

        </Col>

        <Col lg={12}>
            <RecentOrders 
              data={statistics}
            />
        </Col>

      </Row>

    </PageContainer>
  );
};

export default Home;
