import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CustomOption from "../../../Components/Custom/CustomOption";
import ProductsProblemsTable from "../../../Components/Products/Problems/Table";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { deleteProductsDataAction, getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const ProductsProblems = () => {

  const dispatch = useDispatch();
  
  const { problems: { list: problems, total } } = useSelector(state => state.products);

  const { load, callback, itemsToDelete, deleteModal, isDeleting, setItemsToDelete, setDeleteModal } = useDeleteMultiple();
  
  const [loading, setLoading] = useState(true);

  const searchFunc = async (query) => {
    if (!loading) setLoading(true);
    dispatch(
      getAllProductsDataAction({
        field: 'problems',
        query,
        onSuccess: () => setLoading(false),
      })
    );
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'productsProblems',
    cachedValues: {
      issues: 'no_price',
    },
    searchFunc
  });

  const handleDelete = () => {
    load();
    dispatch(
      deleteProductsDataAction({ 
        field: 'problems',
        data: {
          ids: itemsToDelete.selectedRows?.map((row) => row.id)
        },
        onSuccess() {
          callback();
          search();
        }
       })
    );
    callback();
  };

  return (
      <PageContainer>

        <BreadCrumb
          pageTitle="Products"
          title="Problems"
          to="/dashboard/products"
        />

        <Row>
          <Col lg={4} md={6} sm={12}>
            <div className="hstack gap-2">
                
                <select
                  className="form-select"
                  value={searchValues.issues}
                  onChange={(e) => handleChange({
                    type: 'cached',
                    field: 'issues',
                    value: e.target.value
                  })}
                >
                  <CustomOption value="no_price">No Price</CustomOption>
                  <CustomOption value="no_codebar">No Barcode</CustomOption>
                  <CustomOption value="no_category">No Category</CustomOption>
                  <CustomOption value="no_group">No Group</CustomOption>
                  <CustomOption value="no_vat">No Tax</CustomOption>
                  <CustomOption value="same_name">Same Name</CustomOption>
                  <CustomOption value="no_location">No Location</CustomOption>
                </select>

                <CustomButton
                  className="btn btn-danger"
                  disabled={!itemsToDelete.selectedRows?.length > 0}
                  onClick={() => setDeleteModal(true)}
                >
                  <i className="ri-delete-bin-line align-bottom me-1"></i>
                  Delete
                </CustomButton>

            </div>
          </Col>
        </Row>

        <Card className="mt-3">
          <ProductsProblemsTable
            data={problems}
            paginationServer
            paginationTotalRows={total}
            navigateToPage={navigateToPage}
            selectableRows
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDelete={handleDelete}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            sortDirection={searchValues.order_value}
            fetching={loading}
            isDeleting={isDeleting}
            path="/dashboard/products"
          />
        </Card>

      </PageContainer>
  );
};

export default ProductsProblems;
