import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productsApi from "../../../apis/products/api";
import CategoryForm from "../../../Components/Products/Categories/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateCategory = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["categories", id], () => productsApi.categories.getOne(id),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess({data: {data}}) {
        setInitialValues(data);
      }
    }
  );
  
  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb title='Update' pageTitle="Categories" to="/dashboard/products/categories" />
      {initialValues && 
        <CategoryForm 
          type="update" 
          initialValues={initialValues} 
        />
      }
    </PageContainer>
  );
};

export default UpdateCategory;
