import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomOption from '../../../Custom/CustomOption';
import ordersApi from '../../../../apis/orders/api';
import toastAlert from '../../../../helpers/toastAlert';
import { useHistory } from 'react-router-dom';
import { useAuthState } from '../../../../context/authContext';
import { getAllOrdersDataAction } from '../../../../redux/actions/orders';

const OrderStatus = ({ order, status, setStatus, refetchTracking }) => {

  const { statuses: { list: statuses } } = useSelector((state) => state.orders);
  
  const [refreshCondition, setRefreshCondition] = useState(null);

  const { adminData } = useAuthState();

  const dispatch = useDispatch();

  const history = useHistory();

  const handleRefresh = (oldId, newId) => {
    const oldStatus = statuses.find(stat => stat.id === oldId).name;
    const newStatus = statuses.find((stat) => stat.id === newId).name;
    if ((refreshCondition.side1.includes(oldStatus) && refreshCondition.side2.includes(newStatus)) || (refreshCondition.side1.includes(newStatus) && refreshCondition.side2.includes(oldStatus))) {
      history.go(0)
    }

  }

  const handleStatusChange = async (e) => {
    const newId = +e.target.value;
    if (isEnabled(status)) {
      setStatus(newId);
      await ordersApi.orders.status.update({
        order_id: order.id,
        status_id: newId,
      });
      toastAlert({ type: 'success'})
      handleRefresh(status, newId)
      refetchTracking();
    }
  };

  useEffect(() => {
    if (statuses?.length) {
      const statusNames = statuses.map(item => item.name).filter(status => {
        return (status !== 'Fakturert') && (status !== 'Kanselert')
      });
      setRefreshCondition({
        side1: ['Kanselert', 'Fakturert'],
        side2: [...statusNames]
      })
    }
  }, [statuses])

  const isEnabled = (status) => {
    const currentStatus = statuses.find((stat) => stat.id === status)?.name;
    if (currentStatus === 'Fakturert' || currentStatus === 'Kanselert') {
      return adminData?.roles?.every(role => role.name === 'super-admin')
    }
    return true
  }

  useEffect(() => {
    if (order) setStatus(order.current_status.id)
  }, [order])

  useEffect(() => {
    dispatch(getAllOrdersDataAction({
      field: 'statuses',
      query: {
        page_size: 'all'
      }
    }));
  }, [dispatch])

  return (
    <select
        name="status"
        onChange={handleStatusChange}
        value={status || ''}
        className="form-select"
        disabled={!isEnabled(status)}
    >
        {statuses.map((stat) => (
          <CustomOption key={stat.id} value={stat.id}>
              {stat.name}
          </CustomOption>
        ))}
    </select>
  )
}

export default OrderStatus