import React from "react";
import DataTable from "../../Common/DataTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CustomInput from "../../Custom/CustomInput";
import StatusToggle from "../../Common/StatusToggle";

const ZonesTable = ({
  filters,
  handleFilterChange,
  ...props
}) => {

  const { t } = useTranslation();

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },

    {
      name:           
      <CustomInput
        placeholder="Name"
        className="form-control"
        value={filters.name}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'name',
          value: e.target.value
        })}
        data-filtered={!!filters.name}
      />,
      selector: (row) => row.name,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: row => <StatusToggle type="regions" id={row.id} field="status" status={row.status} />
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Created at")}</span>,
      selector: (row) =>
        row.created_at ? moment(row.created_at).format("D MMM, YYYY") : "-",
    },

  ];

  return (
      <DataTable
        columns={columns}
        {...props}
      />
  );
};

export default ZonesTable;
