import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { useHistory} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createVatSchema } from "../../../helpers/validation/Products/createVat";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import CustomLabel from "../../Custom/CustomLabel";
import { createProductsDataAction, updateProductsDataAction } from "../../../redux/actions/products";
import SaveButton from "../../Common/Buttons/SaveButton";

const VatForm = ({ type, initialValues }) => {
  
  const { register,  handleSubmit, formState: { errors } } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(createVatSchema),
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (data) => {
    setIsLoading(true)
    if (type === "create") {
      dispatch(
        createProductsDataAction({
          field: 'vats',
          data: data,
          onSuccess: () => history.push("/dashboard/products/vat"),
        })
      );
    } else {
      const { id, ...rest } = data;
      dispatch(
        updateProductsDataAction({
          field: 'vats',
          data: {
            id,
            data: rest,
          },
          onSuccess: () => setIsLoading(false),
        })
      );
    }
  };

  return (
    <div>
      <Col md={12}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card className="p-3">
            <Row className="gx-4 gy-2">
              <Col md={6}>
                <CustomLabel>Name</CustomLabel>
                <input
                  {...register("name")}
                  className="form-control"
                  id="inputName"
                />
                {errorMessage(errors, "name")}
              </Col>

              <Col md={6}>
                <CustomLabel>Percentage</CustomLabel>
                <input
                  {...register("percentage")}
                  type="number"
                  className="form-control"
                  step="any"
                />
                {errorMessage(errors, "percentage")}
              </Col>
            </Row>

            <Col md={6}>
              <div className="form-check form-switch mt-4">
                  <input 
                    {...register('status')} 
                    className="form-check-input fs-16" 
                    type="checkbox" 
                    role="switch" 
                    id="status" 
                  />
                  <CustomLabel 
                    className="form-check-label" 
                    htmlFor="status"
                  >
                    Active
                  </CustomLabel>
              </div>
            </Col>
          </Card>

          <div className="my-2 d-flex justify-content-end">

            <SaveButton
              isLoading={isLoading}
            />

          </div>

        </form>
      </Col>
    </div>
  );
};

export default VatForm;
