import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { CardBody, CardHeader, Card } from "reactstrap";
import { useAuthState } from "../../../../context/authContext";
import { isSuperAdmin } from "../../../../helpers/isSuperAdmin";
import useCreateUpdateModal from "../../../../hooks/useCreateUpdateModal";
import CustomButton from "../../../Common/CustomButton";
import DeleteModal from "../../../Common/DeleteModal";
import NewOrderModal from "../CreateOrder/Modal";
import {
  calculatePrices,
} from "../../../../helpers/calculateOrderPrices";
import numeral from "numeral";
import { deleteOrdersDataAction } from "../../../../redux/actions/orders";

const OrderDetails = ({
  order,
  status,
  refetch,
  refetchTracking,
  readOnly,
}) => {
  const { t } = useTranslation();

  const [isDeleting, setIsDeleting] = useState(false);
  const { id, type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState();
  const [showDetails, setShowDetails] = useState(false);

  const { modalType, update } = useCreateUpdateModal();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen((prev) => !prev);

  const { adminData } = useAuthState();

  const openUpdateModal = () => {
    update({
      id: order.id,
      user_company_id: order.company.id,
      user_company_name: order?.ompany?.profile?.company || `${order?.company?.first_name} ${order?.company?.last_name}` || "",
      delivery_type_id: order.delivery_type?.id || "",
      delivery_id: order.delivery?.id || "",
      delivered_at: new Date(order.delivered_at),
      current_status_id: status || "",
      payment: order.payment || "",
    });
    setModalOpen(true);
  };

  const deleteOrder = () => {
    if (!id) return;
    setIsDeleting(true);
    dispatch(
      deleteOrdersDataAction({
        field: 'orders',
        data: {
          ids: [+id]
        },
        onSuccess: history.push("/dashboard/orders"),
      })
    );
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
    localStorage.setItem("showOrderDetails", !showDetails);
  };

  useEffect(() => {
    const lcDetails = JSON.parse(localStorage.getItem("showOrderDetails"));
    if (!lcDetails) {
      localStorage.setItem("showOrderDetails", false);
    } else {
      setShowDetails(lcDetails);
    }
  }, []);

  return (
    <Card>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={deleteOrder}
        isDeleting={isDeleting}
      />
      <NewOrderModal
        refetchOrder={refetch}
        modalOpen={modalOpen}
        modalType={modalType}
        toggleModal={toggleModal}
        refetchTracking={refetchTracking}
        userDisabled
      />
      <CardHeader className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0">{t("Order Details")}</h5>
        <div className="d-flex gap-2">
          {!readOnly && (
            <>
              {type === "order" && (
                <CustomButton
                  className="btn btn-success"
                  onClick={openUpdateModal}
                >
                  Edit
                </CustomButton>
              )}
              {isSuperAdmin(adminData) ? 
                <CustomButton
                  className="btn btn-danger"
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </CustomButton>
              : <></>}
            </>
          )}

          <button className="btn btn-success" onClick={toggleDetails}>
            {t(showDetails ? "Hide Details" : "Show Details")}
          </button>
        </div>
      </CardHeader>
      <CardBody className="d-flex gap-1 flex-wrap">
        <div className="border border-dashed py-2 px-3 text-capitalize">
          <h6 className="text-muted">{t("Order Number")}:</h6>
          <h6 className="fs-13 mb-0">{order.matricule || t("Unknown")}</h6>
        </div>

        <div className="border border-dashed py-2 px-3 text-capitalize">
          <h6 className="text-muted">{t("Company No")}:</h6>
          <h6 className="fs-13 mb-0">{order.company?.profile?.matricule || t("Unknown")}</h6>
        </div>

        <div className="border border-dashed py-2 px-3">
          <h6 className="text-muted">{t("Company Name")}:</h6>
          <Link className="fs-14 mb-0 fw-bold text-dark cursor-pointer" to={`/dashboard/users/update/${order.company?.id}`}>
            {order?.company?.profile?.company ||
              `${order?.company?.first_name} ${order?.company?.last_name}`}
          </Link>
        </div>

        {(order.company?.profile?.user_group?.name || order?.group?.name) && (
          <div className="border border-dashed py-2 px-3">
            <h6 className="text-muted">{t("Group")}:</h6>
            <h6 className="fs-13 mb-0">
              {order.company?.profile?.user_group?.name ||
                order.user?.profile?.user_group?.name ||
                order?.group?.name}
            </h6>
          </div>
        )}

        {type === "order" && (
          <>
             <div className="border border-dashed py-2 px-3">
              <h6 className="text-muted">{t("Created at")}:</h6>
              <h6 className="fs-13 mb-0">
                {order.created_at ? moment(order.created_at).format("D MMM, YYYY, HH:mm") : t("Unknown")}
              </h6>
            </div>
            <div className="border border-dashed py-2 px-3">
              <h6 className="text-muted">{t("Delivery Date")}:</h6>
              <h6 className="fs-13 mb-0">
                {moment(order.delivered_at).format("D MMM, YYYY") || t("Unknown")}
              </h6>
            </div>

            <div className="border border-dashed py-2 px-3">
              <h6 className="text-muted">{t("Status")}:</h6>
              <h6 className="text-warning fs-13 mb-0">
                {order.current_status?.name || t("None")}
              </h6>
            </div>

            <div className="border border-dashed py-2 px-3">
              <h6 className="text-muted">{t("Payment")}:</h6>
              <h6 className="text-warning fs-13 mb-0">
                {t(order.payment) || t("Unpaid")}
              </h6>
            </div>

            <div className="border border-dashed py-2 px-3">
              <h6 className="text-muted">{t("Shipping")}:</h6>
              <h6 className="fs-13 mb-0">
                {t(order.delivery_type?.name) || t("Unknown")}
              </h6>
            </div>
          </>
        )}

        <div className="border border-dashed py-2 px-3">
          <h6 className="text-muted">{t("Order From")}:</h6>
          <h6 className="fs-13 mb-0">{t(order.app ? "App" : "Web")}</h6>
        </div>

        {showDetails && (() => {

            const { diff, fullPercentage } = calculatePrices({ order, type });

            return (
              <>
                  <div className="border border-dashed py-2 px-2">
                    <h6 className="text-muted">{diff > 0 ? t("Pd") : t("BELP")}:</h6>
                    <h6 className={`fs-13 mb-0 ${diff > 0 ? 'text-success' : diff < 0 ? 'text-danger' : 'text-primary'}`}>
                      {diff}
                    </h6>
                  </div>

                  <div className="border border-dashed py-2 px-3">
                    <h6 className="text-muted">{t("Net Percentage")}:</h6>
                    <h6
                      className={`${fullPercentage < 0 ? "text-danger" : "text-success"} fs-13 mb-0`}
                    >
                      {fullPercentage === "NaN" ? 0.00 : fullPercentage > 1000000 ? '+1,000,000' : numeral(fullPercentage).format('0,0.00[00]')}%
                    </h6>
                  </div>
              </>
           )
        })()
        }
      </CardBody>
    </Card>
  );
};

export default OrderDetails;
