import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import CustomLabel from "../../Custom/CustomLabel";

const TransportPalletRow = ({ locations, register, remove, index, formState, setValue, pallet }) => {
  
  const { orders: { units: { list: units } }} = useSelector((state) => state);

  const [selectedUnit, setSelectedUnit] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    if (locations.length && units.length) {
      const unit = units.find((unit) => unit.id === pallet?.transport_unit_id);
      const location = locations.find(
        (location) => location.id === pallet?.stock_zone_id
      );
      setSelectedUnit({
        label: unit?.name || "",
        value: unit?.id || "",
      });

      setSelectedLocation({
        label: location?.zone || "",
        value: location?.id || "",
      });
    }
  }, [locations, units, pallet]);

  useEffect(() => {
    if (units.length) {
      const unitType = formState?.units[index]?.unit_type;

      if (!unitType?.value) return;
      const unit = units.find((unit) => +unit.id === +unitType.value);

      if (unit.id === pallet?.transport_unit_id) {
        setValue(`units[${index}].length`, pallet.length);
        setValue(`units[${index}].width`, pallet.width);
        setValue(`units[${index}].height`, pallet.height);
        setValue(`units[${index}].volume`, pallet.volume);
      } else {
        setValue(`units[${index}].length`, unit?.length);
        setValue(`units[${index}].width`, unit?.width);
        setValue(`units[${index}].height`, unit?.height);
        setValue(
          `units[${index}].volume`,
          (unit?.length * unit?.width * unit?.height).toFixed(2)
        );
      }
    }
  }, [units, formState?.units[index]?.unit_type]);


  useEffect(() => {
    setValue(`units.${index}.chargable_weight`, selectedLocation);
  }, [selectedLocation])

  useEffect(() => {
    setValue(`units.${index}.unit_type`, selectedUnit);
  }, [selectedUnit])


  return (
    <Row className="my-4 px-2">
      <Col md={2}>
        <CustomLabel className="p-0">Chargable Weight</CustomLabel>
        {selectedLocation && (
            <Select
              className="p-0"
              options={locations.map((location) => ({
                value: location.id,
                label: location.zone,
              }))}
              onChange={(e) => {
                setSelectedLocation(e);
              }}
              value={selectedLocation}
            />
        )}
      </Col>

      <Col md={1}>
        <CustomLabel>Marks</CustomLabel>
        <input
          className="form-control p-2"
          placeholder="Marks"
          {...register(`units.${index}.marks`)}
        />
      </Col>

      <Col>
        <CustomLabel>Weight</CustomLabel>
        <input
          type="number"
          className="form-control p-2"
          placeholder="Gross Weight"
          {...register(`units.${index}.weight`)}
          step="any"
        />
      </Col>

      <Col>
        <CustomLabel>Units</CustomLabel>

        <input
          type="number"
          className="form-control p-2"
          placeholder="Units"
          {...register(`units.${index}.units`)}
          step="any"
        />
      </Col>

      <Col md={2}>
        <CustomLabel className="p-0">Type</CustomLabel>
        {selectedUnit && (
            <Select
              options={units.map((unit) => {
                return {
                  value: unit.id,
                  label: unit.name,
                };
              })}
              defaultValue={selectedUnit}
              onChange={(e) => {
                setSelectedUnit(e)
              }}
            />
        )}
      </Col>

      <Col>
        <CustomLabel>Length</CustomLabel>
        <input
          type="number"
          className="form-control p-2"
          placeholder="Unit length"
          {...register(`units.${index}.length`)}
          step="any"
        />
      </Col>

      <Col>
        <CustomLabel>Width</CustomLabel>
        <input
          type="number"
          className="form-control p-2"
          placeholder="Unit Width"
          {...register(`units.${index}.width`)}
          step="any"
        />
      </Col>

      <Col>
        <CustomLabel>Height</CustomLabel>

        <input
          type="number"
          className="form-control p-2"
          placeholder="Unit Height"
          {...register(`units.${index}.height`)}
          step="any"
        />
      </Col>
      <Col>
        <CustomLabel>Volume</CustomLabel>

        <input
          type="number"
          className="form-control p-2"
          placeholder="Volume"
          {...register(`units.${index}.volume`)}
          step="any"
        />
      </Col>

      <Col className="d-flex justify-content-center align-items-end p-0 m-0">
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => remove(index)}
        >
          <i className="ri-delete-bin-line"></i>
        </button>
      </Col>
    </Row>
  );
};

export default TransportPalletRow;
