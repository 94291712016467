import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, CardFooter, Col, Form, Row } from "reactstrap";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import socialMediaSchema from "../../../helpers/validation/WebSettings/SocialMedia";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { WEB_SETTINGS_PERMISSIONS } from "./../../../permissions/webSettings";
import Restricted from "./../../Guard/Restricted";
import CustomButton from "../../Common/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import { useTranslation } from "react-i18next";
import { getWebSettingsAction, setWebSettingsAction } from "../../../redux/actions/settings";

const SocialMedia = () => {

  const dispatch = useDispatch();

  const { socialMedia } = useSelector((state) => state.settings.webSettings);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(socialMediaSchema),
    defaultValues: socialMedia,
  });

  const submitSocialMedia = (data) => {
    dispatch(
      setWebSettingsAction({
        field: 'socialMedia',
        data: {
          type: "social",
          data
        }
      })
    );
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (socialMedia) {
      reset(socialMedia);
    }
  }, [])

  useEffect(() => {
    dispatch(getWebSettingsAction({
      field: 'socialMedia',
      type: 'social_media'
    }));
  }, []);
  

  return (
    <Row className="mt-3">
      <Form onSubmit={handleSubmit(submitSocialMedia)}>
        <input type="submit" hidden />
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <CustomLabel
                className="form-label"
                htmlFor="socialMedia-facebook-input"
              >
                Facebook
              </CustomLabel>
              <CustomInput
                name="facebook"
                type="text"
                className="form-control"
                id="socialMedia-facebook-input"
                {...register("facebook")}
              />
              {errorMessage(errors, "facebook")}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <CustomLabel
                className="form-label"
                htmlFor="socialMedia-twitter-input"
              >
                Twitter
              </CustomLabel>
              <CustomInput
                name="twitter"
                type="text"
                className="form-control"
                id="socialMedia-twitter-input"
                {...register("twitter")}
              />
              {errorMessage(errors, "twitter")}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <CustomLabel
                className="form-label"
                htmlFor="socialMedia-instagram-input"
              >
                Instagram
              </CustomLabel>
              <CustomInput
                name="instagram"
                type="text"
                className="form-control"
                id="socialMedia-instagram-input"
                {...register("instagram")}
              />

              {errorMessage(errors, "instagram")}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <CustomLabel
                className="form-label"
                htmlFor="socialMedia-youtube-input"
              >
                Youtube
              </CustomLabel>

              <CustomInput
                name="socialMedia-youtube"
                type="text"
                className="form-control"
                id="socialMedia-youtube-input"
                {...register("youtube")}
              />

              {errorMessage(errors, "youtube")}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <CustomLabel
                className="form-label"
                htmlFor="socialMedia-whatsapp-input"
              >
                Whatsapp
              </CustomLabel>

              <CustomInput
                name="socialMedia-whatsapp"
                type="text"
                className="form-control"
                id="socialMedia-whatsapp-input"
                {...register("whatsapp")}
              />
              {errorMessage(errors, "whatsapp")}
            </div>
          </Col>
        </Row>
        <CardFooter className="px-0 mt-5">
          <div className="d-flex align-items-center gap-2">
            <Link to="/dashboard/">
              <CustomButton type="button" className="btn btn-light">
                Close
              </CustomButton>
            </Link>
            <Restricted permissions={WEB_SETTINGS_PERMISSIONS.update}>
              <Button type="submit" color="success">
                <i className="ri-save-line align-bottom me-1"></i> {t("Save")}
              </Button>
            </Restricted>
          </div>
        </CardFooter>
      </Form>
    </Row>
  );
};

export default SocialMedia;
