import React from "react";
import { Redirect, Route } from "react-router-dom";
import Restricted from "../Components/Guard/Restricted";
import { useAuthState } from "../context/authContext";

const AuthProtected = (props) => {
  
  const { accessToken } = useAuthState();

  if (!accessToken) {
    return (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  
  return props.children
  
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
      <Route
        {...rest}
        render={props => {
          return ( 
              <Restricted permissions={rest.permissions} render={<Redirect to="/dashboard" />}>
                <Component {...props} /> 
              </Restricted>
            );
        }}
      />
  );
};

export { AuthProtected, AccessRoute };