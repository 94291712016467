import React, { useState } from "react";
import { Col, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Active from "./Active";
import Stripe from "./Stripe";
import CustomNavLink from "../../Custom/CustomNavLink";

const OrderSettingsTabs = () => {

  const [activeTab, setactiveTab] = useState("1");
  
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  return (
    <Col md={12}>
      <Nav tabs className="nav-tabs mb-3">

        <NavItem>
          <CustomNavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Active
          </CustomNavLink>
        </NavItem>

        <NavItem>
          <CustomNavLink
            style={{ cursor: "pointer" }}
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Stripe
          </CustomNavLink>
        </NavItem>

      </Nav>

      <TabContent activeTab={activeTab} className="text-muted">

        <TabPane tabId="1" id="active">
          <Active />
        </TabPane>

        <TabPane tabId="2" id="stripe">
          <Stripe />
        </TabPane>

      </TabContent>

    </Col>
  );
};

export default OrderSettingsTabs;
