import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Label } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { errorMessage } from '../../../../helpers/validation/errorMessage';
import { useTranslation } from 'react-i18next';
import { getAllUsersDataAction } from '../../../../redux/actions/users';
import { getAllProductsDataAction } from '../../../../redux/actions/products';

const animatedComponents = makeAnimated();

const ProductFormSidebar = ({initialValues, setValue, errors}) => {

  const [selectedGroups, setSelectedGroups] = useState([1]);

  const [selectedCategories, setSelectedCategories] = useState(null);

  const { t } = useTranslation();

  const { 
    products: { categories: { list: categories } }, 
    users: { groups: { list: groups } } 
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleCheck = (e, group) => {
    if (selectedGroups?.includes(group.id)) setSelectedGroups([ ...selectedGroups.filter((grp) => grp !== group.id)]);
    else setSelectedGroups([...(selectedGroups || []), group.id]);
  };

  useEffect(() => {
    if (initialValues) {
      if (initialValues.groups?.length) setSelectedGroups(initialValues?.groups);
      if (initialValues.categories?.length) setSelectedCategories(initialValues.categories.map(cat => ({
        label: cat.name,
        value: cat.id
      })))
      
    }
  }, [initialValues])

  useEffect(() => {
    setValue("groups", selectedGroups);
    setValue("categories", selectedCategories?.map(item => item.value) || null);
  }, [setValue, selectedGroups, selectedCategories]);


  useEffect(() => {
    dispatch(getAllUsersDataAction({
      field: 'groups',
      query: {
        page_size: 'all'
      }
    }));
    dispatch(getAllProductsDataAction({
      field: 'categories',
      query: {
        page_size: 'all'
      }
    }));
  }, [dispatch])

  return (
    <>
      <Card>
        <CardHeader>{t("Categories")}</CardHeader>
        <CardBody>
          <div className="mb-3">
            <Select
              options={categories?.map((category) => ({
                label: category.name,
                value: category.id,
              }))}
              isMulti={true}
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={selectedCategories}
              onChange={(val) => setSelectedCategories(val)}
            />
            {errorMessage(errors, "categories")}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{t("Groups")}</CardHeader>
        <CardBody>
          
          {groups.length > 0 &&
            groups.map((group) => {
              if (
                group.name === "Standard" &&
                !selectedGroups.includes(group.id)
              ) {
                setSelectedGroups((prev) => [...prev, group.id]);
              }
              return (

                <div
                  key={group.id}
                  className="form-check form-check-primary mb-3"
                >
                  <Label
                    className="form-check-label"
                    htmlFor={`group-${group.id}`}
                  >
                    {group.name}
                  </Label>

                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`group-${group.id}`}
                    checked={group.name !== 'Stanard' ? selectedGroups.includes(group.id) : true}
                    onChange={group.name !== "Standard"  ? (e) => handleCheck(e, group) : null}
                    disabled={group.name === 'Standard' || group.id === 1}
                  />

                </div>

              );
            })}
        </CardBody>
      </Card>
    </>
  )
}

export default ProductFormSidebar