import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import LocationsTable from "../../../Components/Products/Location/Table";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import Restricted from "../../../Components/Guard/Restricted";
import { PRODUCT_LOCATION_PERMISSIONS } from "./../../../permissions/products";
import { deleteProductsDataAction, getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const Locations = () => {

  const { load, callback, itemsToDelete, deleteModal, isDeleting, setItemsToDelete, setDeleteModal } = useDeleteMultiple();

  const dispatch = useDispatch();

  const { locations: { list: locations, total } } = useSelector((state) => state.products);

  const [loading, setLoading] = useState(true);

  const searchFunc = async (query) => {
    if (!loading) setLoading(true);
    dispatch(
      getAllProductsDataAction({
        field: 'locations',
        query,
        onSuccess: () => setLoading(false),
      })
    );
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'locations',
    debouncedValues: {
      zone: '',
    },
    searchFunc
  });

  const handleDelete = () => {
    load();
    dispatch(
      deleteProductsDataAction({
        field: 'locations',
        data: {
          ids: itemsToDelete.selectedRows?.map((row) => row.id),
        },
        onSuccess() {
          callback();
          search();
        }
      })
    );
    callback();
  };

  return (

      <PageContainer>

        <BreadCrumb
          pageTitle="Products"
          to="/dashboard/products"
          title="Locations"
        />
        
        <div className="hstack gap-2">

            <Restricted permissions={PRODUCT_LOCATION_PERMISSIONS.create}>
              <Link to="/dashboard/products/locations/create">
                <CustomButton className="btn-success">
                  <i className="ri-add-fill me-1 align-bottom"></i>
                  Create
                </CustomButton>
              </Link>
            </Restricted>

            <Restricted permissions={PRODUCT_LOCATION_PERMISSIONS.delete}>
              <CustomButton
                className="btn-danger"
                disabled={!itemsToDelete.selectedRows?.length > 0}
                onClick={() => setDeleteModal(true)}
              >
                <i className="ri-delete-bin-line me-1 align-bottom"></i>
                Delete
              </CustomButton>
            </Restricted>

        </div>

        <Card className="mt-3">
          <LocationsTable
              data={locations}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              handleDelete={handleDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              sortDirection={searchValues.order_value}
              fetching={loading}
              isDeleting={isDeleting}
              path="/dashboard/products/locations"
          />
        </Card>

      </PageContainer>
  );
};

export default Locations;
