import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import VatTable from "../../../Components/Products/VAT/Table";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCT_VAT_PERMISSIONS } from "./../../../permissions/products";
import Restricted from "./../../../Components/Guard/Restricted";
import { deleteProductsDataAction, getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const VAT = () => {

  const dispatch = useDispatch();

  const { callback, deleteModal, isDeleting, itemsToDelete, load, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const { vats: { list: vats, total } } = useSelector((state) => state.products);

  const [loading, setLoading] = useState(true);

  const searchFunc = async (query) => {
    if (!loading) setLoading(true);
    dispatch(
      getAllProductsDataAction({
        field: 'vats',
        query,
        onSuccess: () => setLoading(false),
      })
    );
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'vats',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const handleDelete = () => {
    load();
    dispatch(
      deleteProductsDataAction({
        field: 'vats',
        data: {
          ids: itemsToDelete.selectedRows.map((item) => item.id)
        },
        onSuccess() {
          callback();
          search();
        }
      })
    );
  };

  return (
    <PageContainer>

      <BreadCrumb pageTitle="Products" to="/dashboard/products" title="VAT" />

      <div className="hstack gap-2">

        <Restricted permissions={PRODUCT_VAT_PERMISSIONS.create}>
          <Link to="/dashboard/products/vat/create">
            <CustomButton className="btn-success">
              <i className="ri-add-fill me-1 align-bottom"></i>
                Create
            </CustomButton>
          </Link>
        </Restricted>

        <Restricted permissions={PRODUCT_VAT_PERMISSIONS.delete}>
          <CustomButton
            className="btn-danger"
            disabled={!itemsToDelete.selectedRows?.length > 0}
            onClick={() => setDeleteModal(true)}
          >
            <i className="ri-delete-bin-line align-bottom"></i>{" "}
              Delete
          </CustomButton>
        </Restricted>

      </div>

      <Card className="mt-3">
          <VatTable
              data={vats}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              handleDelete={handleDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              sortDirection={searchValues.order_value}
              fetching={loading}
              isDeleting={isDeleting}
              path="/dashboard/products/vat"
          />
      </Card>
    </PageContainer>
  );
};

export default VAT;
