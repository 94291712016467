import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import LotMovementTable from "../../../Components/Storage/LotMovement/Table";
import { objectFilter } from "../../../helpers/objectFilter";
import { useTranslation } from "react-i18next";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import { CSVLink } from "react-csv";
import { getLotMovementCSVData } from "../../../helpers/CSV/lotMovementCSV";
import storageApi from "../../../apis/storage/api";
import CustomButton from "../../../Components/Common/CustomButton";
import { getAllStorageDataAction } from "../../../redux/actions/storage";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useSearch } from "../../../hooks/useSearch";

const LotMovement = () => {

  const { itemsToDelete, setItemsToDelete } = useDeleteMultiple();

  const { lotMovement: { list: lotMovement, total } } = useSelector((state) => state.storage);

  const [loading, setLoading] = useState(true);

  const [csvLoading, setCSVLoading] = useState(false);

  const [exportAllData, setExportAllData] = useState([]);

  const CSVRef = useRef(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const searchFunc = async (query) => {
    setLoading(true);
    dispatch(
      getAllStorageDataAction({
        field: 'lotMovement',
        query,
        onSuccess: () => setLoading(false),
      })
    );
  }

  const { searchValues, handleChange, handleMultipleChanges, setPageSize, navigateToPage, onSort, clearFilter } = useSearch({
    name: 'lotMovement',
    cachedValues: {
      date_from: "",
      date_to: "",
    },
    debouncedValues: {
      product_name: "",
      batch: "",
      lot: "",
      matricule: "",
    },
    searchFunc
  });

  const getAllLotMovements = async () => {
      setCSVLoading(true);
      try {
        const {data: {data: lotMovementsData}} = await storageApi.lotMovement.getAll(objectFilter({
          ...searchValues,
          page_size: 'all'
        }))
        setExportAllData(getLotMovementCSVData({ data: lotMovementsData }))
      } catch (error) {
        console.log('error', error);
      } finally {
        setCSVLoading(false);
      }
  }

  useEffect(() => {
    if (CSVRef.current && exportAllData.length) {
       CSVRef.current.link?.click();
    }
  }, [CSVRef, exportAllData])

  return (
      <PageContainer>

        <BreadCrumb pageTitle="Storage" title="Lot Movement" to="/dashboard/storage" />

        <Row className="gy-4">

          <Col md={12} className="d-flex gap-2">

              <CSVLink
                  data={getLotMovementCSVData({
                    data: itemsToDelete.selectedRows,
                  })}
                  type="button"
                  className={`btn btn-primary ${!itemsToDelete?.selectedRows?.length && "disabled"}`}
                  filename={t('Lot Movements')}
                >
                Export CSV
              </CSVLink>

              <CustomButton
                isLoading={csvLoading}
                type="button"
                className="btn-success"
                onClick={getAllLotMovements}
              >
                Export All CSV
              </CustomButton>

              <CSVLink
                    data={exportAllData}
                    filename={t('Lot Movements')}
                    asyncOnClick={true}
                    ref={CSVRef}
                    hidden
                  >
              </CSVLink>

              <CustomButton
                className="btn-secondary"
                onClick={clearFilter}
              >
                Clear FIlters
              </CustomButton>
              
          </Col>
        </Row>

        <Card className="mt-3">
            <LotMovementTable
              data={lotMovement}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              handleMultipleFilterChanges={handleMultipleChanges}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              onSort={onSort}
              sortDirection={searchValues.order_value}
              fetching={loading}
            />
        </Card>

      </PageContainer>
  );
};

export default LotMovement;
