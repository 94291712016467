import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Label, Row } from 'reactstrap';
import settingsApi from '../../../apis/settings/api';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import PageContainer from '../../../Components/Layout/PageContainer';

const ContactDetails = () => {

  const { id } = useParams();

  const [contact, setContact] = useState(null);

  useQuery(["contacts"], () => settingsApi.contacts.getOne(id), {
    onSuccess({data: {data}}) {
      setContact(data);
    }
  })

  return (
        <PageContainer> 
          <BreadCrumb pageTitle="Contacts" title="View" to="/dashboard/settings/contacts" />
            <Card className='p-4'>
                {contact && 
                  <Row className='gy-4'>
                    
                    <Col md={6}>
                      <Label>User</Label>
                      <input type='text' className='form-control' value={contact.fullname} disabled />
                    </Col>

                    <Col md={6}>
                      <Label>Company</Label>
                      <input type='text' className='form-control' value={contact.company} disabled />
                    </Col>

                    <Col md={6}>
                      <Label>Email</Label>
                      <input type="email" className='form-control' value={contact.email} disabled />
                    </Col>

                    <Col md={6}>
                      <Label>Phone</Label>
                      <input type='text' className='form-control' value={contact.phone} disabled />
                    </Col>

                    <Col md={6}>
                      <Label>Message</Label>
                      <textarea style={{resize: 'none'}} type='text' className='form-control' value={contact.message} disabled />
                    </Col>

                    <Col md={6}>
                      <Label>Message</Label>
                      <input type='text' className='form-control' value={moment(contact.created_at).format("D MMM, YYYY")} disabled />
                    </Col>

                  </Row>
                }
            </Card>
      </PageContainer>
  )
}

export default ContactDetails