import React from "react";
import HistoryForm from "../../../Components/Users/History/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const CreateHistory = () => {

  return (
    <PageContainer>
      <BreadCrumb pageTitle="History" title="Create" to="/dashboard/users/history" />
      <HistoryForm 
        type="create"
      />
    </PageContainer>
  );
};

export default CreateHistory;
