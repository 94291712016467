import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLocationSchema } from "../../../helpers/validation/Products/createLocation";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setFormErrors } from "../../../helpers/setFormErrors";
import CustomButton from "../../Common/CustomButton";
import CustomLabel from "../../Custom/CustomLabel";
import { createProductsDataAction, updateProductsDataAction } from "../../../redux/actions/products";
import SaveButton from "../../Common/Buttons/SaveButton";

const LocationForm = ({ type, initialValues }) => {
  
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(createLocationSchema),
    defaultValues: initialValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const submitForm = async (data) => {
    setLoading(true);
    if (type === "create") {
      dispatch(
        createProductsDataAction({
          field: 'locations',
          data,
          onSuccess: () => history.push("/dashboard/products/locations"),
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    } else if (type === "update") {
      dispatch(
        updateProductsDataAction({
          field: 'locations',
          data: {
            id: initialValues?.id,
            data,
          },
          onSuccess: () => setLoading(false),
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    }
  };

  return (
    <div>
      <Col md={12}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card className="p-3">
            <Row className="gx-4 gy-2">
              <Col md={6}>
                <CustomLabel for="inputName">Zone</CustomLabel>
                <input
                  name="zone"
                  {...register("zone")}
                  className="form-control"
                />
                {errorMessage(errors, "zone")}
              </Col>
              <Col md={6}>
                <CustomLabel for="inputName">Code</CustomLabel>
                <input
                  name="code"
                  {...register("code")}
                  className="form-control"
                />
                {errorMessage(errors, "code")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputLabel">Description</CustomLabel>
                <input
                  name="description"
                  {...register("description")}
                  className="form-control"
                />
                {errorMessage(errors, "description")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputIcon">Corridor</CustomLabel>
                <input
                  name="corridor"
                  {...register("corridor")}
                  className="form-control"
                />
                {errorMessage(errors, "corridor")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputIcon">Rack</CustomLabel>
                <input
                  name="rack"
                  {...register("rack")}
                  className="form-control"
                />
                {errorMessage(errors, "rack")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputIcon">Export ID</CustomLabel>
                <input
                  name="export_id"
                  {...register("export_id")}
                  className="form-control"
                />
                {errorMessage(errors, "export_id")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputIcon">Label on transport</CustomLabel>
                <input
                  name="label_transport"
                  {...register("label_transport")}
                  className="form-control"
                />
                {errorMessage(errors, "label_transport")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputIcon">
                  Temperature on transport
                </CustomLabel>
                <input
                  name="temp_transport"
                  {...register("temp_transport")}
                  type="number"
                  className="form-control"
                  step="any"
                />
                {errorMessage(errors, "temp_transport")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputIcon">Sort</CustomLabel>
                <input
                  {...register("sort")}
                  type="number"
                  className="form-control"
                  id="inputIcon"
                  step="any"
                />
                {errorMessage(errors, "sort")}
              </Col>
            </Row>
          </Card>

          <div className="my-2 d-flex justify-content-end">

            <SaveButton
              isLoading={loading}
            />

          </div>
          
        </form>
      </Col>
    </div>
  );
};

export default LocationForm;
