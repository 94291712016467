import React, { useEffect, useState } from "react";
import { Col, Row, Label, Card } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Common/CustomButton";
import CreateCategoryImage from "./CreateCategoryImage";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import { createProductsDataAction, deleteProductsDataAction, getAllProductsDataAction, updateProductsDataAction } from "../../../redux/actions/products";
import SaveButton from "../../Common/Buttons/SaveButton";

const CategoryForm = ({ type, initialValues }) => {

  const { register,  handleSubmit, setValue, formState: { errors }} = useForm({
    defaultValues: initialValues
  });

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);

  const dispatch = useDispatch();
  
  const history = useHistory();

  const { id } = useParams();

  const { categories: { list: categories } } = useSelector((state) => state.products);

  const submitForm = async (data) => {
    setIsLoading(true);
    const { parent_id, image, ...rest } = data;
    if (type === "create") {
      dispatch(
        createProductsDataAction({
          field: 'categories',
          data: {
            ...rest,
            parent_id: parent_id ? parent_id : 0,
          },
          onSuccess: () => history.push("/dashboard/products/categories"),
          onError: () => {
            setIsLoading(false);
          },
        })
      );
    } else {
      dispatch(
        updateProductsDataAction({
          field: 'categories',
          data: {
            id,
            data,
          },
          onSuccess: () => setIsLoading(false),
          onError: () => {
            setIsLoading(false);
          },
        })
      );
    }
  };

  const deleteCat = () => {
    setIsDeleting(true);
    dispatch(
      deleteProductsDataAction({
        field: 'categories',
        data: {
          ids: [id]
        },
        onSuccess: () => history.push("/dashboard/products/categories"),
      })
    );
  };

  useEffect(() => {
    if (initialValues) {
      if (initialValues.file_id) {
        setCategoryImage({
          id: initialValues.file_id,
          preview: initialValues.image,
        });
      }
    }
  }, [initialValues]);

  useEffect(() => {
    dispatch(getAllProductsDataAction({
      field: 'categories'
    }));
  }, [dispatch]);

  return (
    <div>
      <Col md={12}>
        <form>
          <Card className="p-3">
            <Row className="gx-4 gy-2">
              <Col md={6}>
                <CustomLabel for="inputName">Name</CustomLabel>
                <input
                  {...register(`name`)}
                  className="form-control"
                  id="inputName"
                />
              </Col>

              <Col md={6}>
                <CustomLabel for="inputLabel">Label</CustomLabel>
                <input
                  {...register(`label`)}
                  className="form-control"
                  id="inputLabel"
                />
              </Col>

              <Col md={6}>
                <CustomLabel for="inputIcon">Icon</CustomLabel>
                <input
                  {...register(`icon_class`)}
                  className="form-control"
                  id="inputIcon"
                />
              </Col>

              <Col md={6} className="d-flex align-items-center">
                  <div className="form-check form-switch mt-4">
                      <input 
                        {...register('status')} 
                        className="form-check-input fs-16" 
                        type="checkbox" 
                        role="switch" 
                        id="status" 
                      />
                      <CustomLabel 
                        className="form-check-label" 
                        htmlFor="status"
                      >
                        Active
                      </CustomLabel>
                  </div>
              </Col>

              <CreateCategoryImage
                setValue={setValue}
                errors={errors}
                setCategoryImage={setCategoryImage}
                categoryImage={categoryImage}
              />

              {type === "create" && (
                <Col sm={12}>
                  <CustomLabel>Parent Category</CustomLabel>
                  <select {...register(`parent_id`)} className="form-select">
                    <option value=""></option>
                    {categories
                      .filter((cat) => +cat.id !== +id)
                      .map((category) => (
                        <CustomOption key={category.id} value={category.id}>
                          {category.name}
                        </CustomOption>
                      ))}
                  </select>
                </Col>
              )}
            </Row>
          </Card>

              
          <div className="my-2 d-flex justify-content-end">

            <SaveButton 
              type="button"
              isLoading={isLoading}
              onClick={handleSubmit(submitForm)}
            />

          </div>
          
        </form>
      </Col>
    </div>
  );
};

export default CategoryForm;
