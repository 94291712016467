const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: "users",
  initialState: {
    users: {
        list: [],
        total: 0
    },
    groups: {
        list: [],
        total: 0
    },
    zones: {
        list: [],
        total: 0
    },
    statuses: {
        list: [],
        total: 0
    },
    history: {
        list: [],
        total: 0
    },
  },
  reducers: {
    setData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          list: payload.data,
          total: payload.total,
        };
      }
    },

    createData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: [...state[payload.field].list, payload.data],
        };
      }
    },

    updateData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          ...state[payload.field],
          list: state[payload.field].list.map((item) =>
            item.id === payload.id ? payload.data : item
          ),
        };
      }
    },

    deleteData: (state, { payload }) => {
      if (state[payload.field]) {
        state[payload.field] = {
          total: state[payload.field].total - payload.data.ids.length,
          list: state[payload.field].list.filter(
            (item) => !payload.data.ids.includes(item.id)
          ),
        };
      }
    },
  },
});

export const usersActions = slice.actions;

export default slice.reducer;
