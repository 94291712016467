import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import { formatDate } from "../../../helpers/formatTime";

const RecentOrders = ({ data }) => {

  const [orders, setOrders] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    setOrders(data.last_orders)
  }, [data])

  const history = useHistory();

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{t('Recent Orders')}</h4>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">

              <table className="table table-borderless table-centered align-middle table-nowrap mb-0">

                <thead className="text-muted table-light">
                  <tr>

                    <th scope="col">
                      <CustomTableHeading>Matircule</CustomTableHeading>
                    </th>

                    <th scope="col">
                      <CustomTableHeading>Company</CustomTableHeading>
                    </th>

                    <th scope="col">
                      <CustomTableHeading>Shipping Type</CustomTableHeading>
                    </th>

                    <th scope="col">
                      <CustomTableHeading>Status</CustomTableHeading>
                    </th>

                    <th scope="col">
                      <CustomTableHeading>Payment Method</CustomTableHeading> 
                    </th>

                    <th scope="col">
                      <CustomTableHeading>Date</CustomTableHeading>
                    </th>

                  </tr>
                </thead>

                <tbody>
                  {(orders || []).map((item, key) => (
                    <tr 
                      key={key} 
                      className="homepage-table-row"
                      onClick={() => history.push(`/dashboard/orders/change-order/${item.id}/order`)}
                    >
                      <td className="fw-medium link-primary">
                          {item.matricule}
                      </td>

                      <td>{item.company?.name}</td>

                      <td>
                        <span className="text-muted">{item.delivery_type}</span>
                      </td>

                      <td>
                        {item.current_status}
                      </td>

                      <td>
                          {item.payment}
                      </td>

                      <td>
                          {formatDate(item.created_at)}
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RecentOrders;
