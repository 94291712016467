import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ProductsTable from "../../../Components/Products/Products/Table";
import { useDispatch, useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import productsApi from "../../../apis/products/api";
import Restricted from "./../../../Components/Guard/Restricted";
import { PRODUCT_PERMISSIONS } from "./../../../permissions/products";
import { deleteProductsDataAction, getAllProductsDataAction } from "../../../redux/actions/products";
import { productsActions } from "../../../redux/slices/products";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const Products = () => {

  const { load, callback, itemsToDelete, deleteModal, isDeleting, setItemsToDelete, setDeleteModal } = useDeleteMultiple();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const searchFunc = async (values) => {
    if (!loading) setLoading(true);
    const { data } = await productsApi.products.getAll({
      ...values,
      for: 'op'
    });
    dispatch(productsActions.setData({ 
      field: 'products',
      data: data.data, 
      total: data.meta?.total
     }));
    setLoading(false);
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, onSort, search } = useSearch({
    name: 'products',
    cachedValues: {
      status: "",
      tax: "",
      is_main_publish: "",
      location: "",
      order_by: "created_at",
      order_value: "desc",
    },
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const {  products: { list: products, total: totalProducts } } = useSelector(state => state.products);

  const handleDelete = () => {
    load();
    dispatch(
      deleteProductsDataAction({
        field: 'products',
        data: { ids: itemsToDelete.selectedRows?.map((row) => row.id) },
        onSuccess: () => {
          callback();
          search();
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllProductsDataAction({
      field: 'vats',
      query: {
        page_size: 'all'
      }
    }));
    dispatch(getAllProductsDataAction({
      field: 'locations',
      query: {
        page_size: 'all'
      }
    }));
  }, [dispatch]);

  return (
    <PageContainer>

      <BreadCrumb title="View" pageTitle="Products" />

      <Row>
        <Col className="hstack gap-2">

          <Restricted permissions={PRODUCT_PERMISSIONS.create}>
            <Link to="/dashboard/products/create">
              <CustomButton 
                className="btn-success"
              >
                <i className="ri-add-fill me-1 align-bottom"></i>
                Create
              </CustomButton>
            </Link>
          </Restricted>

          <Restricted permissions={PRODUCT_PERMISSIONS.delete}>
            <CustomButton
              className="btn-danger"
              disabled={!itemsToDelete.selectedRows?.length > 0}
              onClick={() => setDeleteModal(true)}
            >
              <i className="ri-delete-bin-line align-bottom"></i>{" "}
              Delete
            </CustomButton>
          </Restricted>

        </Col>
      </Row>

      <Card className="mt-3">
        <ProductsTable
            data={products}
            paginationServer
            paginationTotalRows={totalProducts}
            navigateToPage={navigateToPage}
            selectableRows
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDelete={handleDelete}
            filters={searchValues}
            handleFilterChange={handleChange}
            pageSize={searchValues.page_size}
            setPageSize={setPageSize}
            onSort={onSort}
            sortDirection={searchValues.order_value}
            fetching={loading}
            isDeleting={isDeleting}
            path="/dashboard/products"
            paginationResetDefaultPage={searchValues.page === 1}
        />
      </Card>
    </PageContainer>
  );
};

export default Products;
