import React, { useState } from "react";
import { Col, Label } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Controller } from "react-hook-form";
import CustomButton from "../../Common/CustomButton";
import usersApi from "../../../apis/users/api";
import CustomOption from "../../Custom/CustomOption";
import CustomLabel from "../../Custom/CustomLabel";

const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const ZoneOrderDay = ({ control, index, remove, getValues }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const removeItem = async () => {
    setIsDeleting(true);

    const deliveryDays = getValues("delivery_days");
    const deliveryDay = deliveryDays.find((_, i) => i === index);
    const ID = deliveryDay.id;

    if (!deliveryDay.isNew) {
      await usersApi.zones.deliveryDays.delete(ID);
    }

    remove(index);
    setIsDeleting(false);
  };

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between border p-3">
      <Col lg={5}>
        <CustomLabel htmlFor="Order_day">Order day</CustomLabel>
        <Controller
          name={`delivery_days.${index}.order_day`}
          control={control}
          render={({ field }) => (
            <select className="form-control" {...field}>
              <option value=""></option>
              {DAYS.map((day) => (
                <CustomOption key={day} value={day}>
                  {day}
                </CustomOption>
              ))}
            </select>
          )}
        />
      </Col>

      <Col lg={5}>
        <CustomLabel htmlFor="Delivery_day">Delivery day</CustomLabel>
        <Controller
          name={`delivery_days.${index}.delivery_day`}
          control={control}
          render={({ field }) => {
            // field.
            return (
              <select className="form-control" {...field}>
                <option value=""></option>
                {DAYS.map((day) => (
                  <CustomOption key={day} value={day}>
                    {day}
                  </CustomOption>
                ))}
              </select>
            );
          }}
        />
        {/* {errorMessage(errors, "deliveryDay")} */}
      </Col>

      <Col lg={12}>
        <div>
          <CustomLabel className="form-label mb-0">Time</CustomLabel>
          <Controller
            name={`delivery_days.${index}.time`}
            control={control}
            render={({ field }) => {
              return (
                <Flatpickr
                  className="form-control"
                  autoFocus={false}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                    mode: "time",
                  }}
                  control={control}
                  name={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e[0].toLocaleTimeString())}
                />
              );
            }}
          />
          {/* {errorMessage(errors, "orderTime")} */}
        </div>
      </Col>
      <Col lg={12} className="mt-2">
        <CustomButton
          className="btn btn-danger"
          type="button"
          onClick={() => removeItem()}
          isLoading={isDeleting}
        >
          Delete
        </CustomButton>
      </Col>
    </div>
  );
};

export default ZoneOrderDay;
