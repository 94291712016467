import axiosInstance from "./axios";

export const getNotificationsApi = (page_size = 10) => {
    
    return axiosInstance.get('/dash/notifications', {
        params: {
            page_size
        }
    })
}

export const seenAllNotificationsApi = () => {
    return axiosInstance.get('/dash/notifications/read/all');
}

export const seenOneNotificationApi =(id) => {
    return axiosInstance.get(`/dash/notifications/${id}/read`)
}