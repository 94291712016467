import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CreateProductTabs from "./CreateProduct/CreateProductTabs";
import { Controller, useForm } from "react-hook-form";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductBody,
} from "../../../helpers/RequestBodies/Users/productRequestBody";
import { useHistory } from "react-router-dom";
import { setFormErrors } from "../../../helpers/setFormErrors";
import ProductQuantity from "./ProductQuantity";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import ProductFormSidebar from "./CreateProduct/Sidebar";
import { createProductsDataAction, getAllProductsDataAction, updateProductsDataAction } from "../../../redux/actions/products";
import SaveButton from "../../Common/Buttons/SaveButton";

const ProductForm = ({ type, initialValues }) => {

  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, formState: { errors }, watch, setValue, setError, reset } = useForm();

  const dispatch = useDispatch();
  
  const history = useHistory();

  const { 
    vats: {list: vats}, 
    vendors: {list: vendors}, 
    locations: {list: locations}
  } = useSelector((state) => state.products);

  const onSubmit = async (data) => {
    setLoading(true);
    if (type === "create") {
      const product = createProductBody(data);
      dispatch(
        createProductsDataAction({
          field: 'products',
          data: product,
          onSuccess: () => {
            setLoading(false);
            history.push("/dashboard/products");
          },
          onError: (error) => {
            setLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    } else {
      const product = createProductBody(data);
      dispatch(
        updateProductsDataAction({
          field: 'products',
          data: {
            data: product,
            id: initialValues?.id,
          },
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            setLoading(false);
            setFormErrors(error?.data?.error, setError);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues])

  useEffect(() => {
    dispatch(getAllProductsDataAction({
      field: 'vats',
      query: {
        page_size: 'all'
      }
    }));
    dispatch(getAllProductsDataAction({
      field: 'vendors',
      query: {
        for: "product",
        page_size: "all",
      }
    }));
    dispatch(getAllProductsDataAction({
      field: 'measurementUnits',
      query: {
        page_size: 'all'
      }
    }));
    dispatch(getAllProductsDataAction({
      field: 'locations',
      query: {
        page_size: 'all'
      }
    }));
  }, [dispatch]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>

          <Col lg={3}>
              <ProductFormSidebar 
                  initialValues={initialValues}
                  setValue={setValue}
                  errors={errors}
              />
          </Col>

          <Col lg={9}>
            <Card className="p-3">
              <Row className="gx-4 gy-3">
                <Col md={6}>
                  <CustomLabel>Number</CustomLabel>
                  <input
                    name="matricule"
                    {...register("matricule")}
                    className="form-control"
                  />
                  {errorMessage(errors, "matricule")}
                </Col>

                <Col md={6}>
                  <CustomLabel>Product Name</CustomLabel>
                  <input
                    name="name"
                    {...register("name")}
                    className="form-control"
                  />
                  {errorMessage(errors, "name")}
                </Col>

                <Col md={6}>
                  <CustomLabel>
                    Vendor Product Number
                  </CustomLabel>
                  <input
                    name="supplier_product_number"
                    {...register("supplier_product_number")}
                    type="number"
                    className="form-control"
                    step="any"
                  />
                  {errorMessage(errors, "supplier_product_number")}
                </Col>

                <Col md={6}>
                  <CustomLabel>VAT</CustomLabel>
                  {vats.length > 0 && (
                    <select
                      name="taxes"
                      {...register("taxes")}
                      className="form-select"
                    >
                      <CustomOption value="">Select...</CustomOption>
                      {vats.map((vat) => (
                        <option key={vat.id} value={vat.id}>
                          {vat.name}, %{vat.percentage}
                        </option>
                      ))}
                    </select>
                  )}
                  {errorMessage(errors, "taxes")}
                </Col>

                <Col md={6}>
                  <CustomLabel for="product-location">Location</CustomLabel>
                  {locations.length > 0 && (
                    <select
                      {...register("stock_zone_id")}
                      className="form-select"
                    >
                      <CustomOption value="">Select...</CustomOption>
                      {locations.map((location) => (
                        <CustomOption key={location.id} value={location.id}>
                          {location.zone}
                        </CustomOption>
                      ))}
                    </select>
                  )}
                  {errorMessage(errors, "stock_zone_id")}
                </Col>

                <Col md={6}>
                  <CustomLabel>Vendor</CustomLabel>
                  {vendors.length > 0 && (
                    <select
                      name="supplyer_id"
                      {...register("supplyer_id")}
                      className="form-select"
                    >
                      <CustomOption value="">Select...</CustomOption>
                      {vendors.map((vendor) => (
                        <option key={vendor.id} value={vendor.id}>
                          {vendor.name}
                        </option>
                      ))}
                    </select>
                  )}
                  {errorMessage(errors, "supplyer_id")}
                </Col>

                <Col md={6}>
                  <CustomLabel>
                    Temperature
                  </CustomLabel>
                  <input
                    name="temperature"
                    {...register("temperature")}
                    type="number"
                    className="form-control"
                    step="any"
                  />
                  {errorMessage(errors, "temperature")}
                </Col>

                {type === "update" && (
                  <ProductQuantity
                    total_qty={initialValues?.total_qty || 0}
                    total_weight={initialValues?.total_weight || 0}
                    unit_prices={initialValues?.unit_prices || []}
                    units={initialValues?.units || []}
                  />
                )}

                <Col md={12}>
                  <CustomLabel for="product-description">
                    Description
                  </CustomLabel>

                  <Controller
                    name="description"
                    control={control}
                    render={({ name, field: { onChange } }) => (
                      <CKEditor
                        name={name}
                        editor={ClassicEditor}
                        onChange={(event, editor) => onChange(editor.getData())}
                        data={initialValues?.description}
                      />
                    )}
                  />
                  {errorMessage(errors, "description")}
                </Col>

                <Col lg={6}>
                    <div className="form-check form-switch mt-4">
                        <input 
                          {...register('is_main_publish')} 
                          className="form-check-input fs-16" 
                          type="checkbox" 
                          role="switch" 
                          id="status" 
                        />
                        <CustomLabel 
                          className="form-check-label" 
                          htmlFor="status"
                        >
                          View in main page
                        </CustomLabel>
                    </div>
                </Col>

                <Col lg={6} className="d-flex align-items-center">

                    <div className="form-check form-switch mt-4">
                        <input 
                          {...register('status')} 
                          className="form-check-input fs-16" 
                          type="checkbox" 
                          role="switch" 
                          id="status" 
                        />
                        <CustomLabel 
                          className="form-check-label" 
                          htmlFor="status"
                        >
                          Active
                        </CustomLabel>
                    </div>

                </Col>  

                <CreateProductTabs
                  initialValues={initialValues}
                  register={register}
                  setValue={setValue}
                  watch={watch}
                />
                
              </Row>
            </Card>
        
            <div className="my-2 d-flex justify-content-end">

              <SaveButton 
                isLoading={loading}
              />

            </div>

          </Col>
        </Row>

      </form>
    </div>
  );
};

export default ProductForm;
