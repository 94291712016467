import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import productsApi from "../../../apis/products/api";
import LocationForm from "../../../Components/Products/Location/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateLocation = () => {

  const [initialValues, setInitialValues] = useState(null);

  const { id } = useParams();

  const { isLoading, isRefetching } = useQuery(["offer"], () => productsApi.locations.getOne(id), {
    onSuccess(data) {
      const { data: locationData } = data.data;
      setInitialValues(locationData);
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb  title="Update" pageTitle="Locations" to="/dashboard/products/locations" />
      {initialValues && (
        <LocationForm type="update" initialValues={initialValues} />
      )}
    </PageContainer>
  );
};

export default UpdateLocation;
