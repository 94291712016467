import * as yup from "yup";

const createZoneSchema = yup.object().shape({
  name: yup.string("").required("Zone name should be required please"),
  status: yup.string("").required("Please select zone acrivity"),
  description: yup.string("").required("Zone note is required please"),

  //   ! Zone order
  


  // order_day: yup.string("").required("Order day is required please"),
  // delivery_day: yup.string("").required("Order day is required please"),
  // time: yup.date().required(),
});

export default createZoneSchema;
