import axiosInstance from "./axios";

export const handleUploadOrCreate = (data) => {
    return axiosInstance.post('/files', data)
}

export const getAllFiles = () => {
    return axiosInstance.get('/files');
}

export const deleteMultipleFiles = (ids) => {
    return axiosInstance.delete('/files', {
        data: {
            ids
        }
    })
}

export const updateFileName = (id, name) => {
    return axiosInstance.post(`/files/${id}`, {
        name
    })
}

export const getFile = (id) => {
    return axiosInstance.get(`/files/${id}`)
}

export const getStats = () => {
    return axiosInstance.get('/files/stats')
}
