import React from "react";
import DataTable from "../../Common/DataTable";
import { useTranslation } from "react-i18next";
import CustomInput from "../../Custom/CustomInput";

const LocationsTable = ({
  filters,
  handleFilterChange,
  ...props
}) => {
  
  const { t } = useTranslation();

  const columns = [
    {
      name:    
      <CustomInput
        className="form-control"
        placeholder="Zone"
        value={filters.zone}
        onChange={(e) => handleFilterChange({
          type: 'debounced',
          field: 'zone',
          value: e.target.value
        })}
        data-filtered={!!filters.zone}
      />,
      selector: (row) => row.zone,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Code")}</span>,
      selector: (row) => row.code,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Corridor")}</span>,
      selector: (row) => row.corridor,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Rack")}</span>,
      selector: (row) => row.rack,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Export ID")}</span>,
      selector: (row) => row.export_id,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Description")}</span>,
      selector: (row) => row.description,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Label on transport")}</span>,
      selector: (row) => row.label_transport,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Temp on transport")}</span>,
      selector: (row) => row.temp_transport,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Sorting")}</span>,
      selector: (row) => row.sort || "-",
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default LocationsTable;
