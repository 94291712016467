import React from "react";
import CatalogueButton from "./CatalogueButton";
import useLoading from "../../../hooks/useLoading";

const AddCatalogueProductButton = ({ data, onAddProduct }) => {
  const { isLoading, onLoading, onStopLoading } = useLoading();

  return (
    <CatalogueButton
      icon="ri-check-line"
      isLoading={isLoading}
      onSubmit={() =>
        onAddProduct({
          data: {
            product_id: data.id,
            price: data.price,
          },
          onLoading,
          onFinally: onStopLoading,
        })
      }
    />
  );
};

export default AddCatalogueProductButton;
