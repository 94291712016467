import React from "react";
import DataTable from "../../Common/DataTable";
import { useSelector } from "react-redux";
import { formatParagraph } from "../../../helpers/formatParagraph";
import { useTranslation } from "react-i18next";
import StatusIcon from "../../Common/StatusIcon";

const StorageTable = () => {

  const { t } = useTranslation();

  const { storage: { list: storage } } = useSelector((state) => state.storage);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: row => row.id,
      grow: -1,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: (row) => row.name,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Code")}</span>,
      selector: (row) => row.code,
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Zones")}</span>,
      selector: (row) => formatParagraph(row?.zones) || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Problems")}</span>,
      selector: (row) => formatParagraph(row?.stock_issue) || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: (row) => <StatusIcon status={row.status} />,
    },
  ];

  return (
      <DataTable 
        data={storage} 
        columns={columns} 
        noPagination
      />
  );
};

export default StorageTable;
