import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ordersApi from "../../../apis/orders/api";
import StatusForm from "../../../Components/Orders/Statuses/Form";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdateStatus = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["order-status", id], () => ordersApi.statuses.getOne(id),
    {
      onSuccess({data: {data}}) {
        setInitialValues(data);
      }
    }
  );
  return (
    <PageContainer isLoading={isLoading || isRefetching} >
      <BreadCrumb to="/dashboard/orders/statuses" pageTitle="Statuses" title="Update" />
        {initialValues && 
          <StatusForm 
            type="update" 
            initialValues={initialValues} 
          />
        }
    </PageContainer>
  );
};

export default UpdateStatus;
