import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomLabel from "../../../Custom/CustomLabel";
import CustomOption from "../../../Custom/CustomOption";

const UpdateOrderInputs = ({ register }) => {
  
  const { statuses: { list: statuses } } = useSelector((state) => state.orders);

  return (
    <>
      <div className="mb-3">
        <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
          Status
        </CustomLabel>
        <select
          {...register("current_status_id")}
          className="form-select mb-3"
          aria-label="Default select example"
        >
          <CustomOption value="">Select status...</CustomOption>
          {statuses.map((status) => (
            <CustomOption key={status.id} value={status.id}>
              {status.name}
            </CustomOption>
          ))}
        </select>
        {/* {errorMessage(errors, 'current_status_id')} */}
      </div>
      <div className="mb-3">
        <CustomLabel htmlFor="exampleInputPassword1" className="form-label">
          Payment
        </CustomLabel>
        <select
          {...register("payment")}
          className="form-select mb-3"
          aria-label="Default select example"
        >
          <CustomOption value="">Select payment...</CustomOption>
          <CustomOption value="paid">paid</CustomOption>
          <CustomOption value="Unpaid">Unpaid</CustomOption>
        </select>
        {/* {errorMessage(errors, 'delivery_id')} */}
      </div>
    </>
  );
};

export default UpdateOrderInputs;
