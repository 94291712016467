import React from 'react'
import VendorForm from '../../../Components/Products/Vendors/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

const CreateVendor = () => {

  return (
      <PageContainer> 
          <BreadCrumb title="Create" pageTitle="Vendors" to="/dashboard/products/vendors" />
          <VendorForm 
            type='create'
          />
      </PageContainer>
  )
}

export default CreateVendor