import React from "react";
import { Col, Label, Row } from "reactstrap";
import CustomLabel from "../../../Custom/CustomLabel";

const Notifier = ({ register }) => {
  return (
    <Row>
      <Col>
        <CustomLabel>Days Notifier</CustomLabel>
        <input {...register("days_notifyer")} className="form-control" />
      </Col>
      <Col>
        <CustomLabel>Quantity Notifier</CustomLabel>
        <input {...register("qty_notifyer")} className="form-control" />
      </Col>
    </Row>
  );
};

export default Notifier;
