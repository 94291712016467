import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import MeasurementUnitsTable from "../../../Components/Products/MeasurementUnits/Table";
import { useDispatch, useSelector } from "react-redux";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import Restricted from "./../../../Components/Guard/Restricted";
import { PRODUCT_UNITS_PERMISSIONS } from "../../../permissions/products";
import { deleteProductsDataAction, getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const MeasurementUnits = () => {

  const dispatch = useDispatch();

  const { callback, deleteModal, isDeleting, itemsToDelete, load, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const { measurementUnits: { list: measurementUnits, total } } = useSelector((state) => state.products);

  const [loading, setLoading] = useState(true);

  const searchFunc = async (query) => {
    if (!loading) setLoading(true);
    dispatch(
      getAllProductsDataAction({
        field: 'measurementUnits',
        query,
        onSuccess: () => setLoading(false),
      })
    );
  };

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'measurementUnits',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const handleDelete = () => {
    load();
    const ids = itemsToDelete.selectedRows.map((item) => item.id);
    dispatch(
      deleteProductsDataAction({
        field: 'measurementUnits',
        data: {
          ids
        },
        onSuccess() {
          callback();
          search();
        }
      })
    );
  };

  return (
      <PageContainer>

        <BreadCrumb
          pageTitle="Products"
          to="/dashboard/products"
          title="Measurement Units"
        />

        <div className="hstack gap-2">
          <Restricted permissions={PRODUCT_UNITS_PERMISSIONS.create}>
            <Link to="/dashboard/products/measurement-units/create">
              <CustomButton 
                className="btn-success"
              >
                <i className="ri-add-fill me-1 align-bottom"></i>
                Create
              </CustomButton>
            </Link>
          </Restricted>
          <Restricted permissions={PRODUCT_UNITS_PERMISSIONS.delete}>
            <CustomButton
              className="btn-danger"
              disabled={!itemsToDelete.selectedRows?.length > 0}
              onClick={() => setDeleteModal(true)}
            >
              <i className="ri-delete-bin-line me-1 align-bottom"></i>
                Delete
            </CustomButton>
          </Restricted>
        </div>

        <Card className="mt-3">
          <MeasurementUnitsTable
              data={measurementUnits}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              handleDelete={handleDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              sortDirection={searchValues.order_value}
              fetching={loading}
              isDeleting={isDeleting}
              path="/dashboard/products/measurement-units"
          />
        </Card>

      </PageContainer>
  );
};

export default MeasurementUnits;
