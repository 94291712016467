import React from "react";
import { useTranslation } from "react-i18next";
import { formatParagraph } from "../../../helpers/formatParagraph";
import DataTable from "../../Common/DataTable";
import StatusToggle from "../../Common/StatusToggle";

const ProductsProblemsTable = ({ ...props }) => {
  
  const { t } = useTranslation();

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product number")}</span>
      ),
      selector: row => row.matricule,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: row => row.name,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Categories")}</span>,
      selector: row => formatParagraph(row?.categories) || "-",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("In Stock")}</span>,
      selector: row => row.total_qty,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Status")}</span>,
      selector: row => <StatusToggle type="products" id={row.id} field="status" status={row.status} />,
    },
  ];

  return (
    <DataTable
      columns={columns}
      {...props}
    />
  );
};

export default ProductsProblemsTable;
