import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ordersApi from "../../../apis/orders/api";
import OrderUnitForm from "../../../Components/Orders/Units/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateOrderUnit = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(["one-order-unit", id], () => ordersApi.units.getOne(id),
    {
      onSuccess({data: {data}}) {
        setInitialValues(data);
      }
    }
  );

  return (
    <PageContainer isLoading={isLoading || isRefetching} >
       <BreadCrumb title="Update" pageTitle="Units" to="/dashboard/orders/units" />
      {initialValues && 
        <OrderUnitForm 
          type="update" 
          initialValues={initialValues} 
        />
      }
    </PageContainer>
  );
};

export default UpdateOrderUnit;
