import { storageActions } from "../slices/storage";
import storageApi from "../../apis/storage/api";

export const getAllStorageDataAction = (payload) => async (dispatch) => {
  try {
    const { data: response } = await storageApi[payload.field].getAll(payload?.query);
    dispatch(
        storageActions.setData({
            field: payload.field,
            data: response.data,
            total: response.meta?.total || null,
      })
    );
    if (payload?.onSuccess) {
        payload?.onSuccess();
    }
  } catch (error) {
    console.log("error", error);
  }
};

