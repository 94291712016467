import React, { useState } from "react";
import { Card } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import UsersStatusesTable from "../../../Components/Users/Statuses/Table";
import { useDispatch, useSelector } from "react-redux";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import useDeleteMultiple from "../../../hooks/useDeleteMultiple";
import Restricted from "../../../Components/Guard/Restricted";
import { USER_STATUS_PERMISSIONS } from "../../../permissions/users";
import { deleteUsersDataAction, getAllUsersDataAction } from "../../../redux/actions/users";
import PageContainer from "../../../Components/Layout/PageContainer";
import CustomButton from "../../../Components/Common/CustomButton";
import { useSearch } from "../../../hooks/useSearch";

const UsersStatuses = () => {
  
  const [statusModal, setStatusModal] = useState(false);

  const { callback, deleteModal, isDeleting, itemsToDelete, load, setDeleteModal, setItemsToDelete } = useDeleteMultiple();

  const dispatch = useDispatch();

  const { create, update, modalType } = useCreateUpdateModal({
    name: "",
    status: false,
  });

  const openModalCreate = () => {
    create();
    setStatusModal(true);
  };

  const toggleStatusModal = () => {
    return setStatusModal(!statusModal);
  };

  const [loading, setLoading] = useState(true);

  const { statuses: { list: statuses, total } } = useSelector((state) => state.users);

  const searchFunc = (query) => {
    if (!loading) setLoading(true);
    dispatch(getAllUsersDataAction({
      field: 'statuses',
      query,
      onSuccess() {
        setLoading(false);
      }
    }));
  }

  const { searchValues, handleChange, setPageSize, navigateToPage, search } = useSearch({
    name: 'usersStatuses',
    debouncedValues: {
      name: '',
    },
    searchFunc
  });

  const handleDelete = () => {
    const ids = itemsToDelete.selectedRows.map((item) => item.id);
    load();
    dispatch(
      deleteUsersDataAction({
        field: 'statuses',
        data: {
          ids
        },
        onSuccess() {
          callback();
          search();
        },
      })
    );
  };

  return (
      <PageContainer>

        <BreadCrumb pageTitle="Users" title="Statuses" to="/dashboard/users" />

        <div className="hstack gap-2">

            <Restricted permissions={USER_STATUS_PERMISSIONS.create}>
              <CustomButton
                className="btn-success"
                onClick={openModalCreate}
              >
                <i className="ri-add-line align-bottom me-1"></i> 
                 Create
              </CustomButton>
            </Restricted>

            <Restricted permissions={USER_STATUS_PERMISSIONS.delete}>
              <CustomButton
                className="btn-danger"
                disabled={!itemsToDelete.selectedRows?.length > 0}
                onClick={() => setDeleteModal(true)}
              >
                <i className="ri-delete-bin-line align-bottom me-1"></i>
                Delete
              </CustomButton>
            </Restricted>

        </div>

        <Card className="mt-3">
            <UsersStatusesTable
              data={statuses}
              paginationServer
              paginationTotalRows={total}
              navigateToPage={navigateToPage}
              selectableRows
              itemsToDelete={itemsToDelete}
              setItemsToDelete={setItemsToDelete}
              deleteModal={deleteModal}
              setDeleteModal={setDeleteModal}
              handleDelete={handleDelete}
              filters={searchValues}
              handleFilterChange={handleChange}
              pageSize={searchValues.page_size}
              setPageSize={setPageSize}
              sortDirection={searchValues.order_value}
              fetching={loading}
              isDeleting={isDeleting}
              update={update}
              modalType={modalType}
              statusModal={statusModal}
              toggleStatusModal={toggleStatusModal}
              loading={loading}
            />
        </Card>

      </PageContainer>
  );
};

export default UsersStatuses;
