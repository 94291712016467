export default function getAdminPermissions(roles) {
    let permissions = []

    roles.forEach(role => {
        const rolePermissions = role.permissions;
        if (rolePermissions.length > 0) {
            permissions.push(...rolePermissions)
        }
    });

    return [...new Set(permissions)].map(item => item.name);
}