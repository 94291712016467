import * as yup from "yup";

export const stockStaffSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(15, "Your Password is more than 15 characters")
    .required("Please enter a valid and strong password"),
  name: yup.string("").required("Please provide a valid name"),
  user_name: yup.string("").required("Please provide a valid user name"),
});

export const updateStockStaffSchema = stockStaffSchema.shape({
  password: yup.string().notRequired()
})
