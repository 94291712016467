import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productsApi from "../../../apis/products/api";
import OfferForm from "../../../Components/Products/Offers/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const UpdateOffer = () => {
  
  const [initialValues, setInitialValues] = useState(null);
  const { id } = useParams();

  const { isLoading, isRefetching } = useQuery(["offer"], () => productsApi.offers.getOne(id), {
    onSuccess({data: {data}}) {
      setInitialValues(data);
    }
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb title="Update" pageTitle="Offers"  to="/dashboard/offers" />
      {initialValues && (
        <OfferForm 
          type="update" 
          initialValues={initialValues} 
        />
      )}
    </PageContainer>
  );
};

export default UpdateOffer;
