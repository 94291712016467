import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import {
  stockStaffSchema,
  updateStockStaffSchema,
} from "../../../helpers/validation/StockStaff/stockStaffValidation";
import { setFormErrors } from "../../../helpers/setFormErrors";
import CustomLabel from "../../Custom/CustomLabel";
import { createSettingsDataAction, updateSettingsDataAction } from "../../../redux/actions/settings";
import SaveButton from "../../Common/Buttons/SaveButton";

const StockStaffForm = ({ type, initialValues }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(
      type === "create" ? stockStaffSchema : updateStockStaffSchema
    ),
    defaultValues: type === "create" ? {} : initialValues,
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = (data) => {
    setIsLoading(true);
    if (type === "create") {
      dispatch(
        createSettingsDataAction({
          field: 'stockStaff',
          data,
          onSuccess: () => {
            history.push("/dashboard/stock-staff");
          },
          onError: (error) => {
            setIsLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    } else {
      dispatch(
        updateSettingsDataAction({
          field: 'stockStaff',
          data: { 
            id: initialValues?.id, 
            data
           },
          onSuccess: () => {
            setIsLoading(false);
          },
          onError: (error) => {
            setIsLoading(false);
            setFormErrors(error.data?.error, setError);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initialValues) reset(initialValues);
  }, [initialValues]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Card>
        <CardBody>
            <Row className="g-3">
              <Col lg={6}>
                <CustomLabel
                  htmlFor="contact-key-field"
                  className="form-label"
                >
                  Name
                </CustomLabel>
                <input
                  id="contact-key-field"
                  className="form-control"
                  type="text"
                  {...register("name")}
                />
                {errorMessage(errors, "name")}
              </Col>

              <Col lg={6}>
                <CustomLabel
                  htmlFor="contact-value-field"
                  className="form-label"
                >
                  Username
                </CustomLabel>
                <input
                  className="form-control"
                  type="text"
                  {...register("user_name")}
                />
                {errorMessage(errors, "user_name")}
              </Col>

              <Col lg={6}>

                <CustomLabel
                  htmlFor="contact-value-field"
                  className="form-label"
                >
                  Password
                </CustomLabel>

                <input
                  className="form-control"
                  type="password"
                  {...register("password")}
                />
                {errorMessage(errors, "password")}

              </Col>
            </Row>
        </CardBody>
      </Card>

      <div className="hstack gap-2 justify-content-end">
        <SaveButton
          isLoading={isLoading}
        />
      </div>

    </form>
  );
};

export default StockStaffForm;

//
