export const grantPermission = ({permissionToGrant, allPermissions, isSuperAdmin}) => {
    // ! Working Code
    // if (Array.isArray(permissionToGrant)) {
    //     return permissionToGrant.every(perm => allPermissions.includes(perm));
    // } else if (permissionToGrant.read || permissionToGrant.create || permissionToGrant.update || permissionToGrant.delete) {
    //     return Object.entries(permissionToGrant).some(([key, val]) => {
    //         return allPermissions.includes(val[0]);
    //     })
    // } else {
    //     return Object.entries(permissionToGrant).some(([key, val]) => {
    //         return Object.entries(val).some(([key2, val2]) => {
    //             return allPermissions.includes(val2[0]);
    //         })
    //     })
    // }

    // ! Replacement

    const adminsAndRoles = ["admins_and_roles-read", "admins_and_roles-update", "admins_and_roles-delete", "admins_and_roles-create", "stock_staff-read", "stock_staff-update", "stock_staff-delete", "stock_staff-create", ]

    if (Array.isArray(permissionToGrant)) {
        return permissionToGrant.every(perm => {
            if (adminsAndRoles.includes(perm)) {
                if (isSuperAdmin) return true
                return false
            }
            return true
        });
    } else if (permissionToGrant.read || permissionToGrant.create || permissionToGrant.update || permissionToGrant.delete) {
        return Object.entries(permissionToGrant).some(([key, val]) => {
            if (adminsAndRoles.includes(val[0])) {
                if (isSuperAdmin) return true
                return false
            }
            return true
        })
    } else {
        return Object.entries(permissionToGrant).some(([key, val]) => {
            return Object.entries(val).some(([key2, val2]) => {
                if (adminsAndRoles.includes(val2[0])) {
                    if (isSuperAdmin) return true
                    return false
                }
                return true
            })
        })
    }
}