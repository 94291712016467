import axiosInstance from "../axios";

// ['footer', 'social_media', 'setup', 'consult', 'about', 'policy', 'seo', 'version', 'email', 'opening_hours]

const settingsApi = {

    web: {

        get: (type) => {
            return axiosInstance.get(`/settings/${type}`);
        },

        update: (data) => {
            return axiosInstance.post(`/settings`, data);
        },
    },
  
    closingHours: {

        get: () => {
            return axiosInstance.get(`/closedates`);
        },

        create: (data) => {
            return axiosInstance.post("/closedates", data);
        },

        delete: (id) => {
            return axiosInstance.delete(`/closedates/${id}`);
        },
    },

    admins: {

        getAll: (query) => {
            return axiosInstance.get('/admins', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/admins/${id}`);
        },

        create: (data) => {
            return axiosInstance.post('/admins', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/admins/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/admins', {
                data
            });
        },

        updateProfile: (data) => {
            return axiosInstance.post(`/admins/edit-profile`, data);
        },

        login: (data) => {
            return axiosInstance.post('/admins/login', data)
        },

        getPermissions: () => {
            return axiosInstance.get('/permissions')
        }
    },

    roles: {

        getAll: (query) => {
            return axiosInstance.get('/roles', {
                params: query
            });
        },

        create: (data) => {
            return axiosInstance.post('/roles', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/roles/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/roles', {
                data
            });
        },
    },

    
    stockStaff: {
        
        getAll: (query) => {
            return axiosInstance.get('/staffs', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/staffs/${id}`);
        },

        create: (data) => {
            return axiosInstance.post('/staffs', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/staffs/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/staffs', {
                data
            });
        },
    },

    homeSliders: {

        getAll: (query) => {
            return axiosInstance.get('/sliders', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/sliders/${id}`);
        },

        create: (data) => {
            return axiosInstance.post('/sliders', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/sliders/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/sliders', {
                data
            });
        },
    },

    contacts: {

        getAll: (query) => {
            return axiosInstance.get('/shop/contactus', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/shop/contactus/${id}`);
        },
    },

    languages: {

        getAll: (query) => {
            return axiosInstance.get('/languages', {
                params: query
            });
        },

        getOne: (id) => {
            return axiosInstance.get(`/languages/${id}`);
        },

        create: (data) => {
            return axiosInstance.post('/languages', data);
        },

        update: (id, data) => {
            return axiosInstance.post(`/languages/${id}`, data)
        },

        delete: (data) => {
            return axiosInstance.delete('/languages', {
                data
            });
        },


        words: {

            getAll: (code) => {
                return axiosInstance.get(`/langkey/${code}`);
            },
    
            create: (code, data) => {
                return axiosInstance.post(`/langkey/${code}`, data);
            },
    
            update: (code, data) => {
                return axiosInstance.post(`/langkey/${code}`, data);
            },
    
            delete: (data) => {
                return axiosInstance.delete('/langkey', {
                    data
                });
            },
        }
    },
}

export default settingsApi