import React, { useEffect } from "react";
import { Col } from "reactstrap";
import { useFileManager } from "../../../../context/FileManagerContext";
import { errorMessage } from "../../../../helpers/validation/errorMessage";
import useSetFile from "../../../../hooks/useSetFile";
import SelectFileButton from "../../../Common/SelectFileButton";
import CustomLabel from "../../../Custom/CustomLabel";

const CreateOfferImages = ({

  initialValues,
  offerImage,
  setOfferImage,
  setValue,
  errors,

}) => {

  const { openFileManagerModal } = useFileManager();

  useSetFile({
    whatFor: "offer-image",
    cb: (file) => {
      setOfferImage({ id: file.id, preview: file.path });
    },
  });

  const removeImage = (pd) => {
    return setOfferImage(null);
  };

  useEffect(() => {
    if (initialValues?.image) {
        setOfferImage({
          id: initialValues.image.id,
          preview: `${initialValues.image.uri}/${initialValues.image.name}`
        });
    }
  }, [initialValues]);

  useEffect(() => {
    setValue("file_id", +offerImage?.id);
  }, [offerImage]);

  return (
    <React.Fragment>
      <Col lg={12} md={12} xs={12}>
        <CustomLabel>Image</CustomLabel>
        <SelectFileButton
          text="Select Image"
          onClick={() => openFileManagerModal("offer-image")}
        />
        {errorMessage(errors, "pic_attachement_id")}
      </Col>

      <Col>
        {
          offerImage && (
            <div style={{ position: "relative", maxWidth: "250px" }}>
              <img 
                className="img-thumbnail mt-4" src={offerImage.preview} 
                alt={offerImage.preview}
              />
              <i
                className="ri-delete-bin-fill"
                style={{
                  position: "absolute",
                  top: "20%",
                  right: "5%",
                  fontSize: "1.1rem",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={removeImage}
              ></i>
            </div>
          )
        }
      </Col>
    </React.Fragment>
  );
};

export default CreateOfferImages;
