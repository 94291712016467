import React from 'react'

const ExportToPdf = ({ onClick, lg }) => {
    return (
        <button
            className={`btn ${lg ? '' : 'btn-sm'} btn-success`}
            onClick={onClick}
            type="button"
        >
            <i className="ri-file-pdf-line align-bottom fs-14"></i> {" "}
        </button>
    )
}

export default ExportToPdf