import React from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/display-name
const CustomTextarea = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return <textarea ref={ref} {...props} placeholder={""} />;
});

export default CustomTextarea;
