import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createDeliveryMethodSchema } from "../../../helpers/validation/Orders/createDeliveryMethod";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { useDispatch } from "react-redux";
import CustomLabel from "../../Custom/CustomLabel";
import { createOrdersDataAction, updateOrdersDataAction } from "../../../redux/actions/orders";
import SaveButton from "../../Common/Buttons/SaveButton";

const DeliveryMethodForm = ({ type, initialValues }) => {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(createDeliveryMethodSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const submitForm = async (data) => {
    setIsLoading(true);
    if (type === "create") {
      dispatch(
        createOrdersDataAction({
          field: 'deliveryMethods',
          data,
          onSuccess: () => history.push("/dashboard/orders/delivery-methods"),
        })
      );
    } else {
      const id = data.id;
      if (!id) return;
      dispatch(
        updateOrdersDataAction({
          field: 'deliveryMethods',
          data: {
            id,
            data,
          },
          onSuccess: () => setIsLoading(false),
        })
      );
    }
  };

  return (
    <div>
      <Col md={12}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card className="p-3">
            <Row className="gx-4 gy-2">
              <Col md={6}>
                <CustomLabel for="inputName">Name</CustomLabel>
                <input
                  {...register("name")}
                  className="form-control"
                  id="inputName"
                />
                {errorMessage(errors, "name")}
              </Col>
              <Col md={6} className="d-flex align-items-center">
                <div className="form-check form-switch mt-4">
                    <input 
                      {...register('status')} 
                      className="form-check-input fs-16" 
                      type="checkbox" 
                      role="switch" 
                      id="status" 
                    />
                    <CustomLabel 
                      className="form-check-label" 
                      htmlFor="status"
                    >
                      Active
                    </CustomLabel>
                  </div>
              </Col>
            </Row>
          </Card>

          <div className="d-flex justify-content-end">
            <SaveButton
              isLoading={isLoading}
            />
          </div>

        </form>

      </Col>
    </div>
  );
};

export default DeliveryMethodForm;
