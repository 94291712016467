import React from "react";
import { Redirect } from "react-router-dom";

// ! Dashbaord landing
import Dashboard from "../pages/Dashboard";

// ! Login
import Login from "../pages/Authentication/Login";

// ! Dashboard Users
import Users from "../pages/Users/Users";
import CreateUser from "../pages/Users/Users/Create";
import UpdateUser from "../pages/Users/Users/Update";
// Users / Groups
import Groups from "../pages/Users/Groups";
// Users / Zones
import Zones from "../pages/Users/Zones";
import CreateZone from "../pages/Users/Zones/Create";
import UpdateZone from "../pages/Users/Zones/Update";
// Users / Statuses
import UsersStatuses from "../pages/Users/Statuses";
// Users / History
import History from "../pages/Users/History";
import CreateHistory from "../pages/Users/History/Create";
import UpdateHistory from "../pages/Users/History/Update";

// ! Dashboard Storage
import Storage from "../pages/Storage/Storage";
// Storage / Lot Movement
import LotMovement from "../pages/Storage/LotMovement";
// Storage / Number of Products
import ProductsNumbers from "../pages/Storage/ProductsNumbers";
// Storage / Storage Problems
import StorageProblems from "../pages/Storage/Problems";
// Storage / Storage Control
import StorageControl from "../pages/Storage/Control";
// Storage / New in Stock
import NewStock from "../pages/Storage/NewStock";

// ! Web Settings
import WebSettings from "../pages/Settings/Web";
// Web Settings / Opening Hours
import OpeningHours from "../pages/Settings/OpeningHours";
// Web Settings / Language Settings
import LanguagesSettings from "../pages/Settings/Language";
import CreateLanguage from "../pages/Settings/Language/Create";
import UpdateLanguage from "../pages/Settings/Language/Update";
import LanguageDetails from "../pages/Settings/Language/Keys";

// ! Home
import HomeSliders from "../pages/Settings/HomeSliders";
import CreateNewSlider from "../pages/Settings/HomeSliders/Create";
import UpdateHomeSlider from "../pages/Settings/HomeSliders/Update";
// Home / Contact us
import Contact from "../pages/Settings/Contacts";
import ContactDetails from "../pages/Settings/Contacts/Details";

// ! Orders
import Orders from "../pages/Orders/Orders";
import UpdateOrder from "../pages/Orders/Orders/Update";
// Orders / Price Offers
import PriceOffers from "../pages/Orders/PriceOffers";
import UpdatePriceOffer from "../pages/Orders/PriceOffers/Update";
// Orders / Newsletters
import Newsletters from "../pages/Orders/Newsletters";
import UpdateNewsletter from "../pages/Orders/Newsletters/Update";
// Orders / Status
import OrdersStatuses from "../pages/Orders/Statuses";
import CreateOrderStatus from "../pages/Orders/Statuses/Create";
import UpdateOrderStatus from "../pages/Orders/Statuses/Update";
// Orders / Delivery Method
import DeliveryMethods from "../pages/Orders/DeliveryMethods";
import CreateDeliveryMethod from "../pages/Orders/DeliveryMethods/Create";
import UpdateDeliveryMethod from "../pages/Orders/DeliveryMethods/Update";
// Orders / Unit
import OrderUnits from "../pages/Orders/Units";
import CreateOrderUnit from "../pages/Orders/Units/Create";
import UpdateOrderUnit from "../pages/Orders/Units/Update";
// Orders / Transport
import Transports from "../pages/Orders/Transports";
import CreateTransport from "../pages/Orders/Transports/Create";
import UpdateTransport from "../pages/Orders/Transports/Update";
// Orders / Settings
import OrderSettings from "../pages/Orders/Settings";
// Orders Unit detail
import OrderTransportDetails from "../pages/Orders/TransportDetails";

// ! Products
import Products from "../pages/Products/Products";
import CreateProduct from "../pages/Products/Products/Create";
import UpdateProduct from "../pages/Products/Products/Update";
// Products / Offers
import Offers from "../pages/Products/Offers";
import CreateOffer from "../pages/Products/Offers/Create";
import UpdateOffer from "../pages/Products/Offers/Update";
// Products / Categories
import Categories from "../pages/Products/Categories";
import CreateCategory from "../pages/Products/Categories/Create";
import UpdateCategory from "../pages/Products/Categories/Update";
import SortCategories from "../pages/Products/Categories/Sort";
// Products / Vendors
import Vendors from "../pages/Products/Vendors";
import CreateVendor from "../pages/Products/Vendors/Create";
import UpdateVendor from "../pages/Products/Vendors/Update";
// Products / Measurement Units
import MeasurementUnits from "../pages/Products/MeasurementUnits";
import CreateUnit from "../pages/Products/MeasurementUnits/Create";
import UpdateUnit from "../pages/Products/MeasurementUnits/Update";
// Products / VAT
import VAT from "../pages/Products/VAT";
import CreateVAT from "../pages/Products/VAT/Create";
import UpdateVAT from "../pages/Products/VAT/Update";
// Products / Location
import Locations from "../pages/Products/Locations";
import CreateLocation from "../pages/Products/Locations/Create";
import UpdateLocation from "../pages/Products/Locations/Update";
// Products / Problems
import ProductsProblems from "../pages/Products/Problems";
// Products / Newsltter
import ProductsCatalogue from "../pages/Products/Catalogue";

// Profile
import Profile from "../pages/Profile";
// Admins 
import Admins from "../pages/Settings/Admins";
// Stock Staff
import StockStaff from "../pages/Settings/StockStaff";
import CreateStockStaff from "../pages/Settings/StockStaff/Create";
import UpdateStockStaff from "../pages/Settings/StockStaff/Update";
// File Manager
import FileManager from "../pages/Settings/FileManager";

// * Permissions
import {
  ORDER_DELIVERY_PERMISSIONS,
  ORDER_DELIVERY_TYPES_PERMISSIONS,
  ORDER_PERMISSIONS,
  ORDER_SETTINGS_PERMISSIONS,
  ORDER_STATUS_PERMISSIONS,
  ORDER_UNITS_PERMISSIONS,
} from "../permissions/orders";
import {
  SLIDER_PERMISSIONS,
  PAGES_PERMISSIONS,
  CONTACTS_PERMISSIONS,
} from "../permissions/home";
import {
  PRODUCT_CATEGORIES_PERMISSIONS,
  PRODUCT_INVALID_PRODUCTS_PERMISSIONS,
  PRODUCT_LOCATION_PERMISSIONS,
  PRODUCT_OFFER_PERMISSIONS,
  PRODUCT_PERMISSIONS,
  PRODUCT_UNITS_PERMISSIONS,
  PRODUCT_VAT_PERMISSIONS,
} from "../permissions/products";
import {
  USER_GROUP_PERMISSIONS,
  USER_HISTORY_PERMISSIONS,
  USER_PERMISSIONS,
  USER_STATUS_PERMISSIONS,
  USER_ZONES_PERMISSIONS,
} from "../permissions/users";
import {
  ADMIN_AND_ROLES_PERMISSIONS,
  FILE_MANAGER,
  STOCK_STAFF,
} from "../permissions/admins_roles";

// Languages settings

const authProtectedRoutes = [
  // ! Orders
  {
    path: "/dashboard",
    component: Dashboard,
    permissions: ORDER_PERMISSIONS.read,
  },
  {
    path: "/dashboard/orders",
    component: Orders,
    permissions: ORDER_PERMISSIONS.read,
  },
  {
    path: "/dashboard/orders/change-order/:id/:type",
    component: UpdateOrder,
    permissions: [ORDER_PERMISSIONS.read[0], ORDER_PERMISSIONS.update[0]],
  },
  // Orders / Price Offers
  {
    path: "/dashboard/orders/price-offers",
    component: PriceOffers,
    permissions: ORDER_PERMISSIONS.read,
  },

  {
    path: "/dashboard/orders/price-offers/update/:id",
    component: UpdatePriceOffer,
    permissions: ORDER_PERMISSIONS.read,
  },

  {
    path: "/dashboard/orders/newsletters",
    component: Newsletters,
    permissions: ORDER_PERMISSIONS.read,
  },

  {
    path: "/dashboard/orders/newsletters/update/:id",
    component: UpdateNewsletter,
    permissions: ORDER_PERMISSIONS.read,
  },

  // Orders / Status
  {
    path: "/dashboard/orders/statuses",
    component: OrdersStatuses,
    permissions: ORDER_STATUS_PERMISSIONS.read,
  },
  {
    path: "/dashboard/orders/statuses/create",
    component: CreateOrderStatus,
    permissions: ORDER_STATUS_PERMISSIONS.create,
  },
  {
    path: "/dashboard/orders/statuses/update/:id",
    component: UpdateOrderStatus,
    permissions: ORDER_STATUS_PERMISSIONS.update,
  },
  // Orders / Delivery Methods
  {
    path: "/dashboard/orders/delivery-methods",
    component: DeliveryMethods,
    permissions: ORDER_DELIVERY_TYPES_PERMISSIONS.read,
  },
  {
    path: "/dashboard/orders/delivery-methods/create",
    component: CreateDeliveryMethod,
    permissions: ORDER_DELIVERY_TYPES_PERMISSIONS.create,
  },
  {
    path: "/dashboard/orders/delivery-methods/update/:id",
    component: UpdateDeliveryMethod,
    permissions: ORDER_DELIVERY_TYPES_PERMISSIONS.create,
  },
  // Orders / Units
  {
    path: "/dashboard/orders/units",
    component: OrderUnits,
    permissions: ORDER_UNITS_PERMISSIONS.read,
  },
  {
    path: "/dashboard/orders/units/create",
    component: CreateOrderUnit,
    permissions: ORDER_UNITS_PERMISSIONS.create,
  },
  {
    path: "/dashboard/orders/units/update/:id",
    component: UpdateOrderUnit,
    permissions: ORDER_UNITS_PERMISSIONS.update,
  },
  // Orders / Transport
  {
    path: "/dashboard/orders/transports",
    component: Transports,
    permissions: ORDER_DELIVERY_PERMISSIONS.read,
  },
  {
    path: "/dashboard/orders/transports/create",
    component: CreateTransport,
    permissions: ORDER_DELIVERY_PERMISSIONS.create,
  },
  {
    path: "/dashboard/orders/transports/update/:id",
    component: UpdateTransport,
    permissions: ORDER_DELIVERY_PERMISSIONS.update,
  },
  {
    path: "/dashboard/orders/:id/transport",
    component: OrderTransportDetails,
  },
  // Orders / Settings
  {
    path: "/dashboard/orders/settings",
    component: OrderSettings,
    permissions: ORDER_SETTINGS_PERMISSIONS.read,
  },

  // ! Products
  {
    path: "/dashboard/products",
    component: Products,
    permissions: PRODUCT_PERMISSIONS.read,
  },
  {
    path: "/dashboard/products/create",
    component: CreateProduct,
    permissions: PRODUCT_PERMISSIONS.create,
  },
  {
    path: "/dashboard/products/update/:id",
    component: UpdateProduct,
    permissions: PRODUCT_PERMISSIONS.update,
  },
  // Products / Categories
  {
    path: "/dashboard/products/categories",
    component: Categories,
    permissions: PRODUCT_CATEGORIES_PERMISSIONS.read,
  },
  {
    path: "/dashboard/products/categories/create",
    component: CreateCategory,
    permissions: PRODUCT_CATEGORIES_PERMISSIONS.create,
  },
  {
    path: "/dashboard/products/categories/update/:id",
    component: UpdateCategory,
    permissions: PRODUCT_CATEGORIES_PERMISSIONS.update,
  },
  {
    path: "/dashboard/products/categories/sort",
    component: SortCategories,
    permissions: [
      PRODUCT_CATEGORIES_PERMISSIONS.read[0],
      PRODUCT_CATEGORIES_PERMISSIONS.update[0],
    ],
  },
  // Products / Vendors
  { path: "/dashboard/products/vendors", component: Vendors },
  { path: "/dashboard/products/vendors/create", component: CreateVendor },
  { path: "/dashboard/products/vendors/update/:id", component: UpdateVendor },
  // Products / Measurement Units
  {
    path: "/dashboard/products/measurement-units",
    component: MeasurementUnits,
    permissions: PRODUCT_UNITS_PERMISSIONS.read,
  },
  {
    path: "/dashboard/products/measurement-units/create",
    component: CreateUnit,
    permissions: PRODUCT_UNITS_PERMISSIONS.create,
  },
  {
    path: "/dashboard/products/measurement-units/update/:id",
    component: UpdateUnit,
    permissions: PRODUCT_UNITS_PERMISSIONS.update,
  },
  // Products / VAT
  {
    path: "/dashboard/products/vat",
    component: VAT,
    permissions: PRODUCT_VAT_PERMISSIONS.read,
  },
  {
    path: "/dashboard/products/vat/create",
    component: CreateVAT,
    permissions: PRODUCT_VAT_PERMISSIONS.create,
  },
  {
    path: "/dashboard/products/vat/update/:id",
    component: UpdateVAT,
    permissions: PRODUCT_VAT_PERMISSIONS.update,
  },
  // Products / Offers
  {
    path: "/dashboard/products/offers",
    component: Offers,
    permissions: PRODUCT_OFFER_PERMISSIONS.read,
  },
  {
    path: "/dashboard/products/offers/create",
    component: CreateOffer,
    permissions: PRODUCT_OFFER_PERMISSIONS.create,
  },
  {
    path: "/dashboard/products/offers/update/:id",
    component: UpdateOffer,
    permissions: PRODUCT_OFFER_PERMISSIONS.update,
  },
  // Products / Locations
  {
    path: "/dashboard/products/locations",
    component: Locations,
    permissions: PRODUCT_LOCATION_PERMISSIONS.read,
  },
  {
    path: "/dashboard/products/locations/create",
    component: CreateLocation,
    permissions: PRODUCT_LOCATION_PERMISSIONS.create,
  },
  {
    path: "/dashboard/products/locations/update/:id",
    component: UpdateLocation,
    permissions: PRODUCT_LOCATION_PERMISSIONS.update,
  },
  // Products / Invalid Products
  {
    path: "/dashboard/products/problems",
    component: ProductsProblems,
    permissions: PRODUCT_INVALID_PRODUCTS_PERMISSIONS.read,
  },

  // Products / Catalogue
  
  {
    path: "/dashboard/products/catalogue",
    component: ProductsCatalogue,
  },

  // ! Users
  {
    path: "/dashboard/users",
    component: Users,
    permissions: USER_PERMISSIONS.read,
  },
  {
    path: "/dashboard/users/create",
    component: CreateUser,
    permissions: USER_PERMISSIONS.create,
  },
  {
    path: "/dashboard/users/update/:id",
    component: UpdateUser,
    permissions: USER_PERMISSIONS.update,
  },
  // Users / Groups
  {
    path: "/dashboard/users/groups",
    component: Groups,
    permissions: USER_GROUP_PERMISSIONS.read,
  },
  // Users / Zones
  {
    path: "/dashboard/users/zones",
    component: Zones,
    permissions: USER_ZONES_PERMISSIONS.read,
  },
  {
    path: "/dashboard/users/zones/create",
    component: CreateZone,
    permissions: USER_ZONES_PERMISSIONS.create,
  },
  {
    path: "/dashboard/users/zones/update/:id",
    component: UpdateZone,
    permissions: USER_ZONES_PERMISSIONS.update,
  },
  // Users / Statuses
  {
    path: "/dashboard/users/statuses",
    component: UsersStatuses,
    permissions: USER_STATUS_PERMISSIONS.read,
  },
  // Users / History
  {
    path: "/dashboard/users/history",
    component: History,
    permissions: USER_HISTORY_PERMISSIONS.read,
  },
  {
    path: "/dashboard/users/history/create",
    component: CreateHistory,
    permissions: USER_HISTORY_PERMISSIONS.create,
  },
  {
    path: "/dashboard/users/history/update/:id",
    component: UpdateHistory,
    permissions: USER_HISTORY_PERMISSIONS.update,
  },

  // ! Storage
  { path: "/dashboard/storage", component: Storage },
  // Storage / Lot Movement
  { path: "/dashboard/storage/lot-movement", component: LotMovement },
  // Storage / Products Numbers
  { path: "/dashboard/storage/products-numbers", component: ProductsNumbers },
  // Storage / Storage Problems
  { path: "/dashboard/storage/problems", component: StorageProblems },
  // Storage / Storage Control
  { path: "/dashboard/storage/control", component: StorageControl },
  // Storage / New in stock
  { path: "/dashboard/storage/new-stock", component: NewStock },

  // ! Web Settings
  { path: "/dashboard/settings/web", component: WebSettings },

  // ! Opening hours
  { path: "/dashboard/settings/opening-hours", component: OpeningHours },
  
  // ! Languages settings

  {
    path: "/dashboard/settings/languages",
    component: LanguagesSettings,
  },

  {
    path: "/dashboard/settings/languages/keys/:code",
    component: LanguageDetails,
  },

  {
    path: "/dashboard/settings/languages/create",
    component: CreateLanguage,
  },

  {
    path: "/dashboard/settings/languages/update/:code",
    component: UpdateLanguage,
  },

  // ! Home
  // Home / Home Slider
  {
    path: "/dashboard/settings/home-sliders",
    component: HomeSliders,
    permissions: SLIDER_PERMISSIONS.read,
  },
  {
    path: "/dashboard/settings/home-sliders/create",
    component: CreateNewSlider,
    permissions: [SLIDER_PERMISSIONS.read[0], SLIDER_PERMISSIONS.create[0]],
  },
  {
    path: "/dashboard/settings/home-sliders/update/:id",
    component: UpdateHomeSlider,
    permissions: [SLIDER_PERMISSIONS.read[0], SLIDER_PERMISSIONS.update[0]],
  },
  // Home / Contact us
  {
    path: "/dashboard/settings/contacts",
    component: Contact,
    permissions: CONTACTS_PERMISSIONS.read,
  },
  {
    path: "/dashboard/settings/contacts/view/:id",
    component: ContactDetails,
    permissions: CONTACTS_PERMISSIONS.read,
  },

  // ! Roles
  {
    path: "/dashboard/settings/admins",
    component: Admins,
    permissions: [
      ADMIN_AND_ROLES_PERMISSIONS.read[0],
      ADMIN_AND_ROLES_PERMISSIONS.create[0],
      ADMIN_AND_ROLES_PERMISSIONS.update[0],
    ],
  },

  // ! Stock Staff
  {
    path: "/dashboard/settings/stock-staff",
    component: StockStaff,
    exact: true,
    permissions: STOCK_STAFF.read[0],
  },
  {
    path: "/dashboard/settings/stock-staff/create",
    component: CreateStockStaff,
    permissions: STOCK_STAFF.create[0],
  },
  {
    path: "/dashboard/settings/stock-staff/update/:id",
    component: UpdateStockStaff,
    permissions: STOCK_STAFF.update[0],
  },

  // ! File manager
  {
    path: "/dashboard/settings/file-manager",
    component: FileManager,
    permissions: [
      FILE_MANAGER.read[0],
      FILE_MANAGER.create[0],
      FILE_MANAGER.update[0],
    ],
  },

  // Profile

  { path: "/dashboard/profile", component: Profile },

  {
    path: "*",
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [{ path: "/login", component: Login }];

export { authProtectedRoutes, publicRoutes };
