import React, { useState, useEffect } from "react";
import { Card, Row } from "reactstrap";
import DataTable from "../../../Common/DataTable";
import { Link, useHistory, useParams } from "react-router-dom";
import calculateTotalPrice from "../../../../helpers/calculateTotalPrice";
import ExportToPdf from "./ExportToPdf";
import { useSelector } from "react-redux";
import { sortByStockZone } from "../../../../helpers/sortByStockZone";
import { extractQuantityBasedonUnit } from "../../../../helpers/calculateOrderPrices";
import { useTranslation } from "react-i18next";

const ReadOnlyTable = ({
  allItems,
  order,
  visibleColumns,
  exportToPdf,
  exportProducts,
}) => {
  const { type } = useParams();

  const { locations: { list: locations } } = useSelector((state) => state.products);

  const history = useHistory();

  const { t } = useTranslation();
  
  const [orders, setOrders] = useState(null);

  const [extraCost, setExtraCost] = useState({
    isExtraCost: true,
    description: "frakt",
    quantity: "",
    price: "",
  });

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-13">{t("Product number")}</span>
      ),
      selector: (row) => row.product?.matricule || "",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Product Name")}</span>,
      selector: (row) =>
        row.isExtraCost ? (
          <input
            name="qty"
            value={extraCost.description || ""}
            className="form-control"
            disabled
          />
        ) : (
          (
            <span
              onClick={() =>
                history.push(`/dashboard/products/update/${row.product?.id}`)
              }
              title={row.product?.name}
              className="cursor-pointer"
            >
              <b>{row.product?.name}</b>
            </span>
          ) || "-"
        ),
      sortable: true,
      grow: 3,
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Qty")}</span>,
      selector: (row) =>
        row.isExtraCost ? (
          <input
            name="qty"
            value={extraCost.quantity || ""}
            type="number"
            className="form-control"
            disabled
            step="any"
          />
        ) : (
          <input
            name="qty"
            value={row.qty || ""}
            type="number"
            className="form-control"
            disabled
            step="any"
          />
        ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Type")}</span>,
      selector: (row) =>
        !row.isExtraCost && (
          <>
            {`${row.product_unit?.name} ${(row.weight && row.product_unit?.id === 1) ? `(${row.weight})` : ""}`}
          </>
        ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Price")}</span>,
      selector: (row) =>
        row.isExtraCost ? (
          <input
            name={type === "order" ? "price" : "prix_unit"}
            value={extraCost.price || ""}
            type="number"
            disabled
            className="form-control"
            step="any"
          />
        ) : (
          <input
            name={type === "order" ? "price" : "prix_unit"}
            value={(type === "order" ? row.price : row.prix_unit) || ""}
            type="number"
            disabled
            className="form-control"
            step="any"
          />
        ),
    },

    {
      name: (
        <span className="font-weight-bold fs-13">{t("Purchase Price")}</span>
      ),
      selector: (row) =>
        !row.isExtraCost && (
          <input
            type="number"
            className="form-control"
            disabled
            value={row?.price_purchase || ""}
            step="any"
          />
        ),
      id: "pruchase_price",
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Discount")}</span>,
      selector: (row) =>
        !row.isExtraCost && (
          <input
            name={type === "order" ? "promo" : "remise"}
            value={(type === "order" ? row.promo : row.remise) || ""}
            className="form-control"
            disabled
          />
        ),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Total")}</span>,
      selector: (row) =>
        !row.isExtraCost &&
        Number(
          calculateTotalPrice(
            row.price,
            row.promo,
            extractQuantityBasedonUnit(row)
          )
        ).toFixed(2),
    },

    {
      name: <span className="font-weight-bold fs-13">{t("Scanned")}</span>,
      selector: (row) =>
        !row.isExtraCost && (
          <div className="d-flex gap-2">
            {row.scanned ? (
              <i className="ri-check-line fs-20" style={{ color: "green" }}></i>
            ) : (
              <i className="ri-close-line fs-20" style={{ color: "red" }}></i>
            )}
          </div>
        ),
      grow: -1,
    },
  ];

  useEffect(() => {
    if (order) {
      setExtraCost({
        isExtraCost: true,
        description: order.description || "Frakt",
        quantity: order.delivery_qty || "",
        price: order.delivery_price || "",
      });
    }
  }, [order]);

  useEffect(() => {
    if (order && allItems) {
      if (order.merged) {
        setOrders(Object.keys(order.items).map(key => ({
          order: `#00${key || order.id}`,
          items: order.items[key]
        })).reverse())
      } else {
        setOrders([
          {
            order: order.matricule,
            items: order.items
          }
        ])
      }
    } 
  }, [order, allItems])

  return (
    <Row className="mt-3">
      {orders ? 
      
        <>
          <div className="hstack gap-3 align-items-end">
              <div className="d-flex flex-column justify-content-end">
                <div className="hstack gap-2">
                  <ExportToPdf onClick={exportToPdf} lg="lg" />

                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={exportProducts}
                  >
                    <i className="ri-car-line"></i>
                  </button>

                  <Link to={`/dashboard/orders/${order.id}/transport`}>
                    <button className="btn btn-success">
                      <i className="ri-barcode-line"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <Row className="mt-3">
              <form>
                {orders.map((ord, index) => {
                    return (
                      <div
                        key={ord.order}
                      >
                           {order?.matricule !== ord.order &&
                            <h4>{ord.order}</h4>
                          }
                          <Card>
                            <DataTable
                              columns={visibleColumns.purchasePrice? columns: columns.filter((col) => !col.id)}
                              data={[
                                ...sortByStockZone(ord.items.map(item => ({
                                  order: ord.order,
                                  ...item
                                })), locations),
                                ...(ord.items?.length > 0 && index === 0 ? [extraCost] : []),
                              ]}
                              selectableRowDisabled={(row) => row.isExtraCost}
                              conditionalRowStyle={[
                                {
                                  when: (row) => row.no_stock,
                                  style: {
                                    backgroundColor: "rgb(215, 50, 67)",
                                    opacity: "1",
                                    pointerEvents: "none",
                                    color: "white",
                                    input: {
                                      display: "none",
                                    },
                                  },
                                },
                              ]}
                              noPagination
                              />
                          </Card>
                      </div>
                      )
                    })}
              </form>
            </Row>
        </>
      : <></>}
    </Row>
  );
};

export default ReadOnlyTable;
