import React, { useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../../helpers/validation/errorMessage";
import { createOrderUnitSchema } from "../../../helpers/validation/Orders/createOrderUnit";
import { useDispatch } from "react-redux";
import CustomLabel from "../../Custom/CustomLabel";
import { createOrdersDataAction, updateOrdersDataAction } from "../../../redux/actions/orders";
import SaveButton from "../../Common/Buttons/SaveButton";

const OrderUnitForm = ({ type, initialValues }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createOrderUnitSchema),
    defaultValues: initialValues,
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  
  const history = useHistory();

  const { id } = useParams();

  const submitForm = (data) => {
    setIsLoading(true);
    const requestBody = {
      ...data,
      length: +data.length,
      width: +data.width,
      height: +data.height,
    };

    if (type === "create") {
      dispatch(
        createOrdersDataAction({
          field: 'units',
          data: requestBody,
          onSuccess: () => history.push("/dashboard/orders/units"),
        })
      );
    } else {
      if (!id) return;
      dispatch(
        updateOrdersDataAction({
          field: 'units',
          data: {
            id,
            data: requestBody,
          },
          onSuccess: () => setIsLoading(false),
        })
      );
    }
  };

  return (
    <div>
      <Col md={12}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Card className="p-3">

            <Row className="gx-4 gy-2">

              <Col md={6}>
                <CustomLabel for="inputName">Code</CustomLabel>
                <input
                  {...register("code")}
                  className="form-control"
                  id="inputName"
                />
                {errorMessage(errors, "code")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputName">Name</CustomLabel>
                <input
                  {...register("name")}
                  className="form-control"
                  id="inputName"
                />
                {errorMessage(errors, "name")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputName">Device width</CustomLabel>
                <input
                  {...register("width")}
                  type="number"
                  className="form-control"
                  id="inputName"
                  step="any"
                />
                {errorMessage(errors, "width")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputName">Device height</CustomLabel>
                <input
                  {...register("height")}
                  type="number"
                  className="form-control"
                  id="inputName"
                  step="any"
                />
                {errorMessage(errors, "height")}
              </Col>

              <Col md={6}>
                <CustomLabel for="inputName">Device length</CustomLabel>
                <input
                  {...register("length")}
                  type="number"
                  className="form-control"
                  id="inputName"
                  step="any"
                />
                {errorMessage(errors, "length")}
              </Col>

              <Col md={6} className="d-flex align-items-center">
                <div className="form-check form-switch mt-4">
                  <input 
                    {...register('status')} 
                    className="form-check-input fs-16" 
                    type="checkbox" 
                    role="switch" 
                    id="status" 
                  />
                  <CustomLabel 
                    className="form-check-label" 
                    htmlFor="status"
                  >
                    Active
                  </CustomLabel>
                </div>
              </Col>

            </Row>
          </Card>

          <div className="d-flex justify-content-end">
            <SaveButton
              isLoading={isLoading}
            />
          </div>

        </form>
      </Col>
    </div>
  );
};

export default OrderUnitForm;
