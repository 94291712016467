export const getUsersCSVData = ({users}) => {
    if (!users) return [];
    
    return users?.map((user) => {
        return {
          Matricule: user.profile?.matricule || '####',
          Company: user.profile?.company || '####',
          Name: `${user.first_name} ${user.last_name}`,
          Telephone: user.phone || '####',
          Zone: user.profile?.region?.name || '####',
          Email: user.email || '####',
          Groups: user.profile?.user_group?.name || '####',
        }
      }) || []
}