import { useQueries, useQuery } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CustomButton from "../../../Components/Common/CustomButton";
import NewOrderModal from "../../../Components/Orders/Orders/CreateOrder/Modal";
import toastAlert from "../../../helpers/toastAlert";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import { useDispatch } from "react-redux";
import usersApi from "../../../apis/users/api";
import {
  calculatePrices,
  calculatePercentage,
} from "../../../helpers/calculateOrderPrices";
import ordersApi from "../../../apis/orders/api";
import PriceOfferDetails from "../../../Components/Orders/PriceOffers/PriceOfferDetails";
import PriceOfferProducts from "../../../Components/Orders/PriceOffers/PriceOfferProducts";
import CopyPriceOfferModal from "../../../Components/Orders/PriceOffers/CopyPriceOfferModal";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import { getAllProductsDataAction } from "../../../redux/actions/products";
import PageContainer from "../../../Components/Layout/PageContainer";

const UpdatePriceOffer = () => {

  const { id } = useParams();

  const { t } = useTranslation();

  const [copyModal, setCopyModal] = useState(false);

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState({
    purchasePrice: true,
  });

  const [userDiscounts, setUserDiscounts] = useState(null);

  const [priceOffer, setPriceOffer] = useState(null);

  const [note, setNote] = useState("");

  const dispatch = useDispatch();

  const [{ isLoading: loadingPriceOffer, refetch: refetchPriceOffer }, { refetch: refetchUserDiscounts }] = useQueries({
    queries: [
      {
        cacheTime: 0,
        queryKey: ["price-offer", id],
        queryFn: () => ordersApi.priceOffers.getOne(id),
        onSuccess({ data: { data } }) {
          setPriceOffer(data);
        },
      },
      {
        cacheTime: 0,
        queryKey: ["user_discounts"],
        enabled: false,
        queryFn: () => usersApi.users.discounts.getAll({ 
          user_id:  priceOffer?.user?.id,
          for: 'order'
        }),
        onSuccess({ data: { data } }) {
          setUserDiscounts(data);
        },
      },
    ],
  })

  const { create, modalType, toggleModal: toggleMakeOrderModal, isOpen: makeOrderModal } = useCreateUpdateModal({
    user_company_id: "",
    user_company_name: "",
    delivery_type_id: "",
    delivery_id: "",
    delivered_at: "",
  });

  const toggleVisibleColumns = () => {
    setVisibleColumns({
      ...visibleColumns,
      purchasePrice: !visibleColumns.purchasePrice,
    });
    localStorage.setItem("showPurchasePrice", !visibleColumns.purchasePrice);
  };

  const updateOffer = async () => {
    try {
      await ordersApi.priceOffers.update(id, { note });
      toastAlert({ type: 'success'})
    } catch (error) {
      toastAlert({ type: 'error' })
    }
  };

  useEffect(() => {
    if (priceOffer) {
      setNote(priceOffer.note);
    }
    if (priceOffer) {
      refetchUserDiscounts();
    }
  }, [priceOffer]);

  useEffect(() => {
    const showPurchasePrice = JSON.parse(
      localStorage.getItem("showPurchasePrice")
    );
    setVisibleColumns({
      ...visibleColumns,
      purchasePrice: showPurchasePrice || false,
    });
  }, []);

  useEffect(() => {
    dispatch(getAllProductsDataAction({
      field: 'locations'
    }));
    dispatch(getAllProductsDataAction({
      field: 'vats'
    }));
  }, [dispatch]);

  return (
      <PageContainer isLoading={loadingPriceOffer || userDiscounts === null}>
        
        <BreadCrumb
          to="/dashboard/orders/price-offers"
          title={t("update")}
          pageTitle="Price Offers"
        />

        {priceOffer && (
          <>

            <Row className="justify-content-end">
              <Col md={12}>

                <PriceOfferDetails
                  refetchPriceOffer={refetchPriceOffer}
                  priceOffer={priceOffer}
                  calculatePrices={calculatePrices}
                  calculatePercentage={calculatePercentage}
                />

                <PriceOfferProducts
                  refetchPriceOffer={refetchPriceOffer}
                  priceOffer={priceOffer}
                  items={priceOffer?.items || []}
                  visibleColumns={visibleColumns}
                  toggleVisibleColumns={toggleVisibleColumns}
                  userDiscounts={userDiscounts}
                  refetchUserDiscounts={refetchUserDiscounts}
                  index={1}
                />

              </Col>
            </Row>

            <Row className="mb-4 mt-4 justify-content-end">
              <Col lg={12}>
                <div>
                  
                  <div className="d-flex justify-content-between mb-3">

                    <CustomLabel className="form-label">
                      {t("Note")}
                    </CustomLabel>

                    <button
                      onClick={updateOffer}
                      type="button"
                      className={`btn btn-sm btn-secondary`}
                    >
                      <i className="ri-save-2-line cursor-pointer fs-16"></i>
                    </button>
                  </div>
                  
                  <textarea
                    className="form-control"
                    rows={5}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />

                </div>
              </Col>
              <Col lg={12} className="mt-2 d-flex gap-2 justify-content-end">
                
                <CustomButton
                  type="button"
                  className="btn btn-success"
                  onClick={() => create({
                    user_company_id: priceOffer?.user?.id,
                    user_company_name: priceOffer?.user?.profile?.company || `${priceOffer?.user?.first_name} ${priceOffer?.user?.last_name}`,
                    newsletter_id: priceOffer?.id,
                    delivery_type_id: "",
                    delivery_id: "",
                    delivered_at: "",
                  })}
                >
                  {t("Make An Order")}
                </CustomButton>

                <CustomButton
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setCopyModal(true)}
                >
                  {t("Copy")}
                </CustomButton>

                <CustomButton
                  type="button"
                  className="btn btn-info"
                  isLoading={isSendingEmail}
                  onClick={async () => {
                    try {
                      setIsSendingEmail(true);
                      await ordersApi.priceOffers.sendToEmail(priceOffer.id);
                      toastAlert({ type: 'success'})
                    } catch (error) {
                      toastAlert({ type: 'error'})
                    } finally {
                      setIsSendingEmail(false);
                    }
                  }}
                >
                  Send Email
                </CustomButton>

              </Col>

              <Col md={4} className="mt-3">
                <Card>
                  <CardBody className="d-flex flex-column gap-3">
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("NET")}</span>
                      <p className="fs-20 font-weight-bold">
                        {calculatePrices({ order: priceOffer }).netPrice}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between border-bottom">
                      <span>{t("Discount")}</span>
                      <p className="fs-20 font-weight-bold">
                        {
                          calculatePrices({ order: priceOffer }).discountPrice
                        }
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                      <span>{t("Total")}</span>
                      <p className="fs-3 font-weight-bold">
                        {
                          calculatePrices({ order: priceOffer }).sellingPrice
                        }
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <CopyPriceOfferModal
              isOpen={copyModal}
              setIsOpen={setCopyModal}
              priceOfferId={priceOffer.id}
            />

            <NewOrderModal
              modalOpen={makeOrderModal}
              toggleModal={toggleMakeOrderModal}
              modalType={modalType}
              whatFor="makeOrder"
            />
            
          </>
        )}
      </PageContainer>
  );
};

export default UpdatePriceOffer;
