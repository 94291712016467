import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

const CustomLabel = ({ children, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Label {...rest}>
      {typeof children === "string" ? t(children) : children}
    </Label>
  );
};

export default CustomLabel;
