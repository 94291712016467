import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthProvider from "./context/authContext";
import AppWrapper from "./Components/Wrappers/AppWrapper";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <AuthProvider>
      <Provider store={store}>
        <React.Fragment>
          <QueryClientProvider client={queryClient}>
            <AppWrapper>
                <App />
            </AppWrapper>
          </QueryClientProvider>
        </React.Fragment>
      </Provider>
    </AuthProvider>
  </BrowserRouter>
);
